import { HttpClient } from '@angular/common/http';
import { shareReplay } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class CqrsService {
  constructor(http) {
    this.http = http;
  }
  command(command, data) {
    return this.http.post(`api:///command/${command}`, data).pipe(shareReplay(1));
  }
  query(query, data) {
    return this.http.post(`api:///query/${query}`, data).pipe(shareReplay(1));
  }
}
CqrsService.ɵfac = function CqrsService_Factory(t) {
  return new (t || CqrsService)(i0.ɵɵinject(i1.HttpClient));
};
CqrsService.ɵprov = /*@__PURE__*/i0.ɵɵdefineInjectable({
  token: CqrsService,
  factory: CqrsService.ɵfac,
  providedIn: 'root'
});