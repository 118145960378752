import * as i0 from "@angular/core";
import * as i1 from "@angular/forms";
import * as i2 from "@progress/kendo-angular-grid";
import * as i3 from "../app-control/app-control.component";
import * as i4 from "../editable-grid/editable-grid.component";
import * as i5 from "@angular/common";
import * as i6 from "@ngx-translate/core";
import * as i7 from "../../pipes/codelist.pipe";
function NoteListComponent_ng_template_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtext(0);
    i0.ɵɵpipe(1, "async");
    i0.ɵɵpipe(2, "codelist");
  }
  if (rf & 2) {
    const dataItem_r3 = ctx.$implicit;
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind1(1, 1, i0.ɵɵpipeBind2(2, 3, dataItem_r3.createdById, "User")), " ");
  }
}
function NoteListComponent_ng_template_10_Template(rf, ctx) {
  if (rf & 1) {
    const _r6 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "app-control", 7);
    i0.ɵɵlistener("ngModelChange", function NoteListComponent_ng_template_10_Template_app_control_ngModelChange_0_listener($event) {
      const restoredCtx = i0.ɵɵrestoreView(_r6);
      const dataItem_r4 = restoredCtx.dataItem;
      return i0.ɵɵresetView(dataItem_r4.content = $event);
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const dataItem_r4 = ctx.dataItem;
    i0.ɵɵproperty("ngModel", dataItem_r4.content)("entity", dataItem_r4);
  }
}
function NoteListComponent_ng_template_11_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtext(0);
  }
  if (rf & 2) {
    const dataItem_r7 = ctx.$implicit;
    i0.ɵɵtextInterpolate1(" ", dataItem_r7.content, " ");
  }
}
export class NoteListComponent {
  get entityName() {
    return `${this.items.parentEntity.entityType.shortName}Note`;
  }
}
NoteListComponent.ɵfac = function NoteListComponent_Factory(t) {
  return new (t || NoteListComponent)();
};
NoteListComponent.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
  type: NoteListComponent,
  selectors: [["app-note-list"]],
  inputs: {
    items: "items",
    editMode: "editMode"
  },
  decls: 12,
  vars: 15,
  consts: [[3, "entityName", "data", "editMode"], ["field", "createdBy.fullName", 3, "title"], ["kendoGridCellTemplate", ""], ["field", "createdDate", "format", "g", 3, "title"], ["field", "lastModifiedDate", "format", "g", 3, "title"], ["field", "content", 3, "title"], ["kendoGridEditTemplate", ""], ["type", "textarea", "property", "content", 3, "ngModel", "entity", "ngModelChange"]],
  template: function NoteListComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "app-editable-grid", 0)(1, "kendo-grid-column", 1);
      i0.ɵɵpipe(2, "translate");
      i0.ɵɵtemplate(3, NoteListComponent_ng_template_3_Template, 3, 6, "ng-template", 2);
      i0.ɵɵelementEnd();
      i0.ɵɵelement(4, "kendo-grid-column", 3);
      i0.ɵɵpipe(5, "translate");
      i0.ɵɵelement(6, "kendo-grid-column", 4);
      i0.ɵɵpipe(7, "translate");
      i0.ɵɵelementStart(8, "kendo-grid-column", 5);
      i0.ɵɵpipe(9, "translate");
      i0.ɵɵtemplate(10, NoteListComponent_ng_template_10_Template, 1, 2, "ng-template", 6);
      i0.ɵɵtemplate(11, NoteListComponent_ng_template_11_Template, 1, 1, "ng-template", 2);
      i0.ɵɵelementEnd()();
    }
    if (rf & 2) {
      i0.ɵɵpropertyInterpolate("entityName", ctx.entityName);
      i0.ɵɵproperty("data", ctx.items)("editMode", ctx.editMode);
      i0.ɵɵadvance(1);
      i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(2, 7, "Created By"));
      i0.ɵɵadvance(3);
      i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(5, 9, "Created Date"));
      i0.ɵɵadvance(2);
      i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(7, 11, "Last Modified Date"));
      i0.ɵɵadvance(2);
      i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(9, 13, "Note Content"));
    }
  },
  dependencies: [i1.NgControlStatus, i1.NgModel, i2.ColumnComponent, i2.CellTemplateDirective, i2.EditTemplateDirective, i3.AppControlComponent, i4.EditableGridComponent, i5.AsyncPipe, i6.TranslatePipe, i7.CodelistPipe],
  styles: ["\n/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IiIsInNvdXJjZVJvb3QiOiIifQ== */"]
});