import { HttpClient } from '@angular/common/http';
import { Injector } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EntityManager, EntityQuery } from '@cime/breeze-client';
import { DialogErrorHandler } from '@common/handlers/dialog-error-handler';
import { ViewMode } from '@common/models/view-mode';
import { BreezeService } from '@common/services/breeze.service';
import { CqrsService } from '@common/services/cqrs.service';
import { environment } from '@environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { firstValueFrom } from 'rxjs';
import { ConsoleWindowService } from './console-window.service';
import { DialogService } from './dialog.service';
import { UserService } from './user.service';
import * as i0 from "@angular/core";
import * as i1 from "@cime/breeze-client";
import * as i2 from "ngx-toastr";
import * as i3 from "./dialog.service";
import * as i4 from "@ng-bootstrap/ng-bootstrap";
import * as i5 from "@ngx-translate/core";
import * as i6 from "@angular/router";
import * as i7 from "@common/services/breeze.service";
import * as i8 from "./user.service";
import * as i9 from "./console-window.service";
import * as i10 from "@angular/common/http";
import * as i11 from "@common/services/cqrs.service";
export class BreezeViewService {
  constructor(entityManager, toastrService, dialogService, modalService, translateService, router, activatedRoute, breezeService, userService, consoleWindowService, httpClient, cqrs, injector) {
    this.toastrService = toastrService;
    this.dialogService = dialogService;
    this.modalService = modalService;
    this.translateService = translateService;
    this.router = router;
    this.activatedRoute = activatedRoute;
    this.breezeService = breezeService;
    this.userService = userService;
    this.consoleWindowService = consoleWindowService;
    this.httpClient = httpClient;
    this.cqrs = cqrs;
    this.injector = injector;
    this.currency = environment.settings.currency;
    this.entityManager = entityManager.createEmptyCopy();
  }
  isViewMode() {
    return this.activatedRoute.snapshot.data.mode === ViewMode.view;
  }
  isEditMode() {
    return !this.isViewMode();
  }
  openModal(content, options = {}) {
    return this.modalService.open(content, {
      ...options,
      injector: this.injector
    });
  }
  handleQuery(queryName, postData = {}, params = {}) {
    const query = new EntityQuery(queryName).using(params.entityManager || this.entityManager).withParameters({
      $method: 'POST',
      $data: postData
    });
    DialogErrorHandler.openErrorModal = false;
    return query.execute().then(response => {
      return response.results;
    }).catch(e => {
      console.error(e);
      this.toastrService.error(this.translateService.instant('An error occured while saving data'));
    }).finally(() => {
      DialogErrorHandler.openErrorModal = true;
    });
  }
  handleCommand(commandName, data, params = {}) {
    DialogErrorHandler.openErrorModal = false;
    const promise = firstValueFrom(this.cqrs.command(commandName, data)).then(result => {
      if (!result || result?.length === 0) this.toastrService.success(this.translateService.instant('Action successful'));
      return result;
    }).finally(() => DialogErrorHandler.openErrorModal = true);
    if (!params.skipCatch) {
      promise.catch(e => {
        console.error(e);
        this.translateService.instant('An error occured while saving data');
        if (!!e.error?.errorMessage) return e.error.errorMessage;
      });
    }
    return promise;
  }
}
BreezeViewService.ɵfac = function BreezeViewService_Factory(t) {
  return new (t || BreezeViewService)(i0.ɵɵinject(i1.EntityManager), i0.ɵɵinject(i2.ToastrService), i0.ɵɵinject(i3.DialogService), i0.ɵɵinject(i4.NgbModal), i0.ɵɵinject(i5.TranslateService), i0.ɵɵinject(i6.Router), i0.ɵɵinject(i6.ActivatedRoute), i0.ɵɵinject(i7.BreezeService), i0.ɵɵinject(i8.UserService), i0.ɵɵinject(i9.ConsoleWindowService), i0.ɵɵinject(i10.HttpClient), i0.ɵɵinject(i11.CqrsService), i0.ɵɵinject(i0.Injector));
};
BreezeViewService.ɵprov = /*@__PURE__*/i0.ɵɵdefineInjectable({
  token: BreezeViewService,
  factory: BreezeViewService.ɵfac
});