import { AdministrationModule } from '@administration/administration.module';
import { UnauthorizedComponent } from '@common/components/unauthorized/unauthorized.component';
import { InvoiceModule } from '@invoice/invoice.module';
import { PriceListModule } from '@price-list/price-list.module';
import { VesselVisitModule } from '@vessel-visit/vessel-visit.module';
import { CodeListModule } from './code-list/code-list.module';
import { VesselRevisionModule } from './code-list/vessel-revision/vessel-revision.module';
import { LoginComponent } from './common/components/login/login.component';
import { LogoutComponent } from './common/components/login/logout.component';
import { AuthGuard } from './common/guards/auth.guard';
import { CustomerModule } from './customer/customer.module';
import { HelpModule } from './help/help.module';
import { HomeComponent } from './home/home.component';
import { MswVesselVisitModule } from './msw-vessel-visit/msw-vessel-visit.module';
import { ProfileModule } from './profile/profile.module';
import { VesselPermitModule } from './vessel-permit/vessel-permit.module';
import { VtsVesselVisitModule } from './vts-vessel-visit/vts-vessel-visit.module';
export const ROUTES = [{
  path: 'login',
  component: LoginComponent
}, {
  path: '',
  canActivate: [AuthGuard],
  canActivateChild: [AuthGuard],
  children: [{
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  }, {
    path: '401',
    component: UnauthorizedComponent
  }, {
    path: 'logout',
    component: LogoutComponent
  }, {
    path: 'invoice',
    loadChildren: () => InvoiceModule,
    data: {
      title: 'Invoices'
    }
  }, {
    path: 'vessel-visit',
    loadChildren: () => VesselVisitModule,
    data: {
      title: 'Visits'
    }
  }, {
    path: 'msw-vessel-visit',
    loadChildren: () => MswVesselVisitModule,
    data: {
      title: 'MSW Visits'
    }
  }, {
    path: 'vts-vessel-visit',
    loadChildren: () => VtsVesselVisitModule,
    data: {
      title: 'VTS Visits'
    }
  }, {
    path: 'vessel-permit',
    loadChildren: () => VesselPermitModule,
    data: {
      title: 'Permits'
    }
  }, {
    path: 'vessel-revision',
    loadChildren: () => VesselRevisionModule,
    data: {
      title: 'Vessel Revision'
    }
  }, {
    path: 'customer',
    loadChildren: () => CustomerModule,
    data: {
      title: 'Customers'
    }
  }, {
    path: 'price-list',
    loadChildren: () => PriceListModule,
    data: {
      title: 'Price list'
    }
  }, {
    path: 'code-list',
    loadChildren: () => CodeListModule,
    data: {
      title: 'Code list'
    }
  }, {
    path: 'administration',
    loadChildren: () => AdministrationModule,
    data: {
      title: 'Administration'
    }
  }, {
    path: 'help',
    loadChildren: () => HelpModule,
    data: {
      title: 'Help'
    }
  }, {
    path: 'profile',
    loadChildren: () => ProfileModule,
    data: {
      title: 'Profile'
    }
  }, {
    path: 'home',
    component: HomeComponent
  }, {
    path: '**',
    redirectTo: '/home'
  }]
}];