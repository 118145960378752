import { EntityQuery } from '@cime/breeze-client';
import { AbstractBreezeViewComponent } from '@common/classes/breeze-view';
import { BreezeViewService } from '@common/services/breeze-view.service';
import * as i0 from "@angular/core";
import * as i1 from "@common/services/breeze-view.service";
import * as i2 from "@angular/common";
import * as i3 from "@angular/forms";
import * as i4 from "@fortawesome/angular-fontawesome";
import * as i5 from "@progress/kendo-angular-grid";
import * as i6 from "@progress/kendo-angular-layout";
import * as i7 from "../../../../common/components/action-bar/action-bar.component";
import * as i8 from "../../../../common/components/app-control/app-control.component";
import * as i9 from "../../../../common/components/loader/loader.component";
import * as i10 from "../../../../common/components/content-header/content-header.component";
import * as i11 from "../../../../common/components/app-grid/app-grid.component";
import * as i12 from "@ngx-translate/core";
const _c0 = function () {
  return ["fas", "plus"];
};
function RoleEditComponent_ng_template_1_ng_template_13_div_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r7 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 8)(1, "div", 17);
    i0.ɵɵelement(2, "app-control", 18, 19);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(4, "div", 20)(5, "button", 21);
    i0.ɵɵlistener("click", function RoleEditComponent_ng_template_1_ng_template_13_div_0_Template_button_click_5_listener() {
      i0.ɵɵrestoreView(_r7);
      const _r5 = i0.ɵɵreference(3);
      const ctx_r6 = i0.ɵɵnextContext(3);
      return i0.ɵɵresetView(ctx_r6.addPermission(_r5.value, ctx_r6.model.role));
    });
    i0.ɵɵelement(6, "fa-icon", 22);
    i0.ɵɵelementEnd()()();
  }
  if (rf & 2) {
    const _r5 = i0.ɵɵreference(3);
    const ctx_r2 = i0.ɵɵnextContext(3);
    i0.ɵɵadvance(5);
    i0.ɵɵproperty("disabled", !_r5.value || ctx_r2.permissionExists(_r5.value));
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("icon", i0.ɵɵpureFunction0(2, _c0));
  }
}
function RoleEditComponent_ng_template_1_ng_template_13_ng_template_6_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span");
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const row_r8 = ctx.$implicit;
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate3(" ", row_r8.permission.module, ".", row_r8.permission.namespace, ".", row_r8.permission.name, " ");
  }
}
const _c1 = function () {
  return ["fas", "trash"];
};
function RoleEditComponent_ng_template_1_ng_template_13_kendo_grid_column_7_ng_template_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r12 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 24);
    i0.ɵɵlistener("click", function RoleEditComponent_ng_template_1_ng_template_13_kendo_grid_column_7_ng_template_1_Template_button_click_0_listener() {
      const restoredCtx = i0.ɵɵrestoreView(_r12);
      const row_r10 = restoredCtx.$implicit;
      const ctx_r11 = i0.ɵɵnextContext(4);
      return i0.ɵɵresetView(ctx_r11.removePermission(row_r10));
    });
    i0.ɵɵelement(1, "fa-icon", 22);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r9 = i0.ɵɵnextContext(4);
    i0.ɵɵproperty("disabled", !ctx_r9.breezeViewService.isEditMode());
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("icon", i0.ɵɵpureFunction0(2, _c1));
  }
}
function RoleEditComponent_ng_template_1_ng_template_13_kendo_grid_column_7_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "kendo-grid-column", 23);
    i0.ɵɵtemplate(1, RoleEditComponent_ng_template_1_ng_template_13_kendo_grid_column_7_ng_template_1_Template, 2, 3, "ng-template", 15);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    i0.ɵɵproperty("width", 1);
  }
}
function RoleEditComponent_ng_template_1_ng_template_13_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, RoleEditComponent_ng_template_1_ng_template_13_div_0_Template, 7, 3, "div", 12);
    i0.ɵɵelementStart(1, "div", 8)(2, "div", 9)(3, "app-grid", 13)(4, "kendo-grid-column", 14);
    i0.ɵɵpipe(5, "translate");
    i0.ɵɵtemplate(6, RoleEditComponent_ng_template_1_ng_template_13_ng_template_6_Template, 2, 3, "ng-template", 15);
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(7, RoleEditComponent_ng_template_1_ng_template_13_kendo_grid_column_7_Template, 2, 1, "kendo-grid-column", 16);
    i0.ɵɵelementEnd()()();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("ngIf", ctx_r1.editMode);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("data", ctx_r1.model.permissions);
    i0.ɵɵadvance(1);
    i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(5, 4, "Name"));
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("ngIf", ctx_r1.editMode);
  }
}
function RoleEditComponent_ng_template_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r14 = i0.ɵɵgetCurrentView();
    i0.ɵɵelement(0, "app-action-bar", 1)(1, "app-content-header", 2);
    i0.ɵɵelementStart(2, "div", 3)(3, "div", 4)(4, "div", 5)(5, "div", 6)(6, "app-control", 7);
    i0.ɵɵlistener("ngModelChange", function RoleEditComponent_ng_template_1_Template_app_control_ngModelChange_6_listener($event) {
      i0.ɵɵrestoreView(_r14);
      const ctx_r13 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r13.model.name = $event);
    });
    i0.ɵɵpipe(7, "translate");
    i0.ɵɵelementEnd()()()()();
    i0.ɵɵelementStart(8, "div", 8)(9, "div", 9)(10, "kendo-tabstrip")(11, "kendo-tabstrip-tab", 10);
    i0.ɵɵpipe(12, "translate");
    i0.ɵɵtemplate(13, RoleEditComponent_ng_template_1_ng_template_13_Template, 8, 6, "ng-template", 11);
    i0.ɵɵelementEnd()()()();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("items", ctx_r0.actionBar)("collapsed", false);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("title", ctx_r0.title)("breadcrumb", ctx_r0.breadcrumb);
    i0.ɵɵadvance(5);
    i0.ɵɵproperty("label", i0.ɵɵpipeBind1(7, 9, "Name"))("ngModel", ctx_r0.model.name)("entity", ctx_r0.model);
    i0.ɵɵadvance(5);
    i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(12, 11, "Effective Permissions"));
    i0.ɵɵproperty("selected", true);
  }
}
export class RoleEditComponent extends AbstractBreezeViewComponent {
  constructor(breezeViewService) {
    super(breezeViewService);
    this.entityName = 'Role';
    this.parentRoute = '/administration/role';
    this.breadcrumb = [{
      icon: 'user-plus',
      title: this.translateService.get('Roles'),
      route: '/administration/role/list'
    }, {
      icon: 'user-plus',
      title: this.translateService.get('Role Edit')
    }];
  }
  addPermission(permissionId, role) {
    const rolePermission = this.entityManager.createEntity('RolePermission', {
      permissionId: permissionId,
      role: role,
      user: this.model
    });
    if (!rolePermission.permission) {
      new EntityQuery('Permission').withParameters({
        id: permissionId
      }).using(this.entityManager).execute().then(() => {
        this.model.permissions.push(rolePermission);
      });
    } else {
      this.model.permissions.push(rolePermission);
    }
  }
  permissionExists(permission) {
    return this.model.permissions.some(perm => perm.permissionId === permission);
  }
  removePermission(permission) {
    permission.entityAspect.setDeleted();
  }
  beforeSave() {
    this.model.normalizedName = this.model.name;
  }
}
RoleEditComponent.ɵfac = function RoleEditComponent_Factory(t) {
  return new (t || RoleEditComponent)(i0.ɵɵdirectiveInject(i1.BreezeViewService));
};
RoleEditComponent.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
  type: RoleEditComponent,
  selectors: [["app-role-edit"]],
  features: [i0.ɵɵProvidersFeature([BreezeViewService]), i0.ɵɵInheritDefinitionFeature],
  decls: 2,
  vars: 1,
  consts: [[3, "isBusy"], [3, "items", "collapsed"], [3, "title", "breadcrumb"], [1, "card", "card-border-top"], [1, "card-body"], [1, "row"], [1, "col-4"], ["property", "name", 3, "label", "ngModel", "entity", "ngModelChange"], [1, "row", "mt-3"], [1, "col"], [3, "title", "selected"], ["kendoTabContent", ""], ["class", "row mt-3", 4, "ngIf"], [1, "mb-2", 3, "data"], ["field", "id", 3, "title"], ["kendoGridCellTemplate", ""], ["field", "id", "title", "", 3, "width", 4, "ngIf"], [1, "col-3"], ["type", "codelist", "codelist", "Permission"], ["selectedPermission", ""], [1, "col-auto"], [1, "btn", "btn-success", "btn-sm", "float-right", 3, "disabled", "click"], [3, "icon"], ["field", "id", "title", "", 3, "width"], [1, "btn", "btn-danger", "btn-xs", 3, "disabled", "click"]],
  template: function RoleEditComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "app-loader", 0);
      i0.ɵɵtemplate(1, RoleEditComponent_ng_template_1_Template, 14, 13, "ng-template");
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      i0.ɵɵproperty("isBusy", !ctx.model);
    }
  },
  dependencies: [i2.NgIf, i3.NgControlStatus, i3.NgModel, i4.FaIconComponent, i5.ColumnComponent, i5.CellTemplateDirective, i6.TabStripComponent, i6.TabStripTabComponent, i6.TabContentDirective, i7.ActionBarComponent, i8.AppControlComponent, i9.LoaderComponent, i10.ContentHeaderComponent, i11.AppGridComponent, i12.TranslatePipe],
  styles: ["\n/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IiIsInNvdXJjZVJvb3QiOiIifQ== */"]
});