import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';
import * as i0 from "@angular/core";
import * as i1 from "@progress/kendo-angular-dialog";
import * as i2 from "@angular/common";
import * as i3 from "@angular/forms";
import * as i4 from "@ngx-translate/core";
import * as i5 from "@progress/kendo-angular-buttons";
import * as i6 from "../app-control/app-control.component";
function DatePickerComponent_div_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "div", 8);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("innerHTML", ctx_r0.message, i0.ɵɵsanitizeHtml);
  }
}
export class DatePickerComponent extends DialogContentBase {
  constructor(dialog) {
    super(dialog);
    this.dialog = dialog;
    this.isBusy = false;
  }
  ok() {
    this.dialog.close({
      success: true,
      date: this.date
    });
  }
  close() {
    this.dialog.close({
      success: false
    });
  }
}
DatePickerComponent.ɵfac = function DatePickerComponent_Factory(t) {
  return new (t || DatePickerComponent)(i0.ɵɵdirectiveInject(i1.DialogRef));
};
DatePickerComponent.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
  type: DatePickerComponent,
  selectors: [["app-date-picker"]],
  inputs: {
    date: "date",
    title: "title",
    message: "message"
  },
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 13,
  vars: 11,
  consts: [[2, "font-size", "18px", "line-height", "1.3em"], ["class", "m-5 text-center", 3, "innerHTML", 4, "ngIf"], [1, "row"], [1, "col"], ["type", "datetime", "property", "date", 3, "time", "label", "isDisabled", "ngModel", "entity", "ngModelChange"], [3, "layout"], ["kendoButton", "", "translate", "", 3, "disabled", "click"], ["kendoButton", "", "primary", "true", "translate", "", 3, "disabled", "click"], [1, "m-5", "text-center", 3, "innerHTML"]],
  template: function DatePickerComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "kendo-dialog-titlebar")(1, "div", 0);
      i0.ɵɵtext(2);
      i0.ɵɵelementEnd()();
      i0.ɵɵtemplate(3, DatePickerComponent_div_3_Template, 1, 1, "div", 1);
      i0.ɵɵelementStart(4, "div", 2)(5, "div", 3)(6, "app-control", 4);
      i0.ɵɵlistener("ngModelChange", function DatePickerComponent_Template_app_control_ngModelChange_6_listener($event) {
        return ctx.date = $event;
      });
      i0.ɵɵpipe(7, "translate");
      i0.ɵɵelementEnd()()();
      i0.ɵɵelementStart(8, "kendo-dialog-actions", 5)(9, "button", 6);
      i0.ɵɵlistener("click", function DatePickerComponent_Template_button_click_9_listener() {
        return ctx.close();
      });
      i0.ɵɵtext(10, "Cancel");
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(11, "button", 7);
      i0.ɵɵlistener("click", function DatePickerComponent_Template_button_click_11_listener() {
        return ctx.ok();
      });
      i0.ɵɵtext(12, "Ok");
      i0.ɵɵelementEnd()();
    }
    if (rf & 2) {
      i0.ɵɵadvance(2);
      i0.ɵɵtextInterpolate(ctx.title);
      i0.ɵɵadvance(1);
      i0.ɵɵproperty("ngIf", ctx.message);
      i0.ɵɵadvance(3);
      i0.ɵɵproperty("time", true)("label", i0.ɵɵpipeBind1(7, 9, "Date"))("isDisabled", false)("ngModel", ctx.date);
      i0.ɵɵadvance(2);
      i0.ɵɵproperty("layout", "normal");
      i0.ɵɵadvance(1);
      i0.ɵɵproperty("disabled", ctx.isBusy);
      i0.ɵɵadvance(2);
      i0.ɵɵproperty("disabled", ctx.isBusy);
    }
  },
  dependencies: [i2.NgIf, i3.NgControlStatus, i3.NgModel, i4.TranslateDirective, i5.Button, i1.DialogTitleBarComponent, i1.DialogActionsComponent, i6.AppControlComponent, i4.TranslatePipe],
  styles: ["\n/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IiIsInNvdXJjZVJvb3QiOiIifQ== */"]
});