import { StaticProvider } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
export class ConsoleWindowService {
  constructor() {
    this.opened = false;
    this.componentSubject = new BehaviorSubject(null);
  }
  initialize(consoleSplitter) {
    this.consoleSplitter = consoleSplitter;
  }
  open(component, dataProviders) {
    this.opened = true;
    // set the component and data providers that will be used by the console-content directive
    this.componentSubject.next({
      component,
      dataProviders
    });
  }
  close() {
    this.opened = false;
    this.componentSubject.next(null);
  }
}
ConsoleWindowService.ɵfac = function ConsoleWindowService_Factory(t) {
  return new (t || ConsoleWindowService)();
};
ConsoleWindowService.ɵprov = /*@__PURE__*/i0.ɵɵdefineInjectable({
  token: ConsoleWindowService,
  factory: ConsoleWindowService.ɵfac,
  providedIn: 'root'
});