import { BreezeViewService } from '@common/services/breeze-view.service';
import * as i0 from "@angular/core";
import * as i1 from "@common/services/breeze-view.service";
import * as i2 from "@angular/common";
import * as i3 from "@angular/forms";
import * as i4 from "@fortawesome/angular-fontawesome";
import * as i5 from "@progress/kendo-angular-grid";
import * as i6 from "../../../common/components/app-control/app-control.component";
import * as i7 from "../../../common/components/app-grid/app-grid.component";
import * as i8 from "@ngx-translate/core";
function InvoiceEmailRecipientComponent_ng_template_3_Template(rf, ctx) {
  if (rf & 1) {
    const _r4 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "app-control", 4);
    i0.ɵɵlistener("ngModelChange", function InvoiceEmailRecipientComponent_ng_template_3_Template_app_control_ngModelChange_0_listener($event) {
      const restoredCtx = i0.ɵɵrestoreView(_r4);
      const row_r2 = restoredCtx.$implicit;
      return i0.ɵɵresetView(row_r2.email = $event);
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const row_r2 = ctx.$implicit;
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("isDisabled", ctx_r0.isDisabled)("ngModel", row_r2.email)("entity", row_r2);
  }
}
const _c0 = function () {
  return ["fas", "trash"];
};
function InvoiceEmailRecipientComponent_kendo_grid_column_4_ng_template_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r9 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 7);
    i0.ɵɵlistener("click", function InvoiceEmailRecipientComponent_kendo_grid_column_4_ng_template_1_Template_button_click_0_listener() {
      const restoredCtx = i0.ɵɵrestoreView(_r9);
      const row_r7 = restoredCtx.$implicit;
      const ctx_r8 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r8.remove(row_r7));
    });
    i0.ɵɵelement(1, "fa-icon", 8);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("icon", i0.ɵɵpureFunction0(1, _c0));
  }
}
const _c1 = function () {
  return ["fas", "plus"];
};
function InvoiceEmailRecipientComponent_kendo_grid_column_4_ng_template_2_Template(rf, ctx) {
  if (rf & 1) {
    const _r11 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 9);
    i0.ɵɵlistener("click", function InvoiceEmailRecipientComponent_kendo_grid_column_4_ng_template_2_Template_button_click_0_listener() {
      i0.ɵɵrestoreView(_r11);
      const ctx_r10 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r10.add());
    });
    i0.ɵɵelement(1, "fa-icon", 8);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("icon", i0.ɵɵpureFunction0(1, _c1));
  }
}
function InvoiceEmailRecipientComponent_kendo_grid_column_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "kendo-grid-column", 5);
    i0.ɵɵtemplate(1, InvoiceEmailRecipientComponent_kendo_grid_column_4_ng_template_1_Template, 2, 2, "ng-template", 2);
    i0.ɵɵtemplate(2, InvoiceEmailRecipientComponent_kendo_grid_column_4_ng_template_2_Template, 2, 2, "ng-template", 6);
    i0.ɵɵelementEnd();
  }
}
export class InvoiceEmailRecipientComponent {
  constructor(breezeViewService) {
    this.breezeViewService = breezeViewService;
  }
  remove(item) {
    item.entityAspect.setDeleted();
  }
  add() {
    this.items.push(this.items.parentEntity.entityAspect.entityManager.createEntity('InvoiceEmailRecipient'));
  }
}
InvoiceEmailRecipientComponent.ɵfac = function InvoiceEmailRecipientComponent_Factory(t) {
  return new (t || InvoiceEmailRecipientComponent)(i0.ɵɵdirectiveInject(i1.BreezeViewService));
};
InvoiceEmailRecipientComponent.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
  type: InvoiceEmailRecipientComponent,
  selectors: [["app-invoice-email-recipient"]],
  inputs: {
    items: "items",
    isDisabled: "isDisabled"
  },
  decls: 5,
  vars: 7,
  consts: [[1, "mb-2", 3, "pageable", "data", "sortable"], ["field", "email", 3, "title"], ["kendoGridCellTemplate", ""], ["title", "", "width", "45px", 4, "ngIf"], ["type", "string", "property", "email", 3, "isDisabled", "ngModel", "entity", "ngModelChange"], ["title", "", "width", "45px"], ["kendoGridHeaderTemplate", ""], [1, "btn", "btn-danger", "btn-xs", 3, "click"], [3, "icon"], [1, "btn", "btn-success", "btn-xs", 3, "click"]],
  template: function InvoiceEmailRecipientComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "app-grid", 0)(1, "kendo-grid-column", 1);
      i0.ɵɵpipe(2, "translate");
      i0.ɵɵtemplate(3, InvoiceEmailRecipientComponent_ng_template_3_Template, 1, 3, "ng-template", 2);
      i0.ɵɵelementEnd();
      i0.ɵɵtemplate(4, InvoiceEmailRecipientComponent_kendo_grid_column_4_Template, 3, 0, "kendo-grid-column", 3);
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      i0.ɵɵproperty("pageable", false)("data", ctx.items)("sortable", true);
      i0.ɵɵadvance(1);
      i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(2, 5, "Email Receivers"));
      i0.ɵɵadvance(3);
      i0.ɵɵproperty("ngIf", !ctx.isDisabled);
    }
  },
  dependencies: [i2.NgIf, i3.NgControlStatus, i3.NgModel, i4.FaIconComponent, i5.ColumnComponent, i5.CellTemplateDirective, i5.HeaderTemplateDirective, i6.AppControlComponent, i7.AppGridComponent, i8.TranslatePipe],
  styles: ["\n/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IiIsInNvdXJjZVJvb3QiOiIifQ== */"]
});