import { RouterModule } from '@angular/router';
import { CanDeactivateView } from '@common/guards/view.deactivate';
import { ViewMode } from '@common/models/view-mode';
import { CommonModule } from '../../common/common.module';
import { VesselListComponent } from './components/vessel-list/vessel-list.component';
import { VesselViewComponent } from './components/vessel-view/vessel-view.component';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
export const VESSEL_ROUTES = [{
  path: '',
  redirectTo: 'list',
  pathMatch: 'full'
}, {
  path: 'list',
  component: VesselListComponent
}, {
  path: 'view/:id',
  component: VesselViewComponent,
  data: {
    mode: ViewMode.view
  }
}, {
  path: 'edit/:id',
  component: VesselViewComponent,
  data: {
    mode: ViewMode.edit
  },
  canDeactivate: [CanDeactivateView]
}, {
  path: 'create',
  component: VesselViewComponent,
  data: {
    mode: ViewMode.create
  },
  canDeactivate: [CanDeactivateView]
}, {
  path: '**',
  redirectTo: 'list'
}];
export class VesselModule {}
VesselModule.ɵfac = function VesselModule_Factory(t) {
  return new (t || VesselModule)();
};
VesselModule.ɵmod = /*@__PURE__*/i0.ɵɵdefineNgModule({
  type: VesselModule
});
VesselModule.ɵinj = /*@__PURE__*/i0.ɵɵdefineInjector({
  providers: [CanDeactivateView],
  imports: [CommonModule, RouterModule.forChild(VESSEL_ROUTES)]
});
(function () {
  (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(VesselModule, {
    declarations: [VesselListComponent, VesselViewComponent],
    imports: [CommonModule, i1.RouterModule]
  });
})();