import { Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "../../services/user.service";
export class LogoutComponent {
  constructor(router, userService) {
    this.router = router;
    this.userService = userService;
    this.userService.logout().then(() => {
      return this.router.navigate(['/login']);
    });
  }
}
LogoutComponent.ɵfac = function LogoutComponent_Factory(t) {
  return new (t || LogoutComponent)(i0.ɵɵdirectiveInject(i1.Router), i0.ɵɵdirectiveInject(i2.UserService));
};
LogoutComponent.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
  type: LogoutComponent,
  selectors: [["ng-component"]],
  decls: 0,
  vars: 0,
  template: function LogoutComponent_Template(rf, ctx) {},
  encapsulation: 2
});