import { environment } from '@environments/environment';
import * as i0 from "@angular/core";
export class RememberStateService {
  constructor() {
    this.rememberState = environment.settings.rememberState;
  }
  initialize(storageKey) {
    const savedState = localStorage.getItem(storageKey.replace(/\/edit|\/view/, ''));
    if (!this.rememberState || !savedState) return;
    if (!savedState.includes(',')) {
      return isNaN(parseInt(savedState, 10)) ? savedState === 'true' : parseInt(savedState, 10);
    }
    return savedState.split(',').map(str => {
      return parseInt(str, 10);
    });
  }
  setState(storageKey, value) {
    storageKey = storageKey.replace(/\/edit|\/view/, '');
    if (typeof value !== 'object') {
      localStorage.setItem(storageKey, value.toString());
    }
    if (value.length === 1) {
      localStorage.setItem(storageKey, `${value},`);
    }
    if (value.length > 1) {
      localStorage.setItem(storageKey, value.join(','));
    }
    if (value.length === 0) {
      localStorage.removeItem(storageKey);
    }
  }
}
RememberStateService.ɵfac = function RememberStateService_Factory(t) {
  return new (t || RememberStateService)();
};
RememberStateService.ɵprov = /*@__PURE__*/i0.ɵɵdefineInjectable({
  token: RememberStateService,
  factory: RememberStateService.ɵfac,
  providedIn: 'root'
});