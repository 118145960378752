import _asyncToGenerator from "C:/GitLab-Runner/builds/VgMjNYji/0/billing/billingmalta/src/frontend/node_modules/@babel/runtime/helpers/esm/asyncToGenerator.js";
import { NavigationProperty } from '@cime/breeze-client';
import { CodelistService } from '@common/services/codelist.service';
import * as i0 from "@angular/core";
import * as i1 from "@common/services/codelist.service";
export class CodelistPipe {
  constructor(codelistService) {
    this.codelistService = codelistService;
  }
  transform(value, ...args) {
    var _this = this;
    return _asyncToGenerator(function* () {
      if (typeof args[0] === 'string') {
        if (!value) {
          return Promise.resolve('-');
        }
        const data = yield _this.codelistService.getCodelist({
          name: args[0],
          selectedIds: [value],
          filter: null,
          take: 1,
          useCache: true
        });
        return _this.getText(data[0], value, args[0]);
      }
      const entity = args[0];
      const property = args[1];
      const {
        navigationProperty,
        dataProperty
      } = _this.getProperties(entity, property);
      if (!navigationProperty || !navigationProperty.entityType) {
        throw new Error(`Property ${property} does not exist on ${entity.entityType.shortName} or is not NavigationProperty`);
      }
      if (!entity || !entity.entityAspect) {
        return Promise.resolve('-');
      }
      const syntheticPropertyName = dataProperty.name;
      const relationEntityType = navigationProperty.entityType.shortName;
      const id = entity[syntheticPropertyName];
      const data = yield _this.codelistService.getCodelist({
        name: relationEntityType,
        selectedIds: [id],
        filter: null,
        take: 1,
        useCache: true
      });
      const item = data.find(item => item.id === entity[syntheticPropertyName] || item.code === entity[syntheticPropertyName]);
      return _this.getText(item, id, relationEntityType);
    })();
  }
  getText(item, id, codelist) {
    if (!item) {
      if (id) {
        console.error(`${id} not found in codelist ${codelist}`);
      }
      return '-';
    }
    return `${item.customText || `${item.code || item.id} - ${item.name}`}`;
  }
  getProperties(entity, propertyName) {
    const property = entity.entityType.getProperty(propertyName);
    return property instanceof NavigationProperty ? {
      navigationProperty: property,
      dataProperty: entity.entityType.foreignKeyProperties.find(fk => fk.relatedNavigationProperty === property)
    } : {
      navigationProperty: entity.entityType.foreignKeyProperties.find(fk => fk.name === propertyName).relatedNavigationProperty,
      dataProperty: property
    };
  }
}
CodelistPipe.ɵfac = function CodelistPipe_Factory(t) {
  return new (t || CodelistPipe)(i0.ɵɵdirectiveInject(i1.CodelistService, 16));
};
CodelistPipe.ɵpipe = /*@__PURE__*/i0.ɵɵdefinePipe({
  name: "codelist",
  type: CodelistPipe,
  pure: true
});