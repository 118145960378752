import * as i0 from "@angular/core";
export class FormatListPipe {
  transform(value, ...args) {
    if (!value) return;
    const replaceCharacter = value.split(args[0]).length - 1 === 1 ? '' : args[1];
    let formatted = value.replace(new RegExp(args[0], 'g'), replaceCharacter);
    if (formatted.split(replaceCharacter).at(-1) === '') formatted = formatted.replace(new RegExp(`${replaceCharacter}$`), '');
    return formatted;
  }
}
FormatListPipe.ɵfac = function FormatListPipe_Factory(t) {
  return new (t || FormatListPipe)();
};
FormatListPipe.ɵpipe = /*@__PURE__*/i0.ɵɵdefinePipe({
  name: "formatList",
  type: FormatListPipe,
  pure: true
});