import * as i0 from "@angular/core";
import * as i1 from "@angular/forms";
import * as i2 from "@progress/kendo-angular-grid";
import * as i3 from "../../../common/components/app-control/app-control.component";
import * as i4 from "../../../common/components/editable-grid/editable-grid.component";
import * as i5 from "../../../common/components/check-icon.component";
import * as i6 from "@angular/common";
import * as i7 from "@ngx-translate/core";
import * as i8 from "../../../common/pipes/codelist.pipe";
function ChargingStartingEventListComponent_ng_template_3_Template(rf, ctx) {
  if (rf & 1) {
    const _r6 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "app-control", 5);
    i0.ɵɵlistener("ngModelChange", function ChargingStartingEventListComponent_ng_template_3_Template_app_control_ngModelChange_0_listener($event) {
      const restoredCtx = i0.ɵɵrestoreView(_r6);
      const dataItem_r4 = restoredCtx.dataItem;
      return i0.ɵɵresetView(dataItem_r4.active = $event);
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const dataItem_r4 = ctx.dataItem;
    i0.ɵɵproperty("ngModel", dataItem_r4.active)("entity", dataItem_r4);
  }
}
function ChargingStartingEventListComponent_ng_template_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "app-check-icon", 6);
  }
  if (rf & 2) {
    const dataItem_r7 = ctx.$implicit;
    i0.ɵɵproperty("check", dataItem_r7.active);
  }
}
function ChargingStartingEventListComponent_ng_template_7_Template(rf, ctx) {
  if (rf & 1) {
    const _r10 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "app-control", 7);
    i0.ɵɵlistener("ngModelChange", function ChargingStartingEventListComponent_ng_template_7_Template_app_control_ngModelChange_0_listener($event) {
      const restoredCtx = i0.ɵɵrestoreView(_r10);
      const dataItem_r8 = restoredCtx.dataItem;
      return i0.ɵɵresetView(dataItem_r8.eventTypeId = $event);
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const dataItem_r8 = ctx.dataItem;
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵproperty("selectLabel", ctx_r2.eventTypeLabel)("ngModel", dataItem_r8.eventTypeId)("entity", dataItem_r8);
  }
}
function ChargingStartingEventListComponent_ng_template_8_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtext(0);
    i0.ɵɵpipe(1, "async");
    i0.ɵɵpipe(2, "codelist");
  }
  if (rf & 2) {
    const dataItem_r11 = ctx.$implicit;
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind1(1, 1, i0.ɵɵpipeBind2(2, 3, dataItem_r11.eventTypeId, "EventType")), " ");
  }
}
export class ChargingStartingEventListComponent {
  eventTypeLabel(item) {
    return `${item.id} - ${item.name}`;
  }
}
ChargingStartingEventListComponent.ɵfac = function ChargingStartingEventListComponent_Factory(t) {
  return new (t || ChargingStartingEventListComponent)();
};
ChargingStartingEventListComponent.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
  type: ChargingStartingEventListComponent,
  selectors: [["app-charging-starting-event-list"]],
  inputs: {
    data: "data",
    createParameters: "createParameters",
    editMode: "editMode"
  },
  decls: 9,
  vars: 11,
  consts: [["entityName", "PriceListStartingEventType", 3, "data", "createParameters", "editMode", "pageable"], ["field", "active", 3, "width", "title"], ["kendoGridEditTemplate", ""], ["kendoGridCellTemplate", ""], ["field", "berth", 3, "title"], ["type", "boolean", "size", "lg", "property", "active", 3, "ngModel", "entity", "ngModelChange"], [3, "check"], ["type", "codelist", "codelist", "EventType", "property", "eventTypeId", 3, "selectLabel", "ngModel", "entity", "ngModelChange"]],
  template: function ChargingStartingEventListComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "app-editable-grid", 0)(1, "kendo-grid-column", 1);
      i0.ɵɵpipe(2, "translate");
      i0.ɵɵtemplate(3, ChargingStartingEventListComponent_ng_template_3_Template, 1, 2, "ng-template", 2);
      i0.ɵɵtemplate(4, ChargingStartingEventListComponent_ng_template_4_Template, 1, 1, "ng-template", 3);
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(5, "kendo-grid-column", 4);
      i0.ɵɵpipe(6, "translate");
      i0.ɵɵtemplate(7, ChargingStartingEventListComponent_ng_template_7_Template, 1, 3, "ng-template", 2);
      i0.ɵɵtemplate(8, ChargingStartingEventListComponent_ng_template_8_Template, 3, 6, "ng-template", 3);
      i0.ɵɵelementEnd()();
    }
    if (rf & 2) {
      i0.ɵɵproperty("data", ctx.data)("createParameters", ctx.createParameters)("editMode", ctx.editMode)("pageable", false);
      i0.ɵɵadvance(1);
      i0.ɵɵproperty("width", 55)("title", i0.ɵɵpipeBind1(2, 7, "Active"));
      i0.ɵɵadvance(4);
      i0.ɵɵproperty("title", i0.ɵɵpipeBind1(6, 9, "Event Type"));
    }
  },
  dependencies: [i1.NgControlStatus, i1.NgModel, i2.ColumnComponent, i2.CellTemplateDirective, i2.EditTemplateDirective, i3.AppControlComponent, i4.EditableGridComponent, i5.CheckIconComponent, i6.AsyncPipe, i7.TranslatePipe, i8.CodelistPipe],
  styles: ["\n/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IiIsInNvdXJjZVJvb3QiOiIifQ== */"]
});