import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@fortawesome/angular-fontawesome";
import * as i3 from "@ngx-translate/core";
const _c0 = function () {
  return ["fas", "exclamation-circle"];
};
const _c1 = function () {
  return ["/"];
};
export class UnauthorizedComponent {}
UnauthorizedComponent.ɵfac = function UnauthorizedComponent_Factory(t) {
  return new (t || UnauthorizedComponent)();
};
UnauthorizedComponent.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
  type: UnauthorizedComponent,
  selectors: [["app-authorization-failed"]],
  decls: 12,
  vars: 4,
  consts: [[1, "row", "mt-5", "text-center"], [1, "col"], [1, "col-auto"], ["size", "2x", 1, "text-danger", "mr-2", 3, "icon"], ["translate", ""], [1, "btn", "btn-link", 3, "routerLink"]],
  template: function UnauthorizedComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "div", 0);
      i0.ɵɵelement(1, "div", 1);
      i0.ɵɵelementStart(2, "div", 2)(3, "h2");
      i0.ɵɵelement(4, "fa-icon", 3)(5, "br");
      i0.ɵɵelementStart(6, "span", 4);
      i0.ɵɵtext(7, "401 - Unauthorized");
      i0.ɵɵelementEnd();
      i0.ɵɵelement(8, "br");
      i0.ɵɵelementStart(9, "a", 5);
      i0.ɵɵtext(10, "Back Home");
      i0.ɵɵelementEnd()()();
      i0.ɵɵelement(11, "div", 1);
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      i0.ɵɵadvance(4);
      i0.ɵɵproperty("icon", i0.ɵɵpureFunction0(2, _c0));
      i0.ɵɵadvance(5);
      i0.ɵɵproperty("routerLink", i0.ɵɵpureFunction0(3, _c1));
    }
  },
  dependencies: [i1.RouterLink, i2.FaIconComponent, i3.TranslateDirective],
  styles: ["\n/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IiIsInNvdXJjZVJvb3QiOiIifQ== */"]
});