import * as i0 from "@angular/core";
import * as i1 from "@angular/forms";
import * as i2 from "@progress/kendo-angular-grid";
import * as i3 from "../../../common/components/app-control/app-control.component";
import * as i4 from "../../../common/components/editable-grid/editable-grid.component";
import * as i5 from "@ngx-translate/core";
function CargoClassListComponent_ng_template_3_Template(rf, ctx) {
  if (rf & 1) {
    const _r14 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "app-control", 9);
    i0.ɵɵlistener("ngModelChange", function CargoClassListComponent_ng_template_3_Template_app_control_ngModelChange_0_listener($event) {
      const restoredCtx = i0.ɵɵrestoreView(_r14);
      const dataItem_r12 = restoredCtx.dataItem;
      return i0.ɵɵresetView(dataItem_r12.exceptBerth = $event);
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const dataItem_r12 = ctx.dataItem;
    i0.ɵɵproperty("ngModel", dataItem_r12.exceptBerth)("entity", dataItem_r12);
  }
}
function CargoClassListComponent_ng_template_4_Template(rf, ctx) {
  if (rf & 1) {
    const _r17 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "app-control", 10);
    i0.ɵɵlistener("ngModelChange", function CargoClassListComponent_ng_template_4_Template_app_control_ngModelChange_0_listener($event) {
      const restoredCtx = i0.ɵɵrestoreView(_r17);
      const dataItem_r15 = restoredCtx.$implicit;
      return i0.ɵɵresetView(dataItem_r15.exceptBerth = $event);
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const dataItem_r15 = ctx.$implicit;
    i0.ɵɵproperty("isDisabled", true)("ngModel", dataItem_r15.exceptBerth)("entity", dataItem_r15);
  }
}
function CargoClassListComponent_ng_template_7_Template(rf, ctx) {
  if (rf & 1) {
    const _r20 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "app-control", 11);
    i0.ɵɵlistener("ngModelChange", function CargoClassListComponent_ng_template_7_Template_app_control_ngModelChange_0_listener($event) {
      const restoredCtx = i0.ɵɵrestoreView(_r20);
      const dataItem_r18 = restoredCtx.dataItem;
      return i0.ɵɵresetView(dataItem_r18.berths = $event);
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const dataItem_r18 = ctx.dataItem;
    i0.ɵɵproperty("multi", true)("ngModel", dataItem_r18.berths)("entity", dataItem_r18);
  }
}
function CargoClassListComponent_ng_template_8_Template(rf, ctx) {
  if (rf & 1) {
    const _r23 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "app-control", 12);
    i0.ɵɵlistener("ngModelChange", function CargoClassListComponent_ng_template_8_Template_app_control_ngModelChange_0_listener($event) {
      const restoredCtx = i0.ɵɵrestoreView(_r23);
      const dataItem_r21 = restoredCtx.$implicit;
      return i0.ɵɵresetView(dataItem_r21.berths = $event);
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const dataItem_r21 = ctx.$implicit;
    i0.ɵɵproperty("multi", true)("isDisabled", true)("ngModel", dataItem_r21.berths)("entity", dataItem_r21);
  }
}
function CargoClassListComponent_ng_template_11_Template(rf, ctx) {
  if (rf & 1) {
    const _r26 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "app-control", 13);
    i0.ɵɵlistener("ngModelChange", function CargoClassListComponent_ng_template_11_Template_app_control_ngModelChange_0_listener($event) {
      const restoredCtx = i0.ɵɵrestoreView(_r26);
      const dataItem_r24 = restoredCtx.dataItem;
      return i0.ɵɵresetView(dataItem_r24.exceptManifestCargoType = $event);
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const dataItem_r24 = ctx.dataItem;
    i0.ɵɵproperty("ngModel", dataItem_r24.exceptManifestCargoType)("entity", dataItem_r24);
  }
}
function CargoClassListComponent_ng_template_12_Template(rf, ctx) {
  if (rf & 1) {
    const _r29 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "app-control", 14);
    i0.ɵɵlistener("ngModelChange", function CargoClassListComponent_ng_template_12_Template_app_control_ngModelChange_0_listener($event) {
      const restoredCtx = i0.ɵɵrestoreView(_r29);
      const dataItem_r27 = restoredCtx.$implicit;
      return i0.ɵɵresetView(dataItem_r27.exceptManifestCargoType = $event);
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const dataItem_r27 = ctx.$implicit;
    i0.ɵɵproperty("isDisabled", true)("ngModel", dataItem_r27.exceptManifestCargoType)("entity", dataItem_r27);
  }
}
function CargoClassListComponent_ng_template_15_Template(rf, ctx) {
  if (rf & 1) {
    const _r32 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "app-control", 15);
    i0.ɵɵlistener("ngModelChange", function CargoClassListComponent_ng_template_15_Template_app_control_ngModelChange_0_listener($event) {
      const restoredCtx = i0.ɵɵrestoreView(_r32);
      const dataItem_r30 = restoredCtx.dataItem;
      return i0.ɵɵresetView(dataItem_r30.manifestCargoTypes = $event);
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const dataItem_r30 = ctx.dataItem;
    i0.ɵɵproperty("multi", true)("ngModel", dataItem_r30.manifestCargoTypes)("entity", dataItem_r30);
  }
}
function CargoClassListComponent_ng_template_16_Template(rf, ctx) {
  if (rf & 1) {
    const _r35 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "app-control", 16);
    i0.ɵɵlistener("ngModelChange", function CargoClassListComponent_ng_template_16_Template_app_control_ngModelChange_0_listener($event) {
      const restoredCtx = i0.ɵɵrestoreView(_r35);
      const dataItem_r33 = restoredCtx.$implicit;
      return i0.ɵɵresetView(dataItem_r33.manifestCargoTypes = $event);
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const dataItem_r33 = ctx.$implicit;
    i0.ɵɵproperty("multi", true)("isDisabled", true)("ngModel", dataItem_r33.manifestCargoTypes)("entity", dataItem_r33);
  }
}
function CargoClassListComponent_ng_template_19_Template(rf, ctx) {
  if (rf & 1) {
    const _r38 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "app-control", 17);
    i0.ɵɵlistener("ngModelChange", function CargoClassListComponent_ng_template_19_Template_app_control_ngModelChange_0_listener($event) {
      const restoredCtx = i0.ɵɵrestoreView(_r38);
      const dataItem_r36 = restoredCtx.dataItem;
      return i0.ɵɵresetView(dataItem_r36.exceptCommodity = $event);
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const dataItem_r36 = ctx.dataItem;
    i0.ɵɵproperty("ngModel", dataItem_r36.exceptCommodity)("entity", dataItem_r36);
  }
}
function CargoClassListComponent_ng_template_20_Template(rf, ctx) {
  if (rf & 1) {
    const _r41 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "app-control", 18);
    i0.ɵɵlistener("ngModelChange", function CargoClassListComponent_ng_template_20_Template_app_control_ngModelChange_0_listener($event) {
      const restoredCtx = i0.ɵɵrestoreView(_r41);
      const dataItem_r39 = restoredCtx.$implicit;
      return i0.ɵɵresetView(dataItem_r39.exceptCommodity = $event);
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const dataItem_r39 = ctx.$implicit;
    i0.ɵɵproperty("isDisabled", true)("ngModel", dataItem_r39.exceptCommodity)("entity", dataItem_r39);
  }
}
function CargoClassListComponent_ng_template_23_Template(rf, ctx) {
  if (rf & 1) {
    const _r44 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "app-control", 19);
    i0.ɵɵlistener("ngModelChange", function CargoClassListComponent_ng_template_23_Template_app_control_ngModelChange_0_listener($event) {
      const restoredCtx = i0.ɵɵrestoreView(_r44);
      const dataItem_r42 = restoredCtx.dataItem;
      return i0.ɵɵresetView(dataItem_r42.commodities = $event);
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const dataItem_r42 = ctx.dataItem;
    i0.ɵɵproperty("multi", true)("ngModel", dataItem_r42.commodities)("entity", dataItem_r42);
  }
}
function CargoClassListComponent_ng_template_24_Template(rf, ctx) {
  if (rf & 1) {
    const _r47 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "app-control", 20);
    i0.ɵɵlistener("ngModelChange", function CargoClassListComponent_ng_template_24_Template_app_control_ngModelChange_0_listener($event) {
      const restoredCtx = i0.ɵɵrestoreView(_r47);
      const dataItem_r45 = restoredCtx.$implicit;
      return i0.ɵɵresetView(dataItem_r45.commodities = $event);
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const dataItem_r45 = ctx.$implicit;
    i0.ɵɵproperty("multi", true)("isDisabled", true)("ngModel", dataItem_r45.commodities)("entity", dataItem_r45);
  }
}
export class CargoClassListComponent {}
CargoClassListComponent.ɵfac = function CargoClassListComponent_Factory(t) {
  return new (t || CargoClassListComponent)();
};
CargoClassListComponent.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
  type: CargoClassListComponent,
  selectors: [["app-cargo-class-list"]],
  inputs: {
    data: "data",
    editMode: "editMode"
  },
  decls: 25,
  vars: 27,
  consts: [["entityName", "DueItemCargoClass", 3, "data", "editMode", "pageable"], ["field", "exceptBerth", 3, "title", "width"], ["kendoGridEditTemplate", ""], ["kendoGridCellTemplate", ""], ["field", "berths", 3, "title", "width"], ["field", "exceptManifestCargoType", 3, "title", "width"], ["field", "manifestCargoTypes", 3, "title", "width"], ["field", "exceptCommodity", 3, "title", "width"], ["field", "commodities", 3, "title", "width"], ["type", "boolean", "property", "exceptBerth", 3, "ngModel", "entity", "ngModelChange"], ["type", "boolean", "property", "exceptBerth", 3, "isDisabled", "ngModel", "entity", "ngModelChange"], ["type", "codelist", "codelist", "Berth", "property", "berths", 3, "multi", "ngModel", "entity", "ngModelChange"], ["type", "codelist", "codelist", "Berth", "property", "berths", 3, "multi", "isDisabled", "ngModel", "entity", "ngModelChange"], ["type", "boolean", "property", "exceptManifestCargoType", 3, "ngModel", "entity", "ngModelChange"], ["type", "boolean", "property", "exceptManifestCargoType", 3, "isDisabled", "ngModel", "entity", "ngModelChange"], ["type", "codelist", "codelist", "ManifestCargoType", "property", "manifestCargoTypes", 3, "multi", "ngModel", "entity", "ngModelChange"], ["type", "codelist", "codelist", "ManifestCargoType", "property", "manifestCargoTypes", 3, "multi", "isDisabled", "ngModel", "entity", "ngModelChange"], ["type", "boolean", "property", "exceptCommodity", 3, "ngModel", "entity", "ngModelChange"], ["type", "boolean", "property", "exceptCommodity", 3, "isDisabled", "ngModel", "entity", "ngModelChange"], ["type", "codelist", "codelist", "Commodity", "property", "commodities", 3, "multi", "ngModel", "entity", "ngModelChange"], ["type", "codelist", "codelist", "Commodity", "property", "commodities", 3, "multi", "isDisabled", "ngModel", "entity", "ngModelChange"]],
  template: function CargoClassListComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "app-editable-grid", 0)(1, "kendo-grid-column", 1);
      i0.ɵɵpipe(2, "translate");
      i0.ɵɵtemplate(3, CargoClassListComponent_ng_template_3_Template, 1, 2, "ng-template", 2);
      i0.ɵɵtemplate(4, CargoClassListComponent_ng_template_4_Template, 1, 3, "ng-template", 3);
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(5, "kendo-grid-column", 4);
      i0.ɵɵpipe(6, "translate");
      i0.ɵɵtemplate(7, CargoClassListComponent_ng_template_7_Template, 1, 3, "ng-template", 2);
      i0.ɵɵtemplate(8, CargoClassListComponent_ng_template_8_Template, 1, 4, "ng-template", 3);
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(9, "kendo-grid-column", 5);
      i0.ɵɵpipe(10, "translate");
      i0.ɵɵtemplate(11, CargoClassListComponent_ng_template_11_Template, 1, 2, "ng-template", 2);
      i0.ɵɵtemplate(12, CargoClassListComponent_ng_template_12_Template, 1, 3, "ng-template", 3);
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(13, "kendo-grid-column", 6);
      i0.ɵɵpipe(14, "translate");
      i0.ɵɵtemplate(15, CargoClassListComponent_ng_template_15_Template, 1, 3, "ng-template", 2);
      i0.ɵɵtemplate(16, CargoClassListComponent_ng_template_16_Template, 1, 4, "ng-template", 3);
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(17, "kendo-grid-column", 7);
      i0.ɵɵpipe(18, "translate");
      i0.ɵɵtemplate(19, CargoClassListComponent_ng_template_19_Template, 1, 2, "ng-template", 2);
      i0.ɵɵtemplate(20, CargoClassListComponent_ng_template_20_Template, 1, 3, "ng-template", 3);
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(21, "kendo-grid-column", 8);
      i0.ɵɵpipe(22, "translate");
      i0.ɵɵtemplate(23, CargoClassListComponent_ng_template_23_Template, 1, 3, "ng-template", 2);
      i0.ɵɵtemplate(24, CargoClassListComponent_ng_template_24_Template, 1, 4, "ng-template", 3);
      i0.ɵɵelementEnd()();
    }
    if (rf & 2) {
      i0.ɵɵproperty("data", ctx.data)("editMode", ctx.editMode)("pageable", false);
      i0.ɵɵadvance(1);
      i0.ɵɵproperty("title", i0.ɵɵpipeBind1(2, 15, "Except"))("width", 1);
      i0.ɵɵadvance(4);
      i0.ɵɵproperty("title", i0.ɵɵpipeBind1(6, 17, "Berths"))("width", 100);
      i0.ɵɵadvance(4);
      i0.ɵɵproperty("title", i0.ɵɵpipeBind1(10, 19, "Except"))("width", 1);
      i0.ɵɵadvance(4);
      i0.ɵɵproperty("title", i0.ɵɵpipeBind1(14, 21, "Cargo Types"))("width", 100);
      i0.ɵɵadvance(4);
      i0.ɵɵproperty("title", i0.ɵɵpipeBind1(18, 23, "Except"))("width", 1);
      i0.ɵɵadvance(4);
      i0.ɵɵproperty("title", i0.ɵɵpipeBind1(22, 25, "Commodities"))("width", 100);
    }
  },
  dependencies: [i1.NgControlStatus, i1.NgModel, i2.ColumnComponent, i2.CellTemplateDirective, i2.EditTemplateDirective, i3.AppControlComponent, i4.EditableGridComponent, i5.TranslatePipe],
  styles: ["\n/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IiIsInNvdXJjZVJvb3QiOiIifQ== */"]
});