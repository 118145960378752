import _asyncToGenerator from "C:/GitLab-Runner/builds/VgMjNYji/0/billing/billingmalta/src/frontend/node_modules/@babel/runtime/helpers/esm/asyncToGenerator.js";
import { HttpClient } from '@angular/common/http';
import { EventEmitter } from '@angular/core';
import { UploadActionButtonsComponent } from '@common/components/upload-action-buttons.component';
import { environment } from '@environments/environment';
import { firstValueFrom } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
function UploadMultipleFilesComponent_ng_template_3_Template(rf, ctx) {
  if (rf & 1) {
    const _r9 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "span", 10);
    i0.ɵɵlistener("click", function UploadMultipleFilesComponent_ng_template_3_Template_span_click_0_listener() {
      const restoredCtx = i0.ɵɵrestoreView(_r9);
      const dataItem_r7 = restoredCtx.$implicit;
      const ctx_r8 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r8.download(dataItem_r7));
    });
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const dataItem_r7 = ctx.$implicit;
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate1(" ", dataItem_r7.name, " ");
  }
}
function UploadMultipleFilesComponent_ng_template_6_Template(rf, ctx) {
  if (rf & 1) {
    const _r12 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "app-control", 11);
    i0.ɵɵlistener("ngModelChange", function UploadMultipleFilesComponent_ng_template_6_Template_app_control_ngModelChange_0_listener($event) {
      const restoredCtx = i0.ɵɵrestoreView(_r12);
      const dataItem_r10 = restoredCtx.dataItem;
      return i0.ɵɵresetView(dataItem_r10.typeId = $event);
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const dataItem_r10 = ctx.dataItem;
    i0.ɵɵproperty("ngModel", dataItem_r10.typeId);
  }
}
function UploadMultipleFilesComponent_ng_template_7_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtext(0);
    i0.ɵɵpipe(1, "async");
    i0.ɵɵpipe(2, "codelist");
  }
  if (rf & 2) {
    const dataItem_r13 = ctx.$implicit;
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind1(1, 1, i0.ɵɵpipeBind2(2, 3, dataItem_r13.typeId, "AttachmentType")), " ");
  }
}
function UploadMultipleFilesComponent_ng_template_10_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtext(0);
    i0.ɵɵpipe(1, "async");
    i0.ɵɵpipe(2, "codelist");
  }
  if (rf & 2) {
    const dataItem_r14 = ctx.$implicit;
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind1(1, 1, i0.ɵɵpipeBind2(2, 3, dataItem_r14.createdById, "User")), " ");
  }
}
function UploadMultipleFilesComponent_ng_template_15_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtext(0);
    i0.ɵɵpipe(1, "async");
    i0.ɵɵpipe(2, "codelist");
  }
  if (rf & 2) {
    const dataItem_r15 = ctx.$implicit;
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind1(1, 1, i0.ɵɵpipeBind2(2, 3, dataItem_r15.lastModifiedById, "User")), " ");
  }
}
function UploadMultipleFilesComponent_ng_template_20_Template(rf, ctx) {
  if (rf & 1) {
    const _r18 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "app-control", 12);
    i0.ɵɵlistener("ngModelChange", function UploadMultipleFilesComponent_ng_template_20_Template_app_control_ngModelChange_0_listener($event) {
      const restoredCtx = i0.ɵɵrestoreView(_r18);
      const dataItem_r16 = restoredCtx.dataItem;
      return i0.ɵɵresetView(dataItem_r16.internal = $event);
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const dataItem_r16 = ctx.dataItem;
    i0.ɵɵproperty("ngModel", dataItem_r16.internal);
  }
}
function UploadMultipleFilesComponent_ng_template_21_Template(rf, ctx) {
  if (rf & 1) {
    const _r21 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "app-control", 13);
    i0.ɵɵlistener("ngModelChange", function UploadMultipleFilesComponent_ng_template_21_Template_app_control_ngModelChange_0_listener($event) {
      const restoredCtx = i0.ɵɵrestoreView(_r21);
      const dataItem_r19 = restoredCtx.$implicit;
      return i0.ɵɵresetView(dataItem_r19.internal = $event);
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const dataItem_r19 = ctx.$implicit;
    i0.ɵɵproperty("isDisabled", true)("ngModel", dataItem_r19.internal);
  }
}
export class UploadMultipleFilesComponent {
  constructor(http) {
    this.http = http;
    this.allowToEditFile = file => true;
    this.allowToDeleteFile = file => true;
    this.removeFile = new EventEmitter();
    this.add = new EventEmitter();
  }
  removeAction(file) {
    if (!this.allowToDeleteFile(file)) return;
    this.removeFile.emit(file);
  }
  rowAction(event) {
    if (event?.action === 'add') this.add.emit();
    if (event?.action === 'remove') this.removeAction(event);
    if (event?.action === 'cancel') this.removeAction(event);
  }
  download(file) {
    var _this = this;
    return _asyncToGenerator(function* () {
      if (!_this.canDownload(file)) {
        return;
      }
      if (file.id > 0) {
        const attachment = yield firstValueFrom(_this.http.get(`${environment.settings.appControl.fileDownloadUrl}?id=${file.id}`));
        UploadActionButtonsComponent.preview(attachment.name, attachment.content);
      } else {
        UploadActionButtonsComponent.preview(file.name, file.content);
      }
    })();
  }
  canDownload(file) {
    return file && (file.id > 0 || !!file.content);
  }
}
UploadMultipleFilesComponent.ɵfac = function UploadMultipleFilesComponent_Factory(t) {
  return new (t || UploadMultipleFilesComponent)(i0.ɵɵdirectiveInject(i1.HttpClient));
};
UploadMultipleFilesComponent.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
  type: UploadMultipleFilesComponent,
  selectors: [["app-upload-multiple-files"]],
  inputs: {
    files: "files",
    editMode: "editMode",
    isInternalDisabled: "isInternalDisabled",
    allowToEditFile: "allowToEditFile",
    allowToDeleteFile: "allowToDeleteFile"
  },
  outputs: {
    removeFile: "removeFile",
    add: "add"
  },
  decls: 22,
  vars: 23,
  consts: [["entityName", "Attachment", 3, "data", "editMode", "rowAction"], ["field", "name", 3, "title"], ["kendoGridCellTemplate", ""], ["field", "typeId", 3, "title"], ["kendoGridEditTemplate", ""], ["field", "createdById", 3, "title"], ["field", "createdDate", "format", "g", 3, "title"], ["field", "lastModifiedById", 3, "title"], ["field", "lastModifiedDate", "format", "g", 3, "title"], ["field", "internal", 3, "title"], [2, "color", "#007bff", "cursor", "pointer", 3, "click"], ["type", "codelist", "codelist", "AttachmentType", 3, "ngModel", "ngModelChange"], ["type", "boolean", 3, "ngModel", "ngModelChange"], ["type", "boolean", 3, "isDisabled", "ngModel", "ngModelChange"]],
  template: function UploadMultipleFilesComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "app-editable-grid", 0);
      i0.ɵɵlistener("rowAction", function UploadMultipleFilesComponent_Template_app_editable_grid_rowAction_0_listener($event) {
        return ctx.rowAction($event);
      });
      i0.ɵɵelementStart(1, "kendo-grid-column", 1);
      i0.ɵɵpipe(2, "translate");
      i0.ɵɵtemplate(3, UploadMultipleFilesComponent_ng_template_3_Template, 2, 1, "ng-template", 2);
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(4, "kendo-grid-column", 3);
      i0.ɵɵpipe(5, "translate");
      i0.ɵɵtemplate(6, UploadMultipleFilesComponent_ng_template_6_Template, 1, 1, "ng-template", 4);
      i0.ɵɵtemplate(7, UploadMultipleFilesComponent_ng_template_7_Template, 3, 6, "ng-template", 2);
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(8, "kendo-grid-column", 5);
      i0.ɵɵpipe(9, "translate");
      i0.ɵɵtemplate(10, UploadMultipleFilesComponent_ng_template_10_Template, 3, 6, "ng-template", 2);
      i0.ɵɵelementEnd();
      i0.ɵɵelement(11, "kendo-grid-column", 6);
      i0.ɵɵpipe(12, "translate");
      i0.ɵɵelementStart(13, "kendo-grid-column", 7);
      i0.ɵɵpipe(14, "translate");
      i0.ɵɵtemplate(15, UploadMultipleFilesComponent_ng_template_15_Template, 3, 6, "ng-template", 2);
      i0.ɵɵelementEnd();
      i0.ɵɵelement(16, "kendo-grid-column", 8);
      i0.ɵɵpipe(17, "translate");
      i0.ɵɵelementStart(18, "kendo-grid-column", 9);
      i0.ɵɵpipe(19, "translate");
      i0.ɵɵtemplate(20, UploadMultipleFilesComponent_ng_template_20_Template, 1, 1, "ng-template", 4);
      i0.ɵɵtemplate(21, UploadMultipleFilesComponent_ng_template_21_Template, 1, 2, "ng-template", 2);
      i0.ɵɵelementEnd()();
    }
    if (rf & 2) {
      i0.ɵɵproperty("data", ctx.files)("editMode", ctx.editMode);
      i0.ɵɵadvance(1);
      i0.ɵɵproperty("title", i0.ɵɵpipeBind1(2, 9, "Name"));
      i0.ɵɵadvance(3);
      i0.ɵɵproperty("title", i0.ɵɵpipeBind1(5, 11, "Type"));
      i0.ɵɵadvance(4);
      i0.ɵɵproperty("title", i0.ɵɵpipeBind1(9, 13, "Created By"));
      i0.ɵɵadvance(3);
      i0.ɵɵproperty("title", i0.ɵɵpipeBind1(12, 15, "Created Date"));
      i0.ɵɵadvance(2);
      i0.ɵɵproperty("title", i0.ɵɵpipeBind1(14, 17, "Last Modified By"));
      i0.ɵɵadvance(3);
      i0.ɵɵproperty("title", i0.ɵɵpipeBind1(17, 19, "Last Modified Date"));
      i0.ɵɵadvance(2);
      i0.ɵɵproperty("title", i0.ɵɵpipeBind1(19, 21, "Internal"));
    }
  },
  encapsulation: 2
});