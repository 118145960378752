import { RouterModule } from '@angular/router';
import { CanDeactivateView } from '@common/guards/view.deactivate';
import { ViewMode } from '@common/models/view-mode';
import { CommonModule } from '../common/common.module';
import { AuditViewComponent } from './components/audit-view/audit-view.component';
import { CargoClassListComponent } from './components/cargo-class-list/cargo-class-list.component';
import { ChargingCompletingEventListComponent } from './components/charging-completing-event-list/charging-completing-event-list.component';
import { ChargingConditionsViewComponent } from './components/charging-conditions-view/charging-conditions-view.component';
import { ChargingStartingEventListComponent } from './components/charging-starting-event-list/charging-starting-event-list.component';
import { DueItemListComponent } from './components/due-item-list/due-item-list.component';
import { ExemptionListComponent } from './components/exemption-list/exemption-list.component';
import { PriceListListComponent } from './components/price-list-list/price-list-list.component';
import { PriceListViewComponent } from './components/price-list-view/price-list-view.component';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
const PRICE_LIST_ROUTES = [{
  path: '',
  redirectTo: 'list',
  pathMatch: 'full'
}, {
  path: 'list',
  component: PriceListListComponent
}, {
  path: 'view/:id',
  component: PriceListViewComponent,
  data: {
    mode: ViewMode.view
  }
}, {
  path: 'edit/:id',
  component: PriceListViewComponent,
  data: {
    mode: ViewMode.edit
  },
  canDeactivate: [CanDeactivateView]
}, {
  path: 'create',
  component: PriceListViewComponent,
  data: {
    mode: ViewMode.create
  },
  canDeactivate: [CanDeactivateView]
}];
export class PriceListModule {}
PriceListModule.ɵfac = function PriceListModule_Factory(t) {
  return new (t || PriceListModule)();
};
PriceListModule.ɵmod = /*@__PURE__*/i0.ɵɵdefineNgModule({
  type: PriceListModule
});
PriceListModule.ɵinj = /*@__PURE__*/i0.ɵɵdefineInjector({
  providers: [CanDeactivateView],
  imports: [CommonModule, RouterModule.forChild(PRICE_LIST_ROUTES)]
});
(function () {
  (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(PriceListModule, {
    declarations: [PriceListListComponent, PriceListViewComponent, AuditViewComponent, ExemptionListComponent, DueItemListComponent, ChargingConditionsViewComponent, ChargingStartingEventListComponent, ChargingCompletingEventListComponent, CargoClassListComponent],
    imports: [CommonModule, i1.RouterModule]
  });
})();