import { AbstractBreezeListComponent } from '@common/classes/breeze-list';
import { BreezeViewService } from '@common/services/breeze-view.service';
import * as i0 from "@angular/core";
import * as i1 from "@common/services/breeze-view.service";
import * as i2 from "@angular/forms";
import * as i3 from "@ng-bootstrap/ng-bootstrap";
import * as i4 from "@fortawesome/angular-fontawesome";
import * as i5 from "@progress/kendo-angular-grid";
import * as i6 from "../../../common/components/app-control/app-control.component";
import * as i7 from "../../../common/components/app-form/app-form.component";
import * as i8 from "../../../common/components/app-grid/app-grid.component";
import * as i9 from "../../../common/components/app-control-container/app-control-container.component";
import * as i10 from "@ngx-translate/core";
function UnbilledAdditionalWorkListComponent_ng_template_33_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span");
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const row_r2 = ctx.$implicit;
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate2("", row_r2.itemServiceId, " - ", row_r2.itemServiceName, "");
  }
}
const _c0 = function () {
  return ["fas", "trash"];
};
const _c1 = function () {
  return ["fas", "search"];
};
export class UnbilledAdditionalWorkListComponent extends AbstractBreezeListComponent {
  constructor(breezeViewService) {
    super(breezeViewService);
    this.breezeViewService = breezeViewService;
    this.queryName = 'UnbilledAdditionalWork';
    this.gridSort = [{
      field: 'containerOwnerId',
      dir: 'asc'
    }];
  }
  getDefaultFilter(data) {
    return this.breezeViewService.entityManager.createEntity('GetUnbilledContainerStorageEventsQuery', {
      ...data
    });
  }
  search() {
    this.appGrid.isBusy = true;
    this.handleFilterHighlighter();
    return this.entityManager.executeQuery(this.getQuery()).then(data => {
      this.data = data.results;
    }).finally(() => {
      this.appGrid.isBusy = false;
    });
  }
}
UnbilledAdditionalWorkListComponent.ɵfac = function UnbilledAdditionalWorkListComponent_Factory(t) {
  return new (t || UnbilledAdditionalWorkListComponent)(i0.ɵɵdirectiveInject(i1.BreezeViewService));
};
UnbilledAdditionalWorkListComponent.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
  type: UnbilledAdditionalWorkListComponent,
  selectors: [["app-unbilled-additional-work-list"]],
  features: [i0.ɵɵProvidersFeature([BreezeViewService]), i0.ɵɵInheritDefinitionFeature],
  decls: 36,
  vars: 37,
  consts: [[1, "row"], [1, "col-12"], [2, "text-transform", "uppercase"], [1, "row", "mb-3"], [3, "submit"], ["filterHighlighter", ""], [1, "col-3"], ["property", "serviceDateFrom", 3, "label", "ngModel", "entity", "ngModelChange"], ["property", "serviceDateTo", 3, "label", "ngModel", "entity", "ngModelChange"], [1, "row", "mt-2"], [1, "col"], [1, "float-right"], ["placement", "bottom", 1, "btn", "btn-danger", "mr-2", 3, "ngbTooltip", "click"], [3, "icon"], ["placement", "bottom", 1, "btn", "btn-primary", 3, "ngbTooltip", "click"], [1, "mb-2", "voyage-summary-table", 3, "pageSize", "filterable", "sort", "data", "sortable"], ["field", "containerOwnerId", 3, "title"], ["field", "itemServiceId", 3, "title"], ["kendoGridCellTemplate", ""], ["field", "count", 3, "title"]],
  template: function UnbilledAdditionalWorkListComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "div", 0)(1, "div", 1)(2, "h6", 2);
      i0.ɵɵtext(3);
      i0.ɵɵpipe(4, "translate");
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(5, "div", 3)(6, "div", 1)(7, "app-form", 4);
      i0.ɵɵlistener("submit", function UnbilledAdditionalWorkListComponent_Template_app_form_submit_7_listener() {
        return ctx.search();
      });
      i0.ɵɵelementStart(8, "app-control-container", null, 5)(10, "div", 0)(11, "div", 6)(12, "app-control", 7);
      i0.ɵɵlistener("ngModelChange", function UnbilledAdditionalWorkListComponent_Template_app_control_ngModelChange_12_listener($event) {
        return ctx.filter.serviceDateFrom = $event;
      });
      i0.ɵɵpipe(13, "translate");
      i0.ɵɵelementEnd()();
      i0.ɵɵelementStart(14, "div", 6)(15, "app-control", 8);
      i0.ɵɵlistener("ngModelChange", function UnbilledAdditionalWorkListComponent_Template_app_control_ngModelChange_15_listener($event) {
        return ctx.filter.serviceDateTo = $event;
      });
      i0.ɵɵpipe(16, "translate");
      i0.ɵɵelementEnd()()();
      i0.ɵɵelementStart(17, "div", 9)(18, "div", 10)(19, "div", 11)(20, "button", 12);
      i0.ɵɵlistener("click", function UnbilledAdditionalWorkListComponent_Template_button_click_20_listener() {
        return ctx.clearFilter();
      });
      i0.ɵɵpipe(21, "translate");
      i0.ɵɵelement(22, "fa-icon", 13);
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(23, "button", 14);
      i0.ɵɵlistener("click", function UnbilledAdditionalWorkListComponent_Template_button_click_23_listener() {
        return ctx.search();
      });
      i0.ɵɵpipe(24, "translate");
      i0.ɵɵelement(25, "fa-icon", 13);
      i0.ɵɵelementEnd()()()()()()()()()();
      i0.ɵɵelementStart(26, "div", 0)(27, "div", 1)(28, "app-grid", 15);
      i0.ɵɵelement(29, "kendo-grid-column", 16);
      i0.ɵɵpipe(30, "translate");
      i0.ɵɵelementStart(31, "kendo-grid-column", 17);
      i0.ɵɵpipe(32, "translate");
      i0.ɵɵtemplate(33, UnbilledAdditionalWorkListComponent_ng_template_33_Template, 2, 2, "ng-template", 18);
      i0.ɵɵelementEnd();
      i0.ɵɵelement(34, "kendo-grid-column", 19);
      i0.ɵɵpipe(35, "translate");
      i0.ɵɵelementEnd()()();
    }
    if (rf & 2) {
      i0.ɵɵadvance(3);
      i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(4, 19, "Additional Work"));
      i0.ɵɵadvance(9);
      i0.ɵɵpropertyInterpolate("label", i0.ɵɵpipeBind1(13, 21, "Service Date From"));
      i0.ɵɵproperty("ngModel", ctx.filter.serviceDateFrom)("entity", ctx.filter);
      i0.ɵɵadvance(3);
      i0.ɵɵpropertyInterpolate("label", i0.ɵɵpipeBind1(16, 23, "Service Date To"));
      i0.ɵɵproperty("ngModel", ctx.filter.serviceDateTo)("entity", ctx.filter);
      i0.ɵɵadvance(5);
      i0.ɵɵpropertyInterpolate("ngbTooltip", i0.ɵɵpipeBind1(21, 25, "Clear"));
      i0.ɵɵadvance(2);
      i0.ɵɵproperty("icon", i0.ɵɵpureFunction0(35, _c0));
      i0.ɵɵadvance(1);
      i0.ɵɵpropertyInterpolate("ngbTooltip", i0.ɵɵpipeBind1(24, 27, "Search"));
      i0.ɵɵadvance(2);
      i0.ɵɵproperty("icon", i0.ɵɵpureFunction0(36, _c1));
      i0.ɵɵadvance(3);
      i0.ɵɵproperty("pageSize", 20)("filterable", true)("sort", ctx.gridSort)("data", ctx.data)("sortable", true);
      i0.ɵɵadvance(1);
      i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(30, 29, "Owner"));
      i0.ɵɵadvance(2);
      i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(32, 31, "Service"));
      i0.ɵɵadvance(3);
      i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(35, 33, "Count"));
    }
  },
  dependencies: [i2.NgControlStatus, i2.NgModel, i3.NgbTooltip, i4.FaIconComponent, i5.ColumnComponent, i5.CellTemplateDirective, i6.AppControlComponent, i7.AppFormComponent, i8.AppGridComponent, i9.AppControlContainerComponent, i10.TranslatePipe],
  styles: ["\n/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IiIsInNvdXJjZVJvb3QiOiIifQ== */"]
});