import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "@fortawesome/angular-fontawesome";
const _c0 = function () {
  return ["far", "square"];
};
function BoolComponent_fa_icon_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "fa-icon", 1);
  }
  if (rf & 2) {
    i0.ɵɵproperty("icon", i0.ɵɵpureFunction0(1, _c0));
  }
}
const _c1 = function () {
  return ["far", "check-square"];
};
function BoolComponent_fa_icon_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "fa-icon", 1);
  }
  if (rf & 2) {
    i0.ɵɵproperty("icon", i0.ɵɵpureFunction0(1, _c1));
  }
}
export class BoolComponent {
  constructor() {}
}
BoolComponent.ɵfac = function BoolComponent_Factory(t) {
  return new (t || BoolComponent)();
};
BoolComponent.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
  type: BoolComponent,
  selectors: [["app-bool"]],
  inputs: {
    value: "value"
  },
  decls: 2,
  vars: 2,
  consts: [[3, "icon", 4, "ngIf"], [3, "icon"]],
  template: function BoolComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, BoolComponent_fa_icon_0_Template, 1, 2, "fa-icon", 0);
      i0.ɵɵtemplate(1, BoolComponent_fa_icon_1_Template, 1, 2, "fa-icon", 0);
    }
    if (rf & 2) {
      i0.ɵɵproperty("ngIf", !ctx.value);
      i0.ɵɵadvance(1);
      i0.ɵɵproperty("ngIf", ctx.value);
    }
  },
  dependencies: [i1.NgIf, i2.FaIconComponent],
  styles: ["\n/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IiIsInNvdXJjZVJvb3QiOiIifQ== */"]
});