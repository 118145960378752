import { ElementRef, EventEmitter } from '@angular/core';
import * as _ from 'lodash';
import * as i0 from "@angular/core";
const _c0 = ["*"];
export class AppFormComponent {
  constructor(_el) {
    this._el = _el;
    // eslint-disable-next-line @angular-eslint/no-output-native
    this.submit = new EventEmitter();
    // eslint-disable-next-line @angular-eslint/no-output-native
    this.change = new EventEmitter();
    this.appControls = [];
  }
  registerAppControl(appControl) {
    this.appControls.push(appControl);
  }
  unregisterAppControl(appControl) {
    _.remove(this.appControls, appControl);
  }
  onSubmit(appControl, event) {
    this.submit.emit({
      form: this,
      appControl: appControl,
      event: event
    });
  }
  onChange(appControl, value) {
    this.change.emit({
      form: this,
      appControl: appControl,
      value: value
    });
  }
}
AppFormComponent.ɵfac = function AppFormComponent_Factory(t) {
  return new (t || AppFormComponent)(i0.ɵɵdirectiveInject(i0.ElementRef));
};
AppFormComponent.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
  type: AppFormComponent,
  selectors: [["app-form"]],
  outputs: {
    submit: "submit",
    change: "change"
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function AppFormComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2
});