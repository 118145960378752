import { RouterModule } from '@angular/router';
import { CommonModule } from '@common/common.module';
import { CanDeactivateView } from '@common/guards/view.deactivate';
import { ViewMode } from '@common/models/view-mode';
import { VesselVisitViewComponent } from '../common/components/vessel-visit-view/vessel-visit-view.component';
import { AddExistingInvoiceModalComponent } from './components/add-existing-invoice-modal/add-existing-invoice-modal.component';
import { InvoiceListModalComponent } from './components/invoice-list-modal/invoice-list-modal.component';
import { VesselVisitListComponent } from './components/vessel-visit-list/vessel-visit-list.component';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
const VESSEL_VISIT_ROUTES = [{
  path: '',
  redirectTo: 'list',
  pathMatch: 'full'
}, {
  path: 'list',
  component: VesselVisitListComponent
}, {
  path: 'view/:id',
  component: VesselVisitViewComponent,
  data: {
    mode: ViewMode.view
  }
}, {
  path: 'edit/:id',
  component: VesselVisitViewComponent,
  data: {
    mode: ViewMode.edit
  },
  canDeactivate: [CanDeactivateView]
}, {
  path: 'create',
  component: VesselVisitViewComponent,
  data: {
    mode: ViewMode.create
  },
  canDeactivate: [CanDeactivateView]
}];
export class VesselVisitModule {}
VesselVisitModule.ɵfac = function VesselVisitModule_Factory(t) {
  return new (t || VesselVisitModule)();
};
VesselVisitModule.ɵmod = /*@__PURE__*/i0.ɵɵdefineNgModule({
  type: VesselVisitModule
});
VesselVisitModule.ɵinj = /*@__PURE__*/i0.ɵɵdefineInjector({
  providers: [CanDeactivateView],
  imports: [CommonModule, RouterModule.forChild(VESSEL_VISIT_ROUTES)]
});
(function () {
  (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(VesselVisitModule, {
    declarations: [VesselVisitListComponent, AddExistingInvoiceModalComponent, InvoiceListModalComponent],
    imports: [CommonModule, i1.RouterModule]
  });
})();