// tslint:disable-next-line:max-line-length
import { HttpErrorResponse, HttpEvent, HttpEventType, HttpHandler, HttpHeaderResponse, HttpProgressEvent, HttpRequest, HttpResponse, HttpSentEvent, HttpUserEvent } from '@angular/common/http';
import { ErrorHandler } from '@angular/core';
import { UserService } from '@common/services/user.service';
import { environment } from '@environments/environment';
import { LoadingBarService } from '@ngx-loading-bar/core';
import * as _ from 'lodash';
import { BehaviorSubject, NEVER, throwError } from 'rxjs';
import { catchError, filter, switchMap, take, tap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@ngx-loading-bar/core";
import * as i2 from "@common/services/user.service";
export class AppHttpInterceptor {
  constructor(loadingBarService, userService, errorHandler) {
    this.loadingBarService = loadingBarService;
    this.userService = userService;
    this.errorHandler = errorHandler;
    this.activeRequests = 0;
    this.refreshingToken = false;
    this.refreshTokenSubject = new BehaviorSubject(null);
    this.loadingBar = this.loadingBarService.useRef('http');
  }
  // tslint:disable-next-line:max-line-length
  intercept(req, next) {
    const refreshToken = this.userService.getRefreshToken();
    req = this.setTokenAndUrl(req, this.userService.getAccessToken());
    this.activeRequests++;
    this.loadingBar.start();
    return next.handle(req).pipe(tap(event => {
      if (event.type === HttpEventType.Response) {
        this.decreaseActiveRequests();
      }
    }, error => {
      this.decreaseActiveRequests();
      return throwError(() => error);
    }), catchError(error => {
      if (this.shouldRefreshToken(error)) {
        return this.refreshToken(req, next, refreshToken);
      }
      if (this.shouldLogout(error)) {
        // In case the user logged out from another window
        this.userService.logout();
        return NEVER;
      }
      if (error.status >= 500) {
        this.errorHandler.handleError(error);
      }
      return throwError(() => error);
    }));
  }
  shouldRefreshToken(error) {
    return error instanceof HttpErrorResponse && !_.endsWith(error.url, 'RefreshAccessToken') && error.headers.has('Token-Expired') && error.status === 401;
  }
  shouldLogout(error) {
    return error instanceof HttpErrorResponse && !_.endsWith(error.url, 'RefreshAccessToken') && !_.endsWith(error.url, 'Login') && !error.headers.has('Token-Expired') && error.status === 401;
  }
  refreshToken(request, next, refreshToken) {
    if (!this.refreshingToken && refreshToken === this.userService.getRefreshToken()) {
      this.refreshingToken = true;
      this.refreshTokenSubject.next(null);
      return this.userService.refreshAccessToken().pipe(switchMap(token => {
        this.refreshingToken = false;
        this.refreshTokenSubject.next(token.accessToken);
        return next.handle(this.setTokenAndUrl(request, token.accessToken));
      }), catchError(error => {
        this.refreshingToken = false;
        // logout only when the refresh token is invalid
        if ([400, 401].includes(error.status)) {
          this.userService.logout();
        }
        return throwError(() => error);
      }));
    }
    return this.refreshTokenSubject.pipe(filter(token => token != null), take(1), switchMap(token => next.handle(this.setTokenAndUrl(request, token))));
  }
  setTokenAndUrl(request, token) {
    const headers = {};
    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }
    return request.clone({
      setHeaders: headers,
      url: request.url && request.url.indexOf('api://') === 0 ? request.url.replace('api://', environment.apiUrl) : request.url
    });
  }
  decreaseActiveRequests() {
    this.activeRequests--;
    if (this.activeRequests === 0) {
      this.loadingBar.complete();
    }
  }
}
AppHttpInterceptor.ɵfac = function AppHttpInterceptor_Factory(t) {
  return new (t || AppHttpInterceptor)(i0.ɵɵinject(i1.LoadingBarService), i0.ɵɵinject(i2.UserService), i0.ɵɵinject(i0.ErrorHandler));
};
AppHttpInterceptor.ɵprov = /*@__PURE__*/i0.ɵɵdefineInjectable({
  token: AppHttpInterceptor,
  factory: AppHttpInterceptor.ɵfac
});