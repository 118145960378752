import { environment } from '@environments/environment';
import { addYears, startOfDay } from 'date-fns';
import * as i0 from "@angular/core";
export class AbstractDueItemListComponent {
  constructor() {
    this.currency = environment.settings.currency;
    this.tabs = [];
  }
  ngOnInit() {
    this.tabs = this.items.map(item => ({
      id: item.id,
      selected: 0
    }));
    this.gridSort = !this.editMode ? [{
      field: 'from',
      dir: 'asc'
    }] : [];
  }
  setItemCreateParameters(unitOfMeasurementId = null, chargingPeriodTypeId = null, calculationTypeId = null) {
    this.itemCreateParameters = {
      unitOfMeasurementId,
      chargingPeriodTypeId,
      calculationTypeId,
      validFrom: startOfDay(new Date()),
      validTo: addYears(startOfDay(new Date()), 1)
    };
  }
  tabSelected(event, id) {
    for (const tab of this.tabs) {
      if (tab.id === id) tab.selected = event.index;
    }
  }
  isTabSelected(id, index) {
    for (const tab of this.tabs) {
      if (tab.id === id && tab.selected === index) return true;
    }
    return false;
  }
  handleRowAction(event) {
    if (event?.action === 'add') {
      const addedItemIndex = this.items.length - 1;
      this.tabs.push({
        id: this.items[addedItemIndex].id,
        selected: 0
      });
    }
  }
}
AbstractDueItemListComponent.ɵfac = function AbstractDueItemListComponent_Factory(t) {
  return new (t || AbstractDueItemListComponent)();
};
AbstractDueItemListComponent.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
  type: AbstractDueItemListComponent,
  selectors: [["ng-component"]],
  inputs: {
    items: "items",
    editMode: "editMode"
  },
  decls: 0,
  vars: 0,
  template: function AbstractDueItemListComponent_Template(rf, ctx) {},
  encapsulation: 2
});