import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as i0 from "@angular/core";
import * as i1 from "@ng-bootstrap/ng-bootstrap";
import * as i2 from "@fortawesome/angular-fontawesome";
import * as i3 from "@ngx-translate/core";
const _c0 = function () {
  return ["fas", "exclamation-circle"];
};
export class NewVersionDialogComponent {
  constructor(activeModal) {
    this.activeModal = activeModal;
  }
}
NewVersionDialogComponent.ɵfac = function NewVersionDialogComponent_Factory(t) {
  return new (t || NewVersionDialogComponent)(i0.ɵɵdirectiveInject(i1.NgbActiveModal));
};
NewVersionDialogComponent.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
  type: NewVersionDialogComponent,
  selectors: [["ng-component"]],
  decls: 18,
  vars: 3,
  consts: [[1, "modal-header"], [1, "modal-title"], ["type", "button", "aria-label", "Close", 1, "close", 3, "click"], ["aria-hidden", "true"], [1, "modal-body"], [1, "row"], [1, "col-auto", "text-danger"], [3, "icon", "size"], [1, "col"], [1, "modal-footer"], ["translate", "", 1, "btn", "btn-danger", 3, "click"], ["translate", "", 1, "btn", "btn-primary", 3, "click"]],
  template: function NewVersionDialogComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "div", 0)(1, "h5", 1);
      i0.ɵɵtext(2, "New version");
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(3, "button", 2);
      i0.ɵɵlistener("click", function NewVersionDialogComponent_Template_button_click_3_listener() {
        return ctx.activeModal.dismiss();
      });
      i0.ɵɵelementStart(4, "span", 3);
      i0.ɵɵtext(5, "\u00D7");
      i0.ɵɵelementEnd()()();
      i0.ɵɵelementStart(6, "div", 4)(7, "div", 5)(8, "div", 6);
      i0.ɵɵelement(9, "fa-icon", 7);
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(10, "div", 8)(11, "span");
      i0.ɵɵtext(12, "There is a newer version. Please refresh this page.");
      i0.ɵɵelementEnd()()()();
      i0.ɵɵelementStart(13, "div", 9)(14, "button", 10);
      i0.ɵɵlistener("click", function NewVersionDialogComponent_Template_button_click_14_listener() {
        return ctx.activeModal.close(false);
      });
      i0.ɵɵtext(15, "Cancel");
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(16, "button", 11);
      i0.ɵɵlistener("click", function NewVersionDialogComponent_Template_button_click_16_listener() {
        return ctx.activeModal.close(true);
      });
      i0.ɵɵtext(17, "Refresh");
      i0.ɵɵelementEnd()();
    }
    if (rf & 2) {
      i0.ɵɵadvance(9);
      i0.ɵɵproperty("icon", i0.ɵɵpureFunction0(2, _c0))("size", "3x");
    }
  },
  dependencies: [i2.FaIconComponent, i3.TranslateDirective],
  encapsulation: 2
});