import { AbstractBreezeListComponent } from '@common/classes/breeze-list';
import { BreezeViewService } from '@common/services/breeze-view.service';
import * as i0 from "@angular/core";
import * as i1 from "@common/services/breeze-view.service";
import * as i2 from "@angular/forms";
import * as i3 from "@progress/kendo-angular-grid";
import * as i4 from "../../../common/components/app-control/app-control.component";
import * as i5 from "../../../common/components/app-grid/app-grid.component";
import * as i6 from "@angular/common";
import * as i7 from "@ngx-translate/core";
import * as i8 from "../../../common/pipes/codelist.pipe";
function InvoiceChangeListComponent_ng_template_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span");
    i0.ɵɵtext(1);
    i0.ɵɵpipe(2, "async");
    i0.ɵɵpipe(3, "codelist");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const item_r6 = ctx.$implicit;
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(2, 1, i0.ɵɵpipeBind2(3, 3, item_r6.createdById, "User")));
  }
}
function InvoiceChangeListComponent_ng_template_6_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span");
    i0.ɵɵtext(1);
    i0.ɵɵpipe(2, "date");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const item_r7 = ctx.$implicit;
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(2, 1, item_r7.createdDate));
  }
}
function InvoiceChangeListComponent_ng_template_9_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span");
    i0.ɵɵtext(1);
    i0.ɵɵpipe(2, "async");
    i0.ɵɵpipe(3, "codelist");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const item_r8 = ctx.$implicit;
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(2, 1, i0.ɵɵpipeBind2(3, 3, item_r8.previousStatusId, "InvoiceStatus")));
  }
}
function InvoiceChangeListComponent_ng_template_12_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span");
    i0.ɵɵtext(1);
    i0.ɵɵpipe(2, "async");
    i0.ɵɵpipe(3, "codelist");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const item_r9 = ctx.$implicit;
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(2, 1, i0.ɵɵpipeBind2(3, 3, item_r9.statusId, "InvoiceStatus")));
  }
}
function InvoiceChangeListComponent_ng_template_15_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span");
    i0.ɵɵtext(1);
    i0.ɵɵpipe(2, "async");
    i0.ɵɵpipe(3, "codelist");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const item_r10 = ctx.$implicit;
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(2, 1, i0.ɵɵpipeBind2(3, 3, item_r10.changeTypeId, "InvoiceChangeType")));
  }
}
function InvoiceChangeListComponent_ng_template_18_Template(rf, ctx) {
  if (rf & 1) {
    const _r13 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "app-control", 6);
    i0.ɵɵlistener("ngModelChange", function InvoiceChangeListComponent_ng_template_18_Template_app_control_ngModelChange_0_listener($event) {
      const restoredCtx = i0.ɵɵrestoreView(_r13);
      const item_r11 = restoredCtx.$implicit;
      return i0.ɵɵresetView(item_r11.remark = $event);
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const item_r11 = ctx.$implicit;
    const ctx_r5 = i0.ɵɵnextContext();
    i0.ɵɵproperty("isDisabled", ctx_r5.editMode)("ngModel", item_r11.remark)("entity", item_r11);
  }
}
export class InvoiceChangeListComponent extends AbstractBreezeListComponent {
  getDefaultFilter() {
    return {
      invoiceId: this.invoiceId
    };
  }
  constructor(breezeViewService) {
    super(breezeViewService);
    this.breezeViewService = breezeViewService;
    this.queryName = 'InvoiceChanges';
  }
}
InvoiceChangeListComponent.ɵfac = function InvoiceChangeListComponent_Factory(t) {
  return new (t || InvoiceChangeListComponent)(i0.ɵɵdirectiveInject(i1.BreezeViewService));
};
InvoiceChangeListComponent.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
  type: InvoiceChangeListComponent,
  selectors: [["app-invoice-change-list"]],
  inputs: {
    invoiceId: "invoiceId"
  },
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 19,
  vars: 20,
  consts: [[1, "mb-2", "mt-2", 3, "data", "pageable"], [3, "title"], ["kendoGridCellTemplate", ""], ["field", "createdDate", 3, "title"], ["field", "changeTypeId", 3, "title"], ["width", "500px", 3, "title"], ["type", "textarea", "property", "remark", 3, "isDisabled", "ngModel", "entity", "ngModelChange"]],
  template: function InvoiceChangeListComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "app-grid", 0)(1, "kendo-grid-column", 1);
      i0.ɵɵpipe(2, "translate");
      i0.ɵɵtemplate(3, InvoiceChangeListComponent_ng_template_3_Template, 4, 6, "ng-template", 2);
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(4, "kendo-grid-column", 3);
      i0.ɵɵpipe(5, "translate");
      i0.ɵɵtemplate(6, InvoiceChangeListComponent_ng_template_6_Template, 3, 3, "ng-template", 2);
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(7, "kendo-grid-column", 1);
      i0.ɵɵpipe(8, "translate");
      i0.ɵɵtemplate(9, InvoiceChangeListComponent_ng_template_9_Template, 4, 6, "ng-template", 2);
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(10, "kendo-grid-column", 1);
      i0.ɵɵpipe(11, "translate");
      i0.ɵɵtemplate(12, InvoiceChangeListComponent_ng_template_12_Template, 4, 6, "ng-template", 2);
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(13, "kendo-grid-column", 4);
      i0.ɵɵpipe(14, "translate");
      i0.ɵɵtemplate(15, InvoiceChangeListComponent_ng_template_15_Template, 4, 6, "ng-template", 2);
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(16, "kendo-grid-column", 5);
      i0.ɵɵpipe(17, "translate");
      i0.ɵɵtemplate(18, InvoiceChangeListComponent_ng_template_18_Template, 1, 3, "ng-template", 2);
      i0.ɵɵelementEnd()();
    }
    if (rf & 2) {
      i0.ɵɵproperty("data", ctx.query)("pageable", false);
      i0.ɵɵadvance(1);
      i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(2, 8, "Changed By"));
      i0.ɵɵadvance(3);
      i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(5, 10, "Change Date"));
      i0.ɵɵadvance(3);
      i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(8, 12, "Previous Status"));
      i0.ɵɵadvance(3);
      i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(11, 14, "New Status"));
      i0.ɵɵadvance(3);
      i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(14, 16, "Action"));
      i0.ɵɵadvance(3);
      i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(17, 18, "Remark"));
    }
  },
  dependencies: [i2.NgControlStatus, i2.NgModel, i3.ColumnComponent, i3.CellTemplateDirective, i4.AppControlComponent, i5.AppGridComponent, i6.AsyncPipe, i6.DatePipe, i7.TranslatePipe, i8.CodelistPipe],
  styles: ["\n/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IiIsInNvdXJjZVJvb3QiOiIifQ== */"]
});