import { AppGridComponent } from '@common/components/app-grid/app-grid.component';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "@angular/forms";
import * as i3 from "@fortawesome/angular-fontawesome";
import * as i4 from "@progress/kendo-angular-grid";
import * as i5 from "../../../common/components/app-control/app-control.component";
import * as i6 from "../../../common/components/app-grid/app-grid.component";
import * as i7 from "@ngx-translate/core";
function CustomerEmailViewComponent_ng_template_3_Template(rf, ctx) {
  if (rf & 1) {
    const _r4 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "app-control", 4);
    i0.ɵɵlistener("ngModelChange", function CustomerEmailViewComponent_ng_template_3_Template_app_control_ngModelChange_0_listener($event) {
      const restoredCtx = i0.ɵɵrestoreView(_r4);
      const item_r2 = restoredCtx.$implicit;
      return i0.ɵɵresetView(item_r2.email = $event);
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const item_r2 = ctx.$implicit;
    i0.ɵɵproperty("ngModel", item_r2.email)("entity", item_r2);
  }
}
const _c0 = function () {
  return ["fas", "trash"];
};
function CustomerEmailViewComponent_kendo_grid_column_4_ng_template_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r9 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 7);
    i0.ɵɵlistener("click", function CustomerEmailViewComponent_kendo_grid_column_4_ng_template_1_Template_button_click_0_listener() {
      const restoredCtx = i0.ɵɵrestoreView(_r9);
      const row_r7 = restoredCtx.$implicit;
      const ctx_r8 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r8.remove(row_r7));
    });
    i0.ɵɵelement(1, "fa-icon", 8);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("icon", i0.ɵɵpureFunction0(1, _c0));
  }
}
const _c1 = function () {
  return ["fas", "plus"];
};
function CustomerEmailViewComponent_kendo_grid_column_4_ng_template_2_Template(rf, ctx) {
  if (rf & 1) {
    const _r11 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 9);
    i0.ɵɵlistener("click", function CustomerEmailViewComponent_kendo_grid_column_4_ng_template_2_Template_button_click_0_listener() {
      i0.ɵɵrestoreView(_r11);
      const ctx_r10 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r10.add());
    });
    i0.ɵɵelement(1, "fa-icon", 8);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("icon", i0.ɵɵpureFunction0(1, _c1));
  }
}
function CustomerEmailViewComponent_kendo_grid_column_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "kendo-grid-column", 5);
    i0.ɵɵtemplate(1, CustomerEmailViewComponent_kendo_grid_column_4_ng_template_1_Template, 2, 2, "ng-template", 2);
    i0.ɵɵtemplate(2, CustomerEmailViewComponent_kendo_grid_column_4_ng_template_2_Template, 2, 2, "ng-template", 6);
    i0.ɵɵelementEnd();
  }
}
export class CustomerEmailViewComponent {
  remove(item) {
    item.entityAspect.setDeleted();
  }
  add() {
    this.items.push(this.items.parentEntity.entityAspect.entityManager.createEntity('CustomerEmail', {}));
    this.appGrid.getGrid().expandRow(this.items.length - 1);
  }
}
CustomerEmailViewComponent.ɵfac = function CustomerEmailViewComponent_Factory(t) {
  return new (t || CustomerEmailViewComponent)();
};
CustomerEmailViewComponent.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
  type: CustomerEmailViewComponent,
  selectors: [["app-customer-email-view"]],
  viewQuery: function CustomerEmailViewComponent_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(AppGridComponent, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.appGrid = _t.first);
    }
  },
  inputs: {
    items: "items",
    editMode: "editMode"
  },
  decls: 5,
  vars: 6,
  consts: [[1, "mb-2", 3, "data", "pageable"], [3, "title"], ["kendoGridCellTemplate", ""], ["title", "", "width", "45px", 4, "ngIf"], ["property", "email", 3, "ngModel", "entity", "ngModelChange"], ["title", "", "width", "45px"], ["kendoGridHeaderTemplate", ""], [1, "btn", "btn-danger", "btn-xs", 3, "click"], [3, "icon"], [1, "btn", "btn-success", "btn-xs", 3, "click"]],
  template: function CustomerEmailViewComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "app-grid", 0)(1, "kendo-grid-column", 1);
      i0.ɵɵpipe(2, "translate");
      i0.ɵɵtemplate(3, CustomerEmailViewComponent_ng_template_3_Template, 1, 2, "ng-template", 2);
      i0.ɵɵelementEnd();
      i0.ɵɵtemplate(4, CustomerEmailViewComponent_kendo_grid_column_4_Template, 3, 0, "kendo-grid-column", 3);
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      i0.ɵɵproperty("data", ctx.items)("pageable", false);
      i0.ɵɵadvance(1);
      i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(2, 4, "Email"));
      i0.ɵɵadvance(3);
      i0.ɵɵproperty("ngIf", ctx.editMode);
    }
  },
  dependencies: [i1.NgIf, i2.NgControlStatus, i2.NgModel, i3.FaIconComponent, i4.ColumnComponent, i4.CellTemplateDirective, i4.HeaderTemplateDirective, i5.AppControlComponent, i6.AppGridComponent, i7.TranslatePipe],
  styles: ["\n/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IiIsInNvdXJjZVJvb3QiOiIifQ== */"]
});