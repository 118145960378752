import { AppGridComponent } from '@common/components/app-grid/app-grid.component';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "@angular/forms";
import * as i3 from "@fortawesome/angular-fontawesome";
import * as i4 from "@progress/kendo-angular-grid";
import * as i5 from "../../../common/components/app-control/app-control.component";
import * as i6 from "../../../common/components/app-grid/app-grid.component";
import * as i7 from "@ngx-translate/core";
function CustomerBankAccountViewComponent_ng_template_3_Template(rf, ctx) {
  if (rf & 1) {
    const _r6 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "app-control", 4);
    i0.ɵɵlistener("ngModelChange", function CustomerBankAccountViewComponent_ng_template_3_Template_app_control_ngModelChange_0_listener($event) {
      const restoredCtx = i0.ɵɵrestoreView(_r6);
      const item_r4 = restoredCtx.$implicit;
      return i0.ɵɵresetView(item_r4.accountNumber = $event);
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const item_r4 = ctx.$implicit;
    i0.ɵɵproperty("ngModel", item_r4.accountNumber)("entity", item_r4);
  }
}
function CustomerBankAccountViewComponent_ng_template_6_Template(rf, ctx) {
  if (rf & 1) {
    const _r9 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "app-control", 5);
    i0.ɵɵlistener("ngModelChange", function CustomerBankAccountViewComponent_ng_template_6_Template_app_control_ngModelChange_0_listener($event) {
      const restoredCtx = i0.ɵɵrestoreView(_r9);
      const item_r7 = restoredCtx.$implicit;
      return i0.ɵɵresetView(item_r7.iban = $event);
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const item_r7 = ctx.$implicit;
    i0.ɵɵproperty("ngModel", item_r7.iban)("entity", item_r7);
  }
}
function CustomerBankAccountViewComponent_ng_template_9_Template(rf, ctx) {
  if (rf & 1) {
    const _r12 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "app-control", 6);
    i0.ɵɵlistener("ngModelChange", function CustomerBankAccountViewComponent_ng_template_9_Template_app_control_ngModelChange_0_listener($event) {
      const restoredCtx = i0.ɵɵrestoreView(_r12);
      const item_r10 = restoredCtx.$implicit;
      return i0.ɵɵresetView(item_r10.bic = $event);
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const item_r10 = ctx.$implicit;
    i0.ɵɵproperty("ngModel", item_r10.bic)("entity", item_r10);
  }
}
const _c0 = function () {
  return ["fas", "trash"];
};
function CustomerBankAccountViewComponent_kendo_grid_column_10_ng_template_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r17 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 9);
    i0.ɵɵlistener("click", function CustomerBankAccountViewComponent_kendo_grid_column_10_ng_template_1_Template_button_click_0_listener() {
      const restoredCtx = i0.ɵɵrestoreView(_r17);
      const row_r15 = restoredCtx.$implicit;
      const ctx_r16 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r16.remove(row_r15));
    });
    i0.ɵɵelement(1, "fa-icon", 10);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("icon", i0.ɵɵpureFunction0(1, _c0));
  }
}
const _c1 = function () {
  return ["fas", "plus"];
};
function CustomerBankAccountViewComponent_kendo_grid_column_10_ng_template_2_Template(rf, ctx) {
  if (rf & 1) {
    const _r19 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 11);
    i0.ɵɵlistener("click", function CustomerBankAccountViewComponent_kendo_grid_column_10_ng_template_2_Template_button_click_0_listener() {
      i0.ɵɵrestoreView(_r19);
      const ctx_r18 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r18.add());
    });
    i0.ɵɵelement(1, "fa-icon", 10);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("icon", i0.ɵɵpureFunction0(1, _c1));
  }
}
function CustomerBankAccountViewComponent_kendo_grid_column_10_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "kendo-grid-column", 7);
    i0.ɵɵtemplate(1, CustomerBankAccountViewComponent_kendo_grid_column_10_ng_template_1_Template, 2, 2, "ng-template", 2);
    i0.ɵɵtemplate(2, CustomerBankAccountViewComponent_kendo_grid_column_10_ng_template_2_Template, 2, 2, "ng-template", 8);
    i0.ɵɵelementEnd();
  }
}
export class CustomerBankAccountViewComponent {
  remove(item) {
    item.entityAspect.setDeleted();
  }
  add() {
    this.items.push(this.items.parentEntity.entityAspect.entityManager.createEntity('CustomerBankAccount', {}));
    this.appGrid.getGrid().expandRow(this.items.length - 1);
  }
}
CustomerBankAccountViewComponent.ɵfac = function CustomerBankAccountViewComponent_Factory(t) {
  return new (t || CustomerBankAccountViewComponent)();
};
CustomerBankAccountViewComponent.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
  type: CustomerBankAccountViewComponent,
  selectors: [["app-customer-bank-account-view"]],
  viewQuery: function CustomerBankAccountViewComponent_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(AppGridComponent, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.appGrid = _t.first);
    }
  },
  inputs: {
    items: "items",
    editMode: "editMode"
  },
  decls: 11,
  vars: 12,
  consts: [[1, "mb-2", 3, "data", "pageable"], [3, "title"], ["kendoGridCellTemplate", ""], ["title", "", "width", "45px", 4, "ngIf"], ["property", "accountNumber", 3, "ngModel", "entity", "ngModelChange"], ["property", "iban", 3, "ngModel", "entity", "ngModelChange"], ["property", "bic", 3, "ngModel", "entity", "ngModelChange"], ["title", "", "width", "45px"], ["kendoGridHeaderTemplate", ""], [1, "btn", "btn-danger", "btn-xs", 3, "click"], [3, "icon"], [1, "btn", "btn-success", "btn-xs", 3, "click"]],
  template: function CustomerBankAccountViewComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "app-grid", 0)(1, "kendo-grid-column", 1);
      i0.ɵɵpipe(2, "translate");
      i0.ɵɵtemplate(3, CustomerBankAccountViewComponent_ng_template_3_Template, 1, 2, "ng-template", 2);
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(4, "kendo-grid-column", 1);
      i0.ɵɵpipe(5, "translate");
      i0.ɵɵtemplate(6, CustomerBankAccountViewComponent_ng_template_6_Template, 1, 2, "ng-template", 2);
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(7, "kendo-grid-column", 1);
      i0.ɵɵpipe(8, "translate");
      i0.ɵɵtemplate(9, CustomerBankAccountViewComponent_ng_template_9_Template, 1, 2, "ng-template", 2);
      i0.ɵɵelementEnd();
      i0.ɵɵtemplate(10, CustomerBankAccountViewComponent_kendo_grid_column_10_Template, 3, 0, "kendo-grid-column", 3);
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      i0.ɵɵproperty("data", ctx.items)("pageable", false);
      i0.ɵɵadvance(1);
      i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(2, 6, "Account Number"));
      i0.ɵɵadvance(3);
      i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(5, 8, "IBAN"));
      i0.ɵɵadvance(3);
      i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(8, 10, "Bic"));
      i0.ɵɵadvance(3);
      i0.ɵɵproperty("ngIf", ctx.editMode);
    }
  },
  dependencies: [i1.NgIf, i2.NgControlStatus, i2.NgModel, i3.FaIconComponent, i4.ColumnComponent, i4.CellTemplateDirective, i4.HeaderTemplateDirective, i5.AppControlComponent, i6.AppGridComponent, i7.TranslatePipe],
  styles: ["\n/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IiIsInNvdXJjZVJvb3QiOiIifQ== */"]
});