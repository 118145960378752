import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
import { UserManualComponent } from './components/user-manual/user-manual.component';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
export const HELP_ROUTES = [{
  path: '',
  redirectTo: 'user-manual',
  pathMatch: 'full'
}, {
  path: 'user-manual',
  component: UserManualComponent
}, {
  path: '**',
  redirectTo: 'user-manual'
}];
export class HelpModule {}
HelpModule.ɵfac = function HelpModule_Factory(t) {
  return new (t || HelpModule)();
};
HelpModule.ɵmod = /*@__PURE__*/i0.ɵɵdefineNgModule({
  type: HelpModule
});
HelpModule.ɵinj = /*@__PURE__*/i0.ɵɵdefineInjector({
  imports: [CommonModule, RouterModule.forChild(HELP_ROUTES), TranslateModule, FontAwesomeModule]
});
(function () {
  (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(HelpModule, {
    declarations: [UserManualComponent],
    imports: [CommonModule, i1.RouterModule, TranslateModule, FontAwesomeModule]
  });
})();