import { TemplateRef } from '@angular/core';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
function LoaderComponent_div_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 2)(1, "div", 3);
    i0.ɵɵelement(2, "div", 4);
    i0.ɵɵelementEnd()();
  }
}
function LoaderComponent_div_1_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function LoaderComponent_div_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵtemplate(1, LoaderComponent_div_1_ng_container_1_Template, 1, 0, "ng-container", 5);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r1.detailRef);
  }
}
export class LoaderComponent {
  constructor() {
    this.isBusy = true;
  }
}
LoaderComponent.ɵfac = function LoaderComponent_Factory(t) {
  return new (t || LoaderComponent)();
};
LoaderComponent.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
  type: LoaderComponent,
  selectors: [["app-loader"]],
  contentQueries: function LoaderComponent_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, TemplateRef, 7);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.detailRef = _t.first);
    }
  },
  inputs: {
    isBusy: "isBusy"
  },
  decls: 2,
  vars: 2,
  consts: [["class", "row", 4, "ngIf"], [4, "ngIf"], [1, "row"], [1, "col-auto", "mr-auto", "ml-auto", "mt-5"], [1, "sk-rotating-plane"], [4, "ngTemplateOutlet"]],
  template: function LoaderComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, LoaderComponent_div_0_Template, 3, 0, "div", 0);
      i0.ɵɵtemplate(1, LoaderComponent_div_1_Template, 2, 1, "div", 1);
    }
    if (rf & 2) {
      i0.ɵɵproperty("ngIf", ctx.isBusy);
      i0.ɵɵadvance(1);
      i0.ɵɵproperty("ngIf", !ctx.isBusy);
    }
  },
  dependencies: [i1.NgIf, i1.NgTemplateOutlet],
  encapsulation: 2
});