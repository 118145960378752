import * as i0 from "@angular/core";
import * as i1 from "@fortawesome/angular-fontawesome";
import * as i2 from "@ngx-translate/core";
const _c0 = function () {
  return ["fas", "file"];
};
export class UserManualComponent {}
UserManualComponent.ɵfac = function UserManualComponent_Factory(t) {
  return new (t || UserManualComponent)();
};
UserManualComponent.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
  type: UserManualComponent,
  selectors: [["app-user-manual"]],
  decls: 8,
  vars: 5,
  consts: [[1, "card", "card-border-top", "mt-3"], [1, "card-body"], [1, "row"], [1, "col=12"], [1, "ml-2", "mr-2", 3, "icon"]],
  template: function UserManualComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "div", 0)(1, "div", 1)(2, "div", 2)(3, "div", 3);
      i0.ɵɵelement(4, "fa-icon", 4);
      i0.ɵɵelementStart(5, "a");
      i0.ɵɵtext(6);
      i0.ɵɵpipe(7, "translate");
      i0.ɵɵelementEnd()()()()();
    }
    if (rf & 2) {
      i0.ɵɵadvance(4);
      i0.ɵɵproperty("icon", i0.ɵɵpureFunction0(4, _c0));
      i0.ɵɵadvance(2);
      i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(7, 2, "User Manual"));
    }
  },
  dependencies: [i1.FaIconComponent, i2.TranslatePipe],
  styles: ["\n/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IiIsInNvdXJjZVJvb3QiOiIifQ== */"]
});