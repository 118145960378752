import { RouterModule } from '@angular/router';
import { CanDeactivateView } from '@common/guards/view.deactivate';
import { ViewMode } from '@common/models/view-mode';
import { CommonModule } from '../common/common.module';
import { PostOfficeListComponent } from './components/post-office-list/post-office-list.component';
import { PostOfficeViewComponent } from './components/post-office-view/post-office-view.component';
import { ImdgModule } from './imdg/imdg.module';
import { VesselModule } from './vessel/vessel.module';
import { VtsVesselModule } from './vts-vessel/vts-vessel.module';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
export const CUSTOMER_ROUTES = [{
  path: '',
  redirectTo: 'list',
  pathMatch: 'full'
}, {
  path: 'post-office-list',
  component: PostOfficeListComponent
}, {
  path: 'post-office-list/view/:id',
  component: PostOfficeViewComponent,
  data: {
    mode: ViewMode.view
  }
}, {
  path: 'post-office-list/edit/:id',
  component: PostOfficeViewComponent,
  data: {
    mode: ViewMode.edit
  },
  canDeactivate: [CanDeactivateView]
}, {
  path: 'post-office-list/create',
  component: PostOfficeViewComponent,
  data: {
    mode: ViewMode.create
  },
  canDeactivate: [CanDeactivateView]
}, {
  path: 'vessel',
  loadChildren: () => VesselModule,
  data: {
    title: 'Vessels'
  }
}, {
  path: 'vts-vessel',
  loadChildren: () => VtsVesselModule,
  data: {
    title: 'VTS Vessels'
  }
}, {
  path: 'imdg',
  loadChildren: () => ImdgModule,
  data: {
    title: 'IMDG'
  }
}, {
  path: '**',
  redirectTo: 'list'
}];
export class CodeListModule {}
CodeListModule.ɵfac = function CodeListModule_Factory(t) {
  return new (t || CodeListModule)();
};
CodeListModule.ɵmod = /*@__PURE__*/i0.ɵɵdefineNgModule({
  type: CodeListModule
});
CodeListModule.ɵinj = /*@__PURE__*/i0.ɵɵdefineInjector({
  providers: [CanDeactivateView],
  imports: [CommonModule, RouterModule.forChild(CUSTOMER_ROUTES)]
});
(function () {
  (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(CodeListModule, {
    declarations: [PostOfficeListComponent, PostOfficeViewComponent],
    imports: [CommonModule, i1.RouterModule]
  });
})();