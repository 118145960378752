import { AppControlComponent } from '@common/components/app-control/app-control.component';
import * as i0 from "@angular/core";
const _c0 = ["filterHighlighterInclude"];
const _c1 = ["filterHighlighterExclude"];
const _c2 = ["*"];
export class AppControlContainerComponent {}
AppControlContainerComponent.ɵfac = function AppControlContainerComponent_Factory(t) {
  return new (t || AppControlContainerComponent)();
};
AppControlContainerComponent.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
  type: AppControlContainerComponent,
  selectors: [["app-control-container"]],
  contentQueries: function AppControlContainerComponent_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, AppControlComponent, 5);
      i0.ɵɵcontentQuery(dirIndex, _c0, 5);
      i0.ɵɵcontentQuery(dirIndex, _c1, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.appControls = _t);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.appControlsToInclude = _t);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.appControlsToExclude = _t);
    }
  },
  ngContentSelectors: _c2,
  decls: 1,
  vars: 0,
  template: function AppControlContainerComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  styles: ["\n/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IiIsInNvdXJjZVJvb3QiOiIifQ== */"]
});