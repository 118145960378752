import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as i0 from "@angular/core";
import * as i1 from "@ng-bootstrap/ng-bootstrap";
import * as i2 from "@ngx-translate/core";
export class ChangesDialogComponent {
  constructor(activeModal) {
    this.activeModal = activeModal;
  }
}
ChangesDialogComponent.ɵfac = function ChangesDialogComponent_Factory(t) {
  return new (t || ChangesDialogComponent)(i0.ɵɵdirectiveInject(i1.NgbActiveModal));
};
ChangesDialogComponent.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
  type: ChangesDialogComponent,
  selectors: [["ng-component"]],
  decls: 12,
  vars: 2,
  consts: [[1, "modal-header"], [1, "modal-title"], ["type", "button", "aria-label", "Close", 1, "close", 3, "click"], ["aria-hidden", "true"], [1, "modal-body", 3, "innerHTML"], [1, "modal-footer"], ["translate", "", 1, "btn", "btn-danger", 3, "click"], ["translate", "", 1, "btn", "btn-primary", 3, "click"]],
  template: function ChangesDialogComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "div", 0)(1, "h5", 1);
      i0.ɵɵtext(2);
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(3, "button", 2);
      i0.ɵɵlistener("click", function ChangesDialogComponent_Template_button_click_3_listener() {
        return ctx.activeModal.close(false);
      });
      i0.ɵɵelementStart(4, "span", 3);
      i0.ɵɵtext(5, "\u00D7");
      i0.ɵɵelementEnd()()();
      i0.ɵɵelement(6, "div", 4);
      i0.ɵɵelementStart(7, "div", 5)(8, "button", 6);
      i0.ɵɵlistener("click", function ChangesDialogComponent_Template_button_click_8_listener() {
        return ctx.activeModal.close(false);
      });
      i0.ɵɵtext(9, "Don't save");
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(10, "button", 7);
      i0.ɵɵlistener("click", function ChangesDialogComponent_Template_button_click_10_listener() {
        return ctx.activeModal.close(true);
      });
      i0.ɵɵtext(11, "Save");
      i0.ɵɵelementEnd()();
    }
    if (rf & 2) {
      i0.ɵɵadvance(2);
      i0.ɵɵtextInterpolate(ctx.title);
      i0.ɵɵadvance(4);
      i0.ɵɵproperty("innerHTML", ctx.message, i0.ɵɵsanitizeHtml);
    }
  },
  dependencies: [i2.TranslateDirective],
  encapsulation: 2
});