import { EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { RememberStateService } from '@common/services/remember-state.service';
import { NgbAccordion, NgbPanel } from '@ng-bootstrap/ng-bootstrap';
import * as i0 from "@angular/core";
import * as i1 from "@ng-bootstrap/ng-bootstrap";
import * as i2 from "@common/services/remember-state.service";
import * as i3 from "@angular/router";
import * as i4 from "@fortawesome/angular-fontawesome";
const _c0 = ["*"];
export class AccordionHeaderComponent {
  constructor(accordion, rememberStateService, router) {
    this.accordion = accordion;
    this.rememberStateService = rememberStateService;
    this.router = router;
    this.disabled = false;
    this.panelChange = new EventEmitter();
    this.panelChange = this.accordion.panelChange;
  }
  ngOnInit() {
    // Default state is open as per user request
    const panelState = !this.rememberStateService.initialize(`panel${this.router.url}`);
    if (panelState) setTimeout(() => this.accordion.expand(this.panel.id));
    if (!panelState) setTimeout(() => this.accordion.collapse(this.panel.id));
    // Angular change detection workaround
    setTimeout(() => this.accordion.closeOtherPanels = this.closeOtherPanels);
  }
  toggle() {
    if (!this.disabled) this.accordion.toggle(this.panel.id);
    this.rememberStateService.setState(`panel${this.router.url}`, this.panel.isOpen);
  }
  close() {
    if (this.panel.isOpen) this.accordion.toggle(this.panel.id);
  }
}
AccordionHeaderComponent.ɵfac = function AccordionHeaderComponent_Factory(t) {
  return new (t || AccordionHeaderComponent)(i0.ɵɵdirectiveInject(i1.NgbAccordion), i0.ɵɵdirectiveInject(i2.RememberStateService), i0.ɵɵdirectiveInject(i3.Router));
};
AccordionHeaderComponent.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
  type: AccordionHeaderComponent,
  selectors: [["app-accordion-header"]],
  inputs: {
    panel: "panel",
    disabled: "disabled",
    closeOtherPanels: "closeOtherPanels"
  },
  outputs: {
    panelChange: "panelChange"
  },
  ngContentSelectors: _c0,
  decls: 5,
  vars: 1,
  consts: [[1, "accordion-header-container", "pointer", 3, "click"], [1, "accordion-title"], [1, "chevron", "d-flex", "align-items-center"], [1, "text-primary", 3, "icon"]],
  template: function AccordionHeaderComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵelementStart(0, "div", 0);
      i0.ɵɵlistener("click", function AccordionHeaderComponent_Template_div_click_0_listener() {
        return ctx.toggle();
      });
      i0.ɵɵelementStart(1, "div", 1);
      i0.ɵɵprojection(2);
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(3, "div", 2);
      i0.ɵɵelement(4, "fa-icon", 3);
      i0.ɵɵelementEnd()();
    }
    if (rf & 2) {
      i0.ɵɵadvance(4);
      i0.ɵɵproperty("icon", ctx.panel.isOpen ? "angle-up" : "angle-down");
    }
  },
  dependencies: [i4.FaIconComponent],
  styles: [".accordion-header-container[_ngcontent-%COMP%] {\n                display: flex;\n            }\n            .chevron[_ngcontent-%COMP%] {\n                margin-left: auto;\n            }\n            .pointer[_ngcontent-%COMP%] {\n                cursor: pointer;\n            }\n/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIndlYnBhY2s6Ly8uL3NyYy9hcHAvY29tbW9uL2NvbXBvbmVudHMvYWNjb3JkaW9uLWhlYWRlci5jb21wb25lbnQudHMiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IjtZQUNZO2dCQUNJLGFBQWE7WUFDakI7WUFDQTtnQkFDSSxpQkFBaUI7WUFDckI7WUFDQTtnQkFDSSxlQUFlO1lBQ25CIiwic291cmNlc0NvbnRlbnQiOlsiXG4gICAgICAgICAgICAuYWNjb3JkaW9uLWhlYWRlci1jb250YWluZXIge1xuICAgICAgICAgICAgICAgIGRpc3BsYXk6IGZsZXg7XG4gICAgICAgICAgICB9XG4gICAgICAgICAgICAuY2hldnJvbiB7XG4gICAgICAgICAgICAgICAgbWFyZ2luLWxlZnQ6IGF1dG87XG4gICAgICAgICAgICB9XG4gICAgICAgICAgICAucG9pbnRlciB7XG4gICAgICAgICAgICAgICAgY3Vyc29yOiBwb2ludGVyO1xuICAgICAgICAgICAgfVxuICAgICAgICAiXSwic291cmNlUm9vdCI6IiJ9 */"]
});