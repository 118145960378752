import { VesselVisitStatusCodes } from '@common/classes/codes';
import { EditableGridComponent } from '@common/components/editable-grid/editable-grid.component';
import { BreezeViewService } from '@common/services/breeze-view.service';
import * as i0 from "@angular/core";
import * as i1 from "@common/services/breeze-view.service";
import * as i2 from "@angular/forms";
import * as i3 from "@progress/kendo-angular-grid";
import * as i4 from "../app-control/app-control.component";
import * as i5 from "../editable-grid/editable-grid.component";
import * as i6 from "../flag-icon.component";
import * as i7 from "../popover-list/popover-list.component";
import * as i8 from "@angular/common";
import * as i9 from "@ngx-translate/core";
import * as i10 from "../../pipes/codelist.pipe";
function VesselVisitEventListComponent_ng_template_3_Template(rf, ctx) {
  if (rf & 1) {
    const _r16 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "app-control", 10);
    i0.ɵɵlistener("ngModelChange", function VesselVisitEventListComponent_ng_template_3_Template_app_control_ngModelChange_0_listener($event) {
      const restoredCtx = i0.ɵɵrestoreView(_r16);
      const dataItem_r14 = restoredCtx.dataItem;
      return i0.ɵɵresetView(dataItem_r14.eventTypeId = $event);
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const dataItem_r14 = ctx.dataItem;
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("selectLabel", ctx_r0.eventTypeLabel)("ngModel", dataItem_r14.eventTypeId)("entity", dataItem_r14);
  }
}
function VesselVisitEventListComponent_ng_template_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtext(0);
    i0.ɵɵpipe(1, "async");
    i0.ɵɵpipe(2, "codelist");
  }
  if (rf & 2) {
    const row_r17 = ctx.$implicit;
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind1(1, 1, i0.ɵɵpipeBind2(2, 3, row_r17.eventTypeId, "EventType")), " ");
  }
}
function VesselVisitEventListComponent_ng_template_7_Template(rf, ctx) {
  if (rf & 1) {
    const _r20 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "app-control", 11);
    i0.ɵɵlistener("ngModelChange", function VesselVisitEventListComponent_ng_template_7_Template_app_control_ngModelChange_0_listener($event) {
      const restoredCtx = i0.ɵɵrestoreView(_r20);
      const dataItem_r18 = restoredCtx.dataItem;
      return i0.ɵɵresetView(dataItem_r18.eventDate = $event);
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const dataItem_r18 = ctx.dataItem;
    i0.ɵɵproperty("time", true)("ngModel", dataItem_r18.eventDate)("entity", dataItem_r18);
  }
}
function VesselVisitEventListComponent_ng_template_8_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtext(0);
    i0.ɵɵpipe(1, "date");
  }
  if (rf & 2) {
    const row_r21 = ctx.$implicit;
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind2(1, 1, row_r21.eventDate, "short"), " ");
  }
}
function VesselVisitEventListComponent_ng_template_11_Template(rf, ctx) {
  if (rf & 1) {
    const _r24 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "app-control", 12);
    i0.ɵɵlistener("ngModelChange", function VesselVisitEventListComponent_ng_template_11_Template_app_control_ngModelChange_0_listener($event) {
      const restoredCtx = i0.ɵɵrestoreView(_r24);
      const dataItem_r22 = restoredCtx.dataItem;
      return i0.ɵɵresetView(dataItem_r22.fromId = $event);
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const dataItem_r22 = ctx.dataItem;
    i0.ɵɵproperty("ngModel", dataItem_r22.fromId)("entity", dataItem_r22);
  }
}
function VesselVisitEventListComponent_ng_template_12_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "app-flag-icon", 13);
    i0.ɵɵtext(1);
    i0.ɵɵpipe(2, "async");
    i0.ɵɵpipe(3, "codelist");
  }
  if (rf & 2) {
    const row_r25 = ctx.$implicit;
    i0.ɵɵproperty("code", row_r25.fromId)("floating", true);
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind1(2, 3, i0.ɵɵpipeBind2(3, 5, row_r25.fromId, "Port")), " ");
  }
}
function VesselVisitEventListComponent_ng_template_15_Template(rf, ctx) {
  if (rf & 1) {
    const _r28 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "app-control", 14);
    i0.ɵɵlistener("ngModelChange", function VesselVisitEventListComponent_ng_template_15_Template_app_control_ngModelChange_0_listener($event) {
      const restoredCtx = i0.ɵɵrestoreView(_r28);
      const dataItem_r26 = restoredCtx.dataItem;
      return i0.ɵɵresetView(dataItem_r26.lastBerthId = $event);
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const dataItem_r26 = ctx.dataItem;
    i0.ɵɵproperty("ngModel", dataItem_r26.lastBerthId)("entity", dataItem_r26);
  }
}
function VesselVisitEventListComponent_ng_template_16_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtext(0);
    i0.ɵɵpipe(1, "async");
    i0.ɵɵpipe(2, "codelist");
  }
  if (rf & 2) {
    const row_r29 = ctx.$implicit;
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind1(1, 1, i0.ɵɵpipeBind2(2, 3, row_r29.lastBerthId, "Berth")), " ");
  }
}
function VesselVisitEventListComponent_ng_template_19_Template(rf, ctx) {
  if (rf & 1) {
    const _r32 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "app-control", 15);
    i0.ɵɵlistener("ngModelChange", function VesselVisitEventListComponent_ng_template_19_Template_app_control_ngModelChange_0_listener($event) {
      const restoredCtx = i0.ɵɵrestoreView(_r32);
      const dataItem_r30 = restoredCtx.dataItem;
      return i0.ɵɵresetView(dataItem_r30.nextBerthId = $event);
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const dataItem_r30 = ctx.dataItem;
    i0.ɵɵproperty("ngModel", dataItem_r30.nextBerthId)("entity", dataItem_r30);
  }
}
function VesselVisitEventListComponent_ng_template_20_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtext(0);
    i0.ɵɵpipe(1, "async");
    i0.ɵɵpipe(2, "codelist");
  }
  if (rf & 2) {
    const row_r33 = ctx.$implicit;
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind1(1, 1, i0.ɵɵpipeBind2(2, 3, row_r33.nextBerthId, "Berth")), " ");
  }
}
function VesselVisitEventListComponent_ng_template_23_Template(rf, ctx) {
  if (rf & 1) {
    const _r36 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "app-control", 16);
    i0.ɵɵlistener("ngModelChange", function VesselVisitEventListComponent_ng_template_23_Template_app_control_ngModelChange_0_listener($event) {
      const restoredCtx = i0.ɵɵrestoreView(_r36);
      const dataItem_r34 = restoredCtx.dataItem;
      return i0.ɵɵresetView(dataItem_r34.toId = $event);
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const dataItem_r34 = ctx.dataItem;
    i0.ɵɵproperty("ngModel", dataItem_r34.toId)("entity", dataItem_r34);
  }
}
function VesselVisitEventListComponent_ng_template_24_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "app-flag-icon", 13);
    i0.ɵɵtext(1);
    i0.ɵɵpipe(2, "async");
    i0.ɵɵpipe(3, "codelist");
  }
  if (rf & 2) {
    const row_r37 = ctx.$implicit;
    i0.ɵɵproperty("code", row_r37.toId)("floating", true);
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind1(2, 3, i0.ɵɵpipeBind2(3, 5, row_r37.toId, "Port")), " ");
  }
}
function VesselVisitEventListComponent_ng_template_27_Template(rf, ctx) {
  if (rf & 1) {
    const _r40 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "app-control", 17);
    i0.ɵɵlistener("ngModelChange", function VesselVisitEventListComponent_ng_template_27_Template_app_control_ngModelChange_0_listener($event) {
      const restoredCtx = i0.ɵɵrestoreView(_r40);
      const dataItem_r38 = restoredCtx.dataItem;
      return i0.ɵɵresetView(dataItem_r38.remarks = $event);
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const dataItem_r38 = ctx.dataItem;
    i0.ɵɵproperty("ngModel", dataItem_r38.remarks)("entity", dataItem_r38);
  }
}
const _c0 = function (a0) {
  return {
    text: a0,
    width: "250px"
  };
};
function VesselVisitEventListComponent_ng_template_28_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "app-popover-list", 18);
  }
  if (rf & 2) {
    const row_r41 = ctx.$implicit;
    i0.ɵɵproperty("data", i0.ɵɵpureFunction1(1, _c0, row_r41.remarks));
  }
}
export class VesselVisitEventListComponent {
  constructor(breezeViewService) {
    this.breezeViewService = breezeViewService;
    this.VesselVisitStatusCodes = VesselVisitStatusCodes;
    this.createParameters = {};
  }
  remove(entity) {
    entity.entityAspect.setDeleted();
  }
  countryLabel(item) {
    return `${item.id} - ${item.name}`;
  }
  eventTypeLabel(item) {
    return `${item.id} - ${item.name}`;
  }
  organizationLabel(item) {
    return item.name;
  }
}
VesselVisitEventListComponent.ɵfac = function VesselVisitEventListComponent_Factory(t) {
  return new (t || VesselVisitEventListComponent)(i0.ɵɵdirectiveInject(i1.BreezeViewService));
};
VesselVisitEventListComponent.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
  type: VesselVisitEventListComponent,
  selectors: [["app-vessel-visit-event-list"]],
  viewQuery: function VesselVisitEventListComponent_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(EditableGridComponent, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.editableGrid = _t.first);
    }
  },
  inputs: {
    model: "model",
    editMode: "editMode"
  },
  decls: 29,
  vars: 32,
  consts: [["entityName", "VesselVisitEvent", 3, "data", "createParameters", "editMode"], ["field", "eventTypeId", 3, "title", "width"], ["kendoGridEditTemplate", ""], ["kendoGridCellTemplate", ""], ["field", "eventDate", 3, "title", "width"], ["field", "fromId", 3, "title", "width"], ["field", "lastBerthId", 3, "width", "title", "sortable"], ["field", "nextBerthId", 3, "width", "title", "sortable"], ["field", "toId", 3, "title", "width"], ["field", "remarks", "width", "260px", 3, "title"], ["type", "codelist", "codelist", "EventType", "property", "eventTypeId", 3, "selectLabel", "ngModel", "entity", "ngModelChange"], ["property", "eventDate", 3, "time", "ngModel", "entity", "ngModelChange"], ["type", "codelist", "codelist", "Port", "property", "fromId", 3, "ngModel", "entity", "ngModelChange"], [3, "code", "floating"], ["type", "codelist", "codelist", "Berth", "property", "lastBerthId", 3, "ngModel", "entity", "ngModelChange"], ["type", "codelist", "codelist", "Berth", "property", "nextBerthId", 3, "ngModel", "entity", "ngModelChange"], ["type", "codelist", "codelist", "Port", "property", "toId", 3, "ngModel", "entity", "ngModelChange"], ["property", "remarks", 3, "ngModel", "entity", "ngModelChange"], [3, "data"]],
  template: function VesselVisitEventListComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "app-editable-grid", 0)(1, "kendo-grid-column", 1);
      i0.ɵɵpipe(2, "translate");
      i0.ɵɵtemplate(3, VesselVisitEventListComponent_ng_template_3_Template, 1, 3, "ng-template", 2);
      i0.ɵɵtemplate(4, VesselVisitEventListComponent_ng_template_4_Template, 3, 6, "ng-template", 3);
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(5, "kendo-grid-column", 4);
      i0.ɵɵpipe(6, "translate");
      i0.ɵɵtemplate(7, VesselVisitEventListComponent_ng_template_7_Template, 1, 3, "ng-template", 2);
      i0.ɵɵtemplate(8, VesselVisitEventListComponent_ng_template_8_Template, 2, 4, "ng-template", 3);
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(9, "kendo-grid-column", 5);
      i0.ɵɵpipe(10, "translate");
      i0.ɵɵtemplate(11, VesselVisitEventListComponent_ng_template_11_Template, 1, 2, "ng-template", 2);
      i0.ɵɵtemplate(12, VesselVisitEventListComponent_ng_template_12_Template, 4, 8, "ng-template", 3);
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(13, "kendo-grid-column", 6);
      i0.ɵɵpipe(14, "translate");
      i0.ɵɵtemplate(15, VesselVisitEventListComponent_ng_template_15_Template, 1, 2, "ng-template", 2);
      i0.ɵɵtemplate(16, VesselVisitEventListComponent_ng_template_16_Template, 3, 6, "ng-template", 3);
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(17, "kendo-grid-column", 7);
      i0.ɵɵpipe(18, "translate");
      i0.ɵɵtemplate(19, VesselVisitEventListComponent_ng_template_19_Template, 1, 2, "ng-template", 2);
      i0.ɵɵtemplate(20, VesselVisitEventListComponent_ng_template_20_Template, 3, 6, "ng-template", 3);
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(21, "kendo-grid-column", 8);
      i0.ɵɵpipe(22, "translate");
      i0.ɵɵtemplate(23, VesselVisitEventListComponent_ng_template_23_Template, 1, 2, "ng-template", 2);
      i0.ɵɵtemplate(24, VesselVisitEventListComponent_ng_template_24_Template, 4, 8, "ng-template", 3);
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(25, "kendo-grid-column", 9);
      i0.ɵɵpipe(26, "translate");
      i0.ɵɵtemplate(27, VesselVisitEventListComponent_ng_template_27_Template, 1, 2, "ng-template", 2);
      i0.ɵɵtemplate(28, VesselVisitEventListComponent_ng_template_28_Template, 1, 3, "ng-template", 3);
      i0.ɵɵelementEnd()();
    }
    if (rf & 2) {
      i0.ɵɵproperty("data", ctx.model.events)("createParameters", ctx.createParameters)("editMode", ctx.editMode);
      i0.ɵɵadvance(1);
      i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(2, 18, "Event Type"));
      i0.ɵɵproperty("width", 200);
      i0.ɵɵadvance(4);
      i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(6, 20, "Date & Time"));
      i0.ɵɵproperty("width", 200);
      i0.ɵɵadvance(4);
      i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(10, 22, "Last Port of Call"));
      i0.ɵɵproperty("width", 250);
      i0.ɵɵadvance(4);
      i0.ɵɵproperty("width", 225)("title", i0.ɵɵpipeBind1(14, 24, "From Berth/Area"))("sortable", false);
      i0.ɵɵadvance(4);
      i0.ɵɵproperty("width", 225)("title", i0.ɵɵpipeBind1(18, 26, "To Berth/Area"))("sortable", false);
      i0.ɵɵadvance(4);
      i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(22, 28, "Next Port of Call"));
      i0.ɵɵproperty("width", 250);
      i0.ɵɵadvance(4);
      i0.ɵɵproperty("title", i0.ɵɵpipeBind1(26, 30, "Remarks"));
    }
  },
  dependencies: [i2.NgControlStatus, i2.NgModel, i3.ColumnComponent, i3.CellTemplateDirective, i3.EditTemplateDirective, i4.AppControlComponent, i5.EditableGridComponent, i6.FlagIconComponent, i7.PopoverListComponent, i8.AsyncPipe, i8.DatePipe, i9.TranslatePipe, i10.CodelistPipe],
  styles: ["\n/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IiIsInNvdXJjZVJvb3QiOiIifQ== */"]
});