import * as i0 from "@angular/core";
import * as i1 from "@progress/kendo-angular-grid";
import * as i2 from "../../../common/components/app-grid/app-grid.component";
import * as i3 from "@ngx-translate/core";
const _c0 = function (a0) {
  return [a0];
};
export class AuditViewComponent {}
AuditViewComponent.ɵfac = function AuditViewComponent_Factory(t) {
  return new (t || AuditViewComponent)();
};
AuditViewComponent.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
  type: AuditViewComponent,
  selectors: [["app-audit-view"]],
  inputs: {
    model: "model"
  },
  decls: 11,
  vars: 21,
  consts: [[3, "data", "sortable", "pageable", "scrollable"], ["field", "status.id", 3, "title"], ["field", "lastModifiedBy.fullName", 3, "title"], ["field", "lastModifiedDate", "format", "g", 3, "title"], ["field", "createdBy.fullName", 3, "title"], ["field", "createdDate", "format", "g", 3, "title"]],
  template: function AuditViewComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "app-grid", 0);
      i0.ɵɵelement(1, "kendo-grid-column", 1);
      i0.ɵɵpipe(2, "translate");
      i0.ɵɵelement(3, "kendo-grid-column", 2);
      i0.ɵɵpipe(4, "translate");
      i0.ɵɵelement(5, "kendo-grid-column", 3);
      i0.ɵɵpipe(6, "translate");
      i0.ɵɵelement(7, "kendo-grid-column", 4);
      i0.ɵɵpipe(8, "translate");
      i0.ɵɵelement(9, "kendo-grid-column", 5);
      i0.ɵɵpipe(10, "translate");
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      i0.ɵɵproperty("data", i0.ɵɵpureFunction1(19, _c0, ctx.model))("sortable", true)("pageable", false)("scrollable", "none");
      i0.ɵɵadvance(1);
      i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(2, 9, "Status"));
      i0.ɵɵadvance(2);
      i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(4, 11, "Last Modified By"));
      i0.ɵɵadvance(2);
      i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(6, 13, "Last Modified Date"));
      i0.ɵɵadvance(2);
      i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(8, 15, "Created By"));
      i0.ɵɵadvance(2);
      i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(10, 17, "Created Date"));
    }
  },
  dependencies: [i1.ColumnComponent, i2.AppGridComponent, i3.TranslatePipe],
  styles: ["\n/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IiIsInNvdXJjZVJvb3QiOiIifQ== */"]
});