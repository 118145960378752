import _asyncToGenerator from "C:/GitLab-Runner/builds/VgMjNYji/0/billing/billingmalta/src/frontend/node_modules/@babel/runtime/helpers/esm/asyncToGenerator.js";
import { HttpClient } from '@angular/common/http';
import { EventEmitter } from '@angular/core';
import { environment } from 'environments/environment';
import { firstValueFrom } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@angular/common";
import * as i3 from "@fortawesome/angular-fontawesome";
import * as i4 from "@ngx-translate/core";
function UploadActionButtonsComponent_strong_0_button_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r6 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 6);
    i0.ɵɵlistener("click", function UploadActionButtonsComponent_strong_0_button_1_Template_button_click_0_listener() {
      i0.ɵɵrestoreView(_r6);
      const ctx_r5 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r5.preview(ctx_r5.file));
    });
    i0.ɵɵelement(1, "fa-icon", 7);
    i0.ɵɵelementEnd();
  }
}
function UploadActionButtonsComponent_strong_0_button_2_Template(rf, ctx) {
  if (rf & 1) {
    const _r8 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 8);
    i0.ɵɵlistener("click", function UploadActionButtonsComponent_strong_0_button_2_Template_button_click_0_listener() {
      i0.ɵɵrestoreView(_r8);
      const ctx_r7 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r7.download(ctx_r7.file));
    });
    i0.ɵɵelement(1, "fa-icon", 9);
    i0.ɵɵelementEnd();
  }
}
function UploadActionButtonsComponent_strong_0_button_3_Template(rf, ctx) {
  if (rf & 1) {
    const _r10 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 10);
    i0.ɵɵlistener("click", function UploadActionButtonsComponent_strong_0_button_3_Template_button_click_0_listener() {
      i0.ɵɵrestoreView(_r10);
      const ctx_r9 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r9.remove());
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    i0.ɵɵattribute("tabIndex", -1);
  }
}
function UploadActionButtonsComponent_strong_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "strong", 2);
    i0.ɵɵtemplate(1, UploadActionButtonsComponent_strong_0_button_1_Template, 2, 0, "button", 3);
    i0.ɵɵtemplate(2, UploadActionButtonsComponent_strong_0_button_2_Template, 2, 0, "button", 4);
    i0.ɵɵtemplate(3, UploadActionButtonsComponent_strong_0_button_3_Template, 1, 1, "button", 5);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r0.canPreview(ctx_r0.file));
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", !ctx_r0.disabled && ctx_r0.canDownload(ctx_r0.file));
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r0.canRemove());
  }
}
function UploadActionButtonsComponent_span_1_button_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r15 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 13);
    i0.ɵɵlistener("click", function UploadActionButtonsComponent_span_1_button_1_Template_button_click_0_listener() {
      i0.ɵɵrestoreView(_r15);
      const ctx_r14 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r14.preview(ctx_r14.file));
    });
    i0.ɵɵpipe(1, "translate");
    i0.ɵɵpipe(2, "translate");
    i0.ɵɵelement(3, "fa-icon", 7);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    i0.ɵɵattribute("aria-label", i0.ɵɵpipeBind1(1, 2, "View"))("title", i0.ɵɵpipeBind1(2, 4, "View"));
  }
}
function UploadActionButtonsComponent_span_1_button_2_Template(rf, ctx) {
  if (rf & 1) {
    const _r17 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 13);
    i0.ɵɵlistener("click", function UploadActionButtonsComponent_span_1_button_2_Template_button_click_0_listener() {
      i0.ɵɵrestoreView(_r17);
      const ctx_r16 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r16.download(ctx_r16.file));
    });
    i0.ɵɵpipe(1, "translate");
    i0.ɵɵpipe(2, "translate");
    i0.ɵɵelement(3, "fa-icon", 9);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    i0.ɵɵattribute("aria-label", i0.ɵɵpipeBind1(1, 2, "Download"))("title", i0.ɵɵpipeBind1(2, 4, "Download"));
  }
}
function UploadActionButtonsComponent_span_1_button_3_Template(rf, ctx) {
  if (rf & 1) {
    const _r19 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 14);
    i0.ɵɵlistener("click", function UploadActionButtonsComponent_span_1_button_3_Template_button_click_0_listener() {
      i0.ɵɵrestoreView(_r19);
      const ctx_r18 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r18.remove());
    });
    i0.ɵɵpipe(1, "translate");
    i0.ɵɵpipe(2, "translate");
    i0.ɵɵelement(3, "fa-icon", 15);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    i0.ɵɵattribute("aria-label", i0.ɵɵpipeBind1(1, 2, "Remove"))("title", i0.ɵɵpipeBind1(2, 4, "Remove"));
  }
}
function UploadActionButtonsComponent_span_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span");
    i0.ɵɵtemplate(1, UploadActionButtonsComponent_span_1_button_1_Template, 4, 6, "button", 11);
    i0.ɵɵtemplate(2, UploadActionButtonsComponent_span_1_button_2_Template, 4, 6, "button", 11);
    i0.ɵɵtemplate(3, UploadActionButtonsComponent_span_1_button_3_Template, 4, 6, "button", 12);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r1.canPreview(ctx_r1.file));
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r1.canDownload(ctx_r1.file));
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r1.canRemove());
  }
}
export var MimeType;
(function (MimeType) {
  MimeType["Excel"] = "application/vnd.ms-excel";
  MimeType["Pdf"] = "application/pdf";
  MimeType["Image"] = "image/png";
  MimeType["Text"] = "text/plain";
})(MimeType || (MimeType = {}));
export class UploadActionButtonsComponent {
  static download(name, content) {
    this.downloadBlob(name, this.createBlob(content, undefined));
  }
  static preview(name, content) {
    if (!this.isFilePreviewable(name)) {
      this.download(name, content);
      return;
    }
    const type = name.toLowerCase().endsWith('pdf') ? MimeType.Pdf : name.toLowerCase().endsWith('tcs') ? MimeType.Text : MimeType.Image;
    const link = this.createBlobLink(name, this.createBlob(content, type));
    window.open(link.href, '_blank');
  }
  static downloadBlob(name, blob) {
    this.createBlobLink(name, blob).click();
  }
  static createBlobLink(name, blob) {
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = name;
    return link;
  }
  static createBlob(content, type) {
    const binaryString = window.atob(content);
    const binaryLen = binaryString.length;
    const bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return new Blob([bytes], {
      type
    });
  }
  static isFilePreviewable(fileName) {
    return this.previewableExtensions.some(ext => fileName.toLowerCase().endsWith(ext));
  }
  static canPreview(file) {
    return (file?.id > 0 || !!file?.content) && this.isFilePreviewable(file.name);
  }
  constructor(http) {
    this.http = http;
    this.removeFile = new EventEmitter();
  }
  canRemove() {
    return !this.disabled;
  }
  remove() {
    this.removeFile.emit(this.file);
  }
  canDownload(file) {
    return file?.id > 0 || !!file?.content;
  }
  canPreview(file) {
    return UploadActionButtonsComponent.canPreview(file);
  }
  download(file) {
    var _this = this;
    return _asyncToGenerator(function* () {
      const attachment = file.id > 0 ? yield firstValueFrom(_this.http.get(`${environment.settings.appControl.fileDownloadUrl}?id=${file.id}`)) : file;
      UploadActionButtonsComponent.download(attachment.name, attachment.content);
    })();
  }
  preview(file) {
    var _this2 = this;
    return _asyncToGenerator(function* () {
      const attachment = file.id > 0 ? yield firstValueFrom(_this2.http.get(`${environment.settings.appControl.fileDownloadUrl}?id=${file.id}`)) : file;
      UploadActionButtonsComponent.preview(attachment.name, attachment.content);
    })();
  }
}
UploadActionButtonsComponent.previewableExtensions = ['pdf', 'png', 'jpg', 'jpeg', 'gif', 'bmp', 'tcs', 'ccs'];
UploadActionButtonsComponent.ɵfac = function UploadActionButtonsComponent_Factory(t) {
  return new (t || UploadActionButtonsComponent)(i0.ɵɵdirectiveInject(i1.HttpClient));
};
UploadActionButtonsComponent.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
  type: UploadActionButtonsComponent,
  selectors: [["app-upload-action-buttons"]],
  inputs: {
    file: "file",
    disabled: "disabled",
    multiple: "multiple"
  },
  outputs: {
    removeFile: "removeFile"
  },
  decls: 2,
  vars: 2,
  consts: [["class", "k-upload-status", 4, "ngIf"], [4, "ngIf"], [1, "k-upload-status"], ["class", "btn btn-sm btn-outline-secondary mr-2", "type", "button", 3, "click", 4, "ngIf"], ["class", "btn btn-sm btn-outline-secondary", "type", "button", 3, "click", 4, "ngIf"], ["class", "k-button k-upload-action", "type", "button", 3, "click", 4, "ngIf"], ["type", "button", 1, "btn", "btn-sm", "btn-outline-secondary", "mr-2", 3, "click"], ["icon", "eye"], ["type", "button", 1, "btn", "btn-sm", "btn-outline-secondary", 3, "click"], ["icon", "download"], ["type", "button", 1, "k-button", "k-upload-action", 3, "click"], ["class", "btn btn-default btn-xs", 3, "click", 4, "ngIf"], ["class", "btn btn-danger btn-xs", 3, "click", 4, "ngIf"], [1, "btn", "btn-default", "btn-xs", 3, "click"], [1, "btn", "btn-danger", "btn-xs", 3, "click"], ["icon", "trash"]],
  template: function UploadActionButtonsComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, UploadActionButtonsComponent_strong_0_Template, 4, 3, "strong", 0);
      i0.ɵɵtemplate(1, UploadActionButtonsComponent_span_1_Template, 4, 3, "span", 1);
    }
    if (rf & 2) {
      i0.ɵɵproperty("ngIf", !ctx.multiple);
      i0.ɵɵadvance(1);
      i0.ɵɵproperty("ngIf", ctx.multiple);
    }
  },
  dependencies: [i2.NgIf, i3.FaIconComponent, i4.TranslatePipe],
  encapsulation: 2
});