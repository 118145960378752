import { BreezeViewService } from '@common/services/breeze-view.service';
import { environment } from '@environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@common/services/breeze-view.service";
import * as i2 from "@angular/router";
import * as i3 from "@fortawesome/angular-fontawesome";
import * as i4 from "@progress/kendo-angular-grid";
import * as i5 from "../app-grid/app-grid.component";
import * as i6 from "@angular/common";
import * as i7 from "@ngx-translate/core";
import * as i8 from "../../pipes/codelist.pipe";
const _c0 = function (a1) {
  return ["/vessel-permit/view/", a1];
};
function VesselPermitDetailComponent_ng_template_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "a", 14);
    i0.ɵɵelement(1, "fa-icon", 15);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(2, "a", 16);
    i0.ɵɵtext(3);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const row_r7 = ctx.$implicit;
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("href", ctx_r0.nmswPermitViewUrl + row_r7.id, i0.ɵɵsanitizeUrl);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("routerLink", i0.ɵɵpureFunction1(3, _c0, row_r7.id));
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate1(" ", row_r7.referenceNumber, " ");
  }
}
function VesselPermitDetailComponent_ng_template_8_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtext(0);
    i0.ɵɵpipe(1, "async");
    i0.ɵɵpipe(2, "codelist");
  }
  if (rf & 2) {
    const row_r8 = ctx.$implicit;
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind1(1, 1, i0.ɵɵpipeBind2(2, 3, row_r8.permitTypeId, "PermitType")), " ");
  }
}
function VesselPermitDetailComponent_ng_template_11_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtext(0);
    i0.ɵɵpipe(1, "async");
    i0.ɵɵpipe(2, "codelist");
  }
  if (rf & 2) {
    const row_r9 = ctx.$implicit;
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind1(1, 1, i0.ɵɵpipeBind2(2, 3, row_r9.permitStatusId, "PermitStatus")), " ");
  }
}
function VesselPermitDetailComponent_ng_template_14_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtext(0);
    i0.ɵɵpipe(1, "async");
    i0.ɵɵpipe(2, "codelist");
  }
  if (rf & 2) {
    const row_r10 = ctx.$implicit;
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind1(1, 1, i0.ɵɵpipeBind2(2, 3, row_r10.vtsVesselVisit.vesselId, "Vessel")), " ");
  }
}
const _c1 = function (a1) {
  return ["/msw-vessel-visit/view/", a1];
};
function VesselPermitDetailComponent_ng_template_17_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "a", 16);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const row_r11 = ctx.$implicit;
    i0.ɵɵproperty("routerLink", i0.ɵɵpureFunction1(2, _c1, row_r11.vtsVesselVisit.id));
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate1(" ", row_r11.vtsVesselVisit.visitNumber, " ");
  }
}
function VesselPermitDetailComponent_ng_template_22_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtext(0);
    i0.ɵɵpipe(1, "async");
    i0.ɵɵpipe(2, "codelist");
  }
  if (rf & 2) {
    const row_r12 = ctx.$implicit;
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind1(1, 1, i0.ɵɵpipeBind2(2, 3, row_r12.requestingOrganizationId, "Organization")), " ");
  }
}
function VesselPermitDetailComponent_ng_template_25_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtext(0);
    i0.ɵɵpipe(1, "async");
    i0.ɵɵpipe(2, "codelist");
  }
  if (rf & 2) {
    const row_r13 = ctx.$implicit;
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind1(1, 1, i0.ɵɵpipeBind2(2, 3, row_r13.berthId, "Berth")), " ");
  }
}
export class VesselPermitDetailComponent {
  constructor(breezeViewService) {
    this.breezeViewService = breezeViewService;
    this.data = [];
    this.gridSort = [{
      field: 'approvalDate',
      dir: 'asc'
    }];
    this.nmswPermitViewUrl = environment.settings.nmswPermitViewUrl;
  }
  ngOnInit() {
    const queryParams = {
      id: this.primaryPermitId,
      primary: false
    };
    if (this.primaryPermitId) {
      this.breezeViewService.handleQuery('VesselPermits', queryParams).then(permits => this.data = permits);
    }
  }
}
VesselPermitDetailComponent.ɵfac = function VesselPermitDetailComponent_Factory(t) {
  return new (t || VesselPermitDetailComponent)(i0.ɵɵdirectiveInject(i1.BreezeViewService));
};
VesselPermitDetailComponent.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
  type: VesselPermitDetailComponent,
  selectors: [["app-vessel-permit-detail"]],
  inputs: {
    primaryPermitId: "primaryPermitId"
  },
  decls: 32,
  vars: 41,
  consts: [[3, "data", "selectable", "sortable", "sort"], ["field", "id", 3, "title", "hidden"], ["field", "referenceNumber", 3, "title"], ["kendoGridCellTemplate", ""], ["field", "permitTypeId", 3, "title"], ["field", "permitStatusId", 3, "title"], ["field", "vtsVesselVisit.vessel.name", 3, "title"], ["field", "vtsVesselVisit.visitNumber", 3, "title"], ["field", "vtsVesselVisit.mswVisitNumber", 3, "title"], ["field", "requestingOrganization.name", 3, "title"], ["field", "berth.name", 3, "title"], ["field", "approvalDate", "format", "g", 3, "title"], ["field", "validFromDate", "format", "g", 3, "title"], ["field", "validToDate", "format", "g", 3, "title"], ["target", "_blank", 1, "text-primary", "link-box", "fixed", 3, "href"], ["icon", "arrow-up-right-from-square"], [1, "text-primary", "link-box", 3, "routerLink"]],
  template: function VesselPermitDetailComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "app-grid", 0);
      i0.ɵɵelement(1, "kendo-grid-column", 1);
      i0.ɵɵpipe(2, "translate");
      i0.ɵɵelementStart(3, "kendo-grid-column", 2);
      i0.ɵɵpipe(4, "translate");
      i0.ɵɵtemplate(5, VesselPermitDetailComponent_ng_template_5_Template, 4, 5, "ng-template", 3);
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(6, "kendo-grid-column", 4);
      i0.ɵɵpipe(7, "translate");
      i0.ɵɵtemplate(8, VesselPermitDetailComponent_ng_template_8_Template, 3, 6, "ng-template", 3);
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(9, "kendo-grid-column", 5);
      i0.ɵɵpipe(10, "translate");
      i0.ɵɵtemplate(11, VesselPermitDetailComponent_ng_template_11_Template, 3, 6, "ng-template", 3);
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(12, "kendo-grid-column", 6);
      i0.ɵɵpipe(13, "translate");
      i0.ɵɵtemplate(14, VesselPermitDetailComponent_ng_template_14_Template, 3, 6, "ng-template", 3);
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(15, "kendo-grid-column", 7);
      i0.ɵɵpipe(16, "translate");
      i0.ɵɵtemplate(17, VesselPermitDetailComponent_ng_template_17_Template, 2, 4, "ng-template", 3);
      i0.ɵɵelementEnd();
      i0.ɵɵelement(18, "kendo-grid-column", 8);
      i0.ɵɵpipe(19, "translate");
      i0.ɵɵelementStart(20, "kendo-grid-column", 9);
      i0.ɵɵpipe(21, "translate");
      i0.ɵɵtemplate(22, VesselPermitDetailComponent_ng_template_22_Template, 3, 6, "ng-template", 3);
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(23, "kendo-grid-column", 10);
      i0.ɵɵpipe(24, "translate");
      i0.ɵɵtemplate(25, VesselPermitDetailComponent_ng_template_25_Template, 3, 6, "ng-template", 3);
      i0.ɵɵelementEnd();
      i0.ɵɵelement(26, "kendo-grid-column", 11);
      i0.ɵɵpipe(27, "translate");
      i0.ɵɵelement(28, "kendo-grid-column", 12);
      i0.ɵɵpipe(29, "translate");
      i0.ɵɵelement(30, "kendo-grid-column", 13);
      i0.ɵɵpipe(31, "translate");
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      i0.ɵɵproperty("data", ctx.data)("selectable", false)("sortable", true)("sort", ctx.gridSort);
      i0.ɵɵadvance(1);
      i0.ɵɵproperty("title", i0.ɵɵpipeBind1(2, 17, "ID"))("hidden", true);
      i0.ɵɵadvance(2);
      i0.ɵɵproperty("title", i0.ɵɵpipeBind1(4, 19, "Permit Number"));
      i0.ɵɵadvance(3);
      i0.ɵɵproperty("title", i0.ɵɵpipeBind1(7, 21, "Permit Type"));
      i0.ɵɵadvance(3);
      i0.ɵɵproperty("title", i0.ɵɵpipeBind1(10, 23, "Permit Status"));
      i0.ɵɵadvance(3);
      i0.ɵɵproperty("title", i0.ɵɵpipeBind1(13, 25, "Vessel Name"));
      i0.ɵɵadvance(3);
      i0.ɵɵproperty("title", i0.ɵɵpipeBind1(16, 27, "VTS Visit Number"));
      i0.ɵɵadvance(3);
      i0.ɵɵproperty("title", i0.ɵɵpipeBind1(19, 29, "MSW Visit Number"));
      i0.ɵɵadvance(2);
      i0.ɵɵproperty("title", i0.ɵɵpipeBind1(21, 31, "Requesting Organization"));
      i0.ɵɵadvance(3);
      i0.ɵɵproperty("title", i0.ɵɵpipeBind1(24, 33, "Berth"));
      i0.ɵɵadvance(3);
      i0.ɵɵproperty("title", i0.ɵɵpipeBind1(27, 35, "MSW Approval Date"));
      i0.ɵɵadvance(2);
      i0.ɵɵproperty("title", i0.ɵɵpipeBind1(29, 37, "Valid From"));
      i0.ɵɵadvance(2);
      i0.ɵɵproperty("title", i0.ɵɵpipeBind1(31, 39, "Valid To"));
    }
  },
  dependencies: [i2.RouterLink, i3.FaIconComponent, i4.ColumnComponent, i4.CellTemplateDirective, i5.AppGridComponent, i6.AsyncPipe, i7.TranslatePipe, i8.CodelistPipe],
  styles: ["\n/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IiIsInNvdXJjZVJvb3QiOiIifQ== */"]
});