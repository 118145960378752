import { BreezeViewService } from '@common/services/breeze-view.service';
import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';
import { isAfter, startOfDay } from 'date-fns';
import * as _ from 'lodash';
import * as i0 from "@angular/core";
import * as i1 from "@progress/kendo-angular-dialog";
import * as i2 from "@common/services/breeze-view.service";
import * as i3 from "@angular/common";
import * as i4 from "@angular/forms";
import * as i5 from "@ngx-translate/core";
import * as i6 from "@progress/kendo-angular-buttons";
import * as i7 from "../../../common/components/app-control/app-control.component";
function InvoiceFullCreditNoteComponent_div_5_p_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "p", 13);
    i0.ɵɵtext(1);
    i0.ɵɵpipe(2, "translate");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind1(2, 1, "Full Credit Note is not allowed"), ". ");
  }
}
function InvoiceFullCreditNoteComponent_div_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵtemplate(1, InvoiceFullCreditNoteComponent_div_5_p_1_Template, 3, 3, "p", 11);
    i0.ɵɵelementStart(2, "p", 12);
    i0.ɵɵtext(3);
    i0.ɵɵpipe(4, "translate");
    i0.ɵɵpipe(5, "date");
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", !ctx_r0.isFullCreditNotePeriodValid());
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate2(" ", i0.ɵɵpipeBind1(4, 3, "Full Credit note is allowed after"), ": ", i0.ɵɵpipeBind2(5, 5, ctx_r0.invoicePeriod == null ? null : ctx_r0.invoicePeriod.validIssueDateFrom, "dd.MM.yyyy"), ". ");
  }
}
export class InvoiceFullCreditNoteComponent extends DialogContentBase {
  constructor(dialog, breezeViewService) {
    super(dialog);
    this.dialog = dialog;
    this.breezeViewService = breezeViewService;
    this.invoicePeriod = {};
    this.serviceDate = startOfDay(new Date());
    this.isBusy = false;
  }
  ngOnInit() {
    this.isBusy = true;
    this.breezeViewService.handleQuery('InvoicePeriodForInvoice', {
      invoiceId: this.invoice.id
    }).then(invoicePeriod => {
      this.invoicePeriod = _.first(invoicePeriod);
    }).finally(() => {
      this.isBusy = false;
    });
  }
  cancel() {
    this.dialog.close();
  }
  close(result) {
    if (!this.isBusy) {
      this.isBusy = true;
      this.dialog.close(false);
    }
  }
  ok() {
    if (!this.isBusy) {
      this.isBusy = true;
      this.dialog.close({
        serviceDate: this.serviceDate,
        remarks: this.remarks
      });
    }
  }
  isFullCreditNotePeriodValid() {
    return isAfter(this.invoice?.serviceDate, this.invoicePeriod?.validIssueDateFrom);
  }
}
InvoiceFullCreditNoteComponent.ɵfac = function InvoiceFullCreditNoteComponent_Factory(t) {
  return new (t || InvoiceFullCreditNoteComponent)(i0.ɵɵdirectiveInject(i1.DialogRef), i0.ɵɵdirectiveInject(i2.BreezeViewService));
};
InvoiceFullCreditNoteComponent.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
  type: InvoiceFullCreditNoteComponent,
  selectors: [["app-invoice-full-credit-note"]],
  inputs: {
    invoice: "invoice"
  },
  features: [i0.ɵɵProvidersFeature([BreezeViewService]), i0.ɵɵInheritDefinitionFeature],
  decls: 19,
  vars: 17,
  consts: [[2, "font-size", "18px", "line-height", "1.3em"], [1, "k-icon", "k-i-warning"], [4, "ngIf"], [1, "row", "mt-2"], [1, "col"], ["type", "datetime", "time", "true", "property", "serviceDate", 3, "label", "isDisabled", "ngModel", "entity", "ngModelChange"], [1, "row", "mb-3"], ["type", "textarea", "rows", "5", "property", "remarks", 3, "label", "isDisabled", "ngModel", "entity", "ngModelChange"], [3, "layout"], ["kendoButton", "", "translate", "", 3, "disabled", "click"], ["kendoButton", "", "primary", "true", "translate", "", 3, "disabled", "click"], ["class", "mb-1", "style", "color: red", 4, "ngIf"], [1, "mb-0"], [1, "mb-1", 2, "color", "red"]],
  template: function InvoiceFullCreditNoteComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "kendo-dialog-titlebar")(1, "div", 0);
      i0.ɵɵelement(2, "span", 1);
      i0.ɵɵtext(3);
      i0.ɵɵpipe(4, "translate");
      i0.ɵɵelementEnd()();
      i0.ɵɵtemplate(5, InvoiceFullCreditNoteComponent_div_5_Template, 6, 8, "div", 2);
      i0.ɵɵelementStart(6, "div", 3)(7, "div", 4)(8, "app-control", 5);
      i0.ɵɵlistener("ngModelChange", function InvoiceFullCreditNoteComponent_Template_app_control_ngModelChange_8_listener($event) {
        return ctx.serviceDate = $event;
      });
      i0.ɵɵpipe(9, "translate");
      i0.ɵɵelementEnd()()();
      i0.ɵɵelementStart(10, "div", 6)(11, "div", 4)(12, "app-control", 7);
      i0.ɵɵlistener("ngModelChange", function InvoiceFullCreditNoteComponent_Template_app_control_ngModelChange_12_listener($event) {
        return ctx.remarks = $event;
      });
      i0.ɵɵpipe(13, "translate");
      i0.ɵɵelementEnd()()();
      i0.ɵɵelementStart(14, "kendo-dialog-actions", 8)(15, "button", 9);
      i0.ɵɵlistener("click", function InvoiceFullCreditNoteComponent_Template_button_click_15_listener() {
        return ctx.close(false);
      });
      i0.ɵɵtext(16, "Cancel");
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(17, "button", 10);
      i0.ɵɵlistener("click", function InvoiceFullCreditNoteComponent_Template_button_click_17_listener() {
        return ctx.ok();
      });
      i0.ɵɵtext(18, " Ok ");
      i0.ɵɵelementEnd()();
    }
    if (rf & 2) {
      i0.ɵɵadvance(3);
      i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind1(4, 11, "Full Credit Note"), " ");
      i0.ɵɵadvance(2);
      i0.ɵɵproperty("ngIf", ctx.invoice);
      i0.ɵɵadvance(3);
      i0.ɵɵproperty("label", i0.ɵɵpipeBind1(9, 13, "Service Date"))("isDisabled", false)("ngModel", ctx.serviceDate);
      i0.ɵɵadvance(4);
      i0.ɵɵproperty("label", i0.ɵɵpipeBind1(13, 15, "Remarks"))("isDisabled", false)("ngModel", ctx.remarks);
      i0.ɵɵadvance(2);
      i0.ɵɵproperty("layout", "normal");
      i0.ɵɵadvance(1);
      i0.ɵɵproperty("disabled", ctx.isBusy);
      i0.ɵɵadvance(2);
      i0.ɵɵproperty("disabled", ctx.isBusy || !ctx.serviceDate || !ctx.isFullCreditNotePeriodValid());
    }
  },
  dependencies: [i3.NgIf, i4.NgControlStatus, i4.NgModel, i5.TranslateDirective, i6.Button, i1.DialogTitleBarComponent, i1.DialogActionsComponent, i7.AppControlComponent, i3.DatePipe, i5.TranslatePipe],
  styles: ["\n/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IiIsInNvdXJjZVJvb3QiOiIifQ== */"]
});