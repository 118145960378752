import { Title } from '@angular/platform-browser';
import { ActivationStart, NavigationEnd, Router } from '@angular/router';
import { ConsoleWindowService } from '@common/services/console-window.service';
import { SignalrService } from '@common/services/signalr.service';
import { environment } from '@environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { SplitterComponent } from '@progress/kendo-angular-layout';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { filter } from 'rxjs/operators';
import { UserService } from './common/services/user.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "./common/services/user.service";
import * as i3 from "@angular/platform-browser";
import * as i4 from "ngx-toastr";
import * as i5 from "@ngx-translate/core";
import * as i6 from "@common/services/console-window.service";
import * as i7 from "@common/services/signalr.service";
import * as i8 from "@angular/common";
import * as i9 from "@ngx-loading-bar/core";
import * as i10 from "@progress/kendo-angular-layout";
import * as i11 from "@progress/kendo-angular-dialog";
import * as i12 from "./common/components/sidebar/sidebar.component";
import * as i13 from "./common/components/header/header.component";
import * as i14 from "@common/directives/console-content.directive";
const _c0 = ["consoleSplitter"];
function AppComponent_kendo_splitter_pane_12_ng_template_1_Template(rf, ctx) {}
function AppComponent_kendo_splitter_pane_12_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "kendo-splitter-pane", 11);
    i0.ɵɵtemplate(1, AppComponent_kendo_splitter_pane_12_ng_template_1_Template, 0, 0, "ng-template", 12);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    i0.ɵɵproperty("collapsible", true);
  }
}
const _c1 = function (a0) {
  return {
    display: a0
  };
};
const _c2 = function (a0) {
  return {
    "margin-left": a0
  };
};
export class AppComponent {
  constructor(router, userService, title, toastrService, translate, consoleWindowService, signalrService) {
    this.router = router;
    this.title = title;
    this.toastrService = toastrService;
    this.translate = translate;
    this.consoleWindowService = consoleWindowService;
    this.isAuthenticated = false;
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        signalrService.info(`Navigated to ${event.url}`);
        const title = this.getDeepestTitle(this.router.routerState.snapshot.root);
        this.title.setTitle(title ? `${title} · ${environment.title}` : `${environment.title}`);
      }
    });
    router.events.pipe(filter(event => event instanceof ActivationStart && event.snapshot.data.permissions && (!_.isArray(event.snapshot.data.permissions) || event.snapshot.data.permissions.length > 0))).subscribe(event => {
      const {
        permissions
      } = event.snapshot.data;
      if (!this.currentUser.hasPermission(permissions)) {
        console.dir(event);
        console.error('Authorization failed.', this.currentUser, permissions);
        signalrService.error(`Authorization for ${this.getResolvedUrl(event.snapshot)} failed.`);
        this.toastrService.error(this.translate.instant('Unauthorized'));
        this.router.navigate(['/401'], {
          queryParams: {
            returnUrl: this.getResolvedUrl(event.snapshot)
          }
        });
      }
    });
    userService.isAuthenticatedSubject.subscribe(value => {
      this.isAuthenticated = value;
    });
    userService.currentUserSubject.subscribe(value => {
      this.currentUser = value;
      if (this.currentUser) {
        try {
          signalrService.info('Client connected');
          const lang = this.currentUser.language.id.toLowerCase();
          this.translate.use(lang);
        } catch {}
      }
    });
  }
  getResolvedUrl(route) {
    return route.pathFromRoot.map(v => v.url.map(segment => segment.toString()).join('/')).join('/');
  }
  getDeepestTitle(routeSnapshot) {
    let title = routeSnapshot.data ? routeSnapshot.data.title : '';
    if (routeSnapshot.firstChild) {
      title = this.getDeepestTitle(routeSnapshot.firstChild) || title;
    }
    return title;
  }
  ngAfterViewInit() {
    this.consoleWindowService.initialize(this.consoleSplitter);
  }
}
AppComponent.ɵfac = function AppComponent_Factory(t) {
  return new (t || AppComponent)(i0.ɵɵdirectiveInject(i1.Router), i0.ɵɵdirectiveInject(i2.UserService), i0.ɵɵdirectiveInject(i3.Title), i0.ɵɵdirectiveInject(i4.ToastrService), i0.ɵɵdirectiveInject(i5.TranslateService), i0.ɵɵdirectiveInject(i6.ConsoleWindowService), i0.ɵɵdirectiveInject(i7.SignalrService));
};
AppComponent.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
  type: AppComponent,
  selectors: [["app-root"]],
  viewQuery: function AppComponent_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(_c0, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.consoleSplitter = _t.first);
    }
  },
  decls: 13,
  vars: 7,
  consts: [["height", "4px", "color", "#fff", "ref", "router"], ["height", "4px", "color", "#fff", "ref", "html"], ["kendoDialogContainer", ""], [3, "toggleSidebar"], [3, "ngStyle"], ["sidebar", ""], [1, "h-100", 3, "ngStyle"], ["orientation", "vertical", 2, "height", "100%", "overflow-y", "hidden"], ["consoleSplitter", ""], [1, "container-fluid", "pb-3", 2, "height", "100%", "overflow-y", "scroll"], ["size", "20%", 3, "collapsible", 4, "ngIf"], ["size", "20%", 3, "collapsible"], ["appConsoleContent", ""]],
  template: function AppComponent_Template(rf, ctx) {
    if (rf & 1) {
      const _r4 = i0.ɵɵgetCurrentView();
      i0.ɵɵelement(0, "ngx-loading-bar", 0)(1, "ngx-loading-bar", 1)(2, "div", 2);
      i0.ɵɵelementStart(3, "app-header", 3);
      i0.ɵɵlistener("toggleSidebar", function AppComponent_Template_app_header_toggleSidebar_3_listener() {
        i0.ɵɵrestoreView(_r4);
        const _r0 = i0.ɵɵreference(5);
        return i0.ɵɵresetView(_r0.toggle());
      });
      i0.ɵɵelementEnd();
      i0.ɵɵelement(4, "app-sidebar", 4, 5);
      i0.ɵɵelementStart(6, "main", 6)(7, "kendo-splitter", 7, 8)(9, "kendo-splitter-pane")(10, "div", 9);
      i0.ɵɵelement(11, "router-outlet");
      i0.ɵɵelementEnd()();
      i0.ɵɵtemplate(12, AppComponent_kendo_splitter_pane_12_Template, 2, 1, "kendo-splitter-pane", 10);
      i0.ɵɵelementEnd()();
    }
    if (rf & 2) {
      const _r0 = i0.ɵɵreference(5);
      i0.ɵɵadvance(4);
      i0.ɵɵproperty("ngStyle", i0.ɵɵpureFunction1(3, _c1, !ctx.isAuthenticated ? "none" : "block"));
      i0.ɵɵadvance(2);
      i0.ɵɵproperty("ngStyle", i0.ɵɵpureFunction1(5, _c2, ctx.isAuthenticated ? _r0.isCollapsed ? "70px" : "260px" : "0px"));
      i0.ɵɵadvance(6);
      i0.ɵɵproperty("ngIf", ctx.consoleWindowService.opened);
    }
  },
  dependencies: [i8.NgIf, i8.NgStyle, i1.RouterOutlet, i9.LoadingBarComponent, i10.SplitterComponent, i10.SplitterPaneComponent, i11.DialogContainerDirective, i12.SidebarComponent, i13.HeaderComponent, i14.ConsoleContentDirective],
  styles: ["main[_ngcontent-%COMP%] {\n  transition: margin-left 0.3s linear;\n}\n/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIndlYnBhY2s6Ly8uL3NyYy9hcHAvYXBwLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBRUksbUNBQUE7QUFDSiIsInNvdXJjZXNDb250ZW50IjpbIm1haW4ge1xyXG4gICAgLXdlYmtpdC10cmFuc2l0aW9uOiBtYXJnaW4tbGVmdCAwLjNzIGxpbmVhcjtcclxuICAgIHRyYW5zaXRpb246IG1hcmdpbi1sZWZ0IDAuM3MgbGluZWFyO1xyXG59XHJcbiJdLCJzb3VyY2VSb290IjoiIn0= */"]
});