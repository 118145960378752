import { RouterModule } from '@angular/router';
import { AdministrationPermissions } from '@common/classes/permissions';
import { CommonModule } from '../common/common.module';
import { ExchangeRateModule } from './exchange-rate/exchange-rate.module';
import { OrganizationModule } from './organization/organization.module';
import { PdfTemplateModule } from './pdf-templates/pdf-template.module';
import { RoleModule } from './role/role.module';
import { UserModule } from './user/user.module';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
const ADMINISTRATION_ROUTES = [{
  path: 'user',
  loadChildren: () => UserModule,
  data: {
    title: 'Users',
    permissions: AdministrationPermissions.User
  }
}, {
  path: 'organization',
  loadChildren: () => OrganizationModule,
  data: {
    title: 'Organizations'
  }
}, {
  path: 'role',
  loadChildren: () => RoleModule,
  data: {
    title: 'Roles'
  }
}, {
  path: 'pdf-template',
  loadChildren: () => PdfTemplateModule,
  data: {
    title: 'Pdf Templates'
  }
}, {
  path: 'exchange-rate',
  loadChildren: () => ExchangeRateModule,
  data: {
    title: 'Exchange Rates'
  }
}, {
  path: '**',
  redirectTo: 'home'
}];
export class AdministrationModule {}
AdministrationModule.ɵfac = function AdministrationModule_Factory(t) {
  return new (t || AdministrationModule)();
};
AdministrationModule.ɵmod = /*@__PURE__*/i0.ɵɵdefineNgModule({
  type: AdministrationModule
});
AdministrationModule.ɵinj = /*@__PURE__*/i0.ɵɵdefineInjector({
  imports: [CommonModule, RouterModule.forChild(ADMINISTRATION_ROUTES)]
});
(function () {
  (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(AdministrationModule, {
    imports: [CommonModule, i1.RouterModule]
  });
})();