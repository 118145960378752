import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ProfileComponent } from './components/profile/profile.component';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
export const PROFILE_ROUTES = [{
  path: '',
  component: ProfileComponent
}];
export class ProfileModule {}
ProfileModule.ɵfac = function ProfileModule_Factory(t) {
  return new (t || ProfileModule)();
};
ProfileModule.ɵmod = /*@__PURE__*/i0.ɵɵdefineNgModule({
  type: ProfileModule
});
ProfileModule.ɵinj = /*@__PURE__*/i0.ɵɵdefineInjector({
  imports: [CommonModule, RouterModule.forChild(PROFILE_ROUTES)]
});
(function () {
  (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(ProfileModule, {
    declarations: [ProfileComponent],
    imports: [CommonModule, i1.RouterModule]
  });
})();