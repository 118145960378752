import { AutoFilterBreezeListComponent } from '@common/classes/auto-filter-list';
import { PriceListPermissions } from '@common/classes/permissions';
import { BreezeViewService } from '@common/services/breeze-view.service';
import { DialogService } from '@common/services/dialog.service';
import { SharedService } from '@common/services/shared.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as i0 from "@angular/core";
import * as i1 from "@common/services/breeze-view.service";
import * as i2 from "@common/services/shared.service";
import * as i3 from "@ng-bootstrap/ng-bootstrap";
import * as i4 from "@common/services/dialog.service";
import * as i5 from "@angular/common";
import * as i6 from "@angular/router";
import * as i7 from "@angular/forms";
import * as i8 from "@fortawesome/angular-fontawesome";
import * as i9 from "@progress/kendo-angular-grid";
import * as i10 from "@progress/kendo-angular-layout";
import * as i11 from "../../../common/components/action-bar/action-bar.component";
import * as i12 from "../../../common/components/app-control/app-control.component";
import * as i13 from "../../../common/components/app-form/app-form.component";
import * as i14 from "../../../common/components/content-header/content-header.component";
import * as i15 from "../../../common/components/app-grid/app-grid.component";
import * as i16 from "../../../common/components/app-control-container/app-control-container.component";
import * as i17 from "../../../common/components/accordion-header.component";
import * as i18 from "@ngx-translate/core";
import * as i19 from "../../../common/pipes/codelist.pipe";
function PriceListListComponent_ng_template_6_span_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span");
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r8 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate1("\u00A0(", ctx_r8.numberOfActiveFilters, ")");
  }
}
function PriceListListComponent_ng_template_6_Template(rf, ctx) {
  if (rf & 1) {
    const _r10 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "app-accordion-header", 11);
    i0.ɵɵlistener("panelChange", function PriceListListComponent_ng_template_6_Template_app_accordion_header_panelChange_0_listener() {
      i0.ɵɵrestoreView(_r10);
      const ctx_r9 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r9.handleFilterHighlighter(true));
    });
    i0.ɵɵelementStart(1, "h5");
    i0.ɵɵelement(2, "fa-icon", 12);
    i0.ɵɵtext(3);
    i0.ɵɵpipe(4, "translate");
    i0.ɵɵtemplate(5, PriceListListComponent_ng_template_6_span_5_Template, 2, 1, "span", 13);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const panel_r7 = ctx.$implicit;
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("panel", panel_r7);
    i0.ɵɵadvance(3);
    i0.ɵɵtextInterpolate1(" \u00A0", i0.ɵɵpipeBind1(4, 3, "Filters"), " ");
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngIf", ctx_r0.numberOfActiveFilters > 0);
  }
}
function PriceListListComponent_ng_template_7_Template(rf, ctx) {
  if (rf & 1) {
    const _r13 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "app-form", 14);
    i0.ɵɵlistener("submit", function PriceListListComponent_ng_template_7_Template_app_form_submit_0_listener() {
      i0.ɵɵrestoreView(_r13);
      const ctx_r12 = i0.ɵɵnextContext();
      ctx_r12.search();
      return i0.ɵɵresetView(ctx_r12.onAutoFilterChange());
    });
    i0.ɵɵelementStart(1, "app-control-container", null, 15)(3, "div", 16)(4, "div", 17)(5, "app-control", 18);
    i0.ɵɵlistener("ngModelChange", function PriceListListComponent_ng_template_7_Template_app_control_ngModelChange_5_listener($event) {
      i0.ɵɵrestoreView(_r13);
      const ctx_r14 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r14.filter.priceListTypeId = $event);
    });
    i0.ɵɵpipe(6, "translate");
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(7, "div", 17)(8, "app-control", 19);
    i0.ɵɵlistener("ngModelChange", function PriceListListComponent_ng_template_7_Template_app_control_ngModelChange_8_listener($event) {
      i0.ɵɵrestoreView(_r13);
      const ctx_r15 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r15.filter.status = $event);
    });
    i0.ɵɵpipe(9, "translate");
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(10, "div", 20)(11, "app-control", 21);
    i0.ɵɵlistener("ngModelChange", function PriceListListComponent_ng_template_7_Template_app_control_ngModelChange_11_listener($event) {
      i0.ɵɵrestoreView(_r13);
      const ctx_r16 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r16.filter.validFrom = $event);
    });
    i0.ɵɵpipe(12, "translate");
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(13, "div", 20)(14, "app-control", 22);
    i0.ɵɵlistener("ngModelChange", function PriceListListComponent_ng_template_7_Template_app_control_ngModelChange_14_listener($event) {
      i0.ɵɵrestoreView(_r13);
      const ctx_r17 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r17.filter.validTo = $event);
    });
    i0.ɵɵpipe(15, "translate");
    i0.ɵɵelementEnd()()();
    i0.ɵɵelementStart(16, "div", 23)(17, "div", 24)(18, "div", 25)(19, "button", 26);
    i0.ɵɵlistener("click", function PriceListListComponent_ng_template_7_Template_button_click_19_listener() {
      i0.ɵɵrestoreView(_r13);
      const ctx_r18 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r18.clearFilter());
    });
    i0.ɵɵelement(20, "fa-icon", 27);
    i0.ɵɵtext(21, " \u00A0 ");
    i0.ɵɵelementStart(22, "span", 28);
    i0.ɵɵtext(23);
    i0.ɵɵpipe(24, "translate");
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(25, "button", 29);
    i0.ɵɵlistener("click", function PriceListListComponent_ng_template_7_Template_button_click_25_listener() {
      i0.ɵɵrestoreView(_r13);
      const ctx_r19 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r19.search());
    });
    i0.ɵɵelement(26, "fa-icon", 30);
    i0.ɵɵtext(27, " \u00A0 ");
    i0.ɵɵelementStart(28, "span", 28);
    i0.ɵɵtext(29);
    i0.ɵɵpipe(30, "translate");
    i0.ɵɵelementEnd()()()()()()();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance(5);
    i0.ɵɵpropertyInterpolate("label", i0.ɵɵpipeBind1(6, 14, "Price List Type"));
    i0.ɵɵproperty("ngModel", ctx_r1.filter.priceListTypeId)("entity", ctx_r1.filter);
    i0.ɵɵadvance(3);
    i0.ɵɵpropertyInterpolate("label", i0.ɵɵpipeBind1(9, 16, "Price List Status"));
    i0.ɵɵproperty("ngModel", ctx_r1.filter.status)("entity", ctx_r1.filter);
    i0.ɵɵadvance(3);
    i0.ɵɵpropertyInterpolate("label", i0.ɵɵpipeBind1(12, 18, "Valid From"));
    i0.ɵɵproperty("ngModel", ctx_r1.filter.validFrom)("entity", ctx_r1.filter);
    i0.ɵɵadvance(3);
    i0.ɵɵpropertyInterpolate("label", i0.ɵɵpipeBind1(15, 20, "Valid To"));
    i0.ɵɵproperty("ngModel", ctx_r1.filter.validTo)("entity", ctx_r1.filter);
    i0.ɵɵadvance(9);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(24, 22, "Clear"));
    i0.ɵɵadvance(6);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(30, 24, "Search"));
  }
}
function PriceListListComponent_ng_template_12_ng_container_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function PriceListListComponent_ng_template_12_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, PriceListListComponent_ng_template_12_ng_container_0_Template, 1, 0, "ng-container", 31);
  }
  if (rf & 2) {
    i0.ɵɵnextContext();
    const _r5 = i0.ɵɵreference(17);
    i0.ɵɵproperty("ngTemplateOutlet", _r5);
  }
}
function PriceListListComponent_ng_template_15_ng_container_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function PriceListListComponent_ng_template_15_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, PriceListListComponent_ng_template_15_ng_container_0_Template, 1, 0, "ng-container", 31);
  }
  if (rf & 2) {
    i0.ɵɵnextContext();
    const _r5 = i0.ɵɵreference(17);
    i0.ɵɵproperty("ngTemplateOutlet", _r5);
  }
}
const _c0 = function (a1, a2) {
  return ["/price-list", a1, a2];
};
function PriceListListComponent_ng_template_16_ng_template_6_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "a", 42);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const row_r24 = ctx.$implicit;
    const ctx_r22 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("routerLink", i0.ɵɵpureFunction2(2, _c0, ctx_r22.defaultViewMode, row_r24.id));
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate1(" ", row_r24.projectId, " ");
  }
}
function PriceListListComponent_ng_template_16_ng_template_9_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtext(0);
    i0.ɵɵpipe(1, "async");
    i0.ɵɵpipe(2, "codelist");
  }
  if (rf & 2) {
    const row_r25 = ctx.$implicit;
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind1(1, 1, i0.ɵɵpipeBind2(2, 3, row_r25.priceListTypeId, "PriceListType")), " ");
  }
}
const _c1 = function () {
  return {
    enabled: true,
    checkboxOnly: true
  };
};
function PriceListListComponent_ng_template_16_Template(rf, ctx) {
  if (rf & 1) {
    const _r27 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 16)(1, "div", 24)(2, "app-grid", 32);
    i0.ɵɵlistener("selectionChange", function PriceListListComponent_ng_template_16_Template_app_grid_selectionChange_2_listener($event) {
      i0.ɵɵrestoreView(_r27);
      const ctx_r26 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r26.selection = $event);
    })("sortChange", function PriceListListComponent_ng_template_16_Template_app_grid_sortChange_2_listener($event) {
      i0.ɵɵrestoreView(_r27);
      const ctx_r28 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r28.onSortChange($event));
    });
    i0.ɵɵelement(3, "kendo-grid-checkbox-column", 33);
    i0.ɵɵelementStart(4, "kendo-grid-column", 34);
    i0.ɵɵpipe(5, "translate");
    i0.ɵɵtemplate(6, PriceListListComponent_ng_template_16_ng_template_6_Template, 2, 5, "ng-template", 35);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(7, "kendo-grid-column", 36);
    i0.ɵɵpipe(8, "translate");
    i0.ɵɵtemplate(9, PriceListListComponent_ng_template_16_ng_template_9_Template, 3, 6, "ng-template", 35);
    i0.ɵɵelementEnd();
    i0.ɵɵelement(10, "kendo-grid-column", 37);
    i0.ɵɵpipe(11, "translate");
    i0.ɵɵelement(12, "kendo-grid-column", 38);
    i0.ɵɵpipe(13, "translate");
    i0.ɵɵelement(14, "kendo-grid-column", 39);
    i0.ɵɵpipe(15, "translate");
    i0.ɵɵelement(16, "kendo-grid-column", 40);
    i0.ɵɵpipe(17, "translate");
    i0.ɵɵelement(18, "kendo-grid-column", 41);
    i0.ɵɵpipe(19, "translate");
    i0.ɵɵelementEnd()()();
  }
  if (rf & 2) {
    const ctx_r6 = i0.ɵɵnextContext();
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("data", ctx_r6.query)("selectable", i0.ɵɵpureFunction0(27, _c1))("sort", ctx_r6.gridSort)("sortable", true)("selection", ctx_r6.selection);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("width", 1);
    i0.ɵɵadvance(1);
    i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(5, 13, "Price List Name"));
    i0.ɵɵadvance(3);
    i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(8, 15, "Price List Type"));
    i0.ɵɵadvance(3);
    i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(11, 17, "Price List Status"));
    i0.ɵɵadvance(2);
    i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(13, 19, "Valid From"));
    i0.ɵɵadvance(2);
    i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(15, 21, "Valid To"));
    i0.ɵɵadvance(2);
    i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(17, 23, "Last Modfied By"));
    i0.ɵɵadvance(2);
    i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(19, 25, "Last Modified Date"));
  }
}
export class PriceListListComponent extends AutoFilterBreezeListComponent {
  constructor(breezeViewService, sharedService, modalService, dialogService) {
    super(breezeViewService, sharedService, modalService, dialogService);
    this.breezeViewService = breezeViewService;
    this.sharedService = sharedService;
    this.modalService = modalService;
    this.dialogService = dialogService;
    this.queryName = 'PriceLists';
    this.parentRoute = '/price-list/';
    this.excelCommand = 'ExportPriceListsToExcel';
    this.createPermission = PriceListPermissions.Action.create;
    this.breadcrumb = [{
      icon: 'list',
      title: this.translateService.get('Price Lists')
    }];
    this.gridSort = [{
      field: 'projectId',
      dir: 'asc'
    }];
    this.actionBar[0].items.push({
      label: this.translateService.instant('Delete'),
      icon: 'trash',
      isDisabled: () => this.selection.length === 0,
      onClick: () => super.executeAndHandle(this.queryName, 'DeletePriceLists', 'Delete'),
      permissions: [PriceListPermissions.Action.delete]
    });
    this.actionBar.push({
      label: this.translateService.instant('Workflow'),
      items: [{
        label: this.translateService.instant('Send To Archive'),
        icon: 'archive',
        isDisabled: () => this.selection.length === 0,
        onClick: () => super.executeAndHandle(this.queryName, 'ArchivePriceLists', 'Archive'),
        permissions: [PriceListPermissions.Action.archive]
      }]
    });
  }
  getDefaultFilter(data) {
    return this.breezeViewService.entityManager.createEntity('GetPriceListsQuery', {
      archived: this.activeTab === 1,
      ...data
    });
  }
  onSortChange(sort) {
    this.gridSort = sort;
  }
}
PriceListListComponent.ɵfac = function PriceListListComponent_Factory(t) {
  return new (t || PriceListListComponent)(i0.ɵɵdirectiveInject(i1.BreezeViewService), i0.ɵɵdirectiveInject(i2.SharedService), i0.ɵɵdirectiveInject(i3.NgbModal), i0.ɵɵdirectiveInject(i4.DialogService));
};
PriceListListComponent.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
  type: PriceListListComponent,
  selectors: [["app-price-list-list"]],
  features: [i0.ɵɵProvidersFeature([BreezeViewService]), i0.ɵɵInheritDefinitionFeature],
  decls: 18,
  vars: 13,
  consts: [[3, "items"], [3, "title", "breadcrumb"], [1, "filter-wrapper"], ["activeIds", "ngb-panel-0"], ["ngbPanelHeader", ""], ["ngbPanelContent", ""], [1, "mt-3", 3, "tabSelect"], ["tabStrip", ""], [3, "title", "selected"], ["kendoTabContent", ""], ["tabContent", ""], [3, "panel", "panelChange"], ["icon", "filter"], [4, "ngIf"], [3, "submit"], ["filterHighlighter", ""], [1, "row"], [1, "col-3"], ["type", "codelist", "codelist", "PriceListType", "property", "priceListTypeId", 3, "label", "ngModel", "entity", "ngModelChange"], ["type", "codelist", "codelist", "PriceListStatus", "property", "status", 3, "label", "ngModel", "entity", "ngModelChange"], [1, "col-2"], ["type", "datetime", "property", "validFrom", 3, "label", "ngModel", "entity", "ngModelChange"], ["type", "datetime", "property", "validTo", 3, "label", "ngModel", "entity", "ngModelChange"], [1, "row", "mt-3"], [1, "col"], [1, "float-right"], [1, "btn", "btn-sm", "btn-danger", "mr-2", 3, "click"], ["icon", "trash", 1, "ml-1"], [1, "btn-text"], [1, "btn", "btn-sm", "btn-primary", 3, "click"], ["icon", "search", 1, "ml-1"], [4, "ngTemplateOutlet"], [1, "mb-2", 3, "data", "selectable", "sort", "sortable", "selection", "selectionChange", "sortChange"], ["showSelectAll", "true", 3, "width"], ["field", "projectId", 3, "title"], ["kendoGridCellTemplate", ""], ["field", "priceListTypeId", 3, "title"], ["field", "status.name", 3, "title"], ["field", "validFrom", "format", "d", 3, "title"], ["field", "validTo", "format", "d", 3, "title"], ["field", "lastModifiedBy.fullName", 3, "title"], ["field", "lastModifiedDate", "format", "g", 3, "title"], [1, "text-primary", "link-box", 2, "cursor", "pointer", 3, "routerLink"]],
  template: function PriceListListComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelement(0, "app-action-bar", 0)(1, "app-content-header", 1);
      i0.ɵɵpipe(2, "translate");
      i0.ɵɵelementStart(3, "div", 2)(4, "ngb-accordion", 3)(5, "ngb-panel");
      i0.ɵɵtemplate(6, PriceListListComponent_ng_template_6_Template, 6, 5, "ng-template", 4);
      i0.ɵɵtemplate(7, PriceListListComponent_ng_template_7_Template, 31, 26, "ng-template", 5);
      i0.ɵɵelementEnd()()();
      i0.ɵɵelementStart(8, "kendo-tabstrip", 6, 7);
      i0.ɵɵlistener("tabSelect", function PriceListListComponent_Template_kendo_tabstrip_tabSelect_8_listener($event) {
        return ctx.tabSelected($event);
      });
      i0.ɵɵelementStart(10, "kendo-tabstrip-tab", 8);
      i0.ɵɵpipe(11, "translate");
      i0.ɵɵtemplate(12, PriceListListComponent_ng_template_12_Template, 1, 1, "ng-template", 9);
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(13, "kendo-tabstrip-tab", 8);
      i0.ɵɵpipe(14, "translate");
      i0.ɵɵtemplate(15, PriceListListComponent_ng_template_15_Template, 1, 1, "ng-template", 9);
      i0.ɵɵelementEnd()();
      i0.ɵɵtemplate(16, PriceListListComponent_ng_template_16_Template, 20, 28, "ng-template", null, 10, i0.ɵɵtemplateRefExtractor);
    }
    if (rf & 2) {
      i0.ɵɵproperty("items", ctx.actionBar);
      i0.ɵɵadvance(1);
      i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(2, 7, "Price Lists"));
      i0.ɵɵproperty("breadcrumb", ctx.breadcrumb);
      i0.ɵɵadvance(9);
      i0.ɵɵproperty("title", i0.ɵɵpipeBind1(11, 9, "Price Lists"))("selected", ctx.activeTab === 0);
      i0.ɵɵadvance(3);
      i0.ɵɵproperty("title", i0.ɵɵpipeBind1(14, 11, "Archived Price Lists"))("selected", ctx.activeTab === 1);
    }
  },
  dependencies: [i5.NgIf, i5.NgTemplateOutlet, i6.RouterLink, i7.NgControlStatus, i7.NgModel, i3.NgbAccordion, i3.NgbPanel, i3.NgbPanelContent, i3.NgbPanelHeader, i8.FaIconComponent, i9.ColumnComponent, i9.CheckboxColumnComponent, i9.CellTemplateDirective, i10.TabStripComponent, i10.TabStripTabComponent, i10.TabContentDirective, i11.ActionBarComponent, i12.AppControlComponent, i13.AppFormComponent, i14.ContentHeaderComponent, i15.AppGridComponent, i16.AppControlContainerComponent, i17.AccordionHeaderComponent, i5.AsyncPipe, i18.TranslatePipe, i19.CodelistPipe],
  styles: ["\n/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IiIsInNvdXJjZVJvb3QiOiIifQ== */"]
});