import { AutoFilterBreezeListComponent } from '@common/classes/auto-filter-list';
import { BreezeViewService } from '@common/services/breeze-view.service';
import { SharedService } from '@common/services/shared.service';
import * as i0 from "@angular/core";
import * as i1 from "@common/services/breeze-view.service";
import * as i2 from "@common/services/shared.service";
import * as i3 from "@angular/common";
import * as i4 from "@angular/router";
import * as i5 from "@angular/forms";
import * as i6 from "@ng-bootstrap/ng-bootstrap";
import * as i7 from "@fortawesome/angular-fontawesome";
import * as i8 from "@progress/kendo-angular-grid";
import * as i9 from "../../../../common/components/action-bar/action-bar.component";
import * as i10 from "../../../../common/components/app-control/app-control.component";
import * as i11 from "../../../../common/components/app-form/app-form.component";
import * as i12 from "../../../../common/components/content-header/content-header.component";
import * as i13 from "../../../../common/components/app-grid/app-grid.component";
import * as i14 from "../../../../common/components/app-control-container/app-control-container.component";
import * as i15 from "../../../../common/components/accordion-header.component";
import * as i16 from "@ngx-translate/core";
function RoleListComponent_ng_template_6_span_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span");
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r4 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate1("\u00A0(", ctx_r4.numberOfActiveFilters, ")");
  }
}
function RoleListComponent_ng_template_6_Template(rf, ctx) {
  if (rf & 1) {
    const _r6 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "app-accordion-header", 11);
    i0.ɵɵlistener("panelChange", function RoleListComponent_ng_template_6_Template_app_accordion_header_panelChange_0_listener() {
      i0.ɵɵrestoreView(_r6);
      const ctx_r5 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r5.handleFilterHighlighter(true));
    });
    i0.ɵɵelementStart(1, "h5");
    i0.ɵɵelement(2, "fa-icon", 12);
    i0.ɵɵtext(3);
    i0.ɵɵpipe(4, "translate");
    i0.ɵɵtemplate(5, RoleListComponent_ng_template_6_span_5_Template, 2, 1, "span", 13);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const panel_r3 = ctx.$implicit;
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("panel", panel_r3);
    i0.ɵɵadvance(3);
    i0.ɵɵtextInterpolate1(" \u00A0", i0.ɵɵpipeBind1(4, 3, "Filters"), " ");
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngIf", ctx_r0.numberOfActiveFilters > 0);
  }
}
function RoleListComponent_ng_template_7_Template(rf, ctx) {
  if (rf & 1) {
    const _r9 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "app-form", 14);
    i0.ɵɵlistener("submit", function RoleListComponent_ng_template_7_Template_app_form_submit_0_listener() {
      i0.ɵɵrestoreView(_r9);
      const ctx_r8 = i0.ɵɵnextContext();
      ctx_r8.search();
      return i0.ɵɵresetView(ctx_r8.onAutoFilterChange());
    });
    i0.ɵɵelementStart(1, "app-control-container", null, 15)(3, "div", 16)(4, "div", 17)(5, "app-control", 18);
    i0.ɵɵlistener("ngModelChange", function RoleListComponent_ng_template_7_Template_app_control_ngModelChange_5_listener($event) {
      i0.ɵɵrestoreView(_r9);
      const ctx_r10 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r10.filter.name = $event);
    });
    i0.ɵɵpipe(6, "translate");
    i0.ɵɵelementEnd()()();
    i0.ɵɵelementStart(7, "div", 19)(8, "div", 17)(9, "div", 20)(10, "button", 21);
    i0.ɵɵlistener("click", function RoleListComponent_ng_template_7_Template_button_click_10_listener() {
      i0.ɵɵrestoreView(_r9);
      const ctx_r11 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r11.clearFilter());
    });
    i0.ɵɵelement(11, "fa-icon", 22);
    i0.ɵɵtext(12, " \u00A0 ");
    i0.ɵɵelementStart(13, "span", 23);
    i0.ɵɵtext(14);
    i0.ɵɵpipe(15, "translate");
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(16, "button", 24);
    i0.ɵɵlistener("click", function RoleListComponent_ng_template_7_Template_button_click_16_listener() {
      i0.ɵɵrestoreView(_r9);
      const ctx_r12 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r12.search());
    });
    i0.ɵɵelement(17, "fa-icon", 25);
    i0.ɵɵtext(18, " \u00A0 ");
    i0.ɵɵelementStart(19, "span", 23);
    i0.ɵɵtext(20);
    i0.ɵɵpipe(21, "translate");
    i0.ɵɵelementEnd()()()()()()();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance(5);
    i0.ɵɵpropertyInterpolate("label", i0.ɵɵpipeBind1(6, 5, "Name"));
    i0.ɵɵproperty("ngModel", ctx_r1.filter.name)("entity", ctx_r1.filter);
    i0.ɵɵadvance(9);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(15, 7, "Clear"));
    i0.ɵɵadvance(6);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(21, 9, "Search"));
  }
}
const _c0 = function (a1, a2) {
  return ["/administration/role/", a1, a2];
};
function RoleListComponent_ng_template_13_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "a", 26);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const row_r13 = ctx.$implicit;
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵproperty("routerLink", i0.ɵɵpureFunction2(2, _c0, ctx_r2.defaultViewMode, row_r13.id));
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate1(" ", row_r13.normalizedName, " ");
  }
}
export class RoleListComponent extends AutoFilterBreezeListComponent {
  constructor(breezeViewService, sharedService) {
    super(breezeViewService, sharedService);
    this.breezeViewService = breezeViewService;
    this.sharedService = sharedService;
    this.queryName = 'Roles';
    this.parentRoute = '/administration/role';
    this.breadcrumb = [{
      icon: 'user-plus',
      title: this.translateService.get('Roles')
    }];
  }
  getDefaultFilter(data) {
    return this.entityManager.createEntity('GetRolesQuery', {
      ...data
    });
  }
}
RoleListComponent.ɵfac = function RoleListComponent_Factory(t) {
  return new (t || RoleListComponent)(i0.ɵɵdirectiveInject(i1.BreezeViewService), i0.ɵɵdirectiveInject(i2.SharedService));
};
RoleListComponent.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
  type: RoleListComponent,
  selectors: [["app-role-list"]],
  features: [i0.ɵɵProvidersFeature([BreezeViewService]), i0.ɵɵInheritDefinitionFeature],
  decls: 14,
  vars: 9,
  consts: [[3, "items"], [3, "title", "breadcrumb"], [1, "filter-wrapper"], ["activeIds", "ngb-panel-0"], ["ngbPanelHeader", ""], ["ngbPanelContent", ""], [1, "card", "card-border-top", "mt-3"], [1, "card-body"], [1, "mb-2", 3, "data"], ["field", "name", 3, "title"], ["kendoGridCellTemplate", ""], [3, "panel", "panelChange"], ["icon", "filter"], [4, "ngIf"], [3, "submit"], ["filterHighlighter", ""], [1, "row"], [1, "col"], ["property", "name", 3, "label", "ngModel", "entity", "ngModelChange"], [1, "row", "mt-3"], [1, "float-right"], [1, "btn", "btn-sm", "btn-danger", "mr-2", 3, "click"], ["icon", "trash", 1, "ml-1"], [1, "btn-text"], [1, "btn", "btn-sm", "btn-primary", 3, "click"], ["icon", "search", 1, "ml-1"], [1, "text-primary", "link-box", 3, "routerLink"]],
  template: function RoleListComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelement(0, "app-action-bar", 0)(1, "app-content-header", 1);
      i0.ɵɵpipe(2, "translate");
      i0.ɵɵelementStart(3, "div", 2)(4, "ngb-accordion", 3)(5, "ngb-panel");
      i0.ɵɵtemplate(6, RoleListComponent_ng_template_6_Template, 6, 5, "ng-template", 4);
      i0.ɵɵtemplate(7, RoleListComponent_ng_template_7_Template, 22, 11, "ng-template", 5);
      i0.ɵɵelementEnd()()();
      i0.ɵɵelementStart(8, "div", 6)(9, "div", 7)(10, "app-grid", 8)(11, "kendo-grid-column", 9);
      i0.ɵɵpipe(12, "translate");
      i0.ɵɵtemplate(13, RoleListComponent_ng_template_13_Template, 2, 5, "ng-template", 10);
      i0.ɵɵelementEnd()()()();
    }
    if (rf & 2) {
      i0.ɵɵproperty("items", ctx.actionBar);
      i0.ɵɵadvance(1);
      i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(2, 5, "Roles"));
      i0.ɵɵproperty("breadcrumb", ctx.breadcrumb);
      i0.ɵɵadvance(9);
      i0.ɵɵproperty("data", ctx.query);
      i0.ɵɵadvance(1);
      i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(12, 7, "Name"));
    }
  },
  dependencies: [i3.NgIf, i4.RouterLink, i5.NgControlStatus, i5.NgModel, i6.NgbAccordion, i6.NgbPanel, i6.NgbPanelContent, i6.NgbPanelHeader, i7.FaIconComponent, i8.ColumnComponent, i8.CellTemplateDirective, i9.ActionBarComponent, i10.AppControlComponent, i11.AppFormComponent, i12.ContentHeaderComponent, i13.AppGridComponent, i14.AppControlContainerComponent, i15.AccordionHeaderComponent, i16.TranslatePipe],
  styles: ["\n/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IiIsInNvdXJjZVJvb3QiOiIifQ== */"]
});