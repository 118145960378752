import { AbstractBreezeViewComponent } from '@common/classes/breeze-view';
import { BreezeViewService } from '@common/services/breeze-view.service';
import * as i0 from "@angular/core";
import * as i1 from "@common/services/breeze-view.service";
import * as i2 from "@angular/forms";
import * as i3 from "../../../../common/components/action-bar/action-bar.component";
import * as i4 from "../../../../common/components/app-control/app-control.component";
import * as i5 from "../../../../common/components/loader/loader.component";
import * as i6 from "../../../../common/components/content-header/content-header.component";
import * as i7 from "@ngx-translate/core";
function ExchangeRateViewComponent_ng_template_2_Template(rf, ctx) {
  if (rf & 1) {
    const _r2 = i0.ɵɵgetCurrentView();
    i0.ɵɵelement(0, "app-action-bar", 2)(1, "app-content-header", 3);
    i0.ɵɵelementStart(2, "div", 4)(3, "div", 5)(4, "div", 6)(5, "div", 7)(6, "app-control", 8);
    i0.ɵɵlistener("ngModelChange", function ExchangeRateViewComponent_ng_template_2_Template_app_control_ngModelChange_6_listener($event) {
      i0.ɵɵrestoreView(_r2);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.model.sourceId = $event);
    });
    i0.ɵɵpipe(7, "translate");
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(8, "div", 7)(9, "app-control", 9);
    i0.ɵɵlistener("ngModelChange", function ExchangeRateViewComponent_ng_template_2_Template_app_control_ngModelChange_9_listener($event) {
      i0.ɵɵrestoreView(_r2);
      const ctx_r3 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r3.model.typeId = $event);
    });
    i0.ɵɵpipe(10, "translate");
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(11, "div", 7)(12, "app-control", 10);
    i0.ɵɵlistener("ngModelChange", function ExchangeRateViewComponent_ng_template_2_Template_app_control_ngModelChange_12_listener($event) {
      i0.ɵɵrestoreView(_r2);
      const ctx_r4 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r4.model.currencyId = $event);
    });
    i0.ɵɵpipe(13, "translate");
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(14, "div", 7)(15, "app-control", 11);
    i0.ɵɵlistener("ngModelChange", function ExchangeRateViewComponent_ng_template_2_Template_app_control_ngModelChange_15_listener($event) {
      i0.ɵɵrestoreView(_r2);
      const ctx_r5 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r5.model.date = $event);
    });
    i0.ɵɵpipe(16, "translate");
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(17, "div", 7)(18, "app-control", 12);
    i0.ɵɵlistener("ngModelChange", function ExchangeRateViewComponent_ng_template_2_Template_app_control_ngModelChange_18_listener($event) {
      i0.ɵɵrestoreView(_r2);
      const ctx_r6 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r6.model.quantity = $event);
    });
    i0.ɵɵpipe(19, "translate");
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(20, "div", 7)(21, "app-control", 13);
    i0.ɵɵlistener("ngModelChange", function ExchangeRateViewComponent_ng_template_2_Template_app_control_ngModelChange_21_listener($event) {
      i0.ɵɵrestoreView(_r2);
      const ctx_r7 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r7.model.value = $event);
    });
    i0.ɵɵpipe(22, "translate");
    i0.ɵɵelementEnd()()()()();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("items", ctx_r0.actionBar);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("title", ctx_r0.title)("breadcrumb", ctx_r0.breadcrumb);
    i0.ɵɵadvance(5);
    i0.ɵɵproperty("label", i0.ɵɵpipeBind1(7, 21, "Source"))("ngModel", ctx_r0.model.sourceId)("entity", ctx_r0.model);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("label", i0.ɵɵpipeBind1(10, 23, "Type"))("ngModel", ctx_r0.model.typeId)("entity", ctx_r0.model);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("label", i0.ɵɵpipeBind1(13, 25, "Currency"))("ngModel", ctx_r0.model.currencyId)("entity", ctx_r0.model);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("label", i0.ɵɵpipeBind1(16, 27, "Date"))("ngModel", ctx_r0.model.date)("entity", ctx_r0.model);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("label", i0.ɵɵpipeBind1(19, 29, "Quantity"))("ngModel", ctx_r0.model.quantity)("entity", ctx_r0.model);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("label", i0.ɵɵpipeBind1(22, 31, "Value"))("ngModel", ctx_r0.model.value)("entity", ctx_r0.model);
  }
}
export class ExchangeRateViewComponent extends AbstractBreezeViewComponent {
  createEntity() {
    const entity = super.createEntity();
    entity.typeId = 'S';
    entity.sourceId = 'BSS';
    entity.date = new Date();
    return entity;
  }
  constructor(breezeViewService) {
    super(breezeViewService);
    this.breezeViewService = breezeViewService;
    this.entityName = 'ExchangeRate';
    this.parentRoute = '/administration/exchange-rate/';
    this.breadcrumb = [{
      icon: 'list',
      title: this.translateService.get('Exchange Rate'),
      route: '/administration/exchange-rate/list'
    }, {
      icon: 'exchange-alt',
      title: this.translateService.get('Exchange Rate')
    }];
    this.editActionBarGroup.items.push({
      label: this.translateService.instant('Delete'),
      icon: 'trash',
      isVisible: () => {
        return true;
      },
      onClick: () => {
        return this.dialogService.confirm(this.translateService.instant('Confirm'), this.translateService.instant('You are going to delete this item')).then(result => {
          if (result === true) {
            return this.breezeViewService.handleCommand('DeleteExchangeRates', {
              Ids: [this.model.id]
            }).then(x => {
              this.router.navigate([`${this.parentRoute}/list`]);
            });
          }
        });
      }
    });
  }
}
ExchangeRateViewComponent.ɵfac = function ExchangeRateViewComponent_Factory(t) {
  return new (t || ExchangeRateViewComponent)(i0.ɵɵdirectiveInject(i1.BreezeViewService));
};
ExchangeRateViewComponent.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
  type: ExchangeRateViewComponent,
  selectors: [["app-exchange-rate-view"]],
  features: [i0.ɵɵProvidersFeature([BreezeViewService]), i0.ɵɵInheritDefinitionFeature],
  decls: 3,
  vars: 1,
  consts: [[3, "isBusy"], [1, "row", "separator"], [3, "items"], [3, "title", "breadcrumb"], [1, "card", "card-border-top"], [1, "card-body"], [1, "row"], [1, "col-4"], ["type", "codelist", "codelist", "ExchangeRateSource", "property", "sourceId", 3, "label", "ngModel", "entity", "ngModelChange"], ["type", "codelist", "codelist", "ExchangeRateType", "property", "typeId", 3, "label", "ngModel", "entity", "ngModelChange"], ["type", "codelist", "codelist", "Currency", "property", "currencyId", 3, "label", "ngModel", "entity", "ngModelChange"], ["property", "date", 3, "label", "ngModel", "entity", "ngModelChange"], ["format", "#", "property", "quantity", 3, "label", "ngModel", "entity", "ngModelChange"], ["format", "n4", "property", "value", 3, "label", "ngModel", "entity", "ngModelChange"]],
  template: function ExchangeRateViewComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "app-loader", 0);
      i0.ɵɵelement(1, "div", 1);
      i0.ɵɵtemplate(2, ExchangeRateViewComponent_ng_template_2_Template, 23, 33, "ng-template");
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      i0.ɵɵproperty("isBusy", !ctx.model);
    }
  },
  dependencies: [i2.NgControlStatus, i2.NgModel, i3.ActionBarComponent, i4.AppControlComponent, i5.LoaderComponent, i6.ContentHeaderComponent, i7.TranslatePipe],
  styles: ["\n/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IiIsInNvdXJjZVJvb3QiOiIifQ== */"]
});