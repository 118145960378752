import { CommonModule as NgCommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ChangesDialogComponent } from '@common/components/changes-dialog.component';
import { NewVersionDialogComponent } from '@common/components/new-version-dialog.component';
import { WarningDialogComponent } from '@common/components/warning-dialog.component';
import { CodelistService } from '@common/services/codelist.service';
import { SignalrService } from '@common/services/signalr.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from '@progress/kendo-angular-buttons';
import { DateInputsModule, DatePickerModule } from '@progress/kendo-angular-dateinputs';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { ExcelModule, GridModule } from '@progress/kendo-angular-grid';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { IntlModule } from '@progress/kendo-angular-intl';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { NavigationModule } from '@progress/kendo-angular-navigation';
import { FileSelectModule } from '@progress/kendo-angular-upload';
import { AccordionHeaderComponent } from './components/accordion-header.component';
import { ActionBarComponent } from './components/action-bar/action-bar.component';
import { AppControlContainerComponent } from './components/app-control-container/app-control-container.component';
import { AppControlComponent } from './components/app-control/app-control.component';
import { AppFormComponent } from './components/app-form/app-form.component';
import { AppGridComponent } from './components/app-grid/app-grid.component';
import { BookmarkComponent } from './components/bookmark/bookmark.component';
import { BoolComponent } from './components/bool/bool.component';
import { CheckIconComponent } from './components/check-icon.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { ConfirmDialogComponent } from './components/confirm-dialog.component';
import { ContentHeaderComponent } from './components/content-header/content-header.component';
import { ContentLinkHeaderComponent } from './components/content-link-header/content-link-header.component';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { DialogFormComponent } from './components/dialog-form/dialog-form.component';
import { EditableGridComponent } from './components/editable-grid/editable-grid.component';
import { EntitiesErrorsComponent } from './components/entities-errors/entities-errors.component';
import { ErrorDialogComponent } from './components/error-dialog.component';
import { FlagIconComponent } from './components/flag-icon.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { InfoButtonComponent } from './components/info-button.component';
import { InvoiceListComponent } from './components/invoice-list/invoice-list.component';
import { LoaderComponent } from './components/loader/loader.component';
import { LoginComponent } from './components/login/login.component';
import { LogoutComponent } from './components/login/logout.component';
import { NmswChargeCandidatesModalComponent } from './components/nmsw-charge-candidates-modal/nmsw-charge-candidates-modal.component';
import { NmswVesselVisitViewComponent } from './components/nmsw-vessel-visit-view/nmsw-vessel-visit-view.component';
import { NoteListComponent } from './components/note-list/note-list.component';
import { NotificationComponent } from './components/notification/notification.component';
import { PopoverListComponent } from './components/popover-list/popover-list.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { ToggleableFilterComponent } from './components/toggleable-filter/toggleable-filter.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { UploadActionButtonsComponent } from './components/upload-action-buttons.component';
import { UploadMultipleFilesComponent } from './components/upload-multiple-files.component';
import { UploadSingleFileComponent } from './components/upload-single-file.component';
import { VesselPermitDetailComponent } from './components/vessel-permit-detail/vessel-permit-detail.component';
import { VesselRevisionChangeApplicatorComponent } from './components/vessel-revision-change-applicator/vessel-revision-change-applicator.component';
import { VesselRevisionChangesModalComponent } from './components/vessel-revision-changes-modal/vessel-revision-changes-modal.component';
import { VesselVisitEventListComponent } from './components/vessel-visit-event-list/vessel-visit-event-list.component';
import { VesselVisitServiceListComponent } from './components/vessel-visit-service-list/vessel-visit-service-list.component';
import { VesselVisitViewComponent } from './components/vessel-visit-view/vessel-visit-view.component';
import { AuthGuard } from './guards/auth.guard';
import { AppHttpInterceptor } from './interceptors/http-interceptor';
import { ActivePipe } from './pipes/active.pipe';
import { CodelistPipe } from './pipes/codelist.pipe';
import { FormatListPipe } from './pipes/format-list.pipe';
import { GetVesselPipe } from './pipes/getVessel.pipe';
import { PadPipe } from './pipes/pad.pipe';
import { BreezeService } from './services/breeze.service';
import { CqrsService } from './services/cqrs.service';
import { DialogService } from './services/dialog.service';
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "@angular/forms";
import * as i3 from "@progress/kendo-angular-grid";
import * as i4 from "@angular/common";
export class CommonModule {}
CommonModule.ɵfac = function CommonModule_Factory(t) {
  return new (t || CommonModule)();
};
CommonModule.ɵmod = /*@__PURE__*/i0.ɵɵdefineNgModule({
  type: CommonModule
});
CommonModule.ɵinj = /*@__PURE__*/i0.ɵɵdefineInjector({
  providers: [DialogService, AuthGuard, BreezeService, CodelistPipe, {
    provide: HTTP_INTERCEPTORS,
    useClass: AppHttpInterceptor,
    multi: true
  }, CodelistService, SignalrService, CqrsService],
  imports: [NgCommonModule, RouterModule, FormsModule, ReactiveFormsModule, FontAwesomeModule, NgbModule, TranslateModule.forChild({
    extend: true
  }), IntlModule, GridModule, ExcelModule, InputsModule, ButtonModule, DropDownsModule, DateInputsModule, DatePickerModule, LayoutModule, DialogModule, FileSelectModule, IndicatorsModule, NavigationModule
  // VesselRevisionModule
  , NgCommonModule, RouterModule, FormsModule, ReactiveFormsModule, NgbModule, TranslateModule, FontAwesomeModule, IntlModule, GridModule, ExcelModule, InputsModule, ButtonModule, DropDownsModule, DateInputsModule, DatePickerModule, LayoutModule, DialogModule, IndicatorsModule, NavigationModule]
});
(function () {
  (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(CommonModule, {
    declarations: [LoginComponent, LogoutComponent, UnauthorizedComponent, SidebarComponent, ActionBarComponent, HeaderComponent, FooterComponent, AppControlComponent, CheckboxComponent, LoaderComponent, ConfirmDialogComponent, DatePickerComponent, ChangesDialogComponent, NewVersionDialogComponent, WarningDialogComponent, ContentHeaderComponent, ContentLinkHeaderComponent, AppGridComponent, EntitiesErrorsComponent, ErrorDialogComponent, UploadSingleFileComponent, UploadMultipleFilesComponent, UploadActionButtonsComponent, UnauthorizedComponent, CodelistPipe, PadPipe, ActivePipe, GetVesselPipe, FormatListPipe, AppFormComponent, BoolComponent, NoteListComponent, AppControlContainerComponent, NotificationComponent, EditableGridComponent, ConfirmDialogComponent, WarningDialogComponent, ChangesDialogComponent, NewVersionDialogComponent, EntitiesErrorsComponent, ErrorDialogComponent, DatePickerComponent, FlagIconComponent, InfoButtonComponent, CheckIconComponent, ToggleableFilterComponent, PopoverListComponent, BookmarkComponent, DialogFormComponent,
    // Billing specific
    AccordionHeaderComponent, InvoiceListComponent, VesselVisitViewComponent, VesselVisitEventListComponent, VesselVisitServiceListComponent, NmswVesselVisitViewComponent, NmswChargeCandidatesModalComponent, VesselPermitDetailComponent, VesselRevisionChangeApplicatorComponent, VesselRevisionChangesModalComponent],
    imports: [NgCommonModule, RouterModule, FormsModule, ReactiveFormsModule, FontAwesomeModule, NgbModule, i1.TranslateModule, IntlModule, GridModule, ExcelModule, InputsModule, ButtonModule, DropDownsModule, DateInputsModule, DatePickerModule, LayoutModule, DialogModule, FileSelectModule, IndicatorsModule, NavigationModule
    // VesselRevisionModule
    ],
    exports: [NgCommonModule, RouterModule, FormsModule, ReactiveFormsModule, NgbModule, TranslateModule, FontAwesomeModule, IntlModule, GridModule, ExcelModule, InputsModule, ButtonModule, DropDownsModule, DateInputsModule, DatePickerModule, LayoutModule, DialogModule, IndicatorsModule, NavigationModule,
    // VesselRevisionModule,
    SidebarComponent, ActionBarComponent, HeaderComponent, FooterComponent, AppControlComponent, AppFormComponent, CheckboxComponent, LoaderComponent, ContentHeaderComponent, ContentLinkHeaderComponent, CodelistPipe, PadPipe, ActivePipe, GetVesselPipe, FormatListPipe, AppGridComponent, BoolComponent, NoteListComponent, AppControlContainerComponent, EditableGridComponent, ConfirmDialogComponent, WarningDialogComponent, ChangesDialogComponent, NewVersionDialogComponent, EntitiesErrorsComponent, ErrorDialogComponent, DatePickerComponent, FlagIconComponent, CheckIconComponent, InfoButtonComponent, AccordionHeaderComponent, ToggleableFilterComponent, PopoverListComponent, BookmarkComponent, DialogFormComponent,
    // Billing specific
    InvoiceListComponent, VesselVisitViewComponent, VesselVisitEventListComponent, VesselVisitServiceListComponent, NmswVesselVisitViewComponent, NmswChargeCandidatesModalComponent, VesselPermitDetailComponent, VesselRevisionChangeApplicatorComponent, VesselRevisionChangesModalComponent]
  });
})();
i0.ɵɵsetComponentScope(UploadMultipleFilesComponent, function () {
  return [i2.NgControlStatus, i2.NgModel, i3.ColumnComponent, i3.CellTemplateDirective, i3.EditTemplateDirective, AppControlComponent, EditableGridComponent];
}, function () {
  return [i4.AsyncPipe, i1.TranslatePipe, CodelistPipe];
});