import { RouterModule } from '@angular/router';
import { CanDeactivateView } from '@common/guards/view.deactivate';
import { ViewMode } from '@common/models/view-mode';
import { CommonModule } from '../common/common.module';
import { CustomerBankAccountViewComponent } from './components/customer-bank-account-view/customer-bank-account-view.component';
import { CustomerEmailViewComponent } from './components/customer-email-view/customer-email-view.component';
import { CustomerListComponent } from './components/customer-list/customer-list.component';
import { CustomerTaxNumberViewComponent } from './components/customer-tax-number-view/customer-tax-number-view.component';
import { CustomerViewComponent } from './components/customer-view/customer-view.component';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
export const CUSTOMER_ROUTES = [{
  path: '',
  redirectTo: 'list',
  pathMatch: 'full'
}, {
  path: 'list',
  component: CustomerListComponent
}, {
  path: 'view/:id',
  component: CustomerViewComponent,
  data: {
    mode: ViewMode.view
  }
}, {
  path: 'edit/:id',
  component: CustomerViewComponent,
  data: {
    mode: ViewMode.edit
  },
  canDeactivate: [CanDeactivateView]
}, {
  path: 'create',
  component: CustomerViewComponent,
  data: {
    mode: ViewMode.create
  },
  canDeactivate: [CanDeactivateView]
}, {
  path: '**',
  redirectTo: 'list'
}];
export class CustomerModule {}
CustomerModule.ɵfac = function CustomerModule_Factory(t) {
  return new (t || CustomerModule)();
};
CustomerModule.ɵmod = /*@__PURE__*/i0.ɵɵdefineNgModule({
  type: CustomerModule
});
CustomerModule.ɵinj = /*@__PURE__*/i0.ɵɵdefineInjector({
  providers: [CanDeactivateView],
  imports: [CommonModule, RouterModule.forChild(CUSTOMER_ROUTES)]
});
(function () {
  (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(CustomerModule, {
    declarations: [CustomerListComponent, CustomerViewComponent, CustomerEmailViewComponent, CustomerTaxNumberViewComponent, CustomerBankAccountViewComponent],
    imports: [CommonModule, i1.RouterModule]
  });
})();