import { RouterModule } from '@angular/router';
import { CommonModule } from '@common/common.module';
import { CanDeactivateView } from '@common/guards/view.deactivate';
import { ViewMode } from '@common/models/view-mode';
import { InvoiceListComponent } from './components/invoice-list/invoice-list.component';
import { InvoiceViewComponent } from './components/invoice-view/invoice-view.component';
import { RecipientReportListComponent } from '@invoice/components/recipient-report-list/recipient-report-list.component';
import { InvoiceService } from '@invoice/services/invoice.service';
import { InvoiceApprovalListComponent } from './components/invoice-approval-list/invoice-approval-list.component';
import { InvoiceChangeListComponent } from './components/invoice-change-list/invoice-change-list.component';
import { InvoiceCloneModalComponent } from './components/invoice-clone-modal/invoice-clone-modal.component';
import { InvoiceEmailRecipientComponent } from './components/invoice-email-recipient/invoice-email-recipient.component';
import { InvoiceFullCreditNoteComponent } from './components/invoice-full-credit-note/invoice-full-credit-note.component';
import { InvoiceIssueDateComponent } from './components/invoice-issue-date/invoice-issue-date.component';
import { InvoiceItemListComponent } from './components/invoice-item-list/invoice-item-list.component';
import { InvoicePeriodListComponent } from './components/invoice-period-list/invoice-period-list.component';
import { InvoicePeriodViewComponent } from './components/invoice-period-view/invoice-period-view.component';
import { ManualEntryInvoicesReportComponent } from './components/manual-entry-invoices-report/manual-entry-invoices-report.component';
import { ManuallyChangedEventsReportComponent } from './components/manually-changed-events-report/manually-changed-events-report.component';
import { ManuallyCreatedInvoicesReportComponent } from './components/manually-created-invoices-report/manually-created-invoices-report.component';
import { PricePerUnitReportComponent } from './components/price-per-unit-report/price-per-unit-report.component';
import { RelatedDetailsComponent } from './components/related-details/related-details.component';
import { ServiceReportListComponent } from './components/service-report-list/service-report-list.component';
import { UnbilledAdditionalWorkListComponent } from './components/unbilled-additional-work-list/unbilled-additional-work-list.component';
import { UnbilledEventsReportComponent } from './components/unbilled-events-report/unbilled-events-report.component';
import { UnbilledManipulationListComponent } from './components/unbilled-manipulation-list/unbilled-manipulation-list.component';
import { UnbilledStorageEventListComponent } from './components/unbilled-storage-event-list/unbilled-storage-event-list.component';
import { UnbilledVesselVisitEventListComponent } from './components/unbilled-vessel-visit-event-list/unbilled-vessel-visit-event-list.component';
import { VoyageReportListComponent } from './components/voyage-report-list/voyage-report-list.component';
import { QrCodeModalComponent } from './components/qr-code-modal/qr-code-modal.component';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
const INVOICE_ROUTES = [{
  path: '',
  redirectTo: 'list',
  pathMatch: 'full'
}, {
  path: 'list',
  component: InvoiceListComponent
}, {
  path: 'approvalList',
  component: InvoiceApprovalListComponent
}, {
  path: 'voyageList',
  component: VoyageReportListComponent
}, {
  path: 'serviceList',
  component: ServiceReportListComponent
}, {
  path: 'unbilledEventsReport',
  component: UnbilledEventsReportComponent
}, {
  path: 'recipientList',
  component: RecipientReportListComponent
}, {
  path: 'manuallyCreatedInvoicesReport',
  component: ManuallyCreatedInvoicesReportComponent
}, {
  path: 'pricePerUnitReport',
  component: PricePerUnitReportComponent
}, {
  path: 'manualEntryInvoicesReport',
  component: ManualEntryInvoicesReportComponent
}, {
  path: 'manuallyChangedEventsReport',
  component: ManuallyChangedEventsReportComponent
}, {
  path: 'invoice-period-list',
  component: InvoicePeriodListComponent
}, {
  path: 'invoice-period-list/view/:id',
  component: InvoicePeriodViewComponent,
  data: {
    mode: ViewMode.view
  }
}, {
  path: 'invoice-period-list/edit/:id',
  component: InvoicePeriodViewComponent,
  data: {
    mode: ViewMode.edit
  },
  canDeactivate: [CanDeactivateView]
}, {
  path: 'invoice-period-list/create',
  component: InvoicePeriodViewComponent,
  data: {
    mode: ViewMode.create
  },
  canDeactivate: [CanDeactivateView]
}, {
  path: 'view/:id',
  component: InvoiceViewComponent,
  data: {
    mode: ViewMode.view
  }
}, {
  path: 'edit/:id',
  component: InvoiceViewComponent,
  data: {
    mode: ViewMode.edit
  },
  canDeactivate: [CanDeactivateView]
}, {
  path: 'create',
  component: InvoiceViewComponent,
  data: {
    mode: ViewMode.create
  },
  canDeactivate: [CanDeactivateView]
}];
export class InvoiceModule {}
InvoiceModule.ɵfac = function InvoiceModule_Factory(t) {
  return new (t || InvoiceModule)();
};
InvoiceModule.ɵmod = /*@__PURE__*/i0.ɵɵdefineNgModule({
  type: InvoiceModule
});
InvoiceModule.ɵinj = /*@__PURE__*/i0.ɵɵdefineInjector({
  providers: [CanDeactivateView, InvoiceService],
  imports: [CommonModule, RouterModule.forChild(INVOICE_ROUTES)]
});
(function () {
  (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(InvoiceModule, {
    declarations: [InvoiceListComponent, InvoiceViewComponent, InvoiceApprovalListComponent, InvoiceApprovalListComponent, InvoiceEmailRecipientComponent, InvoiceCloneModalComponent, RelatedDetailsComponent, InvoiceIssueDateComponent, InvoiceFullCreditNoteComponent, VoyageReportListComponent, ServiceReportListComponent, InvoiceChangeListComponent, RecipientReportListComponent, UnbilledEventsReportComponent, UnbilledManipulationListComponent, UnbilledStorageEventListComponent, UnbilledAdditionalWorkListComponent, UnbilledVesselVisitEventListComponent, InvoicePeriodListComponent, InvoicePeriodViewComponent, ManuallyCreatedInvoicesReportComponent, PricePerUnitReportComponent, ManualEntryInvoicesReportComponent, ManuallyChangedEventsReportComponent, InvoiceItemListComponent, QrCodeModalComponent],
    imports: [CommonModule, i1.RouterModule]
  });
})();