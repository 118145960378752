import * as i0 from "@angular/core";
export class GetVesselPipe {
  transform(visit, args) {
    return visit.vesselRevision || visit.vtsVessel;
  }
}
GetVesselPipe.ɵfac = function GetVesselPipe_Factory(t) {
  return new (t || GetVesselPipe)();
};
GetVesselPipe.ɵpipe = /*@__PURE__*/i0.ɵɵdefinePipe({
  name: "getVessel",
  type: GetVesselPipe,
  pure: true
});