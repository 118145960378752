import { CommonModule as AngularCommonModule, registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import localeMt from '@angular/common/locales/en-MT';
import { ErrorHandler, LOCALE_ID } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, PreloadAllModules, Router, RouterModule } from '@angular/router';
import { config, EntityManager, NamingConvention } from '@cime/breeze-client';
import { AjaxHttpClientAdapter } from '@cime/breeze-client/adapter-ajax-httpclient';
import { AjaxPostWrapper } from '@cime/breeze-client/adapter-ajax-post';
import { DataServiceWebApiAdapter } from '@cime/breeze-client/adapter-data-service-webapi';
import { ModelLibraryBackingStoreAdapter } from '@cime/breeze-client/adapter-model-library-backing-store';
import { UriBuilderJsonAdapter } from '@cime/breeze-client/adapter-uri-builder-json';
import { mixinEntityGraph } from '@cime/breeze-client/mixin-get-entity-graph';
import { ConsoleContentDirective } from '@common/directives/console-content.directive';
import { DialogErrorHandler } from '@common/handlers/dialog-error-handler';
import { ConsoleWindowService } from '@common/services/console-window.service';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { NgbModalConfig, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoadingBarModule, LoadingBarService } from '@ngx-loading-bar/core';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { IntlModule } from '@progress/kendo-angular-intl';
import '@progress/kendo-angular-intl/locales/en-MT/all';
import { ToastrModule } from 'ngx-toastr';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { ROUTES } from './app.routing';
import { FluentValidators } from './common/breeze-validators';
import { CommonModule } from './common/common.module';
import { UserService } from './common/services/user.service';
import { HomeComponent } from './home/home.component';
import * as i0 from "@angular/core";
import * as i1 from "./common/services/user.service";
import * as i2 from "@angular/router";
import * as i3 from "@ngx-loading-bar/core";
import * as i4 from "@ngx-translate/core";
import * as i5 from "@angular/common/http";
import * as i6 from "@ng-bootstrap/ng-bootstrap";
import * as i7 from "@fortawesome/angular-fontawesome";
import * as i8 from "ngx-toastr";
// the second parameter 'fr' is optional
registerLocaleData(localeMt, 'en-MT');
// AoT requires an exported function for factories
export function HttpLoaderFactory(http) {
  return new TranslateHttpLoader(http);
}
let entityManager;
function setupBreeze() {
  mixinEntityGraph(EntityManager);
  NamingConvention.camelCase.setAsDefault();
  const fv = new FluentValidators();
  fv.registerAsBreezeValidators();
  const breezeMetadataUrl = 'api:///query';
  entityManager = new EntityManager(breezeMetadataUrl);
}
setupBreeze();
export function getEntityManager() {
  return entityManager;
}
export class AppModule {
  constructor(userService, router, loadingBarService, translate, http, modalConfig, library) {
    this.userService = userService;
    this.loadingBarService = loadingBarService;
    // the order is important
    ModelLibraryBackingStoreAdapter.register();
    UriBuilderJsonAdapter.register();
    AjaxHttpClientAdapter.register(http);
    DataServiceWebApiAdapter.register();
    AjaxPostWrapper.wrapAjax(config.getAdapterInstance('ajax'));
    library.addIconPacks(fas);
    library.addIconPacks(far);
    modalConfig.backdrop = 'static';
    const routerSate = this.loadingBarService.useRef('router');
    router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        routerSate.start();
      } else if (event instanceof NavigationError) {
        routerSate.complete();
        console.error(event.error);
      } else if (event instanceof NavigationEnd) {
        routerSate.complete();
      } else if (event instanceof NavigationCancel) {
        routerSate.complete();
      }
    });
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('en');
    // the lang to use, if the lang isn't available, it will use the current loader to get them
    translate.use(environment.defaultLanguage);
  }
}
AppModule.ɵfac = function AppModule_Factory(t) {
  return new (t || AppModule)(i0.ɵɵinject(i1.UserService), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.LoadingBarService), i0.ɵɵinject(i4.TranslateService), i0.ɵɵinject(i5.HttpClient), i0.ɵɵinject(i6.NgbModalConfig), i0.ɵɵinject(i7.FaIconLibrary));
};
AppModule.ɵmod = /*@__PURE__*/i0.ɵɵdefineNgModule({
  type: AppModule,
  bootstrap: [AppComponent]
});
AppModule.ɵinj = /*@__PURE__*/i0.ɵɵdefineInjector({
  providers: [{
    provide: LOCALE_ID,
    useValue: 'en-MT'
  }, {
    provide: EntityManager,
    useFactory: getEntityManager
  }, UserService, ConsoleWindowService, {
    provide: ErrorHandler,
    useClass: DialogErrorHandler
  }],
  imports: [AngularCommonModule, BrowserModule, BrowserAnimationsModule, FormsModule, HttpClientModule, RouterModule.forRoot(ROUTES, {
    preloadingStrategy: PreloadAllModules
  }), IntlModule, ToastrModule.forRoot(), NgbModule, LoadingBarModule, TranslateModule.forRoot({
    loader: {
      provide: TranslateLoader,
      useFactory: HttpLoaderFactory,
      deps: [HttpClient]
    }
  }), CommonModule, BrowserAnimationsModule, RouterModule, ToastrModule, NgbModule, LoadingBarModule, IntlModule]
});
(function () {
  (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(AppModule, {
    declarations: [AppComponent, HomeComponent, ConsoleContentDirective],
    imports: [AngularCommonModule, BrowserModule, BrowserAnimationsModule, FormsModule, HttpClientModule, i2.RouterModule, IntlModule, i8.ToastrModule, NgbModule, LoadingBarModule, i4.TranslateModule, CommonModule],
    exports: [BrowserAnimationsModule, RouterModule, ToastrModule, NgbModule, LoadingBarModule, IntlModule]
  });
})();