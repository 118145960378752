import _asyncToGenerator from "C:/GitLab-Runner/builds/VgMjNYji/0/billing/billingmalta/src/frontend/node_modules/@babel/runtime/helpers/esm/asyncToGenerator.js";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { EntityManager } from '@cime/breeze-client';
import { User } from '@common/breeze-models/user';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { filter } from 'rxjs/operators';
import { UserService } from '../services/user.service';
import * as i0 from "@angular/core";
import * as i1 from "@cime/breeze-client";
import * as i2 from "../services/user.service";
import * as i3 from "ngx-toastr";
import * as i4 from "@ngx-translate/core";
import * as i5 from "@angular/router";
export class AuthGuard {
  constructor(entityManager, userService, toastrService, translateService, router) {
    this.entityManager = entityManager;
    this.userService = userService;
    this.toastrService = toastrService;
    this.translateService = translateService;
    this.router = router;
    userService.isAuthenticatedSubject.pipe(filter(x => !x)).subscribe(value => {
      this.fetchMetadataPromise = null;
    });
  }
  fetchUserAndMetadata(state) {
    var _this = this;
    return _asyncToGenerator(function* () {
      try {
        yield _this.userService.getCurrentUser();
        if (_this.entityManager.metadataStore.isEmpty()) {
          yield _this.entityManager.fetchMetadata().then(store => {
            // TODO: move elsewhere
            _this.entityManager.metadataStore.registerEntityTypeCtor('User', User);
          });
        }
        return true;
      } catch (error) {
        _this.toastrService.error(_this.translateService.instant('You must be authenticated') || 'You must be authenticated');
        _this.userService.logout().then(() => {
          _this.router.navigate(['/login'], {
            queryParams: {
              returnUrl: state.url
            }
          });
        });
        return false;
      }
    })();
  }
  canActivate(route, state) {
    if (!this.fetchMetadataPromise) {
      this.fetchMetadataPromise = this.fetchUserAndMetadata(state);
    }
    return this.fetchMetadataPromise;
  }
  canActivateChild(childRoute, state) {
    return this.canActivate(childRoute, state);
  }
}
AuthGuard.ɵfac = function AuthGuard_Factory(t) {
  return new (t || AuthGuard)(i0.ɵɵinject(i1.EntityManager), i0.ɵɵinject(i2.UserService), i0.ɵɵinject(i3.ToastrService), i0.ɵɵinject(i4.TranslateService), i0.ɵɵinject(i5.Router));
};
AuthGuard.ɵprov = /*@__PURE__*/i0.ɵɵdefineInjectable({
  token: AuthGuard,
  factory: AuthGuard.ɵfac,
  providedIn: 'root'
});