import { ComponentRef, Injector, ViewContainerRef } from '@angular/core';
import { ConsoleWindowService } from '@common/services/console-window.service';
import * as i0 from "@angular/core";
import * as i1 from "@common/services/console-window.service";
export class ConsoleContentDirective {
  constructor(viewContainerRef, consoleWindowService, injector) {
    this.viewContainerRef = viewContainerRef;
    this.injector = injector;
    this.componentSubscription = consoleWindowService.componentSubject.subscribe(data => {
      if (!data) {
        this.destroyComponent();
      } else {
        this.loadComponent(data.component, data.dataProviders);
      }
    });
  }
  loadComponent(component, dataProviders) {
    this.destroyComponent();
    this.viewContainerRef.clear();
    const injector = Injector.create({
      providers: dataProviders || [],
      parent: this.injector
    });
    this.componentRef = this.viewContainerRef.createComponent(component, {
      injector
    });
  }
  destroyComponent() {
    if (this.componentRef) {
      this.componentRef.destroy();
    }
  }
  ngOnDestroy() {
    this.componentSubscription.unsubscribe();
  }
}
ConsoleContentDirective.ɵfac = function ConsoleContentDirective_Factory(t) {
  return new (t || ConsoleContentDirective)(i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(i1.ConsoleWindowService), i0.ɵɵdirectiveInject(i0.Injector));
};
ConsoleContentDirective.ɵdir = /*@__PURE__*/i0.ɵɵdefineDirective({
  type: ConsoleContentDirective,
  selectors: [["", "appConsoleContent", ""]]
});