import { UserService } from '@common/services/user.service';
import { environment } from '@environments/environment';
import * as signalR from '@microsoft/signalr';
import { HubConnectionState } from '@microsoft/signalr';
import _ from 'lodash';
import { catchError, from, Subject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@common/services/user.service";
export class SignalrService {
  constructor(userService) {
    this.userService = userService;
    this.data = [];
    this.notifications$ = new Subject();
    this.setupHub();
    userService.currentUserSubject.pipe(catchError(err => from(console.error(err)))).subscribe(user => {
      if (user) {
        if (this.hubConnection.state !== HubConnectionState.Connected && this.hubConnection.state !== HubConnectionState.Connecting) {
          return this.hubConnection.start().then(() => this.flush()).catch(err => console.error(`Error while establishing SignalR connection: ${err}`));
        }
      } else if (this.hubConnection.state !== HubConnectionState.Disconnected && this.hubConnection.state !== HubConnectionState.Disconnecting) {
        return this.hubConnection.stop();
      }
    });
  }
  setupHub() {
    try {
      this.hubConnection = new signalR.HubConnectionBuilder().withUrl(`${environment.serverUrl}/hubs/signalr`, {
        accessTokenFactory: () => this.userService.getAccessToken()
        // transport: signalR.HttpTransportType.ServerSentEvents
      }).configureLogging(environment.production ? signalR.LogLevel.Warning : signalR.LogLevel.Information).withAutomaticReconnect().build();
      this.hubConnection.on('TotalClients', totalClients => {
        if (!environment.production) {
          console.info('TotalClients', totalClients);
        }
      });
      this.hubConnection.on('Notify', data => {
        this.notifications$.next(data);
      });
      return true;
    } catch (error) {
      return false;
    }
  }
  flush() {
    _.forEach(this.data, x => {
      this.hubConnection.invoke(x.level, x.message).then(x.resolve).catch(x.reject);
    });
    this.data = [];
  }
  log(message, level) {
    if (this.hubConnection.state === HubConnectionState.Connected) {
      return this.hubConnection.invoke(level, message).catch(error => console.error(error));
    }
    return new Promise((resolve, reject) => {
      this.data.push({
        level,
        message,
        resolve,
        reject
      });
    });
  }
  success(message) {
    return this.log(message, 'success');
  }
  info(message) {
    return this.log(message, 'info');
  }
  warn(message) {
    return this.log(message, 'warn');
  }
  error(message) {
    return this.log(message, 'error');
  }
}
SignalrService.ɵfac = function SignalrService_Factory(t) {
  return new (t || SignalrService)(i0.ɵɵinject(i1.UserService));
};
SignalrService.ɵprov = /*@__PURE__*/i0.ɵɵdefineInjectable({
  token: SignalrService,
  factory: SignalrService.ɵfac,
  providedIn: 'root'
});