import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BreezeService } from './breeze.service';
import * as i0 from "@angular/core";
import * as i1 from "./breeze.service";
import * as i2 from "@ngx-translate/core";
import * as i3 from "ngx-toastr";
export class GridEditService {
  constructor(breeze, translateService, toastrService) {
    this.breeze = breeze;
    this.translateService = translateService;
    this.toastrService = toastrService;
    this.editedRowIndex = null;
    this.editedRow = null;
  }
  saveHandler({
    sender,
    rowIndex
  }) {
    this.closeEditor(sender, rowIndex);
  }
  cancelHandler({
    sender,
    rowIndex
  }) {
    const row = sender.data.data[rowIndex];
    if (row.id > 0) {
      const entity = this.entityManager.metadataStore.getEntityType(this.entityName);
      entity.getPropertyNames().forEach(prop => {
        if ((typeof sender.data.data[rowIndex][prop] !== 'object' || !isNaN(Date.parse(sender.data.data[rowIndex][prop]))) && sender.data.data[rowIndex][prop] !== null) {
          row[prop] = this.editedRow[prop];
        }
      });
      row.entityAspect.setUnchanged();
    } else {
      this.removeHandler({
        sender,
        rowIndex
      }, true);
    }
    this.closeEditor(sender, rowIndex);
  }
  editHandler({
    sender,
    rowIndex,
    dataItem
  }, entityName) {
    this.entityName = entityName;
    this.closeEditor(sender);
    this.editedRowIndex = rowIndex;
    this.editedRow = this.breeze.convertToDto(dataItem);
    sender.editRow(rowIndex);
    sender.expandRow(rowIndex);
  }
  addHandler({
    sender
  }, gridData, entityName = null, createParamters = null) {
    this.entityName = entityName;
    this.closeEditor(sender);
    gridData.push(this.entityManager.createEntity(entityName, createParamters));
    const rowIndex = gridData.length - 1;
    this.editedRowIndex = rowIndex;
    sender.editRow(rowIndex);
    sender.expandRow(rowIndex);
  }
  removeHandler({
    sender,
    rowIndex
  }, override = false) {
    if (this.editedRowIndex === null || override) {
      const item = sender.data.data[rowIndex];
      if (this.entityName !== 'Attachment') {
        if (item.id > 0) item.entityAspect.setDeleted();else item.entityAspect.setDetached();
      }
    } else {
      this.toastrService.error(this.translateService.instant('You must finish editing your current row first'));
    }
  }
  closeEditor(sender, rowIndex = this.editedRowIndex) {
    sender.collapseRow(rowIndex);
    sender.closeRow(rowIndex);
    const row = sender.data.data[rowIndex];
    if (row?.id < 0 && !this.hasAnyValues(row)) {
      this.removeHandler({
        sender,
        rowIndex
      }, true);
    }
    this.editedRowIndex = null;
  }
  hasAnyValues(row) {
    const entity = this.entityManager.metadataStore.getEntityType(this.entityName);
    // Ignore parent entities and versioned properties
    const properties = entity.getPropertyNames().filter(prop => prop !== 'id' && !prop.includes('created') && !prop.includes('lastModified') && !['version', 'vesselVisit', 'vesselVisitId', 'invoice', 'invoiceId', 'priceList', 'priceListId'].includes(prop));
    if (this.entityName === 'Attachment') properties.filter(prop => !['size', 'manuallyAdded', 'internal', 'fileName', 'content'].includes(prop));
    if (this.entityName === 'VesselVisit') properties.filter(prop => !prop.includes('container'));
    return properties.some(prop => row[prop]?.hasOwnProperty('length') ? row[prop].length > 0 : !!row[prop]);
  }
}
GridEditService.ɵfac = function GridEditService_Factory(t) {
  return new (t || GridEditService)(i0.ɵɵinject(i1.BreezeService), i0.ɵɵinject(i2.TranslateService), i0.ɵɵinject(i3.ToastrService));
};
GridEditService.ɵprov = /*@__PURE__*/i0.ɵɵdefineInjectable({
  token: GridEditService,
  factory: GridEditService.ɵfac,
  providedIn: 'root'
});