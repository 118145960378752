import * as i0 from "@angular/core";
export class PadPipe {
  transform(value, ...args) {
    const maxLength = args[0] || 5;
    const fillString = args[1] || '0';
    return value?.toString().padStart(maxLength, fillString);
  }
}
PadPipe.ɵfac = function PadPipe_Factory(t) {
  return new (t || PadPipe)();
};
PadPipe.ɵpipe = /*@__PURE__*/i0.ɵɵdefinePipe({
  name: "pad",
  type: PadPipe,
  pure: true
});