import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "@ng-bootstrap/ng-bootstrap";
import * as i3 from "../pipes/codelist.pipe";
const _c0 = function (a0) {
  return {
    "box-shadow tight": a0
  };
};
function FlagIconComponent_span_0_span_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span");
    i0.ɵɵelement(1, "span", 1);
    i0.ɵɵtext(2);
    i0.ɵɵpipe(3, "async");
    i0.ɵɵpipe(4, "codelist");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(1);
    i0.ɵɵclassMapInterpolate2("fi fi-", ctx_r2.getCountry(), " ", ctx_r2.square ? "fis" : "", "");
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(11, _c0, ctx_r2.floating));
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate1(" \u00A0", i0.ɵɵpipeBind1(3, 6, i0.ɵɵpipeBind2(4, 8, ctx_r2.code, "" + ctx_r2.codelist)), " ");
  }
}
function FlagIconComponent_span_0_span_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span");
    i0.ɵɵelement(1, "span", 2);
    i0.ɵɵpipe(2, "async");
    i0.ɵɵpipe(3, "codelist");
    i0.ɵɵtext(4, " \u00A0 ");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r3 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(1);
    i0.ɵɵclassMapInterpolate2("fi fi-", ctx_r3.getCountry(), " ", ctx_r3.square ? "fis" : "", "");
    i0.ɵɵpropertyInterpolate("ngbTooltip", i0.ɵɵpipeBind1(2, 6, i0.ɵɵpipeBind2(3, 8, ctx_r3.code, "" + ctx_r3.codelist)));
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(11, _c0, ctx_r3.floating));
  }
}
function FlagIconComponent_span_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span");
    i0.ɵɵtemplate(1, FlagIconComponent_span_0_span_1_Template, 5, 13, "span", 0);
    i0.ɵɵtemplate(2, FlagIconComponent_span_0_span_2_Template, 5, 13, "span", 0);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", !ctx_r0.tooltip);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r0.tooltip);
  }
}
function FlagIconComponent_span_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span");
    i0.ɵɵelement(1, "span", 1);
    i0.ɵɵtext(2, " \u00A0 ");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance(1);
    i0.ɵɵclassMapInterpolate2("fi fi-", ctx_r1.getCountry(), " ", ctx_r1.square ? "fis" : "", "");
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(5, _c0, ctx_r1.floating));
  }
}
const _c1 = ["*"];
export class FlagIconComponent {
  constructor() {
    this.tooltip = false;
    this.square = false;
    this.floating = false;
  }
  getCountry() {
    if (!this.code || this.code.substring(0, 2) === 'ZZ') return 'xx';
    return this.code.substring(0, 2).toLowerCase();
  }
}
FlagIconComponent.ɵfac = function FlagIconComponent_Factory(t) {
  return new (t || FlagIconComponent)();
};
FlagIconComponent.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
  type: FlagIconComponent,
  selectors: [["app-flag-icon"]],
  inputs: {
    code: "code",
    codelist: "codelist",
    tooltip: "tooltip",
    square: "square",
    floating: "floating"
  },
  ngContentSelectors: _c1,
  decls: 3,
  vars: 2,
  consts: [[4, "ngIf"], [3, "ngClass"], ["placement", "left", "container", "body", 3, "ngbTooltip", "ngClass"]],
  template: function FlagIconComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵtemplate(0, FlagIconComponent_span_0_Template, 3, 2, "span", 0);
      i0.ɵɵtemplate(1, FlagIconComponent_span_1_Template, 3, 7, "span", 0);
      i0.ɵɵprojection(2);
    }
    if (rf & 2) {
      i0.ɵɵproperty("ngIf", ctx.code && ctx.codelist);
      i0.ɵɵadvance(1);
      i0.ɵɵproperty("ngIf", ctx.code && !ctx.codelist);
    }
  },
  dependencies: [i1.NgClass, i1.NgIf, i2.NgbTooltip, i1.AsyncPipe, i3.CodelistPipe],
  encapsulation: 2
});