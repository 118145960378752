import { EntityQuery, FilterQueryOp, Predicate } from '@cime/breeze-client';
import { AutoFilterBreezeListComponent } from '@common/classes/auto-filter-list';
import { UploadActionButtonsComponent } from '@common/components/upload-action-buttons.component';
import { BreezeViewService } from '@common/services/breeze-view.service';
import { DialogService } from '@common/services/dialog.service';
import { SharedService } from '@common/services/shared.service';
import * as _ from 'lodash';
import * as i0 from "@angular/core";
import * as i1 from "@common/services/breeze-view.service";
import * as i2 from "@common/services/shared.service";
import * as i3 from "@common/services/dialog.service";
import * as i4 from "@angular/common";
import * as i5 from "@angular/router";
import * as i6 from "@angular/forms";
import * as i7 from "@ng-bootstrap/ng-bootstrap";
import * as i8 from "@fortawesome/angular-fontawesome";
import * as i9 from "@progress/kendo-angular-grid";
import * as i10 from "../../../../common/components/action-bar/action-bar.component";
import * as i11 from "../../../../common/components/app-control/app-control.component";
import * as i12 from "../../../../common/components/app-form/app-form.component";
import * as i13 from "../../../../common/components/content-header/content-header.component";
import * as i14 from "../../../../common/components/app-grid/app-grid.component";
import * as i15 from "../../../../common/components/app-control-container/app-control-container.component";
import * as i16 from "../../../../common/components/check-icon.component";
import * as i17 from "../../../../common/components/accordion-header.component";
import * as i18 from "@ngx-translate/core";
function PdfTemplateListComponent_ng_template_6_span_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span");
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r5 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate1("\u00A0(", ctx_r5.numberOfActiveFilters, ")");
  }
}
function PdfTemplateListComponent_ng_template_6_Template(rf, ctx) {
  if (rf & 1) {
    const _r7 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "app-accordion-header", 18);
    i0.ɵɵlistener("panelChange", function PdfTemplateListComponent_ng_template_6_Template_app_accordion_header_panelChange_0_listener() {
      i0.ɵɵrestoreView(_r7);
      const ctx_r6 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r6.handleFilterHighlighter(true));
    });
    i0.ɵɵelementStart(1, "h5");
    i0.ɵɵelement(2, "fa-icon", 19);
    i0.ɵɵtext(3);
    i0.ɵɵpipe(4, "translate");
    i0.ɵɵtemplate(5, PdfTemplateListComponent_ng_template_6_span_5_Template, 2, 1, "span", 20);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const panel_r4 = ctx.$implicit;
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("panel", panel_r4);
    i0.ɵɵadvance(3);
    i0.ɵɵtextInterpolate1(" \u00A0", i0.ɵɵpipeBind1(4, 3, "Filters"), " ");
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngIf", ctx_r0.numberOfActiveFilters > 0);
  }
}
function PdfTemplateListComponent_ng_template_7_Template(rf, ctx) {
  if (rf & 1) {
    const _r10 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "app-form", 21);
    i0.ɵɵlistener("submit", function PdfTemplateListComponent_ng_template_7_Template_app_form_submit_0_listener() {
      i0.ɵɵrestoreView(_r10);
      const ctx_r9 = i0.ɵɵnextContext();
      ctx_r9.search();
      return i0.ɵɵresetView(ctx_r9.onAutoFilterChange());
    });
    i0.ɵɵelementStart(1, "app-control-container", null, 22)(3, "div", 23)(4, "div", 24)(5, "app-control", 25);
    i0.ɵɵlistener("ngModelChange", function PdfTemplateListComponent_ng_template_7_Template_app_control_ngModelChange_5_listener($event) {
      i0.ɵɵrestoreView(_r10);
      const ctx_r11 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r11.filter.pdfTemplateName = $event);
    });
    i0.ɵɵpipe(6, "translate");
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(7, "div", 24)(8, "app-control", 26);
    i0.ɵɵlistener("ngModelChange", function PdfTemplateListComponent_ng_template_7_Template_app_control_ngModelChange_8_listener($event) {
      i0.ɵɵrestoreView(_r10);
      const ctx_r12 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r12.filter.pdfTemplateInternalNameId = $event);
    });
    i0.ɵɵpipe(9, "translate");
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(10, "div", 24)(11, "app-control", 27);
    i0.ɵɵlistener("ngModelChange", function PdfTemplateListComponent_ng_template_7_Template_app_control_ngModelChange_11_listener($event) {
      i0.ɵɵrestoreView(_r10);
      const ctx_r13 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r13.filter.pdfTemplateLanguageId = $event);
    });
    i0.ɵɵpipe(12, "translate");
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(13, "div", 24)(14, "app-control", 28);
    i0.ɵɵlistener("ngModelChange", function PdfTemplateListComponent_ng_template_7_Template_app_control_ngModelChange_14_listener($event) {
      i0.ɵɵrestoreView(_r10);
      const ctx_r14 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r14.filter.active = $event);
    });
    i0.ɵɵpipe(15, "translate");
    i0.ɵɵelementEnd()()();
    i0.ɵɵelementStart(16, "div", 29)(17, "div", 24)(18, "div", 30)(19, "button", 31);
    i0.ɵɵlistener("click", function PdfTemplateListComponent_ng_template_7_Template_button_click_19_listener() {
      i0.ɵɵrestoreView(_r10);
      const ctx_r15 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r15.clearFilter());
    });
    i0.ɵɵelement(20, "fa-icon", 32);
    i0.ɵɵtext(21, " \u00A0 ");
    i0.ɵɵelementStart(22, "span", 33);
    i0.ɵɵtext(23);
    i0.ɵɵpipe(24, "translate");
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(25, "button", 34);
    i0.ɵɵlistener("click", function PdfTemplateListComponent_ng_template_7_Template_button_click_25_listener() {
      i0.ɵɵrestoreView(_r10);
      const ctx_r16 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r16.search());
    });
    i0.ɵɵelement(26, "fa-icon", 35);
    i0.ɵɵtext(27, " \u00A0 ");
    i0.ɵɵelementStart(28, "span", 33);
    i0.ɵɵtext(29);
    i0.ɵɵpipe(30, "translate");
    i0.ɵɵelementEnd()()()()()()();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance(5);
    i0.ɵɵpropertyInterpolate("label", i0.ɵɵpipeBind1(6, 14, "Name"));
    i0.ɵɵproperty("ngModel", ctx_r1.filter.pdfTemplateName)("entity", ctx_r1.filter);
    i0.ɵɵadvance(3);
    i0.ɵɵpropertyInterpolate("label", i0.ɵɵpipeBind1(9, 16, "Internal Name"));
    i0.ɵɵproperty("ngModel", ctx_r1.filter.pdfTemplateInternalNameId)("entity", ctx_r1.filter);
    i0.ɵɵadvance(3);
    i0.ɵɵpropertyInterpolate("label", i0.ɵɵpipeBind1(12, 18, "Language"));
    i0.ɵɵproperty("ngModel", ctx_r1.filter.pdfTemplateLanguageId)("entity", ctx_r1.filter);
    i0.ɵɵadvance(3);
    i0.ɵɵpropertyInterpolate("label", i0.ɵɵpipeBind1(15, 20, "Active"));
    i0.ɵɵproperty("ngModel", ctx_r1.filter.active)("entity", ctx_r1.filter);
    i0.ɵɵadvance(9);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(24, 22, "Clear"));
    i0.ɵɵadvance(6);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(30, 24, "Search"));
  }
}
const _c0 = function (a1, a2) {
  return ["/administration/pdf-template/", a1, a2];
};
function PdfTemplateListComponent_ng_template_14_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "a", 36);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const row_r17 = ctx.$implicit;
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵproperty("routerLink", i0.ɵɵpureFunction2(2, _c0, ctx_r2.defaultViewMode, row_r17.id));
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate1(" ", row_r17.id, " ");
  }
}
function PdfTemplateListComponent_ng_template_23_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "app-check-icon", 37);
  }
  if (rf & 2) {
    const row_r18 = ctx.$implicit;
    i0.ɵɵproperty("check", row_r18.active);
  }
}
const _c1 = function () {
  return {
    enabled: true,
    checkboxOnly: true
  };
};
export class PdfTemplateListComponent extends AutoFilterBreezeListComponent {
  constructor(breezeViewService, sharedService, dialogService) {
    super(breezeViewService, sharedService, null, dialogService);
    this.breezeViewService = breezeViewService;
    this.sharedService = sharedService;
    this.dialogService = dialogService;
    this.queryName = 'PdfTemplates';
    this.parentRoute = '/administration/pdf-template/';
    this.breadcrumb = [{
      icon: 'file',
      title: this.translateService.get('Pdf Templates')
    }];
    this.addDeleteButton();
  }
  getDefaultFilter() {
    return this.entityManager.createEntity('GetPdfTemplatesQuery');
  }
  addDeleteButton() {
    this.actionBar[0].items = this.actionBar[0].items.concat([{
      label: this.translateService.instant('Delete'),
      icon: 'trash',
      isDisabled: () => {
        return !this.selection.length;
      },
      onClick: () => {
        return this.dialogService.confirm(this.translateService.instant('Confirm'), this.translateService.instant('You are going to delete the pdf templates')).then(result => {
          if (result === true) {
            return this.breezeViewService.handleCommand('DeletePdfTemplates', {
              Ids: this.selection
            }).then(x => {
              this.search();
            });
          }
        });
      }
    }]);
    this.actionBar[0].items = this.actionBar[0].items.concat([{
      label: this.translateService.instant('Download'),
      icon: 'download',
      isDisabled: () => {
        return this.selection.length !== 1;
      },
      onClick: () => {
        const pdfTemplate = _.first(EntityQuery.from('PdfTemplates').using(this.entityManager).where(Predicate.create('id', FilterQueryOp.In, this.selection)).executeLocally());
        return this.breezeViewService.handleQuery('DownloadAttachment', {
          Id: pdfTemplate.contentId
        }).then(result => {
          const attachment = _.first(result);
          UploadActionButtonsComponent.preview(attachment.name, attachment.content);
        });
      }
    }]);
  }
}
PdfTemplateListComponent.ɵfac = function PdfTemplateListComponent_Factory(t) {
  return new (t || PdfTemplateListComponent)(i0.ɵɵdirectiveInject(i1.BreezeViewService), i0.ɵɵdirectiveInject(i2.SharedService), i0.ɵɵdirectiveInject(i3.DialogService));
};
PdfTemplateListComponent.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
  type: PdfTemplateListComponent,
  selectors: [["app-pdf-template-list"]],
  features: [i0.ɵɵProvidersFeature([BreezeViewService]), i0.ɵɵInheritDefinitionFeature],
  decls: 28,
  vars: 32,
  consts: [[3, "items"], [3, "title", "breadcrumb"], [1, "filter-wrapper"], ["activeIds", "ngb-panel-0"], ["ngbPanelHeader", ""], ["ngbPanelContent", ""], [1, "card", "card-border-top", "mt-3"], [1, "card-body"], [1, "mb-2", 3, "data", "selectable", "selectBy", "selection", "selectionChange"], ["showSelectAll", "true", 3, "width"], ["field", "id", 3, "title"], ["kendoGridCellTemplate", ""], ["field", "name", 3, "title"], ["field", "internalNameId", 3, "title"], ["field", "languageId", 3, "title"], ["field", "active", 3, "title"], ["field", "lastModifiedDate", "format", "g", 3, "title"], ["field", "lastModifiedBy.fullName", 3, "title"], [3, "panel", "panelChange"], ["icon", "filter"], [4, "ngIf"], [3, "submit"], ["filterHighlighter", ""], [1, "row"], [1, "col"], ["property", "pdfTemplateName", 3, "label", "ngModel", "entity", "ngModelChange"], ["type", "codelist", "codelist", "PdfTemplateInternalName", "property", "pdfTemplateInternalNameId", 3, "label", "ngModel", "entity", "ngModelChange"], ["type", "codelist", "codelist", "Language", "property", "pdfTemplateLanguageId", 3, "label", "ngModel", "entity", "ngModelChange"], ["property", "active", 3, "label", "ngModel", "entity", "ngModelChange"], [1, "row", "mt-3"], [1, "float-right"], [1, "btn", "btn-sm", "btn-danger", "mr-2", 3, "click"], ["icon", "trash", 1, "ml-1"], [1, "btn-text"], [1, "btn", "btn-sm", "btn-primary", 3, "click"], ["icon", "search", 1, "ml-1"], [1, "text-primary", "link-box", 3, "routerLink"], [3, "check"]],
  template: function PdfTemplateListComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelement(0, "app-action-bar", 0)(1, "app-content-header", 1);
      i0.ɵɵpipe(2, "translate");
      i0.ɵɵelementStart(3, "div", 2)(4, "ngb-accordion", 3)(5, "ngb-panel");
      i0.ɵɵtemplate(6, PdfTemplateListComponent_ng_template_6_Template, 6, 5, "ng-template", 4);
      i0.ɵɵtemplate(7, PdfTemplateListComponent_ng_template_7_Template, 31, 26, "ng-template", 5);
      i0.ɵɵelementEnd()()();
      i0.ɵɵelementStart(8, "div", 6)(9, "div", 7)(10, "app-grid", 8);
      i0.ɵɵlistener("selectionChange", function PdfTemplateListComponent_Template_app_grid_selectionChange_10_listener($event) {
        return ctx.selection = $event;
      });
      i0.ɵɵelement(11, "kendo-grid-checkbox-column", 9);
      i0.ɵɵelementStart(12, "kendo-grid-column", 10);
      i0.ɵɵpipe(13, "translate");
      i0.ɵɵtemplate(14, PdfTemplateListComponent_ng_template_14_Template, 2, 5, "ng-template", 11);
      i0.ɵɵelementEnd();
      i0.ɵɵelement(15, "kendo-grid-column", 12);
      i0.ɵɵpipe(16, "translate");
      i0.ɵɵelement(17, "kendo-grid-column", 13);
      i0.ɵɵpipe(18, "translate");
      i0.ɵɵelement(19, "kendo-grid-column", 14);
      i0.ɵɵpipe(20, "translate");
      i0.ɵɵelementStart(21, "kendo-grid-column", 15);
      i0.ɵɵpipe(22, "translate");
      i0.ɵɵtemplate(23, PdfTemplateListComponent_ng_template_23_Template, 1, 1, "ng-template", 11);
      i0.ɵɵelementEnd();
      i0.ɵɵelement(24, "kendo-grid-column", 16);
      i0.ɵɵpipe(25, "translate");
      i0.ɵɵelement(26, "kendo-grid-column", 17);
      i0.ɵɵpipe(27, "translate");
      i0.ɵɵelementEnd()()();
    }
    if (rf & 2) {
      i0.ɵɵproperty("items", ctx.actionBar);
      i0.ɵɵadvance(1);
      i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(2, 15, "Pdf Templates"));
      i0.ɵɵproperty("breadcrumb", ctx.breadcrumb);
      i0.ɵɵadvance(9);
      i0.ɵɵproperty("data", ctx.query)("selectable", i0.ɵɵpureFunction0(31, _c1))("selectBy", "id")("selection", ctx.selection);
      i0.ɵɵadvance(1);
      i0.ɵɵproperty("width", 1);
      i0.ɵɵadvance(1);
      i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(13, 17, "Id"));
      i0.ɵɵadvance(3);
      i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(16, 19, "Name"));
      i0.ɵɵadvance(2);
      i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(18, 21, "Internal Name"));
      i0.ɵɵadvance(2);
      i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(20, 23, "Language"));
      i0.ɵɵadvance(2);
      i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(22, 25, "Active"));
      i0.ɵɵadvance(3);
      i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(25, 27, "Last Modified Date"));
      i0.ɵɵadvance(2);
      i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(27, 29, "Last Modified By"));
    }
  },
  dependencies: [i4.NgIf, i5.RouterLink, i6.NgControlStatus, i6.NgModel, i7.NgbAccordion, i7.NgbPanel, i7.NgbPanelContent, i7.NgbPanelHeader, i8.FaIconComponent, i9.ColumnComponent, i9.CheckboxColumnComponent, i9.CellTemplateDirective, i10.ActionBarComponent, i11.AppControlComponent, i12.AppFormComponent, i13.ContentHeaderComponent, i14.AppGridComponent, i15.AppControlContainerComponent, i16.CheckIconComponent, i17.AccordionHeaderComponent, i18.TranslatePipe],
  styles: ["\n/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IiIsInNvdXJjZVJvb3QiOiIifQ== */"]
});