import { AbstractBreezeViewComponent } from '@common/classes/breeze-view';
import { CodeListPermissions } from '@common/classes/permissions';
import { ViewMode } from '@common/models/view-mode';
import { BreezeViewService } from '@common/services/breeze-view.service';
import * as i0 from "@angular/core";
import * as i1 from "@common/services/breeze-view.service";
import * as i2 from "@angular/forms";
import * as i3 from "../../../../common/components/action-bar/action-bar.component";
import * as i4 from "../../../../common/components/app-control/app-control.component";
import * as i5 from "../../../../common/components/loader/loader.component";
import * as i6 from "../../../../common/components/content-header/content-header.component";
import * as i7 from "@ngx-translate/core";
function ImdgViewComponent_ng_template_2_Template(rf, ctx) {
  if (rf & 1) {
    const _r2 = i0.ɵɵgetCurrentView();
    i0.ɵɵelement(0, "app-action-bar", 2)(1, "app-content-header", 3);
    i0.ɵɵelementStart(2, "div", 4)(3, "div", 5)(4, "div", 6)(5, "div", 7)(6, "app-control", 8);
    i0.ɵɵlistener("ngModelChange", function ImdgViewComponent_ng_template_2_Template_app_control_ngModelChange_6_listener($event) {
      i0.ɵɵrestoreView(_r2);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.model.name = $event);
    });
    i0.ɵɵpipe(7, "translate");
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(8, "div", 7)(9, "app-control", 9);
    i0.ɵɵlistener("ngModelChange", function ImdgViewComponent_ng_template_2_Template_app_control_ngModelChange_9_listener($event) {
      i0.ɵɵrestoreView(_r2);
      const ctx_r3 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r3.model.id = $event);
    });
    i0.ɵɵpipe(10, "translate");
    i0.ɵɵelementEnd()()();
    i0.ɵɵelementStart(11, "div", 6)(12, "div", 7)(13, "app-control", 10);
    i0.ɵɵlistener("ngModelChange", function ImdgViewComponent_ng_template_2_Template_app_control_ngModelChange_13_listener($event) {
      i0.ɵɵrestoreView(_r2);
      const ctx_r4 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r4.model.active = $event);
    });
    i0.ɵɵpipe(14, "translate");
    i0.ɵɵelementEnd()()()()();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("items", ctx_r0.actionBar);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("title", ctx_r0.title)("breadcrumb", ctx_r0.breadcrumb);
    i0.ɵɵadvance(5);
    i0.ɵɵproperty("label", i0.ɵɵpipeBind1(7, 12, "Imdg Name"))("ngModel", ctx_r0.model.name)("entity", ctx_r0.model);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("label", i0.ɵɵpipeBind1(10, 14, "Imdg Type"))("ngModel", ctx_r0.model.id)("entity", ctx_r0.model);
    i0.ɵɵadvance(4);
    i0.ɵɵproperty("label", i0.ɵɵpipeBind1(14, 16, "Active"))("ngModel", ctx_r0.model.active)("entity", ctx_r0.model);
  }
}
export class ImdgViewComponent extends AbstractBreezeViewComponent {
  constructor(breezeViewService) {
    super(breezeViewService);
    this.entityName = 'Imdg';
    this.parentRoute = '/code-list/imdg';
    this.editPermission = CodeListPermissions.Imdg.edit;
    this.breadcrumb = [{
      icon: 'exclamation-circle',
      title: this.translateService.get('Imdg'),
      route: '/code-list/imdg/list'
    }, {
      icon: 'exclamation-circle',
      title: this.translateService.get('Imdg View')
    }];
  }
  get title() {
    return `${this.model.code} - ${this.model.name}`;
  }
  afterSave() {
    if (this.model.id !== null && this.mode !== ViewMode.view) {
      return this.router.navigate([`${this.parentRoute}/view/${this.model.id}`]);
    }
  }
}
ImdgViewComponent.ɵfac = function ImdgViewComponent_Factory(t) {
  return new (t || ImdgViewComponent)(i0.ɵɵdirectiveInject(i1.BreezeViewService));
};
ImdgViewComponent.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
  type: ImdgViewComponent,
  selectors: [["app-imdg-view"]],
  features: [i0.ɵɵProvidersFeature([BreezeViewService]), i0.ɵɵInheritDefinitionFeature],
  decls: 3,
  vars: 1,
  consts: [[3, "isBusy"], [1, "row", "separator"], [3, "items"], [3, "title", "breadcrumb"], [1, "card", "card-border-top"], [1, "card-body"], [1, "row", "mt-3"], [1, "col-4"], ["pattern", "[\\w\\d\\s\\.\\-\\']", "property", "name", 3, "label", "ngModel", "entity", "ngModelChange"], ["type", "codelist", "codelist", "Imdg", "property", "id", 3, "label", "ngModel", "entity", "ngModelChange"], ["type", "boolean", "property", "active", 3, "label", "ngModel", "entity", "ngModelChange"]],
  template: function ImdgViewComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "app-loader", 0);
      i0.ɵɵelement(1, "div", 1);
      i0.ɵɵtemplate(2, ImdgViewComponent_ng_template_2_Template, 15, 18, "ng-template");
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      i0.ɵɵproperty("isBusy", !ctx.model);
    }
  },
  dependencies: [i2.NgControlStatus, i2.PatternValidator, i2.NgModel, i3.ActionBarComponent, i4.AppControlComponent, i5.LoaderComponent, i6.ContentHeaderComponent, i7.TranslatePipe],
  styles: ["\n/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IiIsInNvdXJjZVJvb3QiOiIifQ== */"]
});