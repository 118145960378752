import { AbstractBreezeViewComponent } from '@common/classes/breeze-view';
import { ViewMode } from '@common/models/view-mode';
import { BreezeViewService } from '@common/services/breeze-view.service';
import * as i0 from "@angular/core";
import * as i1 from "@common/services/breeze-view.service";
import * as i2 from "@angular/forms";
import * as i3 from "@progress/kendo-angular-grid";
import * as i4 from "../../../../common/components/action-bar/action-bar.component";
import * as i5 from "../../../../common/components/app-control/app-control.component";
import * as i6 from "../../../../common/components/loader/loader.component";
import * as i7 from "../../../../common/components/content-header/content-header.component";
import * as i8 from "../../../../common/components/app-grid/app-grid.component";
import * as i9 from "@angular/common";
import * as i10 from "@ngx-translate/core";
import * as i11 from "../../../../common/pipes/codelist.pipe";
function VtsVesselViewComponent_ng_template_2_ng_template_51_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtext(0);
    i0.ɵɵpipe(1, "async");
    i0.ɵɵpipe(2, "codelist");
  }
  if (rf & 2) {
    const row_r2 = ctx.$implicit;
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind1(1, 1, i0.ɵɵpipeBind2(2, 3, row_r2.sourceId, "SourceSystem")), " ");
  }
}
const _c0 = function () {
  return {
    field: "createdDate",
    dir: "desc"
  };
};
const _c1 = function (a0) {
  return [a0];
};
function VtsVesselViewComponent_ng_template_2_Template(rf, ctx) {
  if (rf & 1) {
    const _r4 = i0.ɵɵgetCurrentView();
    i0.ɵɵelement(0, "app-action-bar", 2)(1, "app-content-header", 3);
    i0.ɵɵelementStart(2, "div", 4)(3, "div", 5)(4, "div", 6)(5, "div", 7)(6, "app-control", 8);
    i0.ɵɵlistener("ngModelChange", function VtsVesselViewComponent_ng_template_2_Template_app_control_ngModelChange_6_listener($event) {
      i0.ɵɵrestoreView(_r4);
      const ctx_r3 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r3.model.iMONumber = $event);
    });
    i0.ɵɵpipe(7, "translate");
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(8, "div", 7)(9, "app-control", 9);
    i0.ɵɵlistener("ngModelChange", function VtsVesselViewComponent_ng_template_2_Template_app_control_ngModelChange_9_listener($event) {
      i0.ɵɵrestoreView(_r4);
      const ctx_r5 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r5.model.mmsi = $event);
    });
    i0.ɵɵpipe(10, "translate");
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(11, "div", 7)(12, "app-control", 10);
    i0.ɵɵlistener("ngModelChange", function VtsVesselViewComponent_ng_template_2_Template_app_control_ngModelChange_12_listener($event) {
      i0.ɵɵrestoreView(_r4);
      const ctx_r6 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r6.model.callSign = $event);
    });
    i0.ɵɵpipe(13, "translate");
    i0.ɵɵelementEnd()()();
    i0.ɵɵelementStart(14, "div", 11)(15, "div", 7)(16, "app-control", 12);
    i0.ɵɵlistener("ngModelChange", function VtsVesselViewComponent_ng_template_2_Template_app_control_ngModelChange_16_listener($event) {
      i0.ɵɵrestoreView(_r4);
      const ctx_r7 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r7.model.name = $event);
    });
    i0.ɵɵpipe(17, "translate");
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(18, "div", 7)(19, "app-control", 13);
    i0.ɵɵlistener("ngModelChange", function VtsVesselViewComponent_ng_template_2_Template_app_control_ngModelChange_19_listener($event) {
      i0.ɵɵrestoreView(_r4);
      const ctx_r8 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r8.model.typeId = $event);
    });
    i0.ɵɵpipe(20, "translate");
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(21, "div", 7)(22, "app-control", 14);
    i0.ɵɵlistener("ngModelChange", function VtsVesselViewComponent_ng_template_2_Template_app_control_ngModelChange_22_listener($event) {
      i0.ɵɵrestoreView(_r4);
      const ctx_r9 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r9.model.countryId = $event);
    });
    i0.ɵɵpipe(23, "translate");
    i0.ɵɵelementEnd()()();
    i0.ɵɵelementStart(24, "div", 11)(25, "div", 7)(26, "app-control", 15);
    i0.ɵɵlistener("ngModelChange", function VtsVesselViewComponent_ng_template_2_Template_app_control_ngModelChange_26_listener($event) {
      i0.ɵɵrestoreView(_r4);
      const ctx_r10 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r10.model.length = $event);
    });
    i0.ɵɵpipe(27, "translate");
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(28, "div", 7)(29, "app-control", 16);
    i0.ɵɵlistener("ngModelChange", function VtsVesselViewComponent_ng_template_2_Template_app_control_ngModelChange_29_listener($event) {
      i0.ɵɵrestoreView(_r4);
      const ctx_r11 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r11.model.statusId = $event);
    });
    i0.ɵɵpipe(30, "translate");
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(31, "div", 7)(32, "app-control", 17);
    i0.ɵɵlistener("ngModelChange", function VtsVesselViewComponent_ng_template_2_Template_app_control_ngModelChange_32_listener($event) {
      i0.ɵɵrestoreView(_r4);
      const ctx_r12 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r12.model.cvcExpiry = $event);
    });
    i0.ɵɵpipe(33, "translate");
    i0.ɵɵelementEnd()()();
    i0.ɵɵelementStart(34, "div", 11)(35, "div", 7)(36, "app-control", 18);
    i0.ɵɵlistener("ngModelChange", function VtsVesselViewComponent_ng_template_2_Template_app_control_ngModelChange_36_listener($event) {
      i0.ɵɵrestoreView(_r4);
      const ctx_r13 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r13.model.cvolExpiry = $event);
    });
    i0.ɵɵpipe(37, "translate");
    i0.ɵɵelementEnd()()()()();
    i0.ɵɵelementStart(38, "div", 19)(39, "div", 5)(40, "h5");
    i0.ɵɵtext(41, "Vessel history");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(42, "app-grid", 20);
    i0.ɵɵelement(43, "kendo-grid-column", 21);
    i0.ɵɵpipe(44, "translate");
    i0.ɵɵelement(45, "kendo-grid-column", 22);
    i0.ɵɵpipe(46, "translate");
    i0.ɵɵelement(47, "kendo-grid-column", 23);
    i0.ɵɵpipe(48, "translate");
    i0.ɵɵelementStart(49, "kendo-grid-column", 24);
    i0.ɵɵpipe(50, "translate");
    i0.ɵɵtemplate(51, VtsVesselViewComponent_ng_template_2_ng_template_51_Template, 3, 6, "ng-template", 25);
    i0.ɵɵelementEnd()()()();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("items", ctx_r0.actionBar);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("title", ctx_r0.title)("breadcrumb", ctx_r0.breadcrumb);
    i0.ɵɵadvance(5);
    i0.ɵɵproperty("label", i0.ɵɵpipeBind1(7, 43, "IMO Number"))("min", 0)("disabled", true)("ngModel", ctx_r0.model.iMONumber)("entity", ctx_r0.model);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("label", i0.ɵɵpipeBind1(10, 45, "MMSI"))("maxlength", 9)("ngModel", ctx_r0.model.mmsi)("entity", ctx_r0.model);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("label", i0.ɵɵpipeBind1(13, 47, "Call Sign"))("maxlength", 7)("ngModel", ctx_r0.model.callSign)("entity", ctx_r0.model);
    i0.ɵɵadvance(4);
    i0.ɵɵproperty("label", i0.ɵɵpipeBind1(17, 49, "Vessel Name"))("ngModel", ctx_r0.model.name)("entity", ctx_r0.model);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("label", i0.ɵɵpipeBind1(20, 51, "Vessel Type"))("ngModel", ctx_r0.model.typeId)("entity", ctx_r0.model);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("label", i0.ɵɵpipeBind1(23, 53, "Flag"))("ngModel", ctx_r0.model.countryId)("entity", ctx_r0.model);
    i0.ɵɵadvance(4);
    i0.ɵɵproperty("label", i0.ɵɵpipeBind1(27, 55, "Length"))("ngModel", ctx_r0.model.length)("entity", ctx_r0.model);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("label", i0.ɵɵpipeBind1(30, 57, "Status"))("ngModel", ctx_r0.model.statusId)("entity", ctx_r0.model);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("label", i0.ɵɵpipeBind1(33, 59, "CVC Expiry Date"))("ngModel", ctx_r0.model.cvcExpiry)("entity", ctx_r0.model);
    i0.ɵɵadvance(4);
    i0.ɵɵproperty("label", i0.ɵɵpipeBind1(37, 61, "CVOL Expiry Date"))("ngModel", ctx_r0.model.cvolExpiry)("entity", ctx_r0.model);
    i0.ɵɵadvance(6);
    i0.ɵɵproperty("data", ctx_r0.model.revisions)("sort", i0.ɵɵpureFunction1(72, _c1, i0.ɵɵpureFunction0(71, _c0)));
    i0.ɵɵadvance(1);
    i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(44, 63, "Id"));
    i0.ɵɵadvance(2);
    i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(46, 65, "Created By"));
    i0.ɵɵadvance(2);
    i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(48, 67, "Created Date"));
    i0.ɵɵadvance(2);
    i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(50, 69, "Source"));
  }
}
export class VtsVesselViewComponent extends AbstractBreezeViewComponent {
  constructor(breezeViewService) {
    super(breezeViewService);
    this.entityName = 'VtsVessel';
    this.parentRoute = '/code-list/vts-vessel';
    this.breadcrumb = [{
      icon: 'ship',
      title: this.translateService.get('VTS Vessels'),
      route: '/code-list/vts-vessel/list'
    }, {
      icon: 'ship',
      title: this.translateService.get('VTS Vessel')
    }];
  }
  get title() {
    return `${this.model.name} (${this.model.iMONumber || this.model.mmsi})`;
  }
  canEdit() {
    return false;
  }
  afterSave() {
    if (this.model.id !== null && this.mode !== ViewMode.view) {
      return this.router.navigate([`${this.parentRoute}/view/${this.model.id}`]);
    }
  }
}
VtsVesselViewComponent.ɵfac = function VtsVesselViewComponent_Factory(t) {
  return new (t || VtsVesselViewComponent)(i0.ɵɵdirectiveInject(i1.BreezeViewService));
};
VtsVesselViewComponent.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
  type: VtsVesselViewComponent,
  selectors: [["app-vts-vessel-view"]],
  features: [i0.ɵɵProvidersFeature([BreezeViewService]), i0.ɵɵInheritDefinitionFeature],
  decls: 3,
  vars: 1,
  consts: [[3, "isBusy"], [1, "row", "separator"], [3, "items"], [3, "title", "breadcrumb"], [1, "card", "card-border-top"], [1, "card-body"], [1, "row"], [1, "col-4"], ["format", "#", "property", "iMONumber", 3, "label", "min", "disabled", "ngModel", "entity", "ngModelChange"], ["format", "'#'", "property", "mmsi", 3, "label", "maxlength", "ngModel", "entity", "ngModelChange"], ["property", "callSign", 3, "label", "maxlength", "ngModel", "entity", "ngModelChange"], [1, "row", "mt-3"], ["pattern", "[\\w\\d\\s\\.\\-\\']", "property", "name", 3, "label", "ngModel", "entity", "ngModelChange"], ["type", "codelist", "codelist", "VesselType", "property", "typeId", 3, "label", "ngModel", "entity", "ngModelChange"], ["type", "codelist", "codelist", "Country", "property", "countryId", 3, "label", "ngModel", "entity", "ngModelChange"], ["property", "length", 3, "label", "ngModel", "entity", "ngModelChange"], ["type", "codelist", "property", "statusId", 3, "label", "ngModel", "entity", "ngModelChange"], ["type", "datetime", "property", "cvcExpiry", 3, "label", "ngModel", "entity", "ngModelChange"], ["type", "datetime", "property", "cvolExpiry", 3, "label", "ngModel", "entity", "ngModelChange"], [1, "card", "card-border-top", "mt-3"], [3, "data", "sort"], ["field", "id", 3, "title"], ["field", "createdBy.fullName", 3, "title"], ["field", "createdDate", "format", "g", 3, "title"], ["field", "sourceId", 3, "title"], ["kendoGridCellTemplate", ""]],
  template: function VtsVesselViewComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "app-loader", 0);
      i0.ɵɵelement(1, "div", 1);
      i0.ɵɵtemplate(2, VtsVesselViewComponent_ng_template_2_Template, 52, 74, "ng-template");
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      i0.ɵɵproperty("isBusy", !ctx.model);
    }
  },
  dependencies: [i2.NgControlStatus, i2.MaxLengthValidator, i2.PatternValidator, i2.NgModel, i3.ColumnComponent, i3.CellTemplateDirective, i4.ActionBarComponent, i5.AppControlComponent, i6.LoaderComponent, i7.ContentHeaderComponent, i8.AppGridComponent, i9.AsyncPipe, i10.TranslatePipe, i11.CodelistPipe],
  styles: ["\n/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IiIsInNvdXJjZVJvb3QiOiIifQ== */"]
});