import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
export class SharedService {
  constructor() {
    this.sendDataSubject = new Subject();
    this.sendData$ = this.sendDataSubject.asObservable();
  }
  sendData(data) {
    this.sendDataSubject.next(data);
  }
}
SharedService.ɵfac = function SharedService_Factory(t) {
  return new (t || SharedService)();
};
SharedService.ɵprov = /*@__PURE__*/i0.ɵɵdefineInjectable({
  token: SharedService,
  factory: SharedService.ɵfac,
  providedIn: 'root'
});