import { RouterModule } from '@angular/router';
import { CanDeactivateView } from '@common/guards/view.deactivate';
import { ViewMode } from '@common/models/view-mode';
import { CommonModule } from '../../common/common.module';
import { ImdgListComponent } from './components/imdg-list/imdg-list.component';
import { ImdgViewComponent } from './components/imdg-view/imdg-view.component';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
export const IMDG_ROUTES = [{
  path: '',
  redirectTo: 'list',
  pathMatch: 'full'
}, {
  path: 'list',
  component: ImdgListComponent
}, {
  path: 'view/:id',
  component: ImdgViewComponent,
  data: {
    mode: ViewMode.view
  }
}, {
  path: 'edit/:id',
  component: ImdgViewComponent,
  data: {
    mode: ViewMode.edit
  },
  canDeactivate: [CanDeactivateView]
}, {
  path: '**',
  redirectTo: 'list'
}];
export class ImdgModule {}
ImdgModule.ɵfac = function ImdgModule_Factory(t) {
  return new (t || ImdgModule)();
};
ImdgModule.ɵmod = /*@__PURE__*/i0.ɵɵdefineNgModule({
  type: ImdgModule
});
ImdgModule.ɵinj = /*@__PURE__*/i0.ɵɵdefineInjector({
  providers: [CanDeactivateView],
  imports: [CommonModule, RouterModule.forChild(IMDG_ROUTES)]
});
(function () {
  (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(ImdgModule, {
    declarations: [ImdgListComponent, ImdgViewComponent],
    imports: [CommonModule, i1.RouterModule]
  });
})();