import { RouterModule } from '@angular/router';
import { CommonModule } from '@common/common.module';
import { ViewMode } from '@common/models/view-mode';
import { VesselPermitListComponent } from './components/vessel-permit-list/vessel-permit-list.component';
import { VesselPermitViewComponent } from './components/vessel-permit-view/vessel-permit-view.component';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
const VESSEL_PERMIT_ROUTES = [{
  path: '',
  redirectTo: 'list',
  pathMatch: 'full'
}, {
  path: 'list',
  component: VesselPermitListComponent
}, {
  path: 'view/:id',
  component: VesselPermitViewComponent,
  data: {
    mode: ViewMode.view
  }
}, {
  path: '**',
  redirectTo: 'list'
}];
export class VesselPermitModule {}
VesselPermitModule.ɵfac = function VesselPermitModule_Factory(t) {
  return new (t || VesselPermitModule)();
};
VesselPermitModule.ɵmod = /*@__PURE__*/i0.ɵɵdefineNgModule({
  type: VesselPermitModule
});
VesselPermitModule.ɵinj = /*@__PURE__*/i0.ɵɵdefineInjector({
  imports: [CommonModule, RouterModule.forChild(VESSEL_PERMIT_ROUTES)]
});
(function () {
  (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(VesselPermitModule, {
    declarations: [VesselPermitListComponent, VesselPermitViewComponent],
    imports: [CommonModule, i1.RouterModule]
  });
})();