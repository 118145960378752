import { AbstractBreezeViewComponent } from '@common/classes/breeze-view';
import { chargeCandidateFilters } from '@common/components/toggleable-filter/toggleable-filter.component';
import { BreezeViewService } from '@common/services/breeze-view.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AddExistingInvoiceModalComponent } from '@vessel-visit/components/add-existing-invoice-modal/add-existing-invoice-modal.component';
import * as i0 from "@angular/core";
import * as i1 from "@common/services/breeze-view.service";
import * as i2 from "@ng-bootstrap/ng-bootstrap";
import * as i3 from "@angular/common";
import * as i4 from "@ngx-translate/core";
import * as i5 from "@progress/kendo-angular-grid";
import * as i6 from "@progress/kendo-angular-buttons";
import * as i7 from "../loader/loader.component";
import * as i8 from "../app-grid/app-grid.component";
import * as i9 from "../check-icon.component";
import * as i10 from "../toggleable-filter/toggleable-filter.component";
import * as i11 from "../popover-list/popover-list.component";
import * as i12 from "../../pipes/codelist.pipe";
function NmswChargeCandidatesModalComponent_span_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span");
    i0.ɵɵtext(1);
    i0.ɵɵpipe(2, "translate");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind1(2, 1, "Add Selected Charge Candidates"), " ");
  }
}
function NmswChargeCandidatesModalComponent_ng_template_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtext(0);
    i0.ɵɵpipe(1, "translate");
  }
  if (rf & 2) {
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind1(1, 1, "Charge Selected Candidates"), " ");
  }
}
function NmswChargeCandidatesModalComponent_ng_template_12_ng_template_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtext(0);
    i0.ɵɵpipe(1, "async");
    i0.ɵɵpipe(2, "codelist");
  }
  if (rf & 2) {
    const row_r14 = ctx.$implicit;
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind1(1, 1, i0.ɵɵpipeBind2(2, 3, row_r14.serviceId, "Service")), " ");
  }
}
const _c0 = function (a0) {
  return {
    text: a0,
    separator: ";",
    width: "250px"
  };
};
function NmswChargeCandidatesModalComponent_ng_template_12_ng_template_8_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "app-popover-list", 30);
  }
  if (rf & 2) {
    const dataItem_r15 = ctx.$implicit;
    i0.ɵɵproperty("data", i0.ɵɵpureFunction1(1, _c0, dataItem_r15.description));
  }
}
function NmswChargeCandidatesModalComponent_ng_template_12_ng_template_11_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "app-check-icon", 31);
  }
  if (rf & 2) {
    const row_r17 = ctx.$implicit;
    i0.ɵɵproperty("check", !!row_r17.invoiceItemId);
  }
}
function NmswChargeCandidatesModalComponent_ng_template_12_ng_template_18_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtext(0);
    i0.ɵɵpipe(1, "number");
  }
  if (rf & 2) {
    const row_r18 = ctx.$implicit;
    const ctx_r11 = i0.ɵɵnextContext(2);
    i0.ɵɵtextInterpolate2(" ", i0.ɵɵpipeBind2(1, 2, row_r18.total, "1.2-2"), " ", ctx_r11.currency, " ");
  }
}
function NmswChargeCandidatesModalComponent_ng_template_12_ng_template_21_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtext(0);
    i0.ɵɵpipe(1, "async");
    i0.ɵɵpipe(2, "codelist");
  }
  if (rf & 2) {
    const row_r19 = ctx.$implicit;
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind1(1, 1, i0.ɵɵpipeBind2(2, 3, row_r19.createdById, "User")), " ");
  }
}
function NmswChargeCandidatesModalComponent_ng_template_12_ng_template_26_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtext(0);
    i0.ɵɵpipe(1, "async");
    i0.ɵɵpipe(2, "codelist");
  }
  if (rf & 2) {
    const row_r20 = ctx.$implicit;
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind1(1, 1, i0.ɵɵpipeBind2(2, 3, row_r20 == null ? null : row_r20.lastModifiedById, "User")), " ");
  }
}
const _c1 = function () {
  return {
    enabled: true,
    checkboxOnly: true
  };
};
function NmswChargeCandidatesModalComponent_ng_template_12_Template(rf, ctx) {
  if (rf & 1) {
    const _r22 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "app-toggleable-filter", 16);
    i0.ɵɵlistener("filterToggled", function NmswChargeCandidatesModalComponent_ng_template_12_Template_app_toggleable_filter_filterToggled_0_listener($event) {
      i0.ɵɵrestoreView(_r22);
      const ctx_r21 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r21.filterChargeCandidates($event));
    });
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(1, "app-grid", 17);
    i0.ɵɵlistener("selectionChange", function NmswChargeCandidatesModalComponent_ng_template_12_Template_app_grid_selectionChange_1_listener($event) {
      i0.ɵɵrestoreView(_r22);
      const ctx_r23 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r23.chargeCandidateSelection = $event);
    });
    i0.ɵɵelement(2, "kendo-grid-checkbox-column", 18);
    i0.ɵɵelementStart(3, "kendo-grid-column", 19);
    i0.ɵɵpipe(4, "translate");
    i0.ɵɵtemplate(5, NmswChargeCandidatesModalComponent_ng_template_12_ng_template_5_Template, 3, 6, "ng-template", 20);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(6, "kendo-grid-column", 21);
    i0.ɵɵpipe(7, "translate");
    i0.ɵɵtemplate(8, NmswChargeCandidatesModalComponent_ng_template_12_ng_template_8_Template, 1, 3, "ng-template", 20);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(9, "kendo-grid-column", 22);
    i0.ɵɵpipe(10, "translate");
    i0.ɵɵtemplate(11, NmswChargeCandidatesModalComponent_ng_template_12_ng_template_11_Template, 1, 1, "ng-template", 20);
    i0.ɵɵelementEnd();
    i0.ɵɵelement(12, "kendo-grid-column", 23);
    i0.ɵɵpipe(13, "translate");
    i0.ɵɵelement(14, "kendo-grid-column", 24);
    i0.ɵɵpipe(15, "translate");
    i0.ɵɵelementStart(16, "kendo-grid-column", 25);
    i0.ɵɵpipe(17, "translate");
    i0.ɵɵtemplate(18, NmswChargeCandidatesModalComponent_ng_template_12_ng_template_18_Template, 2, 5, "ng-template", 20);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(19, "kendo-grid-column", 26);
    i0.ɵɵpipe(20, "translate");
    i0.ɵɵtemplate(21, NmswChargeCandidatesModalComponent_ng_template_12_ng_template_21_Template, 3, 6, "ng-template", 20);
    i0.ɵɵelementEnd();
    i0.ɵɵelement(22, "kendo-grid-column", 27);
    i0.ɵɵpipe(23, "translate");
    i0.ɵɵelementStart(24, "kendo-grid-column", 28);
    i0.ɵɵpipe(25, "translate");
    i0.ɵɵtemplate(26, NmswChargeCandidatesModalComponent_ng_template_12_ng_template_26_Template, 3, 6, "ng-template", 20);
    i0.ɵɵelementEnd();
    i0.ɵɵelement(27, "kendo-grid-column", 29);
    i0.ɵɵpipe(28, "translate");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r3 = i0.ɵɵnextContext();
    i0.ɵɵproperty("filters", ctx_r3.chargeCandidateFilters);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("data", ctx_r3.displayedChargeCandidates)("sort", ctx_r3.sort)("selectable", i0.ɵɵpureFunction0(38, _c1))("scrollable", true)("height", 610)("selection", ctx_r3.chargeCandidateSelection);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("showSelectAll", true);
    i0.ɵɵadvance(1);
    i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(4, 18, "Service"));
    i0.ɵɵadvance(3);
    i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(7, 20, "Description"));
    i0.ɵɵadvance(3);
    i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(10, 22, "Invoiced"));
    i0.ɵɵadvance(3);
    i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(13, 24, "Charge From"));
    i0.ɵɵadvance(2);
    i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(15, 26, "Charge To"));
    i0.ɵɵadvance(2);
    i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(17, 28, "Total"));
    i0.ɵɵadvance(3);
    i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(20, 30, "Created By"));
    i0.ɵɵadvance(3);
    i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(23, 32, "Created Date"));
    i0.ɵɵadvance(2);
    i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(25, 34, "Last Modified By"));
    i0.ɵɵadvance(3);
    i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(28, 36, "Last Modified Date"));
  }
}
function NmswChargeCandidatesModalComponent_h5_15_span_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span");
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r24 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate2(" You have selected ", ctx_r24.chargeCandidateSelection.length, " Charge candidate", ctx_r24.chargeCandidateSelection.length >= 2 ? "s" : "", ". How do you wish to proceed? ");
  }
}
function NmswChargeCandidatesModalComponent_h5_15_ng_template_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtext(0, "Select Charge candidates to proceed.");
  }
}
function NmswChargeCandidatesModalComponent_h5_15_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "h5");
    i0.ɵɵtemplate(1, NmswChargeCandidatesModalComponent_h5_15_span_1_Template, 2, 2, "span", 2);
    i0.ɵɵtemplate(2, NmswChargeCandidatesModalComponent_h5_15_ng_template_2_Template, 1, 0, "ng-template", null, 32, i0.ɵɵtemplateRefExtractor);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const _r25 = i0.ɵɵreference(3);
    const ctx_r4 = i0.ɵɵnextContext();
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r4.chargeCandidateSelection.length > 0)("ngIfElse", _r25);
  }
}
function NmswChargeCandidatesModalComponent_span_19_Template(rf, ctx) {
  if (rf & 1) {
    const _r28 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "span")(1, "button", 33);
    i0.ɵɵlistener("click", function NmswChargeCandidatesModalComponent_span_19_Template_button_click_1_listener() {
      i0.ɵɵrestoreView(_r28);
      const ctx_r27 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r27.confirmAddToInvoice(ctx_r27.chargeCandidateSelection));
    });
    i0.ɵɵtext(2, " Add ");
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r5 = i0.ɵɵnextContext();
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("disabled", !ctx_r5.anySelection(ctx_r5.chargeCandidateSelection) || ctx_r5.isBusy);
  }
}
function NmswChargeCandidatesModalComponent_ng_template_20_Template(rf, ctx) {
  if (rf & 1) {
    const _r30 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 33);
    i0.ɵɵlistener("click", function NmswChargeCandidatesModalComponent_ng_template_20_Template_button_click_0_listener() {
      i0.ɵɵrestoreView(_r30);
      const ctx_r29 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r29.selectInvoiceAndClose(ctx_r29.chargeCandidateSelection));
    });
    i0.ɵɵtext(1, " Add to existing Invoice ");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(2, "button", 33);
    i0.ɵɵlistener("click", function NmswChargeCandidatesModalComponent_ng_template_20_Template_button_click_2_listener() {
      i0.ɵɵrestoreView(_r30);
      const ctx_r31 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r31.confirmNewInvoice(ctx_r31.chargeCandidateSelection));
    });
    i0.ɵɵtext(3, " Create new Invoice/s ");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r7 = i0.ɵɵnextContext();
    i0.ɵɵproperty("disabled", !ctx_r7.anySelection(ctx_r7.chargeCandidateSelection) || ctx_r7.isBusy);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("disabled", !ctx_r7.anySelection(ctx_r7.chargeCandidateSelection) || ctx_r7.isBusy);
  }
}
export class NmswChargeCandidatesModalComponent extends AbstractBreezeViewComponent {
  constructor(breezeViewService, activeModal) {
    super(breezeViewService);
    this.breezeViewService = breezeViewService;
    this.activeModal = activeModal;
    this.chargeCandidateSelection = [];
    this.entityName = '';
    this.displayedChargeCandidates = [];
    this.chargeCandidateFilters = chargeCandidateFilters;
    this.sort = [{
      field: 'from',
      dir: 'desc'
    }, {
      field: 'to',
      dir: 'desc'
    }];
  }
  ngOnInit() {
    this.isBusy = true;
    this.fetchChargeCandidates();
  }
  close(selection = null) {
    if (!this.anySelection(selection)) {
      this.activeModal.close();
      return;
    }
    this.isBusy = true;
    this.breezeViewService.handleCommand(`${this.invoice?.id ? 'AddChargeCandidatesToInvoice' : 'CreateNewInvoiceWithChargeCandidates'}`, {
      chargeCandidateIds: selection,
      invoiceId: this.invoice?.id
    }).catch(error => {
      this.isBusy = false;
      this.close();
      super.handleError(error);
    }).then(invoiceId => {
      if (this.isBusy) this.activeModal.close(invoiceId);
    });
  }
  confirmNewInvoice(selection) {
    this.dialogService.confirm('Create new Invoice/s with Charge candidates', `You are about to create new draft Invoice/s with ${selection.length} selected Charge candidate${selection.length >= 2 ? 's' : ''}. Do you wish to proceed?`).then(result => {
      if (result) this.close(selection);
    });
  }
  confirmAddToInvoice(selection) {
    this.dialogService.confirm('Add Charge candidates to Invoice', `You are about to add ${selection.length} selected Charge candidate${selection.length >= 2 ? 's' : ''} to ${this.invoice?.title}. Do you wish to proceed?`).then(result => {
      if (result) this.close(selection);
    });
  }
  selectInvoiceAndClose(selection) {
    this.dialogService.open(AddExistingInvoiceModalComponent, {
      size: 'xl'
    }, modalRef => {
      const componentInstance = modalRef.componentInstance;
      componentInstance.vtsVesselVisitId = this.vtsVesselVisitId;
      componentInstance.listUnlinked = false;
      componentInstance.singleSelection = true; // Surpass the modal's confirm function
    }).then(result => {
      if (!result) return;
      this.invoice = {
        id: result
      };
      this.close(selection);
    });
  }
  anySelection(selection) {
    return !!selection && selection?.length !== 0;
  }
  fetchChargeCandidates() {
    return this.breezeViewService.handleQuery('ChargeCandidates', {
      vtsVesselVisitId: this.vtsVesselVisitId
    }).then(chargeCandidates => {
      this.chargeCandidates = chargeCandidates;
      this.filterChargeCandidates();
      this.isBusy = false;
    });
  }
  filterChargeCandidates(filters = null) {
    this.displayedChargeCandidates = this.chargeCandidates.filter(candidate => !filters || filters.includes(candidate.serviceId));
    // Update notifications
    this.chargeCandidateFilters.forEach(filter => {
      if (filter.filters) {
        filter.notifications = this.chargeCandidates.filter(cc => !cc.invoiceItemId && cc.active && filter.filters?.includes(cc.serviceId)).length;
      } else {
        filter.notifications = this.chargeCandidates.filter(cc => !cc.invoiceItemId && cc.active).length;
      }
    });
  }
}
NmswChargeCandidatesModalComponent.ɵfac = function NmswChargeCandidatesModalComponent_Factory(t) {
  return new (t || NmswChargeCandidatesModalComponent)(i0.ɵɵdirectiveInject(i1.BreezeViewService), i0.ɵɵdirectiveInject(i2.NgbActiveModal));
};
NmswChargeCandidatesModalComponent.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
  type: NmswChargeCandidatesModalComponent,
  selectors: [["app-nmsw-charge-candidates-modal"]],
  inputs: {
    chargeCandidateSelection: "chargeCandidateSelection"
  },
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 22,
  vars: 8,
  consts: [[1, "modal-header"], [1, "modal-title"], [4, "ngIf", "ngIfElse"], ["newInvoiceTitle", ""], ["type", "button", "aria-label", "Close", 1, "close", 3, "disabled", "click"], ["aria-hidden", "true"], [1, "modal-body"], [1, "row"], [1, "col-12"], [3, "isBusy"], [1, "row", "mt-4"], [1, "col-12", "text-center"], [4, "ngIf"], [1, "modal-footer"], ["kendoButton", "", "translate", "", 3, "disabled", "click"], ["newInvoiceButtons", ""], [3, "filters", "filterToggled"], [3, "data", "sort", "selectable", "scrollable", "height", "selection", "selectionChange"], ["width", "33px", 3, "showSelectAll"], ["field", "serviceId", 3, "title"], ["kendoGridCellTemplate", ""], ["field", "description", "width", "260px", 3, "title"], ["field", "invoiceItemId", "width", "70px", 3, "title"], ["field", "from", "format", "g", 3, "title"], ["field", "to", "format", "g", 3, "title"], ["field", "total", 3, "title"], ["field", "createdById", 3, "title"], ["field", "createdDate", "format", "g", 3, "title"], ["field", "lastModifiedById", 3, "title"], ["field", "lastModifiedDate", "format", "g", 3, "title"], [3, "data"], [3, "check"], ["selectionPrompt", ""], ["kendoButton", "", "primary", "true", "translate", "", 3, "disabled", "click"]],
  template: function NmswChargeCandidatesModalComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "div", 0)(1, "h5", 1);
      i0.ɵɵtemplate(2, NmswChargeCandidatesModalComponent_span_2_Template, 3, 3, "span", 2);
      i0.ɵɵtemplate(3, NmswChargeCandidatesModalComponent_ng_template_3_Template, 2, 3, "ng-template", null, 3, i0.ɵɵtemplateRefExtractor);
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(5, "button", 4);
      i0.ɵɵlistener("click", function NmswChargeCandidatesModalComponent_Template_button_click_5_listener() {
        return ctx.close();
      });
      i0.ɵɵelementStart(6, "span", 5);
      i0.ɵɵtext(7, "\u00D7");
      i0.ɵɵelementEnd()()();
      i0.ɵɵelementStart(8, "div", 6)(9, "div", 7)(10, "div", 8)(11, "app-loader", 9);
      i0.ɵɵtemplate(12, NmswChargeCandidatesModalComponent_ng_template_12_Template, 29, 39, "ng-template");
      i0.ɵɵelementEnd()()();
      i0.ɵɵelementStart(13, "div", 10)(14, "div", 11);
      i0.ɵɵtemplate(15, NmswChargeCandidatesModalComponent_h5_15_Template, 4, 2, "h5", 12);
      i0.ɵɵelementEnd()()();
      i0.ɵɵelementStart(16, "div", 13)(17, "button", 14);
      i0.ɵɵlistener("click", function NmswChargeCandidatesModalComponent_Template_button_click_17_listener() {
        return ctx.close();
      });
      i0.ɵɵtext(18, "Cancel");
      i0.ɵɵelementEnd();
      i0.ɵɵtemplate(19, NmswChargeCandidatesModalComponent_span_19_Template, 3, 1, "span", 2);
      i0.ɵɵtemplate(20, NmswChargeCandidatesModalComponent_ng_template_20_Template, 4, 2, "ng-template", null, 15, i0.ɵɵtemplateRefExtractor);
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      const _r1 = i0.ɵɵreference(4);
      const _r6 = i0.ɵɵreference(21);
      i0.ɵɵadvance(2);
      i0.ɵɵproperty("ngIf", ctx.invoice == null ? null : ctx.invoice.id)("ngIfElse", _r1);
      i0.ɵɵadvance(3);
      i0.ɵɵproperty("disabled", ctx.isBusy);
      i0.ɵɵadvance(6);
      i0.ɵɵproperty("isBusy", ctx.isBusy);
      i0.ɵɵadvance(4);
      i0.ɵɵproperty("ngIf", !ctx.isBusy);
      i0.ɵɵadvance(2);
      i0.ɵɵproperty("disabled", ctx.isBusy);
      i0.ɵɵadvance(2);
      i0.ɵɵproperty("ngIf", ctx.invoice == null ? null : ctx.invoice.id)("ngIfElse", _r6);
    }
  },
  dependencies: [i3.NgIf, i4.TranslateDirective, i5.ColumnComponent, i5.CheckboxColumnComponent, i5.CellTemplateDirective, i6.Button, i7.LoaderComponent, i8.AppGridComponent, i9.CheckIconComponent, i10.ToggleableFilterComponent, i11.PopoverListComponent, i3.AsyncPipe, i3.DecimalPipe, i4.TranslatePipe, i12.CodelistPipe],
  styles: ["\n/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IiIsInNvdXJjZVJvb3QiOiIifQ== */"]
});