import { ComponentRef, Injector, StaticProvider, Type, ViewContainerRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import _ from 'lodash';
import * as i0 from "@angular/core";
import * as i1 from "@ng-bootstrap/ng-bootstrap";
import * as i2 from "@angular/common";
import * as i3 from "@angular/forms";
import * as i4 from "@ngx-translate/core";
import * as i5 from "../app-control/app-control.component";
const _c0 = ["template"];
function DialogFormComponent_div_7_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div")(1, "h6");
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(ctx_r0.message);
  }
}
const _c1 = function (a0) {
  return {
    "mt-2": a0
  };
};
function DialogFormComponent_ng_container_8_div_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r8 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 11)(1, "div", 12)(2, "app-control", 13);
    i0.ɵɵlistener("ngModelChange", function DialogFormComponent_ng_container_8_div_1_Template_app_control_ngModelChange_2_listener($event) {
      const restoredCtx = i0.ɵɵrestoreView(_r8);
      const property_r5 = restoredCtx.$implicit;
      const ctx_r7 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r7.model[ctx_r7.getName(property_r5)] = $event);
    });
    i0.ɵɵelementEnd()()();
  }
  if (rf & 2) {
    const property_r5 = ctx.$implicit;
    const i_r6 = ctx.index;
    const ctx_r4 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(16, _c1, i_r6 > 0));
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("type", property_r5.type)("property", ctx_r4.getName(property_r5))("label", property_r5.label)("codelist", property_r5.codelist)("time", property_r5.time)("pattern", property_r5.pattern)("allowedExtensions", property_r5.allowedExtensions)("isDisabled", !property_r5.isDisabled ? false : property_r5.isDisabled(ctx_r4.model))("filter", property_r5.filter == null ? null : property_r5.filter.bind(ctx_r4))("prepend", property_r5.prepend)("min", property_r5.min)("max", property_r5.max)("format", property_r5.format)("initialValue", property_r5.initialValue)("ngModel", ctx_r4.model[ctx_r4.getName(property_r5)]);
  }
}
function DialogFormComponent_ng_container_8_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, DialogFormComponent_ng_container_8_div_1_Template, 3, 18, "div", 10);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngForOf", ctx_r1.options.properties);
  }
}
function DialogFormComponent_ng_template_9_Template(rf, ctx) {}
export class DialogFormComponent {
  constructor(activeModal) {
    this.activeModal = activeModal;
  }
  initialize(options, injector) {
    if (!options.properties && !options.contentComponent) {
      throw new Error('properties or contentComponent option has to be set');
    }
    this.options = options;
    this.model = this.options.model || {};
    this.message = this.options.message;
    this.injector = injector;
    this.canConfirmPredicate = this.options.canConfirm || this.canConfirm.bind(this);
  }
  confirm() {
    this.activeModal.close(this.model);
  }
  canConfirm(model) {
    return true;
  }
  cancel() {
    this.activeModal.close(null);
  }
  loadComponent(component, dataProviders) {
    dataProviders = (dataProviders || []).concat([{
      provide: NgbActiveModal,
      useValue: this.activeModal
    }]);
    const injector = Injector.create({
      providers: dataProviders || [],
      parent: this.injector
    });
    this.contentComponentRef = this.templateRef.createComponent(component, {
      injector
    });
    if (!this.contentComponentRef.instance.model) {
      this.contentComponentRef.instance.model = this.model;
    } else {
      this.model = this.contentComponentRef.instance.model;
    }
    if (_.isFunction(this.contentComponentRef.instance.confirm)) {
      this.confirmAction = this.contentComponentRef.instance.confirm.bind(this.contentComponentRef.instance);
    }
    if (_.isFunction(this.contentComponentRef.instance.canConfirm)) {
      this.canConfirmPredicate = this.contentComponentRef.instance.canConfirm.bind(this.contentComponentRef.instance);
    }
    if (_.isFunction(this.contentComponentRef.instance.cancel)) {
      this.cancelAction = this.contentComponentRef.instance.cancel.bind(this.contentComponentRef.instance);
    }
    if (_.isFunction(this.options.onContentComponentCreated)) {
      this.options.onContentComponentCreated(this.contentComponentRef.instance);
    }
    this.contentComponentRef.changeDetectorRef.detectChanges();
  }
  ngOnInit() {}
  ngAfterViewInit() {
    if (this.options.contentComponent) {
      this.loadComponent(this.options.contentComponent, this.options.contentComponentDataProviders);
    }
    this.confirmAction = this.confirmAction || this.confirm.bind(this);
    this.cancelAction = this.cancelAction || this.cancel.bind(this);
  }
  ngOnDestroy() {
    if (this.contentComponentRef) {
      this.contentComponentRef.destroy();
    }
  }
  getName(property) {
    return property.name;
  }
}
DialogFormComponent.ɵfac = function DialogFormComponent_Factory(t) {
  return new (t || DialogFormComponent)(i0.ɵɵdirectiveInject(i1.NgbActiveModal));
};
DialogFormComponent.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
  type: DialogFormComponent,
  selectors: [["app-dialog-form"]],
  viewQuery: function DialogFormComponent_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(_c0, 5, ViewContainerRef);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.templateRef = _t.first);
    }
  },
  decls: 18,
  vars: 10,
  consts: [[1, "modal-header"], [1, "modal-title"], ["type", "button", "aria-label", "Close", 1, "close", 3, "click"], ["aria-hidden", "true"], [1, "modal-body"], [4, "ngIf"], ["template", ""], [1, "modal-footer"], ["translate", "", 1, "btn", "btn-danger", 3, "click"], ["translate", "", 1, "btn", "btn-primary", 3, "disabled", "click"], ["class", "row", 3, "ngClass", 4, "ngFor", "ngForOf"], [1, "row", 3, "ngClass"], [1, "col"], ["property", "model[getName(property)]", 3, "type", "property", "label", "codelist", "time", "pattern", "allowedExtensions", "isDisabled", "filter", "prepend", "min", "max", "format", "initialValue", "ngModel", "entity", "ngModelChange"]],
  template: function DialogFormComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "div", 0)(1, "h4", 1);
      i0.ɵɵtext(2);
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(3, "button", 2);
      i0.ɵɵlistener("click", function DialogFormComponent_Template_button_click_3_listener() {
        return ctx.cancelAction();
      });
      i0.ɵɵelementStart(4, "span", 3);
      i0.ɵɵtext(5, "\u00D7");
      i0.ɵɵelementEnd()()();
      i0.ɵɵelementStart(6, "div", 4);
      i0.ɵɵtemplate(7, DialogFormComponent_div_7_Template, 3, 1, "div", 5);
      i0.ɵɵtemplate(8, DialogFormComponent_ng_container_8_Template, 2, 1, "ng-container", 5);
      i0.ɵɵtemplate(9, DialogFormComponent_ng_template_9_Template, 0, 0, "ng-template", null, 6, i0.ɵɵtemplateRefExtractor);
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(11, "div", 7)(12, "button", 8);
      i0.ɵɵlistener("click", function DialogFormComponent_Template_button_click_12_listener() {
        return ctx.cancelAction();
      });
      i0.ɵɵtext(13);
      i0.ɵɵpipe(14, "translate");
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(15, "button", 9);
      i0.ɵɵlistener("click", function DialogFormComponent_Template_button_click_15_listener() {
        return ctx.confirmAction();
      });
      i0.ɵɵtext(16);
      i0.ɵɵpipe(17, "translate");
      i0.ɵɵelementEnd()();
    }
    if (rf & 2) {
      i0.ɵɵadvance(2);
      i0.ɵɵtextInterpolate(ctx.options.title);
      i0.ɵɵadvance(5);
      i0.ɵɵproperty("ngIf", ctx.message);
      i0.ɵɵadvance(1);
      i0.ɵɵproperty("ngIf", (ctx.options.properties == null ? null : ctx.options.properties.length) > 0);
      i0.ɵɵadvance(5);
      i0.ɵɵtextInterpolate1(" ", ctx.options.cancelText || i0.ɵɵpipeBind1(14, 6, "Cancel"), " ");
      i0.ɵɵadvance(2);
      i0.ɵɵproperty("disabled", !ctx.canConfirmPredicate(ctx.model));
      i0.ɵɵadvance(1);
      i0.ɵɵtextInterpolate1(" ", ctx.options.confirmText || i0.ɵɵpipeBind1(17, 8, "Confirm"), " ");
    }
  },
  dependencies: [i2.NgClass, i2.NgForOf, i2.NgIf, i3.NgControlStatus, i3.PatternValidator, i3.NgModel, i4.TranslateDirective, i5.AppControlComponent, i4.TranslatePipe],
  styles: ["\n/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IiIsInNvdXJjZVJvb3QiOiIifQ== */"]
});