import { AutoFilterBreezeListComponent } from '@common/classes/auto-filter-list';
import { BreezeViewService } from '@common/services/breeze-view.service';
import { SharedService } from '@common/services/shared.service';
import * as i0 from "@angular/core";
import * as i1 from "@common/services/breeze-view.service";
import * as i2 from "@common/services/shared.service";
import * as i3 from "@angular/common";
import * as i4 from "@angular/router";
import * as i5 from "@angular/forms";
import * as i6 from "@ng-bootstrap/ng-bootstrap";
import * as i7 from "@fortawesome/angular-fontawesome";
import * as i8 from "@progress/kendo-angular-grid";
import * as i9 from "../../../../common/components/action-bar/action-bar.component";
import * as i10 from "../../../../common/components/app-control/app-control.component";
import * as i11 from "../../../../common/components/app-form/app-form.component";
import * as i12 from "../../../../common/components/content-header/content-header.component";
import * as i13 from "../../../../common/components/app-grid/app-grid.component";
import * as i14 from "../../../../common/components/app-control-container/app-control-container.component";
import * as i15 from "../../../../common/components/flag-icon.component";
import * as i16 from "../../../../common/components/accordion-header.component";
import * as i17 from "@ngx-translate/core";
import * as i18 from "../../../../common/pipes/codelist.pipe";
function VtsVesselListComponent_ng_template_6_span_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span");
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r7 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate1("\u00A0(", ctx_r7.numberOfActiveFilters, ")");
  }
}
function VtsVesselListComponent_ng_template_6_Template(rf, ctx) {
  if (rf & 1) {
    const _r9 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "app-accordion-header", 16);
    i0.ɵɵlistener("panelChange", function VtsVesselListComponent_ng_template_6_Template_app_accordion_header_panelChange_0_listener() {
      i0.ɵɵrestoreView(_r9);
      const ctx_r8 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r8.handleFilterHighlighter(true));
    });
    i0.ɵɵelementStart(1, "h5");
    i0.ɵɵelement(2, "fa-icon", 17);
    i0.ɵɵtext(3);
    i0.ɵɵpipe(4, "translate");
    i0.ɵɵtemplate(5, VtsVesselListComponent_ng_template_6_span_5_Template, 2, 1, "span", 18);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const panel_r6 = ctx.$implicit;
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("panel", panel_r6);
    i0.ɵɵadvance(3);
    i0.ɵɵtextInterpolate1(" \u00A0", i0.ɵɵpipeBind1(4, 3, "Filters"), " ");
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngIf", ctx_r0.numberOfActiveFilters > 0);
  }
}
function VtsVesselListComponent_ng_template_7_Template(rf, ctx) {
  if (rf & 1) {
    const _r12 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "app-form", 19);
    i0.ɵɵlistener("submit", function VtsVesselListComponent_ng_template_7_Template_app_form_submit_0_listener() {
      i0.ɵɵrestoreView(_r12);
      const ctx_r11 = i0.ɵɵnextContext();
      ctx_r11.search();
      return i0.ɵɵresetView(ctx_r11.onAutoFilterChange());
    });
    i0.ɵɵelementStart(1, "app-control-container", null, 20)(3, "div", 21)(4, "div", 22)(5, "app-control", 23);
    i0.ɵɵlistener("ngModelChange", function VtsVesselListComponent_ng_template_7_Template_app_control_ngModelChange_5_listener($event) {
      i0.ɵɵrestoreView(_r12);
      const ctx_r13 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r13.filter.vesselId = $event);
    });
    i0.ɵɵpipe(6, "translate");
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(7, "div", 22)(8, "app-control", 24);
    i0.ɵɵlistener("ngModelChange", function VtsVesselListComponent_ng_template_7_Template_app_control_ngModelChange_8_listener($event) {
      i0.ɵɵrestoreView(_r12);
      const ctx_r14 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r14.filter.typeCode = $event);
    });
    i0.ɵɵpipe(9, "translate");
    i0.ɵɵelementEnd()()();
    i0.ɵɵelementStart(10, "div", 21)(11, "div", 22)(12, "app-control", 25);
    i0.ɵɵlistener("ngModelChange", function VtsVesselListComponent_ng_template_7_Template_app_control_ngModelChange_12_listener($event) {
      i0.ɵɵrestoreView(_r12);
      const ctx_r15 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r15.filter.iMONumber = $event);
    });
    i0.ɵɵpipe(13, "translate");
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(14, "div", 22)(15, "app-control", 26);
    i0.ɵɵlistener("ngModelChange", function VtsVesselListComponent_ng_template_7_Template_app_control_ngModelChange_15_listener($event) {
      i0.ɵɵrestoreView(_r12);
      const ctx_r16 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r16.filter.statusId = $event);
    });
    i0.ɵɵpipe(16, "translate");
    i0.ɵɵelementEnd()()();
    i0.ɵɵelementStart(17, "div", 27)(18, "div", 22)(19, "div", 28)(20, "button", 29);
    i0.ɵɵlistener("click", function VtsVesselListComponent_ng_template_7_Template_button_click_20_listener() {
      i0.ɵɵrestoreView(_r12);
      const ctx_r17 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r17.clearFilter());
    });
    i0.ɵɵelement(21, "fa-icon", 30);
    i0.ɵɵtext(22, " \u00A0 ");
    i0.ɵɵelementStart(23, "span", 31);
    i0.ɵɵtext(24);
    i0.ɵɵpipe(25, "translate");
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(26, "button", 32);
    i0.ɵɵlistener("click", function VtsVesselListComponent_ng_template_7_Template_button_click_26_listener() {
      i0.ɵɵrestoreView(_r12);
      const ctx_r18 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r18.search());
    });
    i0.ɵɵelement(27, "fa-icon", 33);
    i0.ɵɵtext(28, " \u00A0 ");
    i0.ɵɵelementStart(29, "span", 31);
    i0.ɵɵtext(30);
    i0.ɵɵpipe(31, "translate");
    i0.ɵɵelementEnd()()()()()()();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance(5);
    i0.ɵɵproperty("label", i0.ɵɵpipeBind1(6, 14, "Vessel Name"))("ngModel", ctx_r1.filter.vesselId)("entity", ctx_r1.filter);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("label", i0.ɵɵpipeBind1(9, 16, "Vessel Type"))("ngModel", ctx_r1.filter.typeCode)("entity", ctx_r1.filter);
    i0.ɵɵadvance(4);
    i0.ɵɵproperty("label", i0.ɵɵpipeBind1(13, 18, "IMO Number"))("ngModel", ctx_r1.filter.iMONumber)("entity", ctx_r1.filter);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("label", i0.ɵɵpipeBind1(16, 20, "Status"))("ngModel", ctx_r1.filter.statusId)("entity", ctx_r1.filter);
    i0.ɵɵadvance(9);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(25, 22, "Clear"));
    i0.ɵɵadvance(6);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(31, 24, "Search"));
  }
}
const _c0 = function (a1, a2) {
  return ["/code-list/vts-vessel", a1, a2];
};
function VtsVesselListComponent_ng_template_13_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "a", 34);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const row_r19 = ctx.$implicit;
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵproperty("routerLink", i0.ɵɵpureFunction2(2, _c0, ctx_r2.defaultViewMode, row_r19.id));
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate1(" ", row_r19.name, " ");
  }
}
function VtsVesselListComponent_ng_template_16_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtext(0);
    i0.ɵɵpipe(1, "async");
    i0.ɵɵpipe(2, "codelist");
  }
  if (rf & 2) {
    const row_r20 = ctx.$implicit;
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind1(1, 1, i0.ɵɵpipeBind2(2, 3, row_r20.typeId, "VesselType")), " ");
  }
}
function VtsVesselListComponent_ng_template_21_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "app-flag-icon", 35);
  }
  if (rf & 2) {
    const row_r21 = ctx.$implicit;
    i0.ɵɵproperty("code", row_r21.countryId)("floating", true);
  }
}
function VtsVesselListComponent_ng_template_26_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtext(0);
    i0.ɵɵpipe(1, "number");
  }
  if (rf & 2) {
    const row_r22 = ctx.$implicit;
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind3(1, 1, row_r22.length, "1.2", "en"), " ");
  }
}
export class VtsVesselListComponent extends AutoFilterBreezeListComponent {
  constructor(breezeViewService, sharedService) {
    super(breezeViewService, sharedService);
    this.breezeViewService = breezeViewService;
    this.sharedService = sharedService;
    this.queryName = 'VtsVessels';
    this.parentRoute = '/code-list/vts-vessel';
    this.breadcrumb = [{
      icon: 'ship',
      title: this.translateService.get('VTS Vessels')
    }];
    this.gridSort = [{
      field: 'name',
      dir: 'asc'
    }];
    this.actionBar[0].items.shift();
  }
  getDefaultFilter(data) {
    return this.entityManager.createEntity('GetVtsVesselsQuery', {
      active: true,
      ...data
    });
  }
}
VtsVesselListComponent.ɵfac = function VtsVesselListComponent_Factory(t) {
  return new (t || VtsVesselListComponent)(i0.ɵɵdirectiveInject(i1.BreezeViewService), i0.ɵɵdirectiveInject(i2.SharedService));
};
VtsVesselListComponent.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
  type: VtsVesselListComponent,
  selectors: [["app-vts-vessel-list"]],
  features: [i0.ɵɵProvidersFeature([BreezeViewService]), i0.ɵɵInheritDefinitionFeature],
  decls: 27,
  vars: 26,
  consts: [[3, "items"], [3, "title", "breadcrumb"], [1, "filter-wrapper"], ["activeIds", "ngb-panel-0"], ["ngbPanelHeader", ""], ["ngbPanelContent", ""], [1, "card", "card-border-top", "mt-3"], [1, "card-body"], [1, "mb-2", 3, "data", "sortable", "sort"], ["field", "name", 3, "title"], ["kendoGridCellTemplate", ""], ["field", "typeId", 3, "title"], ["field", "iMONumber", 3, "title"], ["field", "countryId", 3, "title"], ["field", "status.name", 3, "title"], ["field", "length", 3, "title"], [3, "panel", "panelChange"], ["icon", "filter"], [4, "ngIf"], [3, "submit"], ["filterHighlighter", ""], [1, "row"], [1, "col"], ["type", "codelist", "codelist", "VtsVessel", "property", "vesselId", 3, "label", "ngModel", "entity", "ngModelChange"], ["type", "codelist", "codelist", "VesselType", "property", "typeCode", 3, "label", "ngModel", "entity", "ngModelChange"], ["onkeypress", "return event.charCode >= 48 && event.charCode <= 57", "property", "iMONumber", 3, "label", "ngModel", "entity", "ngModelChange"], ["type", "codelist", "codelist", "VesselStatus", "property", "statusId", 3, "label", "ngModel", "entity", "ngModelChange"], [1, "row", "mt-3"], [1, "float-right"], [1, "btn", "btn-sm", "btn-danger", "mr-2", 3, "click"], ["icon", "trash", 1, "ml-1"], [1, "btn-text"], [1, "btn", "btn-sm", "btn-primary", 3, "click"], ["icon", "search", 1, "ml-1"], [1, "text-primary", "link-box", 3, "routerLink"], ["codelist", "Country", 3, "code", "floating"]],
  template: function VtsVesselListComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelement(0, "app-action-bar", 0)(1, "app-content-header", 1);
      i0.ɵɵpipe(2, "translate");
      i0.ɵɵelementStart(3, "div", 2)(4, "ngb-accordion", 3)(5, "ngb-panel");
      i0.ɵɵtemplate(6, VtsVesselListComponent_ng_template_6_Template, 6, 5, "ng-template", 4);
      i0.ɵɵtemplate(7, VtsVesselListComponent_ng_template_7_Template, 32, 26, "ng-template", 5);
      i0.ɵɵelementEnd()()();
      i0.ɵɵelementStart(8, "div", 6)(9, "div", 7)(10, "app-grid", 8)(11, "kendo-grid-column", 9);
      i0.ɵɵpipe(12, "translate");
      i0.ɵɵtemplate(13, VtsVesselListComponent_ng_template_13_Template, 2, 5, "ng-template", 10);
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(14, "kendo-grid-column", 11);
      i0.ɵɵpipe(15, "translate");
      i0.ɵɵtemplate(16, VtsVesselListComponent_ng_template_16_Template, 3, 6, "ng-template", 10);
      i0.ɵɵelementEnd();
      i0.ɵɵelement(17, "kendo-grid-column", 12);
      i0.ɵɵpipe(18, "translate");
      i0.ɵɵelementStart(19, "kendo-grid-column", 13);
      i0.ɵɵpipe(20, "translate");
      i0.ɵɵtemplate(21, VtsVesselListComponent_ng_template_21_Template, 1, 2, "ng-template", 10);
      i0.ɵɵelementEnd();
      i0.ɵɵelement(22, "kendo-grid-column", 14);
      i0.ɵɵpipe(23, "translate");
      i0.ɵɵelementStart(24, "kendo-grid-column", 15);
      i0.ɵɵpipe(25, "translate");
      i0.ɵɵtemplate(26, VtsVesselListComponent_ng_template_26_Template, 2, 5, "ng-template", 10);
      i0.ɵɵelementEnd()()()();
    }
    if (rf & 2) {
      i0.ɵɵproperty("items", ctx.actionBar);
      i0.ɵɵadvance(1);
      i0.ɵɵproperty("title", i0.ɵɵpipeBind1(2, 12, "VTS Vessels"))("breadcrumb", ctx.breadcrumb);
      i0.ɵɵadvance(9);
      i0.ɵɵproperty("data", ctx.query)("sortable", true)("sort", ctx.gridSort);
      i0.ɵɵadvance(1);
      i0.ɵɵproperty("title", i0.ɵɵpipeBind1(12, 14, "Vessel Name"));
      i0.ɵɵadvance(3);
      i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(15, 16, "Vessel Type"));
      i0.ɵɵadvance(3);
      i0.ɵɵproperty("title", i0.ɵɵpipeBind1(18, 18, "IMO Number"));
      i0.ɵɵadvance(2);
      i0.ɵɵproperty("title", i0.ɵɵpipeBind1(20, 20, "Flag"));
      i0.ɵɵadvance(3);
      i0.ɵɵproperty("title", i0.ɵɵpipeBind1(23, 22, "Status"));
      i0.ɵɵadvance(2);
      i0.ɵɵproperty("title", i0.ɵɵpipeBind1(25, 24, "Length"));
    }
  },
  dependencies: [i3.NgIf, i4.RouterLink, i5.NgControlStatus, i5.NgModel, i6.NgbAccordion, i6.NgbPanel, i6.NgbPanelContent, i6.NgbPanelHeader, i7.FaIconComponent, i8.ColumnComponent, i8.CellTemplateDirective, i9.ActionBarComponent, i10.AppControlComponent, i11.AppFormComponent, i12.ContentHeaderComponent, i13.AppGridComponent, i14.AppControlContainerComponent, i15.FlagIconComponent, i16.AccordionHeaderComponent, i3.AsyncPipe, i3.DecimalPipe, i17.TranslatePipe, i18.CodelistPipe],
  styles: ["\n/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IiIsInNvdXJjZVJvb3QiOiIifQ== */"]
});