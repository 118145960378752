import { BreezeViewService } from '@common/services/breeze-view.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as i0 from "@angular/core";
import * as i1 from "@ng-bootstrap/ng-bootstrap";
import * as i2 from "@angular/router";
import * as i3 from "@progress/kendo-angular-grid";
import * as i4 from "../../../common/components/app-grid/app-grid.component";
import * as i5 from "../../../common/components/popover-list/popover-list.component";
import * as i6 from "@angular/common";
import * as i7 from "@ngx-translate/core";
import * as i8 from "../../../common/pipes/codelist.pipe";
import * as i9 from "../../../common/pipes/pad.pipe";
const _c0 = function (a1) {
  return ["/invoice/view", a1];
};
function InvoiceListModalComponent_ng_template_13_Template(rf, ctx) {
  if (rf & 1) {
    const _r8 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "a", 17);
    i0.ɵɵlistener("click", function InvoiceListModalComponent_ng_template_13_Template_a_click_0_listener() {
      i0.ɵɵrestoreView(_r8);
      const ctx_r7 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r7.close());
    });
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const row_r6 = ctx.$implicit;
    i0.ɵɵproperty("routerLink", i0.ɵɵpureFunction1(2, _c0, row_r6.id));
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate1(" ", row_r6.invoiceNumber, " ");
  }
}
const _c1 = function (a0) {
  return {
    text: a0,
    separator: ";",
    width: "150px"
  };
};
function InvoiceListModalComponent_ng_template_16_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "app-popover-list", 18);
  }
  if (rf & 2) {
    const dataItem_r9 = ctx.$implicit;
    i0.ɵɵproperty("data", i0.ɵɵpureFunction1(1, _c1, dataItem_r9.services));
  }
}
function InvoiceListModalComponent_ng_template_19_Template(rf, ctx) {
  if (rf & 1) {
    const _r12 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "a", 17);
    i0.ɵɵlistener("click", function InvoiceListModalComponent_ng_template_19_Template_a_click_0_listener() {
      i0.ɵɵrestoreView(_r12);
      const ctx_r11 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r11.close());
    });
    i0.ɵɵtext(1);
    i0.ɵɵpipe(2, "pad");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const row_r10 = ctx.$implicit;
    i0.ɵɵproperty("routerLink", i0.ɵɵpureFunction1(4, _c0, row_r10.id));
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind1(2, 2, row_r10.internalInvoiceNumber), " ");
  }
}
function InvoiceListModalComponent_ng_template_22_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtext(0);
    i0.ɵɵpipe(1, "async");
    i0.ɵɵpipe(2, "codelist");
  }
  if (rf & 2) {
    const row_r13 = ctx.$implicit;
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind1(1, 1, i0.ɵɵpipeBind2(2, 3, row_r13.typeId, "InvoiceType")), " ");
  }
}
function InvoiceListModalComponent_ng_template_25_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtext(0);
  }
  if (rf & 2) {
    const row_r14 = ctx.$implicit;
    i0.ɵɵtextInterpolate1(" ", row_r14.vesselRevision == null ? null : row_r14.vesselRevision.name, " ");
  }
}
function InvoiceListModalComponent_ng_template_28_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtext(0);
    i0.ɵɵpipe(1, "async");
    i0.ɵɵpipe(2, "codelist");
  }
  if (rf & 2) {
    const row_r15 = ctx.$implicit;
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind1(1, 1, i0.ɵɵpipeBind2(2, 3, row_r15.statusId, "InvoiceStatus")), " ");
  }
}
export class InvoiceListModalComponent {
  constructor(activeModal) {
    this.activeModal = activeModal;
  }
  close() {
    this.activeModal.close();
  }
}
InvoiceListModalComponent.ɵfac = function InvoiceListModalComponent_Factory(t) {
  return new (t || InvoiceListModalComponent)(i0.ɵɵdirectiveInject(i1.NgbActiveModal));
};
InvoiceListModalComponent.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
  type: InvoiceListModalComponent,
  selectors: [["app-invoice-list-modal"]],
  features: [i0.ɵɵProvidersFeature([BreezeViewService])],
  decls: 33,
  vars: 28,
  consts: [[1, "modal-header"], [1, "modal-title"], ["type", "button", "aria-label", "Close", 1, "close", 3, "click"], ["aria-hidden", "true"], [1, "modal-body"], [1, "row"], [1, "col-12"], [1, "mb-2", 3, "data"], ["field", "invoiceNumber", 3, "title"], ["kendoGridCellTemplate", ""], ["field", "services", "width", "160px", 3, "title"], ["field", "internalInvoiceNumber", 3, "title"], ["field", "typeId", 3, "title"], ["field", "vesselRevision.name", 3, "title"], ["field", "statusId", 3, "title"], ["field", "createdBy.fullName", 3, "title"], ["field", "customer.name", 3, "title"], [1, "text-primary", "link-box", 2, "cursor", "pointer", 3, "routerLink", "click"], [3, "data"]],
  template: function InvoiceListModalComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "div", 0)(1, "h5", 1);
      i0.ɵɵtext(2);
      i0.ɵɵpipe(3, "translate");
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(4, "button", 2);
      i0.ɵɵlistener("click", function InvoiceListModalComponent_Template_button_click_4_listener() {
        return ctx.activeModal.close();
      });
      i0.ɵɵelementStart(5, "span", 3);
      i0.ɵɵtext(6, "\u00D7");
      i0.ɵɵelementEnd()()();
      i0.ɵɵelementStart(7, "div", 4)(8, "div", 5)(9, "div", 6)(10, "app-grid", 7)(11, "kendo-grid-column", 8);
      i0.ɵɵpipe(12, "translate");
      i0.ɵɵtemplate(13, InvoiceListModalComponent_ng_template_13_Template, 2, 4, "ng-template", 9);
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(14, "kendo-grid-column", 10);
      i0.ɵɵpipe(15, "translate");
      i0.ɵɵtemplate(16, InvoiceListModalComponent_ng_template_16_Template, 1, 3, "ng-template", 9);
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(17, "kendo-grid-column", 11);
      i0.ɵɵpipe(18, "translate");
      i0.ɵɵtemplate(19, InvoiceListModalComponent_ng_template_19_Template, 3, 6, "ng-template", 9);
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(20, "kendo-grid-column", 12);
      i0.ɵɵpipe(21, "translate");
      i0.ɵɵtemplate(22, InvoiceListModalComponent_ng_template_22_Template, 3, 6, "ng-template", 9);
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(23, "kendo-grid-column", 13);
      i0.ɵɵpipe(24, "translate");
      i0.ɵɵtemplate(25, InvoiceListModalComponent_ng_template_25_Template, 1, 1, "ng-template", 9);
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(26, "kendo-grid-column", 14);
      i0.ɵɵpipe(27, "translate");
      i0.ɵɵtemplate(28, InvoiceListModalComponent_ng_template_28_Template, 3, 6, "ng-template", 9);
      i0.ɵɵelementEnd();
      i0.ɵɵelement(29, "kendo-grid-column", 15);
      i0.ɵɵpipe(30, "translate");
      i0.ɵɵelement(31, "kendo-grid-column", 16);
      i0.ɵɵpipe(32, "translate");
      i0.ɵɵelementEnd()()()();
    }
    if (rf & 2) {
      i0.ɵɵadvance(2);
      i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(3, 10, "Invoices"));
      i0.ɵɵadvance(8);
      i0.ɵɵproperty("data", ctx.invoices);
      i0.ɵɵadvance(1);
      i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(12, 12, "Invoice Number"));
      i0.ɵɵadvance(3);
      i0.ɵɵproperty("title", i0.ɵɵpipeBind1(15, 14, "Type of Dues"));
      i0.ɵɵadvance(3);
      i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(18, 16, "ID"));
      i0.ɵɵadvance(3);
      i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(21, 18, "Type"));
      i0.ɵɵadvance(3);
      i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(24, 20, "Vessel Name"));
      i0.ɵɵadvance(3);
      i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(27, 22, "Status"));
      i0.ɵɵadvance(3);
      i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(30, 24, "Created By"));
      i0.ɵɵadvance(2);
      i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(32, 26, "Payer"));
    }
  },
  dependencies: [i2.RouterLink, i3.ColumnComponent, i3.CellTemplateDirective, i4.AppGridComponent, i5.PopoverListComponent, i6.AsyncPipe, i7.TranslatePipe, i8.CodelistPipe, i9.PadPipe],
  styles: ["\n/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IiIsInNvdXJjZVJvb3QiOiIifQ== */"]
});