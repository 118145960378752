import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';
import * as i0 from "@angular/core";
import * as i1 from "@progress/kendo-angular-dialog";
import * as i2 from "@angular/common";
import * as i3 from "@ngx-translate/core";
import * as i4 from "@progress/kendo-angular-buttons";
function ConfirmDialogComponent_button_6_Template(rf, ctx) {
  if (rf & 1) {
    const _r2 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 6);
    i0.ɵɵlistener("click", function ConfirmDialogComponent_button_6_Template_button_click_0_listener() {
      i0.ɵɵrestoreView(_r2);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.close(false));
    });
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("disabled", ctx_r0.isBusy);
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate1(" ", ctx_r0.cancelText, " ");
  }
}
export class ConfirmDialogComponent extends DialogContentBase {
  constructor(dialog) {
    super(dialog);
    this.dialog = dialog;
    this.hideCancel = false;
    this.cancelText = 'Cancel';
    this.confirmText = 'Confirm';
    this.isBusy = false;
  }
  close(result) {
    if (!this.isBusy) {
      this.isBusy = true;
      this.dialog.close(result);
    }
  }
}
ConfirmDialogComponent.ɵfac = function ConfirmDialogComponent_Factory(t) {
  return new (t || ConfirmDialogComponent)(i0.ɵɵdirectiveInject(i1.DialogRef));
};
ConfirmDialogComponent.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
  type: ConfirmDialogComponent,
  selectors: [["ng-component"]],
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 9,
  vars: 6,
  consts: [[2, "font-size", "18px", "line-height", "1.3em"], [1, "k-icon", "k-i-warning"], [1, "m-5", "text-center", 3, "innerHTML"], [3, "layout"], ["kendoButton", "", "translate", "", 3, "disabled", "click", 4, "ngIf"], ["kendoButton", "", "primary", "true", "translate", "", 3, "disabled", "click"], ["kendoButton", "", "translate", "", 3, "disabled", "click"]],
  template: function ConfirmDialogComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "kendo-dialog-titlebar")(1, "div", 0);
      i0.ɵɵelement(2, "span", 1);
      i0.ɵɵtext(3);
      i0.ɵɵelementEnd()();
      i0.ɵɵelement(4, "div", 2);
      i0.ɵɵelementStart(5, "kendo-dialog-actions", 3);
      i0.ɵɵtemplate(6, ConfirmDialogComponent_button_6_Template, 2, 2, "button", 4);
      i0.ɵɵelementStart(7, "button", 5);
      i0.ɵɵlistener("click", function ConfirmDialogComponent_Template_button_click_7_listener() {
        return ctx.close(true);
      });
      i0.ɵɵtext(8);
      i0.ɵɵelementEnd()();
    }
    if (rf & 2) {
      i0.ɵɵadvance(3);
      i0.ɵɵtextInterpolate1(" ", ctx.title, " ");
      i0.ɵɵadvance(1);
      i0.ɵɵproperty("innerHTML", ctx.message, i0.ɵɵsanitizeHtml);
      i0.ɵɵadvance(1);
      i0.ɵɵproperty("layout", "normal");
      i0.ɵɵadvance(1);
      i0.ɵɵproperty("ngIf", !ctx.hideCancel);
      i0.ɵɵadvance(1);
      i0.ɵɵproperty("disabled", ctx.isBusy);
      i0.ɵɵadvance(1);
      i0.ɵɵtextInterpolate1(" ", ctx.confirmText, " ");
    }
  },
  dependencies: [i2.NgIf, i3.TranslateDirective, i4.Button, i1.DialogTitleBarComponent, i1.DialogActionsComponent],
  encapsulation: 2
});