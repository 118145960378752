import { RouterModule } from '@angular/router';
import { CanDeactivateView } from '@common/guards/view.deactivate';
import { ViewMode } from '@common/models/view-mode';
import { CommonModule } from '../../common/common.module';
import { VtsVesselListComponent } from './Components/vts-vessel-list/vts-vessel-list.component';
import { VtsVesselViewComponent } from './Components/vts-vessel-view/vts-vessel-view.component';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
export const VTS_VESSEL_ROUTES = [{
  path: '',
  redirectTo: 'list',
  pathMatch: 'full'
}, {
  path: 'list',
  component: VtsVesselListComponent
}, {
  path: 'view/:id',
  component: VtsVesselViewComponent,
  data: {
    mode: ViewMode.view
  }
}, {
  path: 'edit/:id',
  component: VtsVesselViewComponent,
  data: {
    mode: ViewMode.edit
  },
  canDeactivate: [CanDeactivateView]
}, {
  path: '**',
  redirectTo: 'list'
}];
export class VtsVesselModule {}
VtsVesselModule.ɵfac = function VtsVesselModule_Factory(t) {
  return new (t || VtsVesselModule)();
};
VtsVesselModule.ɵmod = /*@__PURE__*/i0.ɵɵdefineNgModule({
  type: VtsVesselModule
});
VtsVesselModule.ɵinj = /*@__PURE__*/i0.ɵɵdefineInjector({
  providers: [CanDeactivateView],
  imports: [CommonModule, RouterModule.forChild(VTS_VESSEL_ROUTES)]
});
(function () {
  (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(VtsVesselModule, {
    declarations: [VtsVesselViewComponent, VtsVesselListComponent],
    imports: [CommonModule, i1.RouterModule]
  });
})();