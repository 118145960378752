import { AbstractBreezeViewComponent } from '@common/classes/breeze-view';
import { BreezeViewService } from '@common/services/breeze-view.service';
import { endOfDay, startOfDay } from 'date-fns';
import * as i0 from "@angular/core";
import * as i1 from "@common/services/breeze-view.service";
import * as i2 from "@angular/common";
import * as i3 from "@angular/forms";
import * as i4 from "../../../common/components/action-bar/action-bar.component";
import * as i5 from "../../../common/components/app-control/app-control.component";
import * as i6 from "../../../common/components/loader/loader.component";
import * as i7 from "../../../common/components/content-header/content-header.component";
import * as i8 from "@ngx-translate/core";
const _c0 = function (a0, a1) {
  return {
    "border-danger": a0,
    "border-success": a1
  };
};
function InvoicePeriodViewComponent_ng_template_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r2 = i0.ɵɵgetCurrentView();
    i0.ɵɵelement(0, "app-action-bar", 1)(1, "app-content-header", 2);
    i0.ɵɵpipe(2, "translate");
    i0.ɵɵelementStart(3, "div", 3)(4, "div", 4)(5, "div", 5)(6, "div", 6)(7, "div", 5)(8, "div", 7)(9, "app-control", 8);
    i0.ɵɵlistener("ngModelChange", function InvoicePeriodViewComponent_ng_template_1_Template_app_control_ngModelChange_9_listener($event) {
      i0.ɵɵrestoreView(_r2);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.model.organizationUnitId = $event);
    });
    i0.ɵɵpipe(10, "translate");
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(11, "div", 7)(12, "app-control", 9);
    i0.ɵɵlistener("ngModelChange", function InvoicePeriodViewComponent_ng_template_1_Template_app_control_ngModelChange_12_listener($event) {
      i0.ɵɵrestoreView(_r2);
      const ctx_r3 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r3.model.organizationId = $event);
    });
    i0.ɵɵpipe(13, "translate");
    i0.ɵɵelementEnd()()()();
    i0.ɵɵelementStart(14, "div", 10)(15, "div", 5)(16, "div", 11)(17, "app-control", 12);
    i0.ɵɵlistener("ngModelChange", function InvoicePeriodViewComponent_ng_template_1_Template_app_control_ngModelChange_17_listener($event) {
      i0.ɵɵrestoreView(_r2);
      const ctx_r4 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r4.model.validIssueDateFrom = $event);
    });
    i0.ɵɵpipe(18, "translate");
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(19, "div", 11)(20, "app-control", 13);
    i0.ɵɵlistener("ngModelChange", function InvoicePeriodViewComponent_ng_template_1_Template_app_control_ngModelChange_20_listener($event) {
      i0.ɵɵrestoreView(_r2);
      const ctx_r5 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r5.model.validIssueDateTo = $event);
    });
    i0.ɵɵpipe(21, "translate");
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(22, "div", 11)(23, "app-control", 14);
    i0.ɵɵlistener("ngModelChange", function InvoicePeriodViewComponent_ng_template_1_Template_app_control_ngModelChange_23_listener($event) {
      i0.ɵɵrestoreView(_r2);
      const ctx_r6 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r6.model.currentAccountingYear = $event);
    });
    i0.ɵɵpipe(24, "translate");
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(25, "div", 11)(26, "app-control", 15);
    i0.ɵɵlistener("ngModelChange", function InvoicePeriodViewComponent_ng_template_1_Template_app_control_ngModelChange_26_listener($event) {
      i0.ɵɵrestoreView(_r2);
      const ctx_r7 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r7.model.alternativeAccountingYear = $event);
    });
    i0.ɵɵpipe(27, "translate");
    i0.ɵɵelementEnd()()()();
    i0.ɵɵelementStart(28, "div", 6)(29, "div", 5)(30, "div", 7)(31, "app-control", 16);
    i0.ɵɵlistener("ngModelChange", function InvoicePeriodViewComponent_ng_template_1_Template_app_control_ngModelChange_31_listener($event) {
      i0.ɵɵrestoreView(_r2);
      const ctx_r8 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r8.model.lastModifiedById = $event);
    });
    i0.ɵɵpipe(32, "translate");
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(33, "div", 7)(34, "app-control", 17);
    i0.ɵɵlistener("ngModelChange", function InvoicePeriodViewComponent_ng_template_1_Template_app_control_ngModelChange_34_listener($event) {
      i0.ɵɵrestoreView(_r2);
      const ctx_r9 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r9.model.createdById = $event);
    });
    i0.ɵɵpipe(35, "translate");
    i0.ɵɵelementEnd()()()();
    i0.ɵɵelementStart(36, "div", 6)(37, "div", 5)(38, "div", 7)(39, "app-control", 18);
    i0.ɵɵlistener("ngModelChange", function InvoicePeriodViewComponent_ng_template_1_Template_app_control_ngModelChange_39_listener($event) {
      i0.ɵɵrestoreView(_r2);
      const ctx_r10 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r10.model.lastModifiedDate = $event);
    });
    i0.ɵɵpipe(40, "translate");
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(41, "div", 7)(42, "app-control", 19);
    i0.ɵɵlistener("ngModelChange", function InvoicePeriodViewComponent_ng_template_1_Template_app_control_ngModelChange_42_listener($event) {
      i0.ɵɵrestoreView(_r2);
      const ctx_r11 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r11.model.createdDate = $event);
    });
    i0.ɵɵpipe(43, "translate");
    i0.ɵɵelementEnd()()()()()()();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("items", ctx_r0.actionBar)("collapsed", false);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("title", i0.ɵɵpipeBind1(2, 41, ctx_r0.title))("breadcrumb", ctx_r0.breadcrumb);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction2(63, _c0, ctx_r0.model.error, ctx_r0.model.processed));
    i0.ɵɵadvance(6);
    i0.ɵɵproperty("label", i0.ɵɵpipeBind1(10, 43, "Organization Unit"))("ngModel", ctx_r0.model.organizationUnitId)("entity", ctx_r0.model);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("label", i0.ɵɵpipeBind1(13, 45, "Organization"))("ngModel", ctx_r0.model.organizationId)("entity", ctx_r0.model);
    i0.ɵɵadvance(5);
    i0.ɵɵproperty("label", i0.ɵɵpipeBind1(18, 47, "Valid Issue Date From"))("ngModel", ctx_r0.model.validIssueDateFrom)("entity", ctx_r0.model);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("label", i0.ɵɵpipeBind1(21, 49, "Valid Issue Date To"))("ngModel", ctx_r0.model.validIssueDateTo)("entity", ctx_r0.model);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("label", i0.ɵɵpipeBind1(24, 51, "Current Accounting Year"))("ngModel", ctx_r0.model.currentAccountingYear)("entity", ctx_r0.model);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("label", i0.ɵɵpipeBind1(27, 53, "Alternative Accounting Year"))("ngModel", ctx_r0.model.alternativeAccountingYear)("entity", ctx_r0.model);
    i0.ɵɵadvance(5);
    i0.ɵɵproperty("label", i0.ɵɵpipeBind1(32, 55, "Last Modified By"))("isDisabled", true)("ngModel", ctx_r0.model.lastModifiedById)("entity", ctx_r0.model);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("label", i0.ɵɵpipeBind1(35, 57, "Created By"))("isDisabled", true)("ngModel", ctx_r0.model.createdById)("entity", ctx_r0.model);
    i0.ɵɵadvance(5);
    i0.ɵɵproperty("label", i0.ɵɵpipeBind1(40, 59, "Last Modified Date"))("time", true)("isDisabled", true)("ngModel", ctx_r0.model.lastModifiedDate)("entity", ctx_r0.model);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("label", i0.ɵɵpipeBind1(43, 61, "Created Date"))("time", true)("isDisabled", true)("ngModel", ctx_r0.model.createdDate)("entity", ctx_r0.model);
  }
}
export class InvoicePeriodViewComponent extends AbstractBreezeViewComponent {
  constructor(breezeViewService) {
    super(breezeViewService);
    this.breezeViewService = breezeViewService;
    this.entityName = 'InvoicePeriod';
    this.parentRoute = 'invoice/invoice-period-list';
    this.breadcrumb = [{
      icon: 'list',
      title: this.translateService.get('Invoice Period List'),
      route: '/invoice/invoice-period-list'
    }, {
      icon: 'file',
      title: this.translateService.get('Invoice Period')
    }];
    this.editActionBarGroup.items.push({
      label: this.translateService.instant('Delete'),
      icon: 'trash',
      onClick: () => {
        this.dialogService.confirm(this.translateService.instant('Confirm'), this.translateService.instant(`Are you sure you want to delete this item?`)).then(result => {
          if (result !== true) {
            return;
          }
          this.model.entityAspect.setDeleted();
          this.saveChanges().then(() => {
            this.router.navigate([`${this.parentRoute}`]);
          });
        });
      }
    });
  }
  modelLoaded() {
    super.modelLoaded();
    if (this.isNew) {
      this.model.validIssueDateFrom = this.model.validIssueDateTo = this.model.validFullCreditNoteDateFrom = new Date();
      this.model.currentAccountingYear = new Date().getFullYear();
      this.model.alternativeAccountingYear = this.model.currentAccountingYear + 1;
    }
  }
  beforeSave() {
    this.setHours({
      value: this.model.validIssueDateFrom,
      key: 'validIssueDateFrom'
    });
    this.setHours({
      value: this.model.validIssueDateTo,
      key: 'validIssueDateTo'
    });
  }
  setHours(input) {
    const date = input.value;
    const key = input.key;
    if (key.includes('From')) this.model[key] = startOfDay(date);
    if (key.includes('To')) this.model[key] = endOfDay(date);
  }
}
InvoicePeriodViewComponent.ɵfac = function InvoicePeriodViewComponent_Factory(t) {
  return new (t || InvoicePeriodViewComponent)(i0.ɵɵdirectiveInject(i1.BreezeViewService));
};
InvoicePeriodViewComponent.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
  type: InvoicePeriodViewComponent,
  selectors: [["app-invoice-period-view"]],
  features: [i0.ɵɵProvidersFeature([BreezeViewService]), i0.ɵɵInheritDefinitionFeature],
  decls: 2,
  vars: 1,
  consts: [[3, "isBusy"], [3, "items", "collapsed"], [3, "title", "breadcrumb"], [1, "card", "card-border-top", 3, "ngClass"], [1, "card-body"], [1, "row"], [1, "col-2"], [1, "col-12"], ["property", "organizationUnitId", 3, "label", "ngModel", "entity", "ngModelChange"], ["codelist", "Organization", "property", "organizationId", 3, "label", "ngModel", "entity", "ngModelChange"], [1, "col-3"], [1, "col-6"], ["property", "validIssueDateFrom", 3, "label", "ngModel", "entity", "ngModelChange"], ["property", "validIssueDateTo", 3, "label", "ngModel", "entity", "ngModelChange"], ["type", "number", "min", "1900", "max", "2100", "format", "#", "property", "currentAccountingYear", 3, "label", "ngModel", "entity", "ngModelChange"], ["type", "number", "min", "1900", "max", "2100", "format", "#", "property", "alternativeAccountingYear", 3, "label", "ngModel", "entity", "ngModelChange"], ["type", "codelist", "codelist", "User", "property", "lastModifiedById", 3, "label", "isDisabled", "ngModel", "entity", "ngModelChange"], ["type", "codelist", "codelist", "User", "property", "createdById", 3, "label", "isDisabled", "ngModel", "entity", "ngModelChange"], ["property", "lastModifiedDate", 3, "label", "time", "isDisabled", "ngModel", "entity", "ngModelChange"], ["property", "createdDate", 3, "label", "time", "isDisabled", "ngModel", "entity", "ngModelChange"]],
  template: function InvoicePeriodViewComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "app-loader", 0);
      i0.ɵɵtemplate(1, InvoicePeriodViewComponent_ng_template_1_Template, 44, 66, "ng-template");
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      i0.ɵɵproperty("isBusy", !ctx.model);
    }
  },
  dependencies: [i2.NgClass, i3.NgControlStatus, i3.NgModel, i4.ActionBarComponent, i5.AppControlComponent, i6.LoaderComponent, i7.ContentHeaderComponent, i8.TranslatePipe],
  styles: ["\n/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IiIsInNvdXJjZVJvb3QiOiIifQ== */"]
});