import * as _ from 'lodash';
export class User {
  constructor(data) {
    this.cache = {};
    Object.assign(this, data);
    const userRoles = _.flatMap(this.roles, x => x.role);
    const organizationRoles = this.organization ? _.flatMap(this.organization.roles, x => x.role) : [];
    const roles = _.uniq([...userRoles, ...organizationRoles]);
    this.allRoles = _.map(roles, x => x.name);
    this.allPermissions = _.uniq(_.map(_.flatMap(roles, x => x.permissions), x => `${x.permission.module}.${x.permission.namespace}.${x.permission.name}`));
    _.forEach(_.filter(this.allPermissions, x => x), x => {
      const temp = x.split('.');
      this.cache[`${temp[0]}`] = true;
      this.cache[`${temp[0]}.${temp[1]}`] = true;
      this.cache[x] = true;
    });
  }
  hasPermission(...permissions) {
    if (this.isSystemUser) {
      return this.isSystemUser;
    }
    const perms = _.map(permissions, x => _.isString(x) ? x : x._);
    return _.some(perms, y => {
      return this.cache[y];
    });
  }
  hasRole(role) {
    return _.includes(this.allRoles, role);
  }
}