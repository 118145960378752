import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "@fortawesome/angular-fontawesome";
function CheckIconComponent_fa_icon_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "fa-icon", 4);
  }
}
function CheckIconComponent_fa_icon_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "fa-icon", 5);
  }
}
function CheckIconComponent_fa_icon_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "fa-icon", 6);
  }
}
export class CheckIconComponent {
  constructor() {
    this.hideFalse = false;
    this.neutral = false;
  }
}
CheckIconComponent.ɵfac = function CheckIconComponent_Factory(t) {
  return new (t || CheckIconComponent)();
};
CheckIconComponent.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
  type: CheckIconComponent,
  selectors: [["app-check-icon"]],
  inputs: {
    check: "check",
    hideFalse: "hideFalse",
    neutral: "neutral"
  },
  decls: 4,
  vars: 3,
  consts: [[1, "check-icon-container"], ["class", "green-icon", "icon", "check-circle", 4, "ngIf"], ["class", "red-icon", "icon", "times-circle", 4, "ngIf"], ["class", "grey-icon", "icon", "circle", 4, "ngIf"], ["icon", "check-circle", 1, "green-icon"], ["icon", "times-circle", 1, "red-icon"], ["icon", "circle", 1, "grey-icon"]],
  template: function CheckIconComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "div", 0);
      i0.ɵɵtemplate(1, CheckIconComponent_fa_icon_1_Template, 1, 0, "fa-icon", 1);
      i0.ɵɵtemplate(2, CheckIconComponent_fa_icon_2_Template, 1, 0, "fa-icon", 2);
      i0.ɵɵtemplate(3, CheckIconComponent_fa_icon_3_Template, 1, 0, "fa-icon", 3);
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      i0.ɵɵadvance(1);
      i0.ɵɵproperty("ngIf", ctx.check);
      i0.ɵɵadvance(1);
      i0.ɵɵproperty("ngIf", !ctx.check && !ctx.hideFalse && !ctx.neutral);
      i0.ɵɵadvance(1);
      i0.ɵɵproperty("ngIf", !ctx.check && !ctx.hideFalse && ctx.neutral);
    }
  },
  dependencies: [i1.NgIf, i2.FaIconComponent],
  styles: [".check-icon-container[_ngcontent-%COMP%] { text-align: center; }\n/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIndlYnBhY2s6Ly8uL3NyYy9hcHAvY29tbW9uL2NvbXBvbmVudHMvY2hlY2staWNvbi5jb21wb25lbnQudHMiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUEsd0JBQXdCLGtCQUFrQixFQUFFIiwic291cmNlc0NvbnRlbnQiOlsiLmNoZWNrLWljb24tY29udGFpbmVyIHsgdGV4dC1hbGlnbjogY2VudGVyOyB9Il0sInNvdXJjZVJvb3QiOiIifQ== */"]
});