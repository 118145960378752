import { EventEmitter } from '@angular/core';
import * as i0 from "@angular/core";
import * as i1 from "./upload-action-buttons.component";
export class UploadSingleFileComponent {
  constructor() {
    this.removeFile = new EventEmitter();
  }
  get fileName() {
    return this.file ? this.file.name : null;
  }
  onRemoveFile(file) {
    this.removeFile.emit(file);
  }
}
UploadSingleFileComponent.ɵfac = function UploadSingleFileComponent_Factory(t) {
  return new (t || UploadSingleFileComponent)();
};
UploadSingleFileComponent.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
  type: UploadSingleFileComponent,
  selectors: [["app-upload-single-file"]],
  inputs: {
    file: "file",
    disabled: "disabled"
  },
  outputs: {
    removeFile: "removeFile"
  },
  decls: 3,
  vars: 5,
  consts: [[1, "k-file-single", "input-group", "input-group-sm"], ["type", "text", 1, "form-control", 3, "disabled", "value"], [1, "input-group-append", 3, "file", "disabled", "multiple", "removeFile"]],
  template: function UploadSingleFileComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "div", 0);
      i0.ɵɵelement(1, "input", 1);
      i0.ɵɵelementStart(2, "app-upload-action-buttons", 2);
      i0.ɵɵlistener("removeFile", function UploadSingleFileComponent_Template_app_upload_action_buttons_removeFile_2_listener($event) {
        return ctx.onRemoveFile($event);
      });
      i0.ɵɵelementEnd()();
    }
    if (rf & 2) {
      i0.ɵɵadvance(1);
      i0.ɵɵproperty("disabled", true)("value", ctx.fileName);
      i0.ɵɵadvance(1);
      i0.ɵɵproperty("file", ctx.file)("disabled", ctx.disabled)("multiple", false);
    }
  },
  dependencies: [i1.UploadActionButtonsComponent],
  encapsulation: 2
});