import { EntityQuery } from '@cime/breeze-client';
import { AppGridComponent } from '@common/components/app-grid/app-grid.component';
import { BreezeViewService } from '@common/services/breeze-view.service';
import { environment } from '@environments/environment';
import _ from 'lodash';
import * as i0 from "@angular/core";
import * as i1 from "@common/services/breeze-view.service";
const _c0 = ["additionalFilters"];
const _c1 = ["filterHighlighter"];
export class AbstractBreezeListComponent {
  get query() {
    return this._query;
  }
  constructor(breeze) {
    this.breeze = breeze;
    this.selection = [];
    this.showAdditionalFilters = false;
    this.rememberTabs = true;
    this.persistFilter = environment.settings.list.persistFilter;
    this.defaultViewMode = environment.settings.view.defaultViewMode;
    this.state = {
      skip: 0,
      take: 50,
      sort: [{
        field: 'id',
        dir: 'asc'
      }]
    };
    this.isBusy = false;
    this.excelExport = false;
    this.getQuery = this.getQuery.bind(this);
    this.search = _.debounce(this.search.bind(this), 50);
    this.clearFilter = _.debounce(this.clearFilter.bind(this), 50);
    this.entityManager = breeze.entityManager;
    this.translateService = breeze.translateService;
    this.toastrService = breeze.toastrService;
    this.router = breeze.router;
    this.breezeService = breeze.breezeService;
    this.userSubscription = breeze.userService.currentUserSubject.subscribe(user => {
      this.user = user;
    });
    this.activeTab = +this.router.routerState.snapshot.root.queryParams.activeTab || 0;
    this.addActionBar();
  }
  getFilter() {
    const persistedFilter = this.getPersistedFilter();
    const filter = this.getDefaultFilter(persistedFilter);
    if (this.persistFilter && _.isObject(filter) && !filter.entityAspect) {
      return {
        ...filter,
        ...persistedFilter
      };
    }
    return filter;
  }
  ngOnInit() {
    this.filter = this.getFilter();
    if (this.excelExport) {
      this.addExportToExcelCommand();
    }
  }
  ngAfterViewInit() {
    this.search(this.appGrid.state);
    this.handleAdditionalFilters();
    this.handleFilterHighlighter();
    // Count boolean filters to remove them from filter count
    setTimeout(() => {
      this.booleanFilters = this.filterHighlighter?.appControls.toArray().filter(appControl => appControl.type === 'boolean').length;
    });
    if (this.persistFilter) {
      const key = this.getFilterPersistedKey('sort');
      const persistedSort = localStorage.getItem(key);
      let sort = persistedSort && JSON.parse(persistedSort);
      if (_.isArray(sort)) {
        // allow only sort on binded columns + id
        sort = sort.filter(x => this.appGrid.fields.filter(f => f === x.field || x.field === 'id').length > 0);
        if (sort.length === 0) {
          sort = [...this.getDefaultTableSort()];
        }
        setTimeout(() => {
          this.appGrid.state.sort = this.appGrid.sort = sort;
        });
      }
      this.appGrid.stateChange.subscribe(state => {
        localStorage.setItem(key, JSON.stringify(state.sort));
      });
    }
  }
  ngOnDestroy() {
    this.userSubscription.unsubscribe();
  }
  dataStateChanged(state) {
    this.state = state;
    this.search();
  }
  clearFilter() {
    localStorage.removeItem(this.getFilterPersistedKey('filter'));
    if (this.filter?.entityAspect) this.filter.entityAspect.rejectChanges();
    this.filter = this.getFilter();
    this.search();
  }
  clearSort() {
    localStorage.removeItem(this.getFilterPersistedKey('sort'));
    this.appGrid.state.sort = this.appGrid.sort = [...this.getDefaultTableSort()];
    this.search();
  }
  clearAll() {
    this.appGrid.initializeState({
      skip: 0,
      take: environment.settings.grid.pageSize
    });
    this.clearFilter();
    this.clearSort();
    this.selection = [];
  }
  getQuery(state) {
    const filter = this.breezeService.convertToDto(this.filter || {});
    if (this.persistFilter) {
      localStorage.setItem(this.getFilterPersistedKey('filter'), JSON.stringify(filter));
    }
    const query = EntityQuery.from(this.queryName).withParameters({
      $method: 'POST',
      $data: filter
    });
    return query;
  }
  canCreateNew() {
    if (this.createPermission && !this.user.isSystemUser) {
      return this.user?.hasPermission(this.createPermission);
    }
    return true;
  }
  search(state = null, preventChanges = false) {
    this._query = this.getQuery(state);
    if (!state) this.resetPages(preventChanges);
    this.handleFilterHighlighter();
    return this._query;
  }
  getPersistedFilter() {
    if (this.persistFilter === false) {
      return {};
    }
    try {
      const filter = JSON.parse(localStorage.getItem(this.getFilterPersistedKey('filter')));
      delete filter.manual;
      delete filter.archived;
      delete filter.ids;
      return filter;
    } catch (error) {
      return {};
    }
  }
  get numberOfActiveFilters() {
    const clone = _.clone(this.breezeService.convertToDto(this.filter || {}));
    this.removeKeys(clone, ['$id', 'id', 'archived', 'manual']);
    this.removeEmptyStrings(clone);
    if (clone.activeTab) this.removeKeys(clone, ['activeTab', 'typeId']);
    return Object.values(clone).length - this.booleanFilters || 0;
  }
  removeKeys(object, keys) {
    keys.forEach(key => {
      delete object[key];
    });
  }
  removeEmptyStrings(object) {
    for (const [key, value] of Object.entries(object)) {
      if (typeof value === 'string' && !value) delete object[key];
    }
  }
  getFilterPersistedKey(action) {
    return `${action}:${this.router.url.split('?')[0]}${this.queryName}`;
  }
  getDefaultTableSort() {
    return this.gridSort || environment.settings.grid.sort;
  }
  handleFilterHighlighter(triggered = false) {
    setTimeout(() => {
      if (!triggered && !this.filterHighlighter) return;
      if (triggered) {
        setTimeout(() => {
          if (!this.filterHighlighter) return;
        });
      }
      this.defaultSystemFilter = this.defaultSystemFilter || this.getDefaultFilter({
        id: -2323
      });
      this.defaultFilter = this.defaultFilter || this.entityManager.createEntity(this.defaultSystemFilter.entityType.shortName, {
        id: -123
      });
      const appControls = this.filterHighlighter?.appControls.toArray();
      appControls?.forEach(appControl => {
        const p = appControl.property;
        appControl.elementRef.nativeElement.className = '';
        const isSystemValueEqualToDefault = this.defaultSystemFilter[p] === this.defaultFilter[p] || this.defaultSystemFilter[p] === null && _.isArray(this.defaultFilter[p]) && _.isEmpty(this.defaultFilter[p]) || _.isArray(this.defaultSystemFilter[p]) && JSON.stringify(this.defaultSystemFilter[p]) === JSON.stringify(this.defaultFilter[p]);
        const isUserValueEqualToSystem = this.defaultSystemFilter[p] === appControl.value || this.defaultSystemFilter[p] === null && _.isArray(appControl.value) && _.isEmpty(appControl.value) || _.isArray(this.defaultSystemFilter[p]) && JSON.stringify(this.defaultSystemFilter[p]) === JSON.stringify(appControl.value) || this.defaultSystemFilter[p] === null && appControl.value === '' || _.isDate(this.defaultSystemFilter[p]) && _.isDate(appControl.value) && this.defaultSystemFilter[p].getTime() === appControl.value.getTime();
        if (this.filterHighlighter.appControlsToExclude.toArray().includes(appControl)) {
          return;
        }
        if (this.filterHighlighter.appControlsToInclude.toArray().includes(appControl)) {
          appControl.elementRef.nativeElement.className = 'user-value';
          return;
        }
        if (!isUserValueEqualToSystem) {
          appControl.elementRef.nativeElement.className = 'user-value';
        }
        if (!isSystemValueEqualToDefault && isUserValueEqualToSystem) {
          appControl.elementRef.nativeElement.className = 'system-value';
        }
      });
    });
  }
  handleAdditionalFilters() {
    if (!this.additionalFilters) return;
    setTimeout(() => {
      const properties = _.map(this.additionalFilters.appControls.toArray(), x => x.property);
      const defaultFilter = this.getDefaultFilter({
        id: -9992
      });
      const persistedFilter = this.getPersistedFilter();
      this.showAdditionalFilters = !_.every(properties, p => {
        const persistedValue = persistedFilter[p] === undefined || persistedFilter[p] === '' || persistedFilter[p]?.length === 0 ? null : persistedFilter[p];
        return persistedValue === defaultFilter[p];
      });
    });
  }
  toggleAdditionalFilters() {
    this.showAdditionalFilters = !this.showAdditionalFilters;
  }
  queryAndTriggerSaveExcel() {
    const rowsCount = _.isEmpty(this.selection) ? this.appGrid.internalData.total : this.selection.length;
    if (this.selection.length > 0) {
      this.filter['ids'] = this.selection;
    }
    this.appGrid.queryDataStateChanged(this.getQuery(), {
      skip: 0,
      take: rowsCount,
      sort: this.appGrid.getGrid().sort
    }).then(() => {
      setTimeout(() => {
        // Workaround for grid to update
        this.appGrid.getGrid().saveAsExcel();
      });
    });
  }
  tabSelected(select) {
    if (!this.rememberTabs) return;
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        activeTab: +select.index
      },
      queryParamsHandling: 'merge',
      replaceUrl: true
    });
  }
  resetPages(preventChanges = false) {
    setTimeout(() => {
      this.appGrid.state.skip = 0;
      this.appGrid.onPageChange({
        skip: this.appGrid.state.skip,
        take: this.appGrid.state.take
      });
      if (!preventChanges) this.appGrid.onDataStateChange(this.appGrid.state);
    });
  }
  addExportToExcelCommand() {
    this.actionBar.push({
      label: this.translateService.instant('Export'),
      items: [{
        label: this.translateService.instant('Excel'),
        icon: 'file-excel',
        isDisabled: () => this.isBusy,
        onClick: () => this.queryAndTriggerSaveExcel()
      }]
    });
  }
  addActionBar() {
    this.actionGroup = {
      label: this.translateService.instant('Actions'),
      items: [{
        label: this.translateService.instant('New'),
        icon: 'plus',
        onClick: () => this.router.navigate([`${this.parentRoute}/create/`]),
        isVisible: () => this.canCreateNew()
      }]
    };
    this.searchGroup = {
      label: this.translateService.instant('Search'),
      items: [{
        label: this.translateService.instant('Search'),
        icon: 'search',
        isDisabled: () => false,
        onClick: () => {
          this.search();
        }
      }, {
        label: this.translateService.instant('Clear All'),
        icon: 'asterisk',
        isDisabled: () => this.isBusy,
        onClick: () => this.clearAll()
      }, {
        smallItems: [{
          label: this.translateService.instant('Clear Filter'),
          icon: 'filter',
          isDisabled: () => this.isBusy,
          onClick: () => this.clearFilter()
        }, {
          label: this.translateService.instant('Clear Sort'),
          icon: 'arrow-up-wide-short',
          isDisabled: () => this.isBusy,
          onClick: () => this.clearSort()
        }, {
          label: this.translateService.instant('Clear Selection'),
          icon: 'square-check',
          isDisabled: () => this.isBusy,
          onClick: () => {
            this.selection = [];
          }
        }]
      }]
    };
    this.actionBar = [this.actionGroup, this.searchGroup];
  }
}
AbstractBreezeListComponent.ɵfac = function AbstractBreezeListComponent_Factory(t) {
  return new (t || AbstractBreezeListComponent)(i0.ɵɵdirectiveInject(i1.BreezeViewService));
};
AbstractBreezeListComponent.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
  type: AbstractBreezeListComponent,
  selectors: [["ng-component"]],
  viewQuery: function AbstractBreezeListComponent_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(AppGridComponent, 5);
      i0.ɵɵviewQuery(_c0, 5);
      i0.ɵɵviewQuery(_c1, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.appGrid = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.additionalFilters = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.filterHighlighter = _t.first);
    }
  },
  decls: 0,
  vars: 0,
  template: function AbstractBreezeListComponent_Template(rf, ctx) {},
  encapsulation: 2
});