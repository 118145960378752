import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import * as i0 from "@angular/core";
import * as i1 from "@ng-bootstrap/ng-bootstrap";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/forms";
import * as i4 from "@progress/kendo-angular-buttons";
import * as i5 from "../../../common/components/app-control/app-control.component";
export class InvoiceCloneModalComponent {
  constructor(activeModal, translateService) {
    this.activeModal = activeModal;
    this.translateService = translateService;
    this.content = this.translateService.instant(`Are you sure you want to create a new invoice with the same content as this one?`);
    this.title = this.translateService.instant(`Confirm`);
    this.cloneAttachments = false;
    this.cloneInternalAttachments = false;
    this.cloneItems = true;
  }
  ok() {
    this.activeModal.close({
      cloneAttachments: this.cloneAttachments,
      cloneInternalAttachments: this.cloneInternalAttachments,
      cloneItems: this.cloneItems
    });
  }
}
InvoiceCloneModalComponent.ɵfac = function InvoiceCloneModalComponent_Factory(t) {
  return new (t || InvoiceCloneModalComponent)(i0.ɵɵdirectiveInject(i1.NgbActiveModal), i0.ɵɵdirectiveInject(i2.TranslateService));
};
InvoiceCloneModalComponent.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
  type: InvoiceCloneModalComponent,
  selectors: [["app-invoice-clone-modal"]],
  decls: 28,
  vars: 25,
  consts: [[1, "modal-header"], [1, "modal-title"], ["type", "button", "aria-label", "Close", 1, "close", 3, "click"], ["aria-hidden", "true"], [1, "modal-body"], [1, "text-center"], [1, "row", "mb-3"], [1, "col"], ["type", "boolean", "property", "cloneAttachments", 3, "label", "isDisabled", "ngModel", "entity", "ngModelChange"], ["type", "boolean", "property", "cloneInternalAttachments", 3, "label", "isDisabled", "ngModel", "entity", "ngModelChange"], ["type", "boolean", "property", "cloneItems", 3, "label", "isDisabled", "ngModel", "entity", "ngModelChange"], [1, "modal-footer"], ["kendoButton", "", 3, "click"], ["kendoButton", "", "primary", "true", 3, "click"]],
  template: function InvoiceCloneModalComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "div", 0)(1, "h5", 1);
      i0.ɵɵtext(2);
      i0.ɵɵpipe(3, "translate");
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(4, "button", 2);
      i0.ɵɵlistener("click", function InvoiceCloneModalComponent_Template_button_click_4_listener() {
        return ctx.activeModal.dismiss();
      });
      i0.ɵɵelementStart(5, "span", 3);
      i0.ɵɵtext(6, "\u00D7");
      i0.ɵɵelementEnd()()();
      i0.ɵɵelementStart(7, "div", 4)(8, "h5", 5);
      i0.ɵɵtext(9);
      i0.ɵɵelementEnd();
      i0.ɵɵelement(10, "hr");
      i0.ɵɵelementStart(11, "div", 6)(12, "div", 7)(13, "app-control", 8);
      i0.ɵɵlistener("ngModelChange", function InvoiceCloneModalComponent_Template_app_control_ngModelChange_13_listener($event) {
        return ctx.cloneAttachments = $event;
      });
      i0.ɵɵpipe(14, "translate");
      i0.ɵɵelementEnd()();
      i0.ɵɵelementStart(15, "div", 7)(16, "app-control", 9);
      i0.ɵɵlistener("ngModelChange", function InvoiceCloneModalComponent_Template_app_control_ngModelChange_16_listener($event) {
        return ctx.cloneInternalAttachments = $event;
      });
      i0.ɵɵpipe(17, "translate");
      i0.ɵɵelementEnd()();
      i0.ɵɵelementStart(18, "div", 7)(19, "app-control", 10);
      i0.ɵɵlistener("ngModelChange", function InvoiceCloneModalComponent_Template_app_control_ngModelChange_19_listener($event) {
        return ctx.cloneItems = $event;
      });
      i0.ɵɵpipe(20, "translate");
      i0.ɵɵelementEnd()()()();
      i0.ɵɵelementStart(21, "div", 11)(22, "button", 12);
      i0.ɵɵlistener("click", function InvoiceCloneModalComponent_Template_button_click_22_listener() {
        return ctx.activeModal.dismiss();
      });
      i0.ɵɵtext(23);
      i0.ɵɵpipe(24, "translate");
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(25, "button", 13);
      i0.ɵɵlistener("click", function InvoiceCloneModalComponent_Template_button_click_25_listener() {
        return ctx.ok();
      });
      i0.ɵɵtext(26);
      i0.ɵɵpipe(27, "translate");
      i0.ɵɵelementEnd()();
    }
    if (rf & 2) {
      i0.ɵɵadvance(2);
      i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(3, 13, ctx.title));
      i0.ɵɵadvance(7);
      i0.ɵɵtextInterpolate(ctx.content);
      i0.ɵɵadvance(4);
      i0.ɵɵproperty("label", i0.ɵɵpipeBind1(14, 15, "Transfer Attachments"))("isDisabled", false)("ngModel", ctx.cloneAttachments);
      i0.ɵɵadvance(3);
      i0.ɵɵproperty("label", i0.ɵɵpipeBind1(17, 17, "Transfer Internal Attachments"))("isDisabled", false)("ngModel", ctx.cloneInternalAttachments);
      i0.ɵɵadvance(3);
      i0.ɵɵproperty("label", i0.ɵɵpipeBind1(20, 19, "Transfer Invoice Items"))("isDisabled", false)("ngModel", ctx.cloneItems);
      i0.ɵɵadvance(4);
      i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(24, 21, "Close"));
      i0.ɵɵadvance(3);
      i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(27, 23, "Ok"));
    }
  },
  dependencies: [i3.NgControlStatus, i3.NgModel, i4.Button, i5.AppControlComponent, i2.TranslatePipe],
  styles: ["\n/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IiIsInNvdXJjZVJvb3QiOiIifQ== */"]
});