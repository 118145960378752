import { EntityQuery } from '@cime/breeze-client';
import { AbstractBreezeViewComponent } from '@common/classes/breeze-view';
import { BreezeViewService } from '@common/services/breeze-view.service';
import * as _ from 'lodash';
import * as i0 from "@angular/core";
import * as i1 from "@common/services/breeze-view.service";
import * as i2 from "@angular/common";
import * as i3 from "@angular/router";
import * as i4 from "@angular/forms";
import * as i5 from "@fortawesome/angular-fontawesome";
import * as i6 from "@progress/kendo-angular-grid";
import * as i7 from "@progress/kendo-angular-layout";
import * as i8 from "../../../../common/components/action-bar/action-bar.component";
import * as i9 from "../../../../common/components/app-control/app-control.component";
import * as i10 from "../../../../common/components/loader/loader.component";
import * as i11 from "../../../../common/components/content-header/content-header.component";
import * as i12 from "../../../../common/components/app-grid/app-grid.component";
import * as i13 from "@ngx-translate/core";
import * as i14 from "../../../../common/pipes/codelist.pipe";
function OrganizationEditComponent_ng_template_1_app_control_15_Template(rf, ctx) {
  if (rf & 1) {
    const _r6 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "app-control", 18);
    i0.ɵɵlistener("ngModelChange", function OrganizationEditComponent_ng_template_1_app_control_15_Template_app_control_ngModelChange_0_listener($event) {
      i0.ɵɵrestoreView(_r6);
      const ctx_r5 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r5.model.customerId = $event);
    });
    i0.ɵɵpipe(1, "translate");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("label", i0.ɵɵpipeBind1(1, 3, "Customer"))("ngModel", ctx_r1.model.customerId)("entity", ctx_r1.model);
  }
}
const _c0 = function (a1) {
  return ["/customer/view/", a1];
};
function OrganizationEditComponent_ng_template_1_div_16_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 19)(1, "a", 20);
    i0.ɵɵtext(2);
    i0.ɵɵpipe(3, "async");
    i0.ɵɵpipe(4, "codelist");
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("routerLink", i0.ɵɵpureFunction1(7, _c0, ctx_r2.model.customerId));
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind1(3, 2, i0.ɵɵpipeBind2(4, 4, ctx_r2.model.customerId, "Customer")), " ");
  }
}
const _c1 = function () {
  return ["fas", "plus"];
};
function OrganizationEditComponent_ng_template_1_ng_template_22_div_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r11 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 13)(1, "div", 25);
    i0.ɵɵelement(2, "app-control", 26, 27);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(4, "div", 28)(5, "button", 29);
    i0.ɵɵlistener("click", function OrganizationEditComponent_ng_template_1_ng_template_22_div_0_Template_button_click_5_listener() {
      i0.ɵɵrestoreView(_r11);
      const _r9 = i0.ɵɵreference(3);
      const ctx_r10 = i0.ɵɵnextContext(3);
      return i0.ɵɵresetView(ctx_r10.addRole(_r9.value));
    });
    i0.ɵɵelement(6, "fa-icon", 30);
    i0.ɵɵelementEnd()()();
  }
  if (rf & 2) {
    const _r9 = i0.ɵɵreference(3);
    i0.ɵɵadvance(5);
    i0.ɵɵproperty("disabled", !_r9.value);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("icon", i0.ɵɵpureFunction0(2, _c1));
  }
}
const _c2 = function () {
  return ["fas", "trash"];
};
function OrganizationEditComponent_ng_template_1_ng_template_22_kendo_grid_column_6_ng_template_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r15 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 33);
    i0.ɵɵlistener("click", function OrganizationEditComponent_ng_template_1_ng_template_22_kendo_grid_column_6_ng_template_1_Template_button_click_0_listener() {
      const restoredCtx = i0.ɵɵrestoreView(_r15);
      const row_r13 = restoredCtx.$implicit;
      const ctx_r14 = i0.ɵɵnextContext(4);
      return i0.ɵɵresetView(ctx_r14.removeRole(row_r13));
    });
    i0.ɵɵelement(1, "fa-icon", 30);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("icon", i0.ɵɵpureFunction0(1, _c2));
  }
}
function OrganizationEditComponent_ng_template_1_ng_template_22_kendo_grid_column_6_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "kendo-grid-column", 31);
    i0.ɵɵtemplate(1, OrganizationEditComponent_ng_template_1_ng_template_22_kendo_grid_column_6_ng_template_1_Template, 2, 2, "ng-template", 32);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    i0.ɵɵproperty("width", 1);
  }
}
function OrganizationEditComponent_ng_template_1_ng_template_22_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, OrganizationEditComponent_ng_template_1_ng_template_22_div_0_Template, 7, 3, "div", 21);
    i0.ɵɵelementStart(1, "div", 13)(2, "div", 14)(3, "app-grid", 22);
    i0.ɵɵelement(4, "kendo-grid-column", 23);
    i0.ɵɵpipe(5, "translate");
    i0.ɵɵtemplate(6, OrganizationEditComponent_ng_template_1_ng_template_22_kendo_grid_column_6_Template, 2, 1, "kendo-grid-column", 24);
    i0.ɵɵelementEnd()()();
  }
  if (rf & 2) {
    const ctx_r3 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("ngIf", ctx_r3.editMode);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("data", ctx_r3.model.roles);
    i0.ɵɵadvance(1);
    i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(5, 4, "Name"));
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngIf", ctx_r3.editMode);
  }
}
function OrganizationEditComponent_ng_template_1_ng_template_25_li_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "li", 37);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const permission_r17 = ctx.$implicit;
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate1(" ", permission_r17, " ");
  }
}
function OrganizationEditComponent_ng_template_1_ng_template_25_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 34)(1, "div", 14)(2, "ul", 35);
    i0.ɵɵtemplate(3, OrganizationEditComponent_ng_template_1_ng_template_25_li_3_Template, 2, 1, "li", 36);
    i0.ɵɵelementEnd()()();
  }
  if (rf & 2) {
    const ctx_r4 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("ngForOf", ctx_r4.permissions);
  }
}
function OrganizationEditComponent_ng_template_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r19 = i0.ɵɵgetCurrentView();
    i0.ɵɵelement(0, "app-action-bar", 1)(1, "app-content-header", 2);
    i0.ɵɵelementStart(2, "div", 3)(3, "div", 4)(4, "div", 5)(5, "div", 6)(6, "app-control", 7);
    i0.ɵɵlistener("ngModelChange", function OrganizationEditComponent_ng_template_1_Template_app_control_ngModelChange_6_listener($event) {
      i0.ɵɵrestoreView(_r19);
      const ctx_r18 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r18.model.typeId = $event);
    });
    i0.ɵɵpipe(7, "translate");
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(8, "div", 6)(9, "app-control", 8);
    i0.ɵɵlistener("ngModelChange", function OrganizationEditComponent_ng_template_1_Template_app_control_ngModelChange_9_listener($event) {
      i0.ɵɵrestoreView(_r19);
      const ctx_r20 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r20.model.code = $event);
    });
    i0.ɵɵpipe(10, "translate");
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(11, "div", 9)(12, "app-control", 10);
    i0.ɵɵlistener("ngModelChange", function OrganizationEditComponent_ng_template_1_Template_app_control_ngModelChange_12_listener($event) {
      i0.ɵɵrestoreView(_r19);
      const ctx_r21 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r21.model.name = $event);
    });
    i0.ɵɵpipe(13, "translate");
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(14, "div", 9);
    i0.ɵɵtemplate(15, OrganizationEditComponent_ng_template_1_app_control_15_Template, 2, 5, "app-control", 11);
    i0.ɵɵtemplate(16, OrganizationEditComponent_ng_template_1_div_16_Template, 5, 9, "div", 12);
    i0.ɵɵelementEnd()()()();
    i0.ɵɵelementStart(17, "div", 13)(18, "div", 14)(19, "kendo-tabstrip")(20, "kendo-tabstrip-tab", 15);
    i0.ɵɵpipe(21, "translate");
    i0.ɵɵtemplate(22, OrganizationEditComponent_ng_template_1_ng_template_22_Template, 7, 6, "ng-template", 16);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(23, "kendo-tabstrip-tab", 17);
    i0.ɵɵpipe(24, "translate");
    i0.ɵɵtemplate(25, OrganizationEditComponent_ng_template_1_ng_template_25_Template, 4, 1, "ng-template", 16);
    i0.ɵɵelementEnd()()()();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("items", ctx_r0.actionBar)("collapsed", false);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("title", ctx_r0.title)("breadcrumb", ctx_r0.breadcrumb);
    i0.ɵɵadvance(5);
    i0.ɵɵproperty("label", i0.ɵɵpipeBind1(7, 19, "Type"))("ngModel", ctx_r0.model.typeId)("entity", ctx_r0.model);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("label", i0.ɵɵpipeBind1(10, 21, "Code"))("disabled", ctx_r0.model.id > 0)("ngModel", ctx_r0.model.code)("entity", ctx_r0.model);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("label", i0.ɵɵpipeBind1(13, 23, "Name"))("ngModel", ctx_r0.model.name)("entity", ctx_r0.model);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("ngIf", ctx_r0.editMode);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r0.viewMode && ctx_r0.model.customerId);
    i0.ɵɵadvance(4);
    i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(21, 25, "Roles"));
    i0.ɵɵproperty("selected", true);
    i0.ɵɵadvance(3);
    i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(24, 27, "Effective Permissions"));
  }
}
export class OrganizationEditComponent extends AbstractBreezeViewComponent {
  get permissions() {
    const permissions = _.map(_.flatMap(this.model.roles, x => x.role.permissions), x => x.permission);
    return _.uniq(_.map(permissions, x => `${x.module}.${x.namespace}.${x.name}`));
  }
  constructor(breezeViewService) {
    super(breezeViewService);
    this.entityName = 'Organization';
    this.parentRoute = '/administration/organization';
    this.breadcrumb = [{
      icon: 'building',
      title: this.translateService.get('Organizations'),
      route: '/administration/organization/list'
    }, {
      icon: 'building',
      title: this.translateService.get('Organization Edit')
    }];
  }
  addRole(roleId) {
    if (this.roleExists(this.model.roles, roleId)) {
      this.toastrService.error(this.translateService.instant('Duplicate roles not allowed!'));
      return;
    }
    const organizationRole = this.entityManager.createEntity('OrganizationRole', {
      roleId: roleId,
      organization: this.model
    });
    if (!organizationRole.role) {
      new EntityQuery('Role').withParameters({
        id: roleId
      }).using(this.entityManager).execute();
    }
    this.model.roles.push(organizationRole);
  }
  roleExists(roles, roleId) {
    return roles.map(role => role.roleId).includes(roleId);
  }
  removeRole(role) {
    role.entityAspect.setDeleted();
  }
}
OrganizationEditComponent.ɵfac = function OrganizationEditComponent_Factory(t) {
  return new (t || OrganizationEditComponent)(i0.ɵɵdirectiveInject(i1.BreezeViewService));
};
OrganizationEditComponent.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
  type: OrganizationEditComponent,
  selectors: [["app-organization-edit"]],
  features: [i0.ɵɵProvidersFeature([BreezeViewService]), i0.ɵɵInheritDefinitionFeature],
  decls: 2,
  vars: 1,
  consts: [[3, "isBusy"], [3, "items", "collapsed"], [3, "title", "breadcrumb"], [1, "card", "card-border-top"], [1, "card-body"], [1, "row", "align-items-end"], [1, "col-2"], ["type", "codelist", "codelist", "OrganizationType", "property", "typeId", 3, "label", "ngModel", "entity", "ngModelChange"], ["property", "code", 3, "label", "disabled", "ngModel", "entity", "ngModelChange"], [1, "col-4"], ["property", "name", 3, "label", "ngModel", "entity", "ngModelChange"], ["type", "codelist", "codelist", "Customer", "property", "customerId", 3, "label", "ngModel", "entity", "ngModelChange", 4, "ngIf"], ["class", "fake-app-control", 4, "ngIf"], [1, "row", "mt-3"], [1, "col"], [3, "title", "selected"], ["kendoTabContent", ""], [3, "title"], ["type", "codelist", "codelist", "Customer", "property", "customerId", 3, "label", "ngModel", "entity", "ngModelChange"], [1, "fake-app-control"], [3, "routerLink"], ["class", "row mt-3", 4, "ngIf"], [1, "mb-2", 3, "data"], ["field", "role.name", 3, "title"], ["field", "id", "title", "", 3, "width", 4, "ngIf"], [1, "col-3"], ["type", "codelist", "codelist", "Role"], ["selectedRole", ""], [1, "col-auto"], [1, "btn", "btn-success", "btn-sm", "float-right", 3, "disabled", "click"], [3, "icon"], ["field", "id", "title", "", 3, "width"], ["kendoGridCellTemplate", ""], [1, "btn", "btn-danger", "btn-xs", 3, "click"], [1, "row"], [1, "list-group", "list-group-flush"], ["class", "list-group-item", 4, "ngFor", "ngForOf"], [1, "list-group-item"]],
  template: function OrganizationEditComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "app-loader", 0);
      i0.ɵɵtemplate(1, OrganizationEditComponent_ng_template_1_Template, 26, 29, "ng-template");
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      i0.ɵɵproperty("isBusy", !ctx.model);
    }
  },
  dependencies: [i2.NgForOf, i2.NgIf, i3.RouterLink, i4.NgControlStatus, i4.NgModel, i5.FaIconComponent, i6.ColumnComponent, i6.CellTemplateDirective, i7.TabStripComponent, i7.TabStripTabComponent, i7.TabContentDirective, i8.ActionBarComponent, i9.AppControlComponent, i10.LoaderComponent, i11.ContentHeaderComponent, i12.AppGridComponent, i2.AsyncPipe, i13.TranslatePipe, i14.CodelistPipe],
  styles: ["\n/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IiIsInNvdXJjZVJvb3QiOiIifQ== */"]
});