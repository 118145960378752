import _asyncToGenerator from "C:/GitLab-Runner/builds/VgMjNYji/0/billing/billingmalta/src/frontend/node_modules/@babel/runtime/helpers/esm/asyncToGenerator.js";
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '@environments/environment';
import { fromUnixTime, isAfter } from 'date-fns';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { tap } from 'rxjs/operators';
import { User } from '../models/User';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@angular/router";
export class UserService {
  constructor(http, router) {
    this.http = http;
    this.router = router;
    this.isAuthenticatedSubject = new BehaviorSubject(this.isJwtAuthenticated());
    this.currentUserSubject = new BehaviorSubject(null);
  }
  login(username, password) {
    var _this = this;
    return _asyncToGenerator(function* () {
      const data = {
        username: username,
        password: password
      };
      try {
        const response = yield firstValueFrom(_this.http.post('api:///command/login', data));
        _this.setAccessToken(response.accessToken);
        _this.setRefreshToken(response.refreshToken);
        _this.updateIsAuthenticatedSubject(true);
        return true;
      } catch (error) {
        _this.updateIsAuthenticatedSubject(false);
        _this.updateCurrentUser(null);
        throw error.status === 400 ? error.error.validationErrors[0].errorMessage : 'Unknown error';
      }
    })();
  }
  logout(addReturnUrl = true) {
    if (this.hasAccessToken()) {
      localStorage.removeItem(this.getAccessTokenKey());
      localStorage.removeItem(this.getRefreshTokenKey());
    }
    this.updateIsAuthenticatedSubject(false);
    this.updateCurrentUser(null);
    const routerOptions = addReturnUrl ? {
      queryParams: {
        returnUrl: this.router.url
      }
    } : {};
    return this.router.navigate(['/login'], routerOptions);
  }
  getCurrentUser() {
    var _this2 = this;
    return _asyncToGenerator(function* () {
      try {
        const data = yield firstValueFrom(_this2.http.get('api:///query/CurrentUser'));
        let user = null;
        if (data) {
          user = new User(data);
        }
        _this2.updateIsAuthenticatedSubject(!!data);
        _this2.updateCurrentUser(user);
        return user;
      } catch (error) {
        _this2.updateIsAuthenticatedSubject(false);
        _this2.updateCurrentUser(null);
        throw error;
      }
    })();
  }
  refreshAccessToken() {
    return this.http.post('api:///command/RefreshAccessToken', {
      refreshToken: this.getRefreshToken()
    }).pipe(tap(response => {
      this.setAccessToken(response.accessToken);
      this.setRefreshToken(response.refreshToken);
    }));
  }
  getAccessTokenKey() {
    return environment.accessToken;
  }
  getRefreshTokenKey() {
    return environment.refreshToken;
  }
  getAccessToken() {
    return localStorage.getItem(this.getAccessTokenKey());
  }
  getRefreshToken() {
    return localStorage.getItem(this.getRefreshTokenKey());
  }
  hasAccessToken() {
    return !!this.getAccessToken();
  }
  setAccessToken(accessToken) {
    return localStorage.setItem(this.getAccessTokenKey(), accessToken);
  }
  setRefreshToken(refreshToken) {
    return localStorage.setItem(this.getRefreshTokenKey(), refreshToken);
  }
  updateIsAuthenticatedSubject(value) {
    if (this.isAuthenticatedSubject.getValue() !== value) {
      this.isAuthenticatedSubject.next(value);
    }
  }
  updateCurrentUser(user) {
    if (this.currentUserSubject.getValue() !== user) {
      this.currentUserSubject.next(user);
    }
  }
  parseJwt(token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return JSON.parse(jsonPayload);
  }
  getJwtPayload() {
    const token = localStorage.getItem(environment.accessToken);
    if (!token) {
      return null;
    }
    return this.parseJwt(token);
  }
  isJwtAuthenticated() {
    try {
      const payload = this.getJwtPayload();
      if (!payload) {
        return false;
      }
      const expireDate = fromUnixTime(payload.exp);
      return isAfter(expireDate, new Date());
    } catch (ex) {
      console.error(ex);
      return false;
    }
  }
}
UserService.ɵfac = function UserService_Factory(t) {
  return new (t || UserService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Router));
};
UserService.ɵprov = /*@__PURE__*/i0.ɵɵdefineInjectable({
  token: UserService,
  factory: UserService.ɵfac,
  providedIn: 'root'
});