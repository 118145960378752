import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';
import * as i0 from "@angular/core";
import * as i1 from "@progress/kendo-angular-dialog";
import * as i2 from "@angular/common";
import * as i3 from "@ngx-translate/core";
import * as i4 from "@progress/kendo-angular-buttons";
function RelatedDetailsComponent_tr_21_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "tr")(1, "td");
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(3, "td");
    i0.ɵɵtext(4);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(5, "td");
    i0.ɵɵtext(6);
    i0.ɵɵpipe(7, "number");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(8, "td");
    i0.ɵɵtext(9);
    i0.ɵɵpipe(10, "number");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(11, "td");
    i0.ɵɵtext(12);
    i0.ɵɵpipe(13, "number");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(14, "td");
    i0.ɵɵtext(15);
    i0.ɵɵpipe(16, "date");
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const detail_r1 = ctx.$implicit;
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(detail_r1.invoiceItem.serviceId);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(detail_r1.unitOfMeasurementId);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind2(7, 6, detail_r1.quantity, "0.2-2"));
    i0.ɵɵadvance(3);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind2(10, 9, detail_r1.price, "0.2-2"));
    i0.ɵɵadvance(3);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind2(13, 12, detail_r1.totalPrice, "0.2-2"));
    i0.ɵɵadvance(3);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind2(16, 15, detail_r1.serviceDate, "dd.MM.yyyy"));
  }
}
export class RelatedDetailsComponent extends DialogContentBase {
  constructor(dialog) {
    super(dialog);
    this.dialog = dialog;
    this.isBusy = false;
  }
  cancel() {
    this.dialog.close();
  }
  close(result) {
    if (!this.isBusy) {
      this.isBusy = true;
      this.dialog.close(result);
    }
  }
}
RelatedDetailsComponent.ɵfac = function RelatedDetailsComponent_Factory(t) {
  return new (t || RelatedDetailsComponent)(i0.ɵɵdirectiveInject(i1.DialogRef));
};
RelatedDetailsComponent.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
  type: RelatedDetailsComponent,
  selectors: [["ng-component"]],
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 29,
  vars: 7,
  consts: [[2, "font-size", "18px", "line-height", "1.3em"], [1, "k-icon", "k-i-warning"], [1, "table", "table-striped", "table-bordered"], ["translate", ""], [4, "ngFor", "ngForOf"], ["translate", "", 1, "alert", "alert-warning"], [3, "layout"], ["kendoButton", "", "translate", "", 3, "disabled", "click"], ["kendoButton", "", "primary", "true", "translate", "", 3, "disabled", "click"]],
  template: function RelatedDetailsComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "kendo-dialog-titlebar")(1, "div", 0);
      i0.ɵɵelement(2, "span", 1);
      i0.ɵɵtext(3);
      i0.ɵɵpipe(4, "translate");
      i0.ɵɵelementEnd()();
      i0.ɵɵelementStart(5, "table", 2)(6, "thead")(7, "tr")(8, "th", 3);
      i0.ɵɵtext(9, "Service");
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(10, "th", 3);
      i0.ɵɵtext(11, "Unit Of Measurement");
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(12, "th", 3);
      i0.ɵɵtext(13, "Quantity");
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(14, "th", 3);
      i0.ɵɵtext(15, "Price");
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(16, "th", 3);
      i0.ɵɵtext(17, "Total Price");
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(18, "th", 3);
      i0.ɵɵtext(19, "Date");
      i0.ɵɵelementEnd()()();
      i0.ɵɵelementStart(20, "tbody");
      i0.ɵɵtemplate(21, RelatedDetailsComponent_tr_21_Template, 17, 18, "tr", 4);
      i0.ɵɵelementEnd()();
      i0.ɵɵelementStart(22, "div", 5);
      i0.ɵɵtext(23, "If you delete all connected items all events will be reactivated.");
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(24, "kendo-dialog-actions", 6)(25, "button", 7);
      i0.ɵɵlistener("click", function RelatedDetailsComponent_Template_button_click_25_listener() {
        return ctx.close(false);
      });
      i0.ɵɵtext(26, "Cancel");
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(27, "button", 8);
      i0.ɵɵlistener("click", function RelatedDetailsComponent_Template_button_click_27_listener() {
        return ctx.close(true);
      });
      i0.ɵɵtext(28, "Ok");
      i0.ɵɵelementEnd()();
    }
    if (rf & 2) {
      i0.ɵɵadvance(3);
      i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind1(4, 5, "Delete related items?"), " ");
      i0.ɵɵadvance(18);
      i0.ɵɵproperty("ngForOf", ctx.details);
      i0.ɵɵadvance(3);
      i0.ɵɵproperty("layout", "normal");
      i0.ɵɵadvance(1);
      i0.ɵɵproperty("disabled", ctx.isBusy);
      i0.ɵɵadvance(2);
      i0.ɵɵproperty("disabled", ctx.isBusy);
    }
  },
  dependencies: [i2.NgForOf, i3.TranslateDirective, i4.Button, i1.DialogTitleBarComponent, i1.DialogActionsComponent, i2.DecimalPipe, i2.DatePipe, i3.TranslatePipe],
  styles: ["\n/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IiIsInNvdXJjZVJvb3QiOiIifQ== */"]
});