import * as i0 from "@angular/core";
import * as i1 from "@fortawesome/angular-fontawesome";
import * as i2 from "@ng-bootstrap/ng-bootstrap";
import * as i3 from "@ngx-translate/core";
export class InfoButtonComponent {
  constructor() {
    this.infoVisible = false;
  }
  toggleInfo(visibility) {
    this.infoVisible = visibility;
  }
}
InfoButtonComponent.ɵfac = function InfoButtonComponent_Factory(t) {
  return new (t || InfoButtonComponent)();
};
InfoButtonComponent.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
  type: InfoButtonComponent,
  selectors: [["app-info-button"]],
  inputs: {
    info: "info"
  },
  decls: 6,
  vars: 4,
  consts: [["placement", "top", "container", "body", 1, "info-hover", "btn", "btn-sm", "btn-primary", "mb-2", 3, "ngbTooltip"], ["icon", "info", 1, "ml-1"], [1, "btn-text"]],
  template: function InfoButtonComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "button", 0);
      i0.ɵɵelement(1, "fa-icon", 1);
      i0.ɵɵtext(2, " \u00A0 ");
      i0.ɵɵelementStart(3, "span", 2);
      i0.ɵɵtext(4);
      i0.ɵɵpipe(5, "translate");
      i0.ɵɵelementEnd()();
    }
    if (rf & 2) {
      i0.ɵɵproperty("ngbTooltip", ctx.info);
      i0.ɵɵadvance(4);
      i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(5, 2, "Info"));
    }
  },
  dependencies: [i1.FaIconComponent, i2.NgbTooltip, i3.TranslatePipe],
  encapsulation: 2
});