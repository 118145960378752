import { AutoFilterBreezeListComponent } from '@common/classes/auto-filter-list';
import { BreezeViewService } from '@common/services/breeze-view.service';
import { SharedService } from '@common/services/shared.service';
import * as i0 from "@angular/core";
import * as i1 from "@common/services/breeze-view.service";
import * as i2 from "@common/services/shared.service";
import * as i3 from "@angular/common";
import * as i4 from "@angular/router";
import * as i5 from "@angular/forms";
import * as i6 from "@ng-bootstrap/ng-bootstrap";
import * as i7 from "@fortawesome/angular-fontawesome";
import * as i8 from "@progress/kendo-angular-grid";
import * as i9 from "../../../common/components/action-bar/action-bar.component";
import * as i10 from "../../../common/components/app-control/app-control.component";
import * as i11 from "../../../common/components/app-form/app-form.component";
import * as i12 from "../../../common/components/content-header/content-header.component";
import * as i13 from "../../../common/components/app-grid/app-grid.component";
import * as i14 from "../../../common/components/app-control-container/app-control-container.component";
import * as i15 from "../../../common/components/accordion-header.component";
import * as i16 from "@ngx-translate/core";
function InvoicePeriodListComponent_ng_template_6_span_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span");
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r4 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate1("\u00A0(", ctx_r4.numberOfActiveFilters, ")");
  }
}
function InvoicePeriodListComponent_ng_template_6_Template(rf, ctx) {
  if (rf & 1) {
    const _r6 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "app-accordion-header", 18);
    i0.ɵɵlistener("panelChange", function InvoicePeriodListComponent_ng_template_6_Template_app_accordion_header_panelChange_0_listener() {
      i0.ɵɵrestoreView(_r6);
      const ctx_r5 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r5.handleFilterHighlighter(true));
    });
    i0.ɵɵelementStart(1, "h5");
    i0.ɵɵelement(2, "fa-icon", 19);
    i0.ɵɵtext(3);
    i0.ɵɵpipe(4, "translate");
    i0.ɵɵtemplate(5, InvoicePeriodListComponent_ng_template_6_span_5_Template, 2, 1, "span", 20);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const panel_r3 = ctx.$implicit;
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("panel", panel_r3);
    i0.ɵɵadvance(3);
    i0.ɵɵtextInterpolate1(" \u00A0", i0.ɵɵpipeBind1(4, 3, "Filters"), " ");
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngIf", ctx_r0.numberOfActiveFilters > 0);
  }
}
function InvoicePeriodListComponent_ng_template_7_Template(rf, ctx) {
  if (rf & 1) {
    const _r9 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "app-form", 21);
    i0.ɵɵlistener("submit", function InvoicePeriodListComponent_ng_template_7_Template_app_form_submit_0_listener() {
      i0.ɵɵrestoreView(_r9);
      const ctx_r8 = i0.ɵɵnextContext();
      ctx_r8.search();
      return i0.ɵɵresetView(ctx_r8.onAutoFilterChange());
    });
    i0.ɵɵelementStart(1, "app-control-container", null, 22)(3, "div", 23)(4, "div", 24)(5, "app-control", 25);
    i0.ɵɵlistener("ngModelChange", function InvoicePeriodListComponent_ng_template_7_Template_app_control_ngModelChange_5_listener($event) {
      i0.ɵɵrestoreView(_r9);
      const ctx_r10 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r10.filter.organizationId = $event);
    });
    i0.ɵɵpipe(6, "translate");
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(7, "div", 24)(8, "app-control", 26);
    i0.ɵɵlistener("ngModelChange", function InvoicePeriodListComponent_ng_template_7_Template_app_control_ngModelChange_8_listener($event) {
      i0.ɵɵrestoreView(_r9);
      const ctx_r11 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r11.filter.organizationUnitId = $event);
    });
    i0.ɵɵpipe(9, "translate");
    i0.ɵɵelementEnd()()();
    i0.ɵɵelementStart(10, "div", 27)(11, "div", 28)(12, "div", 29)(13, "button", 30);
    i0.ɵɵlistener("click", function InvoicePeriodListComponent_ng_template_7_Template_button_click_13_listener() {
      i0.ɵɵrestoreView(_r9);
      const ctx_r12 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r12.clearFilter());
    });
    i0.ɵɵelement(14, "fa-icon", 31);
    i0.ɵɵtext(15, " \u00A0 ");
    i0.ɵɵelementStart(16, "span", 32);
    i0.ɵɵtext(17);
    i0.ɵɵpipe(18, "translate");
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(19, "button", 33);
    i0.ɵɵlistener("click", function InvoicePeriodListComponent_ng_template_7_Template_button_click_19_listener() {
      i0.ɵɵrestoreView(_r9);
      const ctx_r13 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r13.search());
    });
    i0.ɵɵelement(20, "fa-icon", 34);
    i0.ɵɵtext(21, " \u00A0 ");
    i0.ɵɵelementStart(22, "span", 32);
    i0.ɵɵtext(23);
    i0.ɵɵpipe(24, "translate");
    i0.ɵɵelementEnd()()()()()()();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance(5);
    i0.ɵɵproperty("label", i0.ɵɵpipeBind1(6, 8, "Organization"))("ngModel", ctx_r1.filter.organizationId)("entity", ctx_r1.filter);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("label", i0.ɵɵpipeBind1(9, 10, "Organization Unit"))("ngModel", ctx_r1.filter.organizationUnitId)("entity", ctx_r1.filter);
    i0.ɵɵadvance(9);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(18, 12, "Clear"));
    i0.ɵɵadvance(6);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(24, 14, "Search"));
  }
}
const _c0 = function (a1) {
  return ["/invoice/invoice-period-list/view", a1];
};
function InvoicePeriodListComponent_ng_template_13_a_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "a", 36);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const row_r14 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵproperty("routerLink", i0.ɵɵpureFunction1(2, _c0, row_r14.id));
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate1(" ", row_r14.organization.name, " ");
  }
}
function InvoicePeriodListComponent_ng_template_13_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, InvoicePeriodListComponent_ng_template_13_a_0_Template, 2, 4, "a", 35);
  }
  if (rf & 2) {
    const row_r14 = ctx.$implicit;
    i0.ɵɵproperty("ngIf", row_r14.organization);
  }
}
export class InvoicePeriodListComponent extends AutoFilterBreezeListComponent {
  constructor(breezeViewService, sharedService) {
    super(breezeViewService, sharedService);
    this.breezeViewService = breezeViewService;
    this.sharedService = sharedService;
    this.queryName = 'InvoicePeriods';
    this.parentRoute = 'invoice/invoice-period-list';
    this.breadcrumb = [{
      icon: 'list',
      title: this.translateService.get('Invoice Period List')
    }];
  }
  getDefaultFilter(data) {
    return this.entityManager.createEntity('GetInvoicePeriodsQuery', {
      ...data
    });
  }
}
InvoicePeriodListComponent.ɵfac = function InvoicePeriodListComponent_Factory(t) {
  return new (t || InvoicePeriodListComponent)(i0.ɵɵdirectiveInject(i1.BreezeViewService), i0.ɵɵdirectiveInject(i2.SharedService));
};
InvoicePeriodListComponent.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
  type: InvoicePeriodListComponent,
  selectors: [["app-invoice-period-list"]],
  features: [i0.ɵɵProvidersFeature([BreezeViewService]), i0.ɵɵInheritDefinitionFeature],
  decls: 28,
  vars: 31,
  consts: [[3, "items"], [3, "title", "breadcrumb"], [1, "filter-wrapper"], ["activeIds", "ngb-panel-0"], ["ngbPanelHeader", ""], ["ngbPanelContent", ""], [1, "card", "card-border-top", "mt-3"], [1, "card-body"], [3, "data", "sortable"], ["field", "organization.name", 3, "title"], ["kendoGridCellTemplate", ""], ["field", "organizationUnit.name", 3, "title"], ["field", "validIssueDateFrom", "format", "d", 3, "title"], ["field", "validIssueDateTo", "format", "d", 3, "title"], ["field", "currentAccountingYear", 3, "title"], ["field", "alternativeAccountingYear", 3, "title"], ["field", "lastModifiedBy.fullName", 3, "title"], ["field", "lastModifiedDate", "format", "g", 3, "title"], [3, "panel", "panelChange"], ["icon", "filter"], [4, "ngIf"], [3, "submit"], ["filterHighlighter", ""], [1, "row"], [1, "col-3"], ["type", "codelist", "codelist", "Organization", "property", "organizationId", 3, "label", "ngModel", "entity", "ngModelChange"], ["type", "codelist", "codelist", "OrganizationUnit", "property", "organizationUnitId", 3, "label", "ngModel", "entity", "ngModelChange"], [1, "row", "mt-3"], [1, "col"], [1, "float-right"], [1, "btn", "btn-sm", "btn-danger", "mr-2", 3, "click"], ["icon", "trash", 1, "ml-1"], [1, "btn-text"], [1, "btn", "btn-sm", "btn-primary", 3, "click"], ["icon", "search", 1, "ml-1"], ["class", "text-primary", 3, "routerLink", 4, "ngIf"], [1, "text-primary", 3, "routerLink"]],
  template: function InvoicePeriodListComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelement(0, "app-action-bar", 0)(1, "app-content-header", 1);
      i0.ɵɵpipe(2, "translate");
      i0.ɵɵelementStart(3, "div", 2)(4, "ngb-accordion", 3)(5, "ngb-panel");
      i0.ɵɵtemplate(6, InvoicePeriodListComponent_ng_template_6_Template, 6, 5, "ng-template", 4);
      i0.ɵɵtemplate(7, InvoicePeriodListComponent_ng_template_7_Template, 25, 16, "ng-template", 5);
      i0.ɵɵelementEnd()()();
      i0.ɵɵelementStart(8, "div", 6)(9, "div", 7)(10, "app-grid", 8)(11, "kendo-grid-column", 9);
      i0.ɵɵpipe(12, "translate");
      i0.ɵɵtemplate(13, InvoicePeriodListComponent_ng_template_13_Template, 1, 1, "ng-template", 10);
      i0.ɵɵelementEnd();
      i0.ɵɵelement(14, "kendo-grid-column", 11);
      i0.ɵɵpipe(15, "translate");
      i0.ɵɵelement(16, "kendo-grid-column", 12);
      i0.ɵɵpipe(17, "translate");
      i0.ɵɵelement(18, "kendo-grid-column", 13);
      i0.ɵɵpipe(19, "translate");
      i0.ɵɵelement(20, "kendo-grid-column", 14);
      i0.ɵɵpipe(21, "translate");
      i0.ɵɵelement(22, "kendo-grid-column", 15);
      i0.ɵɵpipe(23, "translate");
      i0.ɵɵelement(24, "kendo-grid-column", 16);
      i0.ɵɵpipe(25, "translate");
      i0.ɵɵelement(26, "kendo-grid-column", 17);
      i0.ɵɵpipe(27, "translate");
      i0.ɵɵelementEnd()()();
    }
    if (rf & 2) {
      i0.ɵɵproperty("items", ctx.actionBar);
      i0.ɵɵadvance(1);
      i0.ɵɵproperty("title", i0.ɵɵpipeBind1(2, 13, "Invoice Period List"))("breadcrumb", ctx.breadcrumb);
      i0.ɵɵadvance(9);
      i0.ɵɵproperty("data", ctx.query)("sortable", true);
      i0.ɵɵadvance(1);
      i0.ɵɵproperty("title", i0.ɵɵpipeBind1(12, 15, "Organization"));
      i0.ɵɵadvance(3);
      i0.ɵɵproperty("title", i0.ɵɵpipeBind1(15, 17, "Organization Unit"));
      i0.ɵɵadvance(2);
      i0.ɵɵproperty("title", i0.ɵɵpipeBind1(17, 19, "Valid Issue Date From"));
      i0.ɵɵadvance(2);
      i0.ɵɵproperty("title", i0.ɵɵpipeBind1(19, 21, "Valid Issue Date To"));
      i0.ɵɵadvance(2);
      i0.ɵɵproperty("title", i0.ɵɵpipeBind1(21, 23, "Current Accounting Year"));
      i0.ɵɵadvance(2);
      i0.ɵɵproperty("title", i0.ɵɵpipeBind1(23, 25, "Alternative Accounting Year"));
      i0.ɵɵadvance(2);
      i0.ɵɵproperty("title", i0.ɵɵpipeBind1(25, 27, "Last Modified By"));
      i0.ɵɵadvance(2);
      i0.ɵɵproperty("title", i0.ɵɵpipeBind1(27, 29, "Last Modified Date"));
    }
  },
  dependencies: [i3.NgIf, i4.RouterLink, i5.NgControlStatus, i5.NgModel, i6.NgbAccordion, i6.NgbPanel, i6.NgbPanelContent, i6.NgbPanelHeader, i7.FaIconComponent, i8.ColumnComponent, i8.CellTemplateDirective, i9.ActionBarComponent, i10.AppControlComponent, i11.AppFormComponent, i12.ContentHeaderComponent, i13.AppGridComponent, i14.AppControlContainerComponent, i15.AccordionHeaderComponent, i16.TranslatePipe],
  styles: ["\n/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IiIsInNvdXJjZVJvb3QiOiIifQ== */"]
});