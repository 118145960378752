export class InvoiceStatusCodes {}
InvoiceStatusCodes.Rejected = 'REJC';
InvoiceStatusCodes.Issued = 'ISSD';
InvoiceStatusCodes.Approved = 'APPD';
InvoiceStatusCodes.Deleted = 'DLTD';
InvoiceStatusCodes.Locked = 'LOCK';
InvoiceStatusCodes.Cancelled = 'CANC';
InvoiceStatusCodes.Draft = 'DRAF';
InvoiceStatusCodes.Closed = 'CLOS';
InvoiceStatusCodes.WaitingForApproval = 'WFAP';
export class OutOfGaugeHeightCodes {}
OutOfGaugeHeightCodes.Below5cm = 'do 5';
OutOfGaugeHeightCodes.Above5cm = 'nad 5';
export class InvoicingPeriodCodes {}
InvoicingPeriodCodes.Online = 'ONL';
InvoicingPeriodCodes.Weekly = 'WKL';
InvoicingPeriodCodes.HalfAMonth = 'HFM';
InvoicingPeriodCodes.Monthly = 'MON';
export class PdfTemplateInternalNameCodes {}
PdfTemplateInternalNameCodes.DECVD = 'DECVD';
PdfTemplateInternalNameCodes.CSCD = 'CSCD';
PdfTemplateInternalNameCodes.CSPLD = 'CSPLD';
PdfTemplateInternalNameCodes.IA = 'IA';
PdfTemplateInternalNameCodes.IPDF = 'IPDF';
export class ExchangeRateTypeCodes {}
ExchangeRateTypeCodes.N = 'N';
ExchangeRateTypeCodes.P = 'P';
ExchangeRateTypeCodes.S = 'S';
export class InvoiceChangeTypeCodes {}
InvoiceChangeTypeCodes.StatusChange = 'SC';
InvoiceChangeTypeCodes.ContentChange = 'CC';
InvoiceChangeTypeCodes.AttachmentAdded = 'AA';
InvoiceChangeTypeCodes.AttachmentDeleted = 'AD';
InvoiceChangeTypeCodes.SentToERP = 'SE';
export class ContainerVisitStatusCodes {}
ContainerVisitStatusCodes.Active = 'ACT';
ContainerVisitStatusCodes.Completed = 'COM';
ContainerVisitStatusCodes.Billed = 'BIL';
ContainerVisitStatusCodes.Archived = 'ARH';
export class StorageAreaCodes {}
StorageAreaCodes.Terminal = 'TERMINAL';
StorageAreaCodes.Depo = 'DEPO';
export class PriceListStatusCodes {}
PriceListStatusCodes.New = 'NEW';
PriceListStatusCodes.Active = 'ACT';
PriceListStatusCodes.Archived = 'ARC';
PriceListStatusCodes.Deleted = 'DLT';
export class EventStatusCodes {}
EventStatusCodes.Active = 'ACT';
EventStatusCodes.InProgress = 'INP';
EventStatusCodes.Billed = 'BIL';
EventStatusCodes.StorageNotBillable = 'SNB';
EventStatusCodes.OperationalNotSupported = 'ONP';
EventStatusCodes.ManuallyClosed = 'MC';
EventStatusCodes.NotBillable = 'NB';
export class ServiceTypeCodes {}
ServiceTypeCodes.R = 'R';
ServiceTypeCodes.N = 'N';
ServiceTypeCodes.D = 'D';
ServiceTypeCodes.T = 'T';
ServiceTypeCodes.P = 'P';
ServiceTypeCodes.A = 'A';
ServiceTypeCodes.O = 'O';
ServiceTypeCodes.B = 'B';
ServiceTypeCodes.J = 'J';
ServiceTypeCodes.K = 'K';
ServiceTypeCodes.M = 'M';
ServiceTypeCodes.S = 'S';
export class ContainerStatusCodes {}
ContainerStatusCodes.Full = 'F';
ContainerStatusCodes.Empty = 'E';
export class SourceSystemCodes {}
SourceSystemCodes.TinO = 'TinO';
SourceSystemCodes.EdiCenter = 'EdiCenter';
export class OutOfGaugeWidthCodes {}
OutOfGaugeWidthCodes.Below50cm = 'do 50';
OutOfGaugeWidthCodes.Below150cm = 'do 150';
OutOfGaugeWidthCodes.Above150cm = 'nad 150';
export class StorageBillTypeCodes {}
StorageBillTypeCodes.Pool = 'POOL';
StorageBillTypeCodes.Time = 'TIME';
export class InvoiceSequenceNumberCodes {}
InvoiceSequenceNumberCodes.InternalNumberKey = 'InternalInvoiceNumber';
InvoiceSequenceNumberCodes.DefaultKey = 'InvoiceNumber:Default';
InvoiceSequenceNumberCodes.EstimateKey = 'InvoiceNumber:Estimate';
InvoiceSequenceNumberCodes.InternalKey = 'InvoiceNumber:Internal';
InvoiceSequenceNumberCodes.PrePaymentKey = 'InvoiceNumber:PrePayment';
export class ExchangeRateSourceCodes {}
ExchangeRateSourceCodes.ABS = 'ABS';
ExchangeRateSourceCodes.BKP = 'BKP';
ExchangeRateSourceCodes.BSS = 'BSS';
ExchangeRateSourceCodes.NLB = 'NLB';
export class ContainerTypeCodes {}
ContainerTypeCodes.Dry = 'DRY';
ContainerTypeCodes.Reefer = 'REF';
export class OrganizationTypeCodes {}
OrganizationTypeCodes.Agent = 'AG';
OrganizationTypeCodes.Actual = 'AT';
OrganizationTypeCodes.TransportMalta = 'TM';
export class TaxCodes {}
TaxCodes.VAT0 = 'VAT0';
TaxCodes.VAT5 = 'VAT5';
TaxCodes.VAT18 = 'VAT18';
export class AdditionalChargeTypeCodes {}
AdditionalChargeTypeCodes.AbsolutePlus = 'PLUS';
AdditionalChargeTypeCodes.AbsoluteMinus = 'MINUS';
AdditionalChargeTypeCodes.PercentagePlus = 'RELPLUS';
AdditionalChargeTypeCodes.PercentageMinus = 'RELMINUS';
export class PaymentConditionStatusCodes {}
PaymentConditionStatusCodes.Active = 'AKTN';
PaymentConditionStatusCodes.Closed = 'ZPRT';
export class ContainerStorageUnitCodes {}
ContainerStorageUnitCodes.Size20 = '20';
ContainerStorageUnitCodes.Size40 = '40';
ContainerStorageUnitCodes.Size45 = '45';
ContainerStorageUnitCodes.Container = 'CNT';
ContainerStorageUnitCodes.Teu = 'TEU';
export class EventTypeCodes {}
EventTypeCodes.ArrivingAtAnchorageArea = 'AAAA';
EventTypeCodes.ArrivingAtInternalAnchorageArea = 'AAIW';
EventTypeCodes.BunkeringBargeAlongside = 'BBAL';
EventTypeCodes.BunkerBargeCastOff = 'BBCO';
EventTypeCodes.BunkerOperationCompleted = 'BOCD';
EventTypeCodes.BunkerOperationCommenced = 'BOCM';
EventTypeCodes.CastOffMooring = 'CAOM';
EventTypeCodes.ChangeOfPurposeOfCall = 'COPC';
EventTypeCodes.ChangeOfVesselAgent = 'COVA';
EventTypeCodes.ChangesOnVesselCode = 'COVC';
EventTypeCodes.DepartureFromAnchorageArea = 'DAAA';
EventTypeCodes.DepartureFromInternalAnchorageArea = 'DAIW';
EventTypeCodes.DepartureFromBreakwater = 'DBWA';
EventTypeCodes.DeadVessel = 'DEVS';
EventTypeCodes.DepartureFromBerth = 'DFBE';
EventTypeCodes.DepartingTerritorialWaters = 'DTWA';
EventTypeCodes.EnteringAtFishMarket = 'EAFM';
EventTypeCodes.EnteringBreakwater = 'EBWA';
EventTypeCodes.DepartureFromFishMarket = 'EDFM';
EventTypeCodes.EnteringTerritorialWaters = 'ETWA';
EventTypeCodes.FirstLineAshore = 'FILA';
EventTypeCodes.PilotDisembarked = 'PIDM';
EventTypeCodes.PilotEmbarked = 'PIEM';
EventTypeCodes.SecurelyMoored = 'SECM';
EventTypeCodes.SupplyVesselAlongside = 'SVAL';
EventTypeCodes.SupplyVesselCompleted = 'SVCL';
EventTypeCodes.FiveMilesOff = '5OFF';
EventTypeCodes.BreakwaterIn = 'ARABK';
EventTypeCodes.ArrivingAtAta = 'ARATA';
EventTypeCodes.ArrivalLaidUpFrom = 'ARLUP';
EventTypeCodes.ArrivalOverhangingFrom = 'AROVR';
EventTypeCodes.ArrivingAt = 'ARRAT';
EventTypeCodes.ArrivingAtSpoilGrounds = 'ARSPG';
EventTypeCodes.SecurelyMooredAta = 'ATA';
EventTypeCodes.AllLinesLetGoAtd = 'ATD';
EventTypeCodes.AtPilotStation = 'ATPST';
EventTypeCodes.CompletedShifting = 'ATS';
EventTypeCodes.BunkerBargeAS = 'BALSH';
EventTypeCodes.ConveyanceAS = 'CALSH';
EventTypeCodes.BunkerBargeCompleted = 'CMPBU';
EventTypeCodes.ConveyanceCompleted = 'CMPCO';
EventTypeCodes.CancelOperations = 'CNOPR';
EventTypeCodes.CastOff = 'COFF';
EventTypeCodes.BreakwaterOut = 'DEABK';
EventTypeCodes.Default = 'DEFLT';
EventTypeCodes.DepartingFrom = 'DEPAR';
EventTypeCodes.DepartingFromAtd = 'DPATD';
EventTypeCodes.DepartureLaidUpFrom = 'DPLUP';
EventTypeCodes.DepartureOverhangingFrom = 'DPOVR';
EventTypeCodes.DepartingFromSpoilGrounds = 'DPSPG';
EventTypeCodes.ETA = 'ETA';
EventTypeCodes.ETD = 'ETD';
EventTypeCodes.ETS = 'ETS';
EventTypeCodes.Event = 'EVENT';
EventTypeCodes.OperationEnded = 'OPEND';
EventTypeCodes.PilotBoardingInsidePort = 'PIBIH';
EventTypeCodes.PilotDisembarkingInsidePort = 'PIDIH';
EventTypeCodes.PilotOff = 'PIOFF';
EventTypeCodes.PilotOnBoard = 'PION';
EventTypeCodes.StartShifting = 'STS';
EventTypeCodes.ArrivalOverhangingTo = 'AROVE';
EventTypeCodes.DepartureOverhangingTo = 'DPOVE';
EventTypeCodes.ArrivalLaidUpTo = 'ARLUE';
EventTypeCodes.DepartureLaidUpTo = 'DPLUE';
EventTypeCodes.LaidUpFrom = 'LUDFR';
EventTypeCodes.LaidUpTo = 'LUDTO';
export class DirectionChangedReasonCodes {}
DirectionChangedReasonCodes.ImportCancellation = 'IC';
DirectionChangedReasonCodes.ExportCancellation = 'EC';
DirectionChangedReasonCodes.TranshipmentCancellation = 'TC';
DirectionChangedReasonCodes.ChangedVoyage = 'CV ';
export class PortCustomerTypeCodes {}
PortCustomerTypeCodes.Agent = 'A';
PortCustomerTypeCodes.Payer = 'P';
PortCustomerTypeCodes.Forwarder = 'S';
export class PriceListTypeCodes {}
PriceListTypeCodes.AnchorageDues = 'ADE';
PriceListTypeCodes.BerthageDues = 'BDE';
PriceListTypeCodes.Contract = 'CON';
PriceListTypeCodes.EnergyConsumption = 'ENC';
PriceListTypeCodes.LaidUpDues = 'LUD';
PriceListTypeCodes.Offer = 'OFR';
PriceListTypeCodes.OverhangingonLascarisWharf = 'OLW';
PriceListTypeCodes.PortDues = 'PDE';
PriceListTypeCodes.PermitFee = 'PFE';
PriceListTypeCodes.SecurityFee = 'SFE';
PriceListTypeCodes.Tariff = 'TAR';
PriceListTypeCodes.ThroughputFee = 'TFE';
PriceListTypeCodes.WasteManagementFee = 'WMF';
PriceListTypeCodes.YearlyContract = 'YCN';
export class AdditionalWorkTypeCodes {}
AdditionalWorkTypeCodes.AdditionalWork = 'DDD';
AdditionalWorkTypeCodes.AutomaticallyAdded = 'ADD';
export class CargoDirectionCodes {}
CargoDirectionCodes.Import = 'U';
CargoDirectionCodes.Export = 'I';
CargoDirectionCodes.Transit = 'T';
CargoDirectionCodes.Home = 'D';
export class InvoiceTypeCodes {}
InvoiceTypeCodes.Invoice = 'INVO';
InvoiceTypeCodes.DebitNote = 'DBNT';
InvoiceTypeCodes.CreditNote = 'CRNT';
InvoiceTypeCodes.FullCreditNote = 'FCNT';
export class CurrencyCodes {}
CurrencyCodes.Euro = 'EUR';
CurrencyCodes.Dollar = 'USD';
export class ServiceGroupCodes {}
ServiceGroupCodes.MS = 'MS';
ServiceGroupCodes.AD = 'AD';
export class DirectionCodes {}
DirectionCodes.Import = 'U';
DirectionCodes.Export = 'I';
DirectionCodes.VesselTransshipment = 'T';
DirectionCodes.VehicleTransshipment = 'L';
export class StorageChargeUnitCodes {}
StorageChargeUnitCodes.Day = 'DAY';
StorageChargeUnitCodes.Week = 'WEEK';
StorageChargeUnitCodes.Teu = 'TEU';
StorageChargeUnitCodes.Cnt = 'CNT';
export class CustomerTypeCodes {}
CustomerTypeCodes.Domestic = 'D';
CustomerTypeCodes.Foreign = 'T';
CustomerTypeCodes.Mediator = 'P';
CustomerTypeCodes.ForeignCurrency = 'C';
CustomerTypeCodes.Cancellation = 'S';
export class WarehouseCodes {}
WarehouseCodes.Depo = 'DPO';
WarehouseCodes.Evak = 'EVAK';
export class OrganizationCodes {}
OrganizationCodes.PortOfKoper = '1';
export class AdditionalWorkOrderTypeCodes {}
AdditionalWorkOrderTypeCodes.Email = 'EMAIL';
AdditionalWorkOrderTypeCodes.Eorder = 'ENAROCILO';
AdditionalWorkOrderTypeCodes.PaperOrder = 'BON';
AdditionalWorkOrderTypeCodes.Storage = 'SDD';
AdditionalWorkOrderTypeCodes.AdministrativeService = 'ADS';
export class PaymentConditionInsuranceCodes {}
PaymentConditionInsuranceCodes.BG = 'BG';
PaymentConditionInsuranceCodes.NV = 'NV';
PaymentConditionInsuranceCodes.PI = 'PI';
PaymentConditionInsuranceCodes.MEN = 'Menice';
export class LanguageCodes {}
LanguageCodes.English = 'EN';
LanguageCodes.Slovenian = 'SL';
export class JobStatusCodes {}
JobStatusCodes.Created = 'CRT';
JobStatusCodes.InProgress = 'INP';
JobStatusCodes.Completed = 'COM';
JobStatusCodes.Failed = 'FLD';
export class DocumentTypeCodes {}
DocumentTypeCodes.Pdf = 'PDF';
DocumentTypeCodes.Excel = 'Xlsx';
DocumentTypeCodes.Xml = 'Xml';
export class AdditionalWorkSourceCodes {}
AdditionalWorkSourceCodes.Vessel = 'V';
AdditionalWorkSourceCodes.Container = 'C';
export class RoleCodes {}
RoleCodes.Administrator = 'Administrator';
RoleCodes.TransportMalta = 'TransportMalta';
export class AdministrativeServiceTypeCodes {}
AdministrativeServiceTypeCodes.VgmSolas = 'VGM';
AdministrativeServiceTypeCodes.ChangeCargoWeight = 'CCW';
AdministrativeServiceTypeCodes.ChangeGoodsowner = 'CGO';
export class OrganizationUnitCodes {}
OrganizationUnitCodes.TransportMalta = 'TM';
export class UnitOfMeasurementCodes {}
UnitOfMeasurementCodes.CubicMetres = 'M3';
UnitOfMeasurementCodes.GrossTonnage = 'GRT';
UnitOfMeasurementCodes.Kilogram = 'KGM';
UnitOfMeasurementCodes.Liter = 'L';
UnitOfMeasurementCodes.LengthOverall = 'LOA';
UnitOfMeasurementCodes.Manifest = 'MNE';
UnitOfMeasurementCodes.MetricTonne = 'TNE';
UnitOfMeasurementCodes.NetTonnage = 'NRT';
UnitOfMeasurementCodes.PersonsOnBoard = 'POB';
UnitOfMeasurementCodes.Pieces = 'PCS';
UnitOfMeasurementCodes.Other = 'OTH';
export class ServiceCodes {}
ServiceCodes.PermitAmendment = 'AMNP';
ServiceCodes.AnchorageDues = 'ANDU';
ServiceCodes.BerthageDues = 'BRDU';
ServiceCodes.DeclarationCancellation = 'CAND';
ServiceCodes.PermitCancellation = 'CANP';
ServiceCodes.PermitRevocation = 'REVP';
ServiceCodes.NewUserAccountFee = 'CNUA';
ServiceCodes.DeactivatedUserAccount = 'DEUA';
ServiceCodes.PermitExtension = 'EXTP';
ServiceCodes.TmDeclaration = 'ISSD';
ServiceCodes.NewPermit = 'ISSP';
ServiceCodes.LaidUpDues = 'LUDU';
ServiceCodes.Other = 'OTHR';
ServiceCodes.OverhangingOnLascarisWharf = 'ONLW';
ServiceCodes.PortDues = 'PODU';
ServiceCodes.SecurityFee = 'SEFE';
ServiceCodes.WasteManagementFee = 'SGFE';
ServiceCodes.ThroughputFee = 'THFE';
ServiceCodes.EnergyTariff = 'ENTA';
export class VesselStatusCodes {}
VesselStatusCodes.Active = 'AC';
VesselStatusCodes.Inactive = 'IA';
export class CountryCodes {}
CountryCodes.Slovenia = 'SI';
export class CargoStatusCodes {}
CargoStatusCodes.Tax = 'D';
CargoStatusCodes.GroupStatement = 'I';
CargoStatusCodes.NoGroup = 'N';
CargoStatusCodes.Group = 'S';
export class VesselVisitStatusCodes {}
VesselVisitStatusCodes.Active = 'ACT';
VesselVisitStatusCodes.Completed = 'COM';
VesselVisitStatusCodes.Closed = 'CLS';
VesselVisitStatusCodes.Billed = 'BIL';
VesselVisitStatusCodes.NonBillable = 'NOB';
export class AttachmentTypeCodes {}
AttachmentTypeCodes.Image = 'IMAGE';
AttachmentTypeCodes.Certificate = 'CERTIFICATE';
AttachmentTypeCodes.Bon = 'BON';
AttachmentTypeCodes.Offer = 'OFFER';
AttachmentTypeCodes.Report = 'REPORT';
AttachmentTypeCodes.Attachment = 'ATTACHMENT';
AttachmentTypeCodes.CertificateOfPerformance = 'CERT_OF_PERF';
export class ExemptionTypeCodes {}
ExemptionTypeCodes.Flag = 'FLG';
ExemptionTypeCodes.ImdgExplosive = 'IEX';
ExemptionTypeCodes.PurposeOfCall = 'POC';
ExemptionTypeCodes.VesselType = 'VTP';
export class ChargingPeriodTypeCodes {}
ChargingPeriodTypeCodes.Day = 'DAY';
ChargingPeriodTypeCodes.Hours = 'HRS';
ChargingPeriodTypeCodes.Minutes = 'MIN';
ChargingPeriodTypeCodes.OneTime = 'OTM';
export class CalculationTypeCodes {}
CalculationTypeCodes.Fixed = 'FXD';
CalculationTypeCodes.Range = 'RNG';