import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as i0 from "@angular/core";
import * as i1 from "@ng-bootstrap/ng-bootstrap";
import * as i2 from "@fortawesome/angular-fontawesome";
import * as i3 from "@ngx-translate/core";
const _c0 = function () {
  return ["fas", "exclamation-circle"];
};
export class WarningDialogComponent {
  constructor(activeModal) {
    this.activeModal = activeModal;
  }
}
WarningDialogComponent.ɵfac = function WarningDialogComponent_Factory(t) {
  return new (t || WarningDialogComponent)(i0.ɵɵdirectiveInject(i1.NgbActiveModal));
};
WarningDialogComponent.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
  type: WarningDialogComponent,
  selectors: [["ng-component"]],
  decls: 14,
  vars: 5,
  consts: [[1, "modal-header"], [1, "modal-title"], ["type", "button", "aria-label", "Close", 1, "close", 3, "click"], ["aria-hidden", "true"], [1, "modal-body"], [1, "row"], [1, "col-auto", "text-danger"], [3, "icon", "size"], [1, "col", 3, "innerHTML"], [1, "modal-footer"], ["translate", "", 1, "btn", "btn-primary", 3, "click"]],
  template: function WarningDialogComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "div", 0)(1, "h5", 1);
      i0.ɵɵtext(2);
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(3, "button", 2);
      i0.ɵɵlistener("click", function WarningDialogComponent_Template_button_click_3_listener() {
        return ctx.activeModal.close();
      });
      i0.ɵɵelementStart(4, "span", 3);
      i0.ɵɵtext(5, "\u00D7");
      i0.ɵɵelementEnd()()();
      i0.ɵɵelementStart(6, "div", 4)(7, "div", 5)(8, "div", 6);
      i0.ɵɵelement(9, "fa-icon", 7);
      i0.ɵɵelementEnd();
      i0.ɵɵelement(10, "div", 8);
      i0.ɵɵelementEnd()();
      i0.ɵɵelementStart(11, "div", 9)(12, "button", 10);
      i0.ɵɵlistener("click", function WarningDialogComponent_Template_button_click_12_listener() {
        return ctx.activeModal.close();
      });
      i0.ɵɵtext(13, "Ok");
      i0.ɵɵelementEnd()();
    }
    if (rf & 2) {
      i0.ɵɵadvance(2);
      i0.ɵɵtextInterpolate(ctx.title);
      i0.ɵɵadvance(7);
      i0.ɵɵproperty("icon", i0.ɵɵpureFunction0(4, _c0))("size", "3x");
      i0.ɵɵadvance(1);
      i0.ɵɵproperty("innerHTML", ctx.message, i0.ɵɵsanitizeHtml);
    }
  },
  dependencies: [i2.FaIconComponent, i3.TranslateDirective],
  encapsulation: 2
});