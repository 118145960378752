import { EntityQuery } from '@cime/breeze-client';
import { AbstractBreezeViewComponent } from '@common/classes/breeze-view';
import { BreezeViewService } from '@common/services/breeze-view.service';
import * as _ from 'lodash';
import * as i0 from "@angular/core";
import * as i1 from "@common/services/breeze-view.service";
import * as i2 from "@angular/common";
import * as i3 from "@angular/forms";
import * as i4 from "@fortawesome/angular-fontawesome";
import * as i5 from "@progress/kendo-angular-grid";
import * as i6 from "@progress/kendo-angular-layout";
import * as i7 from "../../../../common/components/action-bar/action-bar.component";
import * as i8 from "../../../../common/components/app-control/app-control.component";
import * as i9 from "../../../../common/components/loader/loader.component";
import * as i10 from "../../../../common/components/content-header/content-header.component";
import * as i11 from "../../../../common/components/app-grid/app-grid.component";
import * as i12 from "@ngx-translate/core";
const _c0 = function () {
  return ["fas", "plus"];
};
function UserEditComponent_ng_template_1_ng_template_36_div_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r7 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 16)(1, "div", 25);
    i0.ɵɵelement(2, "app-control", 26, 27);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(4, "div", 28)(5, "button", 29);
    i0.ɵɵlistener("click", function UserEditComponent_ng_template_1_ng_template_36_div_0_Template_button_click_5_listener() {
      i0.ɵɵrestoreView(_r7);
      const _r5 = i0.ɵɵreference(3);
      const ctx_r6 = i0.ɵɵnextContext(3);
      return i0.ɵɵresetView(ctx_r6.addRole(_r5.value));
    });
    i0.ɵɵelement(6, "fa-icon", 30);
    i0.ɵɵelementEnd()()();
  }
  if (rf & 2) {
    const _r5 = i0.ɵɵreference(3);
    i0.ɵɵadvance(5);
    i0.ɵɵproperty("disabled", !_r5.value);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("icon", i0.ɵɵpureFunction0(2, _c0));
  }
}
const _c1 = function () {
  return ["fas", "trash"];
};
function UserEditComponent_ng_template_1_ng_template_36_kendo_grid_column_6_ng_template_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r11 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 33);
    i0.ɵɵlistener("click", function UserEditComponent_ng_template_1_ng_template_36_kendo_grid_column_6_ng_template_1_Template_button_click_0_listener() {
      const restoredCtx = i0.ɵɵrestoreView(_r11);
      const row_r9 = restoredCtx.$implicit;
      const ctx_r10 = i0.ɵɵnextContext(4);
      return i0.ɵɵresetView(ctx_r10.removeRole(row_r9));
    });
    i0.ɵɵelement(1, "fa-icon", 30);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("icon", i0.ɵɵpureFunction0(1, _c1));
  }
}
function UserEditComponent_ng_template_1_ng_template_36_kendo_grid_column_6_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "kendo-grid-column", 31);
    i0.ɵɵtemplate(1, UserEditComponent_ng_template_1_ng_template_36_kendo_grid_column_6_ng_template_1_Template, 2, 2, "ng-template", 32);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    i0.ɵɵproperty("width", 1);
  }
}
function UserEditComponent_ng_template_1_ng_template_36_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, UserEditComponent_ng_template_1_ng_template_36_div_0_Template, 7, 3, "div", 21);
    i0.ɵɵelementStart(1, "div", 16)(2, "div", 17)(3, "app-grid", 22);
    i0.ɵɵelement(4, "kendo-grid-column", 23);
    i0.ɵɵpipe(5, "translate");
    i0.ɵɵtemplate(6, UserEditComponent_ng_template_1_ng_template_36_kendo_grid_column_6_Template, 2, 1, "kendo-grid-column", 24);
    i0.ɵɵelementEnd()()();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("ngIf", ctx_r1.editMode);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("data", ctx_r1.model.roles)("pageable", false);
    i0.ɵɵadvance(1);
    i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(5, 5, "Name"));
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngIf", ctx_r1.editMode);
  }
}
function UserEditComponent_ng_template_1_ng_template_39_li_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "li", 36);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const permission_r13 = ctx.$implicit;
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate1(" ", permission_r13, " ");
  }
}
function UserEditComponent_ng_template_1_ng_template_39_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 5)(1, "div", 17)(2, "ul", 34);
    i0.ɵɵtemplate(3, UserEditComponent_ng_template_1_ng_template_39_li_3_Template, 2, 1, "li", 35);
    i0.ɵɵelementEnd()()();
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("ngForOf", ctx_r2.permissions);
  }
}
function UserEditComponent_ng_template_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r15 = i0.ɵɵgetCurrentView();
    i0.ɵɵelement(0, "app-action-bar", 1)(1, "app-content-header", 2);
    i0.ɵɵelementStart(2, "div", 3)(3, "div", 4)(4, "div", 5)(5, "div", 6)(6, "app-control", 7);
    i0.ɵɵlistener("ngModelChange", function UserEditComponent_ng_template_1_Template_app_control_ngModelChange_6_listener($event) {
      i0.ɵɵrestoreView(_r15);
      const ctx_r14 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r14.model.userName = $event);
    });
    i0.ɵɵpipe(7, "translate");
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(8, "div", 6)(9, "app-control", 8);
    i0.ɵɵlistener("ngModelChange", function UserEditComponent_ng_template_1_Template_app_control_ngModelChange_9_listener($event) {
      i0.ɵɵrestoreView(_r15);
      const ctx_r16 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r16.model.fullName = $event);
    });
    i0.ɵɵpipe(10, "translate");
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(11, "div", 6)(12, "app-control", 9);
    i0.ɵɵlistener("ngModelChange", function UserEditComponent_ng_template_1_Template_app_control_ngModelChange_12_listener($event) {
      i0.ɵɵrestoreView(_r15);
      const ctx_r17 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r17.model.organizationId = $event);
    });
    i0.ɵɵpipe(13, "translate");
    i0.ɵɵelementEnd()()();
    i0.ɵɵelementStart(14, "div", 10)(15, "div", 6)(16, "app-control", 11);
    i0.ɵɵlistener("ngModelChange", function UserEditComponent_ng_template_1_Template_app_control_ngModelChange_16_listener($event) {
      i0.ɵɵrestoreView(_r15);
      const ctx_r18 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r18.model.email = $event);
    });
    i0.ɵɵpipe(17, "translate");
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(18, "div", 6)(19, "app-control", 12);
    i0.ɵɵlistener("ngModelChange", function UserEditComponent_ng_template_1_Template_app_control_ngModelChange_19_listener($event) {
      i0.ɵɵrestoreView(_r15);
      const ctx_r19 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r19.model.phoneNumber = $event);
    });
    i0.ɵɵpipe(20, "translate");
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(21, "div", 6)(22, "app-control", 13);
    i0.ɵɵlistener("ngModelChange", function UserEditComponent_ng_template_1_Template_app_control_ngModelChange_22_listener($event) {
      i0.ɵɵrestoreView(_r15);
      const ctx_r20 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r20.model.languageId = $event);
    });
    i0.ɵɵpipe(23, "translate");
    i0.ɵɵelementEnd()()();
    i0.ɵɵelementStart(24, "div", 10)(25, "div", 6)(26, "app-control", 14);
    i0.ɵɵlistener("ngModelChange", function UserEditComponent_ng_template_1_Template_app_control_ngModelChange_26_listener($event) {
      i0.ɵɵrestoreView(_r15);
      const ctx_r21 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r21.model.password = $event);
    });
    i0.ɵɵpipe(27, "translate");
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(28, "div", 6)(29, "app-control", 15);
    i0.ɵɵlistener("ngModelChange", function UserEditComponent_ng_template_1_Template_app_control_ngModelChange_29_listener($event) {
      i0.ɵɵrestoreView(_r15);
      const ctx_r22 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r22.model.active = $event);
    });
    i0.ɵɵpipe(30, "translate");
    i0.ɵɵelementEnd()()()()();
    i0.ɵɵelementStart(31, "div", 16)(32, "div", 17)(33, "kendo-tabstrip")(34, "kendo-tabstrip-tab", 18);
    i0.ɵɵpipe(35, "translate");
    i0.ɵɵtemplate(36, UserEditComponent_ng_template_1_ng_template_36_Template, 7, 7, "ng-template", 19);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(37, "kendo-tabstrip-tab", 20);
    i0.ɵɵpipe(38, "translate");
    i0.ɵɵtemplate(39, UserEditComponent_ng_template_1_ng_template_39_Template, 4, 1, "ng-template", 19);
    i0.ɵɵelementEnd()()()();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("items", ctx_r0.actionBar)("collapsed", false);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("title", ctx_r0.title)("breadcrumb", ctx_r0.breadcrumb);
    i0.ɵɵadvance(5);
    i0.ɵɵproperty("label", i0.ɵɵpipeBind1(7, 32, "Username"))("isDisabled", ctx_r0.model.id > 0)("ngModel", ctx_r0.model.userName)("entity", ctx_r0.model);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("label", i0.ɵɵpipeBind1(10, 34, "Full Name"))("ngModel", ctx_r0.model.fullName)("entity", ctx_r0.model);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("label", i0.ɵɵpipeBind1(13, 36, "Organization"))("ngModel", ctx_r0.model.organizationId)("entity", ctx_r0.model);
    i0.ɵɵadvance(4);
    i0.ɵɵproperty("label", i0.ɵɵpipeBind1(17, 38, "Email"))("ngModel", ctx_r0.model.email)("entity", ctx_r0.model);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("label", i0.ɵɵpipeBind1(20, 40, "Phone Number"))("ngModel", ctx_r0.model.phoneNumber)("entity", ctx_r0.model);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("label", i0.ɵɵpipeBind1(23, 42, "Language"))("ngModel", ctx_r0.model.languageId)("entity", ctx_r0.model);
    i0.ɵɵadvance(4);
    i0.ɵɵproperty("label", i0.ɵɵpipeBind1(27, 44, "Password"))("ngModel", ctx_r0.model.password)("entity", ctx_r0.model);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("label", i0.ɵɵpipeBind1(30, 46, "Active"))("ngModel", ctx_r0.model.active)("entity", ctx_r0.model);
    i0.ɵɵadvance(5);
    i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(35, 48, "Roles"));
    i0.ɵɵproperty("selected", true);
    i0.ɵɵadvance(3);
    i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(38, 50, "Effective Permissions"));
  }
}
export class UserEditComponent extends AbstractBreezeViewComponent {
  get permissions() {
    const permissions = _.map(_.flatMap(this.model.roles, x => x.role.permissions), x => x.permission);
    return _.uniq(_.map(permissions, x => `${x.module}.${x.namespace}.${x.name}`));
  }
  constructor(breezeViewService) {
    super(breezeViewService);
    this.entityName = 'User';
    this.parentRoute = '/administration/user';
    this.breadcrumb = [{
      icon: 'users',
      title: this.translateService.get('Users'),
      route: '/administration/user/list'
    }, {
      icon: 'user',
      title: this.translateService.get('User Edit')
    }];
    this.actionBar[0].items.push({
      label: this.translateService.instant('Change Password'),
      icon: 'key',
      isDisabled: () => {
        return this.model.id < 1;
      },
      onClick: () => {
        this.changePassword();
      }
    });
  }
  addRole(roleId) {
    if (this.roleExists(this.model.roles, roleId)) {
      this.toastrService.error(this.translateService.instant('Duplicate roles not allowed!'));
      return;
    }
    const userRole = this.entityManager.createEntity('UserRole', {
      roleId: roleId,
      user: this.model
    });
    if (!userRole.role) {
      new EntityQuery('Role').withParameters({
        id: roleId
      }).using(this.entityManager).execute().then(() => {
        this.model.roles.push(userRole);
      });
    } else {
      this.model.roles.push(userRole);
    }
  }
  roleExists(roles, roleId) {
    return roles.map(role => role.roleId).includes(roleId);
  }
  removeRole(role) {
    role.entityAspect.setDeleted();
  }
  changePassword() {
    alert('TODO');
  }
}
UserEditComponent.ɵfac = function UserEditComponent_Factory(t) {
  return new (t || UserEditComponent)(i0.ɵɵdirectiveInject(i1.BreezeViewService));
};
UserEditComponent.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
  type: UserEditComponent,
  selectors: [["app-user-edit"]],
  features: [i0.ɵɵProvidersFeature([BreezeViewService]), i0.ɵɵInheritDefinitionFeature],
  decls: 2,
  vars: 1,
  consts: [[3, "isBusy"], [3, "items", "collapsed"], [3, "title", "breadcrumb"], [1, "card", "card-border-top"], [1, "card-body"], [1, "row"], [1, "col-4"], ["property", "userName", 3, "label", "isDisabled", "ngModel", "entity", "ngModelChange"], ["property", "fullName", 3, "label", "ngModel", "entity", "ngModelChange"], ["property", "organizationId", 3, "label", "ngModel", "entity", "ngModelChange"], [1, "row", "mt-2"], ["property", "email", 3, "label", "ngModel", "entity", "ngModelChange"], ["property", "phoneNumber", 3, "label", "ngModel", "entity", "ngModelChange"], ["property", "languageId", 3, "label", "ngModel", "entity", "ngModelChange"], ["type", "password", "property", "password", 3, "label", "ngModel", "entity", "ngModelChange"], ["property", "active", 3, "label", "ngModel", "entity", "ngModelChange"], [1, "row", "mt-3"], [1, "col"], [3, "title", "selected"], ["kendoTabContent", ""], [3, "title"], ["class", "row mt-3", 4, "ngIf"], [1, "mb-2", 3, "data", "pageable"], ["field", "role.name", 3, "title"], ["field", "id", "title", "", 3, "width", 4, "ngIf"], [1, "col-3"], ["type", "codelist", "codelist", "Role"], ["selectedRole", ""], [1, "col-auto"], [1, "btn", "btn-success", "btn-sm", "float-right", 3, "disabled", "click"], [3, "icon"], ["field", "id", "title", "", 3, "width"], ["kendoGridCellTemplate", ""], [1, "btn", "btn-danger", "btn-xs", 3, "click"], [1, "list-group", "list-group-flush"], ["class", "list-group-item", 4, "ngFor", "ngForOf"], [1, "list-group-item"]],
  template: function UserEditComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "app-loader", 0);
      i0.ɵɵtemplate(1, UserEditComponent_ng_template_1_Template, 40, 52, "ng-template");
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      i0.ɵɵproperty("isBusy", !ctx.model);
    }
  },
  dependencies: [i2.NgForOf, i2.NgIf, i3.NgControlStatus, i3.NgModel, i4.FaIconComponent, i5.ColumnComponent, i5.CellTemplateDirective, i6.TabStripComponent, i6.TabStripTabComponent, i6.TabContentDirective, i7.ActionBarComponent, i8.AppControlComponent, i9.LoaderComponent, i10.ContentHeaderComponent, i11.AppGridComponent, i12.TranslatePipe],
  styles: ["\n/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IiIsInNvdXJjZVJvb3QiOiIifQ== */"]
});