import { EditableGridComponent } from '@common/components/editable-grid/editable-grid.component';
import { BreezeViewService } from '@common/services/breeze-view.service';
import * as i0 from "@angular/core";
import * as i1 from "@common/services/breeze-view.service";
import * as i2 from "@angular/forms";
import * as i3 from "@progress/kendo-angular-grid";
import * as i4 from "../app-control/app-control.component";
import * as i5 from "../editable-grid/editable-grid.component";
import * as i6 from "../flag-icon.component";
import * as i7 from "@angular/common";
import * as i8 from "@ngx-translate/core";
import * as i9 from "../../pipes/codelist.pipe";
function VesselVisitServiceListComponent_ng_template_3_Template(rf, ctx) {
  if (rf & 1) {
    const _r12 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "app-control", 9);
    i0.ɵɵlistener("ngModelChange", function VesselVisitServiceListComponent_ng_template_3_Template_app_control_ngModelChange_0_listener($event) {
      const restoredCtx = i0.ɵɵrestoreView(_r12);
      const dataItem_r10 = restoredCtx.dataItem;
      return i0.ɵɵresetView(dataItem_r10.serviceId = $event);
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const dataItem_r10 = ctx.dataItem;
    i0.ɵɵproperty("ngModel", dataItem_r10.serviceId)("entity", dataItem_r10);
  }
}
function VesselVisitServiceListComponent_ng_template_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtext(0);
    i0.ɵɵpipe(1, "async");
    i0.ɵɵpipe(2, "codelist");
  }
  if (rf & 2) {
    const row_r13 = ctx.$implicit;
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind1(1, 1, i0.ɵɵpipeBind2(2, 3, row_r13.serviceId, "Service")), " ");
  }
}
function VesselVisitServiceListComponent_ng_template_7_Template(rf, ctx) {
  if (rf & 1) {
    const _r16 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "app-control", 10);
    i0.ɵɵlistener("ngModelChange", function VesselVisitServiceListComponent_ng_template_7_Template_app_control_ngModelChange_0_listener($event) {
      const restoredCtx = i0.ɵɵrestoreView(_r16);
      const dataItem_r14 = restoredCtx.dataItem;
      return i0.ɵɵresetView(dataItem_r14.description = $event);
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const dataItem_r14 = ctx.dataItem;
    i0.ɵɵproperty("ngModel", dataItem_r14.description)("entity", dataItem_r14);
  }
}
function VesselVisitServiceListComponent_ng_template_8_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtext(0);
  }
  if (rf & 2) {
    const row_r17 = ctx.$implicit;
    i0.ɵɵtextInterpolate1(" ", row_r17.description, " ");
  }
}
function VesselVisitServiceListComponent_ng_template_11_Template(rf, ctx) {
  if (rf & 1) {
    const _r20 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "app-control", 11);
    i0.ɵɵlistener("ngModelChange", function VesselVisitServiceListComponent_ng_template_11_Template_app_control_ngModelChange_0_listener($event) {
      const restoredCtx = i0.ɵɵrestoreView(_r20);
      const dataItem_r18 = restoredCtx.dataItem;
      return i0.ɵɵresetView(dataItem_r18.serviceDateFrom = $event);
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const dataItem_r18 = ctx.dataItem;
    i0.ɵɵproperty("time", true)("ngModel", dataItem_r18.serviceDateFrom)("entity", dataItem_r18);
  }
}
function VesselVisitServiceListComponent_ng_template_14_Template(rf, ctx) {
  if (rf & 1) {
    const _r23 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "app-control", 12);
    i0.ɵɵlistener("ngModelChange", function VesselVisitServiceListComponent_ng_template_14_Template_app_control_ngModelChange_0_listener($event) {
      const restoredCtx = i0.ɵɵrestoreView(_r23);
      const dataItem_r21 = restoredCtx.dataItem;
      return i0.ɵɵresetView(dataItem_r21.serviceDateTo = $event);
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const dataItem_r21 = ctx.dataItem;
    i0.ɵɵproperty("time", true)("ngModel", dataItem_r21.serviceDateTo)("entity", dataItem_r21);
  }
}
function VesselVisitServiceListComponent_ng_template_17_Template(rf, ctx) {
  if (rf & 1) {
    const _r26 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "app-control", 13);
    i0.ɵɵlistener("ngModelChange", function VesselVisitServiceListComponent_ng_template_17_Template_app_control_ngModelChange_0_listener($event) {
      const restoredCtx = i0.ɵɵrestoreView(_r26);
      const dataItem_r24 = restoredCtx.dataItem;
      return i0.ɵɵresetView(dataItem_r24.portId = $event);
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const dataItem_r24 = ctx.dataItem;
    i0.ɵɵproperty("ngModel", dataItem_r24.portId)("entity", dataItem_r24);
  }
}
function VesselVisitServiceListComponent_ng_template_18_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "app-flag-icon", 14);
    i0.ɵɵtext(1);
    i0.ɵɵpipe(2, "async");
    i0.ɵɵpipe(3, "codelist");
  }
  if (rf & 2) {
    const row_r27 = ctx.$implicit;
    i0.ɵɵproperty("code", row_r27.portId)("floating", true);
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind1(2, 3, i0.ɵɵpipeBind2(3, 5, row_r27.portId, "Port")), " ");
  }
}
function VesselVisitServiceListComponent_ng_template_21_Template(rf, ctx) {
  if (rf & 1) {
    const _r30 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "app-control", 15);
    i0.ɵɵlistener("ngModelChange", function VesselVisitServiceListComponent_ng_template_21_Template_app_control_ngModelChange_0_listener($event) {
      const restoredCtx = i0.ɵɵrestoreView(_r30);
      const dataItem_r28 = restoredCtx.dataItem;
      return i0.ɵɵresetView(dataItem_r28.berthId = $event);
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const dataItem_r28 = ctx.dataItem;
    const ctx_r8 = i0.ɵɵnextContext();
    i0.ɵɵproperty("selectLabel", ctx_r8.berthLabel)("ngModel", dataItem_r28.berthId)("entity", dataItem_r28);
  }
}
function VesselVisitServiceListComponent_ng_template_22_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtext(0);
    i0.ɵɵpipe(1, "async");
    i0.ɵɵpipe(2, "codelist");
  }
  if (rf & 2) {
    const row_r31 = ctx.$implicit;
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind1(1, 1, i0.ɵɵpipeBind2(2, 3, row_r31.berthId, "Berth")), " ");
  }
}
export class VesselVisitServiceListComponent {
  constructor(breezeViewService) {
    this.breezeViewService = breezeViewService;
    this.createParameters = {};
  }
  ngAfterViewInit() {
    this.createParameters = {
      berthId: this.model?.berthId,
      portId: this.model?.portOfCallId,
      agentId: this.model?.primaryAgentId
    };
  }
  berthLabel(item) {
    return item.name;
  }
}
VesselVisitServiceListComponent.ɵfac = function VesselVisitServiceListComponent_Factory(t) {
  return new (t || VesselVisitServiceListComponent)(i0.ɵɵdirectiveInject(i1.BreezeViewService));
};
VesselVisitServiceListComponent.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
  type: VesselVisitServiceListComponent,
  selectors: [["app-vessel-visit-service-list"]],
  viewQuery: function VesselVisitServiceListComponent_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(EditableGridComponent, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.editableGrid = _t.first);
    }
  },
  inputs: {
    model: "model",
    editMode: "editMode"
  },
  decls: 23,
  vars: 21,
  consts: [["entityName", "VesselVisitService", 3, "data", "createParameters", "editMode"], ["field", "serviceId", 3, "title"], ["kendoGridEditTemplate", ""], ["kendoGridCellTemplate", ""], ["field", "description", 3, "title"], ["field", "serviceDateFrom", "format", "g", 3, "title"], ["field", "serviceDateTo", "format", "g", 3, "title"], ["field", "portId", 3, "title"], ["field", "berthId", 3, "title"], ["type", "codelist", "codelist", "Service", "property", "serviceId", 3, "ngModel", "entity", "ngModelChange"], ["property", "description", 3, "ngModel", "entity", "ngModelChange"], ["property", "serviceDateFrom", 3, "time", "ngModel", "entity", "ngModelChange"], ["property", "serviceDateTo", 3, "time", "ngModel", "entity", "ngModelChange"], ["type", "codelist", "codelist", "LocalPortFilter", "property", "portId", 3, "ngModel", "entity", "ngModelChange"], [3, "code", "floating"], ["type", "codelist", "codelist", "Berth", "property", "berthId", 3, "selectLabel", "ngModel", "entity", "ngModelChange"]],
  template: function VesselVisitServiceListComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "app-editable-grid", 0)(1, "kendo-grid-column", 1);
      i0.ɵɵpipe(2, "translate");
      i0.ɵɵtemplate(3, VesselVisitServiceListComponent_ng_template_3_Template, 1, 2, "ng-template", 2);
      i0.ɵɵtemplate(4, VesselVisitServiceListComponent_ng_template_4_Template, 3, 6, "ng-template", 3);
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(5, "kendo-grid-column", 4);
      i0.ɵɵpipe(6, "translate");
      i0.ɵɵtemplate(7, VesselVisitServiceListComponent_ng_template_7_Template, 1, 2, "ng-template", 2);
      i0.ɵɵtemplate(8, VesselVisitServiceListComponent_ng_template_8_Template, 1, 1, "ng-template", 3);
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(9, "kendo-grid-column", 5);
      i0.ɵɵpipe(10, "translate");
      i0.ɵɵtemplate(11, VesselVisitServiceListComponent_ng_template_11_Template, 1, 3, "ng-template", 2);
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(12, "kendo-grid-column", 6);
      i0.ɵɵpipe(13, "translate");
      i0.ɵɵtemplate(14, VesselVisitServiceListComponent_ng_template_14_Template, 1, 3, "ng-template", 2);
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(15, "kendo-grid-column", 7);
      i0.ɵɵpipe(16, "translate");
      i0.ɵɵtemplate(17, VesselVisitServiceListComponent_ng_template_17_Template, 1, 2, "ng-template", 2);
      i0.ɵɵtemplate(18, VesselVisitServiceListComponent_ng_template_18_Template, 4, 8, "ng-template", 3);
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(19, "kendo-grid-column", 8);
      i0.ɵɵpipe(20, "translate");
      i0.ɵɵtemplate(21, VesselVisitServiceListComponent_ng_template_21_Template, 1, 3, "ng-template", 2);
      i0.ɵɵtemplate(22, VesselVisitServiceListComponent_ng_template_22_Template, 3, 6, "ng-template", 3);
      i0.ɵɵelementEnd()();
    }
    if (rf & 2) {
      i0.ɵɵproperty("data", ctx.model.services)("createParameters", ctx.createParameters)("editMode", ctx.editMode);
      i0.ɵɵadvance(1);
      i0.ɵɵproperty("title", i0.ɵɵpipeBind1(2, 9, "Service Type"));
      i0.ɵɵadvance(4);
      i0.ɵɵproperty("title", i0.ɵɵpipeBind1(6, 11, "Description"));
      i0.ɵɵadvance(4);
      i0.ɵɵproperty("title", i0.ɵɵpipeBind1(10, 13, "Date & Time From"));
      i0.ɵɵadvance(3);
      i0.ɵɵproperty("title", i0.ɵɵpipeBind1(13, 15, "Date & Time To"));
      i0.ɵɵadvance(3);
      i0.ɵɵproperty("title", i0.ɵɵpipeBind1(16, 17, "Port"));
      i0.ɵɵadvance(4);
      i0.ɵɵproperty("title", i0.ɵɵpipeBind1(20, 19, "Berth"));
    }
  },
  dependencies: [i2.NgControlStatus, i2.NgModel, i3.ColumnComponent, i3.CellTemplateDirective, i3.EditTemplateDirective, i4.AppControlComponent, i5.EditableGridComponent, i6.FlagIconComponent, i7.AsyncPipe, i8.TranslatePipe, i9.CodelistPipe],
  styles: ["\n/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IiIsInNvdXJjZVJvb3QiOiIifQ== */"]
});