import { PriceListTypeCodes } from '@common/classes/codes';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "@angular/forms";
import * as i3 from "@ngx-translate/core";
import * as i4 from "@progress/kendo-angular-grid";
import * as i5 from "../../../common/components/app-control/app-control.component";
import * as i6 from "../../../common/components/editable-grid/editable-grid.component";
import * as i7 from "../../../common/components/check-icon.component";
import * as i8 from "../charging-starting-event-list/charging-starting-event-list.component";
import * as i9 from "../charging-completing-event-list/charging-completing-event-list.component";
import * as i10 from "../../../common/pipes/codelist.pipe";
function ChargingConditionsViewComponent_div_9_ng_template_8_Template(rf, ctx) {
  if (rf & 1) {
    const _r7 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "app-control", 11);
    i0.ɵɵlistener("ngModelChange", function ChargingConditionsViewComponent_div_9_ng_template_8_Template_app_control_ngModelChange_0_listener($event) {
      const restoredCtx = i0.ɵɵrestoreView(_r7);
      const dataItem_r5 = restoredCtx.dataItem;
      return i0.ɵɵresetView(dataItem_r5.active = $event);
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const dataItem_r5 = ctx.dataItem;
    i0.ɵɵproperty("ngModel", dataItem_r5.active)("entity", dataItem_r5);
  }
}
function ChargingConditionsViewComponent_div_9_ng_template_9_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "app-check-icon", 12);
  }
  if (rf & 2) {
    const dataItem_r8 = ctx.$implicit;
    i0.ɵɵproperty("check", dataItem_r8.active);
  }
}
function ChargingConditionsViewComponent_div_9_ng_template_12_Template(rf, ctx) {
  if (rf & 1) {
    const _r11 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "app-control", 13);
    i0.ɵɵlistener("ngModelChange", function ChargingConditionsViewComponent_div_9_ng_template_12_Template_app_control_ngModelChange_0_listener($event) {
      const restoredCtx = i0.ɵɵrestoreView(_r11);
      const dataItem_r9 = restoredCtx.dataItem;
      return i0.ɵɵresetView(dataItem_r9.berthId = $event);
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const dataItem_r9 = ctx.dataItem;
    i0.ɵɵproperty("ngModel", dataItem_r9.berthId)("entity", dataItem_r9);
  }
}
function ChargingConditionsViewComponent_div_9_ng_template_13_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtext(0);
    i0.ɵɵpipe(1, "async");
    i0.ɵɵpipe(2, "codelist");
  }
  if (rf & 2) {
    const dataItem_r12 = ctx.$implicit;
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind1(1, 1, i0.ɵɵpipeBind2(2, 3, dataItem_r12.berthId, "Berth")), " ");
  }
}
function ChargingConditionsViewComponent_div_9_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 5)(1, "div", 1)(2, "h5");
    i0.ɵɵtext(3);
    i0.ɵɵpipe(4, "translate");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(5, "app-editable-grid", 6)(6, "kendo-grid-column", 7);
    i0.ɵɵpipe(7, "translate");
    i0.ɵɵtemplate(8, ChargingConditionsViewComponent_div_9_ng_template_8_Template, 1, 2, "ng-template", 8);
    i0.ɵɵtemplate(9, ChargingConditionsViewComponent_div_9_ng_template_9_Template, 1, 1, "ng-template", 9);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(10, "kendo-grid-column", 10);
    i0.ɵɵpipe(11, "translate");
    i0.ɵɵtemplate(12, ChargingConditionsViewComponent_div_9_ng_template_12_Template, 1, 2, "ng-template", 8);
    i0.ɵɵtemplate(13, ChargingConditionsViewComponent_div_9_ng_template_13_Template, 3, 6, "ng-template", 9);
    i0.ɵɵelementEnd()()()();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(3);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(4, 7, ctx_r0.berthsTitle));
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("data", ctx_r0.data.berths)("createParameters", ctx_r0.createParameters)("editMode", ctx_r0.editMode);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("width", 55)("title", i0.ɵɵpipeBind1(7, 9, "Active"));
    i0.ɵɵadvance(4);
    i0.ɵɵproperty("title", i0.ɵɵpipeBind1(11, 11, "Berth"));
  }
}
export class ChargingConditionsViewComponent {
  constructor() {
    this.createParameters = {
      active: true
    };
  }
  ngOnInit() {
    this.showBerths = [PriceListTypeCodes.AnchorageDues, PriceListTypeCodes.BerthageDues, PriceListTypeCodes.SecurityFee, PriceListTypeCodes.OverhangingonLascarisWharf, PriceListTypeCodes.ThroughputFee].includes(this.priceListTypeId);
    this.berthsTitle = [PriceListTypeCodes.BerthageDues, PriceListTypeCodes.SecurityFee, PriceListTypeCodes.OverhangingonLascarisWharf, PriceListTypeCodes.ThroughputFee].includes(this.priceListTypeId) ? 'Chargeable Berths' : 'Chargeable Areas';
  }
}
ChargingConditionsViewComponent.ɵfac = function ChargingConditionsViewComponent_Factory(t) {
  return new (t || ChargingConditionsViewComponent)();
};
ChargingConditionsViewComponent.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
  type: ChargingConditionsViewComponent,
  selectors: [["app-charging-conditions-view"]],
  inputs: {
    priceListTypeId: "priceListTypeId",
    data: "data",
    editMode: "editMode"
  },
  decls: 10,
  vars: 7,
  consts: [[1, "row"], [1, "col"], ["translate", ""], [3, "data", "createParameters", "editMode"], ["class", "row mt-3", 4, "ngIf"], [1, "row", "mt-3"], ["entityName", "PriceListBerth", 3, "data", "createParameters", "editMode"], ["field", "active", 3, "width", "title"], ["kendoGridEditTemplate", ""], ["kendoGridCellTemplate", ""], ["field", "berth", 3, "title"], ["type", "boolean", "size", "lg", "property", "active", 3, "ngModel", "entity", "ngModelChange"], [3, "check"], ["type", "codelist", "codelist", "Berth", "property", "berthId", 3, "ngModel", "entity", "ngModelChange"]],
  template: function ChargingConditionsViewComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "div", 0)(1, "div", 1)(2, "h5", 2);
      i0.ɵɵtext(3, "Starting Events");
      i0.ɵɵelementEnd();
      i0.ɵɵelement(4, "app-charging-starting-event-list", 3);
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(5, "div", 1)(6, "h5", 2);
      i0.ɵɵtext(7, "Completing Events");
      i0.ɵɵelementEnd();
      i0.ɵɵelement(8, "app-charging-completing-event-list", 3);
      i0.ɵɵelementEnd()();
      i0.ɵɵtemplate(9, ChargingConditionsViewComponent_div_9_Template, 14, 13, "div", 4);
    }
    if (rf & 2) {
      i0.ɵɵadvance(4);
      i0.ɵɵproperty("data", ctx.data.startingEvents)("createParameters", ctx.createParameters)("editMode", ctx.editMode);
      i0.ɵɵadvance(4);
      i0.ɵɵproperty("data", ctx.data.completingEvents)("createParameters", ctx.createParameters)("editMode", ctx.editMode);
      i0.ɵɵadvance(1);
      i0.ɵɵproperty("ngIf", ctx.showBerths);
    }
  },
  dependencies: [i1.NgIf, i2.NgControlStatus, i2.NgModel, i3.TranslateDirective, i4.ColumnComponent, i4.CellTemplateDirective, i4.EditTemplateDirective, i5.AppControlComponent, i6.EditableGridComponent, i7.CheckIconComponent, i8.ChargingStartingEventListComponent, i9.ChargingCompletingEventListComponent, i1.AsyncPipe, i3.TranslatePipe, i10.CodelistPipe],
  styles: ["\n/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IiIsInNvdXJjZVJvb3QiOiIifQ== */"]
});