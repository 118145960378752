import { AbstractBreezeListComponent } from '@common/classes/breeze-list';
import { BreezeViewService } from '@common/services/breeze-view.service';
import * as i0 from "@angular/core";
import * as i1 from "@common/services/breeze-view.service";
import * as i2 from "@angular/common";
import * as i3 from "@progress/kendo-angular-grid";
import * as i4 from "../../../common/components/app-grid/app-grid.component";
import * as i5 from "@ngx-translate/core";
function UnbilledVesselVisitEventListComponent_ng_template_12_span_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span");
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const row_r2 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate2("", row_r2.vesselId, " - ", row_r2.vesselName, "");
  }
}
function UnbilledVesselVisitEventListComponent_ng_template_12_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, UnbilledVesselVisitEventListComponent_ng_template_12_span_0_Template, 2, 2, "span", 10);
  }
  if (rf & 2) {
    const row_r2 = ctx.$implicit;
    i0.ɵɵproperty("ngIf", row_r2.vesselId);
  }
}
function UnbilledVesselVisitEventListComponent_ng_template_17_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span");
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const row_r5 = ctx.$implicit;
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate2("", row_r5.serviceId, " - ", row_r5.serviceName, "");
  }
}
export class UnbilledVesselVisitEventListComponent extends AbstractBreezeListComponent {
  constructor(breezeViewService) {
    super(breezeViewService);
    this.breezeViewService = breezeViewService;
    this.queryName = 'UnbilledVesselVisitEvents';
    this.gridSort = [{
      field: 'voyageNumber',
      dir: 'asc'
    }, {
      field: 'vesselId',
      dir: 'asc'
    }, {
      field: 'containerOwnerId',
      dir: 'asc'
    }];
  }
  getDefaultFilter(data) {
    this.handleFilterHighlighter();
    return this.breezeViewService.entityManager.createEntity('GetUnbilledVesselVisitEventsQuery', {
      ...data
    });
  }
  search() {
    this.appGrid.isBusy = true;
    this.handleFilterHighlighter();
    return this.entityManager.executeQuery(this.getQuery()).then(data => {
      this.data = data.results;
    }).finally(() => {
      this.appGrid.isBusy = false;
    });
  }
}
UnbilledVesselVisitEventListComponent.ɵfac = function UnbilledVesselVisitEventListComponent_Factory(t) {
  return new (t || UnbilledVesselVisitEventListComponent)(i0.ɵɵdirectiveInject(i1.BreezeViewService));
};
UnbilledVesselVisitEventListComponent.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
  type: UnbilledVesselVisitEventListComponent,
  selectors: [["app-unbilled-vessel-visit-event-list"]],
  features: [i0.ɵɵProvidersFeature([BreezeViewService]), i0.ɵɵInheritDefinitionFeature],
  decls: 20,
  vars: 23,
  consts: [[1, "row"], [1, "col-12"], [2, "text-transform", "uppercase"], [1, "mb-2", "voyage-summary-table", 3, "data", "filterable", "pageSize", "sort", "sortable"], ["field", "voyageNumber", 3, "title"], ["field", "vesselId", 3, "title"], ["kendoGridCellTemplate", ""], ["field", "containerOwnerId", 3, "title"], ["field", "serviceId", 3, "title"], ["field", "count", 3, "title"], [4, "ngIf"]],
  template: function UnbilledVesselVisitEventListComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "div", 0)(1, "div", 1)(2, "h6", 2);
      i0.ɵɵtext(3);
      i0.ɵɵpipe(4, "translate");
      i0.ɵɵelementEnd()()();
      i0.ɵɵelementStart(5, "div", 0)(6, "div", 1)(7, "app-grid", 3);
      i0.ɵɵelement(8, "kendo-grid-column", 4);
      i0.ɵɵpipe(9, "translate");
      i0.ɵɵelementStart(10, "kendo-grid-column", 5);
      i0.ɵɵpipe(11, "translate");
      i0.ɵɵtemplate(12, UnbilledVesselVisitEventListComponent_ng_template_12_Template, 1, 1, "ng-template", 6);
      i0.ɵɵelementEnd();
      i0.ɵɵelement(13, "kendo-grid-column", 7);
      i0.ɵɵpipe(14, "translate");
      i0.ɵɵelementStart(15, "kendo-grid-column", 8);
      i0.ɵɵpipe(16, "translate");
      i0.ɵɵtemplate(17, UnbilledVesselVisitEventListComponent_ng_template_17_Template, 2, 2, "ng-template", 6);
      i0.ɵɵelementEnd();
      i0.ɵɵelement(18, "kendo-grid-column", 9);
      i0.ɵɵpipe(19, "translate");
      i0.ɵɵelementEnd()()();
    }
    if (rf & 2) {
      i0.ɵɵadvance(3);
      i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(4, 11, "Port Dues"));
      i0.ɵɵadvance(4);
      i0.ɵɵproperty("data", ctx.data)("filterable", true)("pageSize", 20)("sort", ctx.gridSort)("sortable", true);
      i0.ɵɵadvance(1);
      i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(9, 13, "Voyage Number"));
      i0.ɵɵadvance(2);
      i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(11, 15, "Vessel"));
      i0.ɵɵadvance(3);
      i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(14, 17, "Owner"));
      i0.ɵɵadvance(2);
      i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(16, 19, "Service"));
      i0.ɵɵadvance(3);
      i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(19, 21, "Count"));
    }
  },
  dependencies: [i2.NgIf, i3.ColumnComponent, i3.CellTemplateDirective, i4.AppGridComponent, i5.TranslatePipe],
  styles: ["\n/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IiIsInNvdXJjZVJvb3QiOiIifQ== */"]
});