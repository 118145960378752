import { EntityQuery } from '@cime/breeze-client';
import { AbstractBreezeViewComponent } from '@common/classes/breeze-view';
import { UploadActionButtonsComponent } from '@common/components/upload-action-buttons.component';
import { BreezeViewService } from '@common/services/breeze-view.service';
import * as i0 from "@angular/core";
import * as i1 from "@common/services/breeze-view.service";
import * as i2 from "@angular/forms";
import * as i3 from "@progress/kendo-angular-layout";
import * as i4 from "../../../common/components/action-bar/action-bar.component";
import * as i5 from "../../../common/components/app-control/app-control.component";
import * as i6 from "../../../common/components/loader/loader.component";
import * as i7 from "../../../common/components/content-header/content-header.component";
import * as i8 from "../../../common/components/note-list/note-list.component";
import * as i9 from "../customer-email-view/customer-email-view.component";
import * as i10 from "../customer-tax-number-view/customer-tax-number-view.component";
import * as i11 from "../customer-bank-account-view/customer-bank-account-view.component";
import * as i12 from "@ngx-translate/core";
const _c0 = ["files"];
const _c1 = ["tabStrip"];
function CustomerViewComponent_ng_template_1_ng_template_56_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "app-customer-email-view", 30);
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("items", ctx_r2.model.emails)("editMode", ctx_r2.editMode);
  }
}
function CustomerViewComponent_ng_template_1_ng_template_59_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "app-customer-tax-number-view", 30);
  }
  if (rf & 2) {
    const ctx_r3 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("items", ctx_r3.model.taxNumbers)("editMode", ctx_r3.editMode);
  }
}
function CustomerViewComponent_ng_template_1_ng_template_62_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "app-customer-bank-account-view", 30);
  }
  if (rf & 2) {
    const ctx_r4 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("items", ctx_r4.model.bankAccounts)("editMode", ctx_r4.editMode);
  }
}
function CustomerViewComponent_ng_template_1_ng_template_65_Template(rf, ctx) {
  if (rf & 1) {
    const _r9 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "app-control", 31, 32);
    i0.ɵɵlistener("ngModelChange", function CustomerViewComponent_ng_template_1_ng_template_65_Template_app_control_ngModelChange_0_listener($event) {
      i0.ɵɵrestoreView(_r9);
      const ctx_r8 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r8.model.attachments = $event);
    })("addFile", function CustomerViewComponent_ng_template_1_ng_template_65_Template_app_control_addFile_0_listener($event) {
      i0.ɵɵrestoreView(_r9);
      const ctx_r10 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r10.onAddFile($event));
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r5 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("multiple", true)("isDisabled", !ctx_r5.editMode)("ngModel", ctx_r5.model.attachments)("entity", ctx_r5.model);
  }
}
function CustomerViewComponent_ng_template_1_ng_template_68_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "app-note-list", 30);
  }
  if (rf & 2) {
    const ctx_r6 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("items", ctx_r6.model.notes)("editMode", ctx_r6.editMode);
  }
}
function CustomerViewComponent_ng_template_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r12 = i0.ɵɵgetCurrentView();
    i0.ɵɵelement(0, "app-action-bar", 1)(1, "app-content-header", 2);
    i0.ɵɵelementStart(2, "div", 3)(3, "div", 4)(4, "div", 5)(5, "div", 6)(6, "app-control", 7);
    i0.ɵɵlistener("ngModelChange", function CustomerViewComponent_ng_template_1_Template_app_control_ngModelChange_6_listener($event) {
      i0.ɵɵrestoreView(_r12);
      const ctx_r11 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r11.model.erpNumber = $event);
    });
    i0.ɵɵpipe(7, "translate");
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(8, "div", 8)(9, "app-control", 9);
    i0.ɵɵlistener("ngModelChange", function CustomerViewComponent_ng_template_1_Template_app_control_ngModelChange_9_listener($event) {
      i0.ɵɵrestoreView(_r12);
      const ctx_r13 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r13.model.name1 = $event);
    });
    i0.ɵɵpipe(10, "translate");
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(11, "div", 6)(12, "app-control", 10);
    i0.ɵɵlistener("ngModelChange", function CustomerViewComponent_ng_template_1_Template_app_control_ngModelChange_12_listener($event) {
      i0.ɵɵrestoreView(_r12);
      const ctx_r14 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r14.model.addressLine1 = $event);
    });
    i0.ɵɵpipe(13, "translate");
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(14, "div", 6)(15, "app-control", 11);
    i0.ɵɵlistener("ngModelChange", function CustomerViewComponent_ng_template_1_Template_app_control_ngModelChange_15_listener($event) {
      i0.ɵɵrestoreView(_r12);
      const ctx_r15 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r15.model.addressLine2 = $event);
    });
    i0.ɵɵpipe(16, "translate");
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(17, "div", 6)(18, "app-control", 12);
    i0.ɵɵlistener("ngModelChange", function CustomerViewComponent_ng_template_1_Template_app_control_ngModelChange_18_listener($event) {
      i0.ɵɵrestoreView(_r12);
      const ctx_r16 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r16.model.city = $event);
    });
    i0.ɵɵpipe(19, "translate");
    i0.ɵɵelementEnd()()();
    i0.ɵɵelementStart(20, "div", 5)(21, "div", 6)(22, "app-control", 13);
    i0.ɵɵlistener("ngModelChange", function CustomerViewComponent_ng_template_1_Template_app_control_ngModelChange_22_listener($event) {
      i0.ɵɵrestoreView(_r12);
      const ctx_r17 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r17.model.sageNumber = $event);
    });
    i0.ɵɵpipe(23, "translate");
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(24, "div", 8)(25, "app-control", 14);
    i0.ɵɵlistener("ngModelChange", function CustomerViewComponent_ng_template_1_Template_app_control_ngModelChange_25_listener($event) {
      i0.ɵɵrestoreView(_r12);
      const ctx_r18 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r18.model.name2 = $event);
    });
    i0.ɵɵpipe(26, "translate");
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(27, "div", 6)(28, "app-control", 15);
    i0.ɵɵlistener("ngModelChange", function CustomerViewComponent_ng_template_1_Template_app_control_ngModelChange_28_listener($event) {
      i0.ɵɵrestoreView(_r12);
      const ctx_r19 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r19.model.countryId = $event);
    });
    i0.ɵɵpipe(29, "translate");
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(30, "div", 16)(31, "app-control", 17);
    i0.ɵɵlistener("ngModelChange", function CustomerViewComponent_ng_template_1_Template_app_control_ngModelChange_31_listener($event) {
      i0.ɵɵrestoreView(_r12);
      const ctx_r20 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r20.model.postOfficeId = $event);
    })("ngModelChange", function CustomerViewComponent_ng_template_1_Template_app_control_ngModelChange_31_listener($event) {
      i0.ɵɵrestoreView(_r12);
      const ctx_r21 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r21.onPostOfficeChange($event));
    });
    i0.ɵɵpipe(32, "translate");
    i0.ɵɵelementEnd()()();
    i0.ɵɵelementStart(33, "div", 5)(34, "div", 6)(35, "app-control", 18);
    i0.ɵɵlistener("ngModelChange", function CustomerViewComponent_ng_template_1_Template_app_control_ngModelChange_35_listener($event) {
      i0.ɵɵrestoreView(_r12);
      const ctx_r22 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r22.model.identificationNumber = $event);
    });
    i0.ɵɵpipe(36, "translate");
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(37, "div", 8)(38, "app-control", 19);
    i0.ɵɵlistener("ngModelChange", function CustomerViewComponent_ng_template_1_Template_app_control_ngModelChange_38_listener($event) {
      i0.ɵɵrestoreView(_r12);
      const ctx_r23 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r23.model.name3 = $event);
    });
    i0.ɵɵpipe(39, "translate");
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(40, "div", 6)(41, "app-control", 20);
    i0.ɵɵlistener("ngModelChange", function CustomerViewComponent_ng_template_1_Template_app_control_ngModelChange_41_listener($event) {
      i0.ɵɵrestoreView(_r12);
      const ctx_r24 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r24.model.siteNumber = $event);
    });
    i0.ɵɵpipe(42, "translate");
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(43, "div", 6)(44, "app-control", 21);
    i0.ɵɵlistener("ngModelChange", function CustomerViewComponent_ng_template_1_Template_app_control_ngModelChange_44_listener($event) {
      i0.ɵɵrestoreView(_r12);
      const ctx_r25 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r25.model.sitePurposeSourceReference = $event);
    });
    i0.ɵɵpipe(45, "translate");
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(46, "div", 22)(47, "app-control", 23);
    i0.ɵɵlistener("ngModelChange", function CustomerViewComponent_ng_template_1_Template_app_control_ngModelChange_47_listener($event) {
      i0.ɵɵrestoreView(_r12);
      const ctx_r26 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r26.model.taxPayer = $event);
    });
    i0.ɵɵpipe(48, "translate");
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(49, "div", 22)(50, "app-control", 24);
    i0.ɵɵlistener("ngModelChange", function CustomerViewComponent_ng_template_1_Template_app_control_ngModelChange_50_listener($event) {
      i0.ɵɵrestoreView(_r12);
      const ctx_r27 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r27.model.blocked = $event);
    });
    i0.ɵɵpipe(51, "translate");
    i0.ɵɵelementEnd()()()()();
    i0.ɵɵelementStart(52, "kendo-tabstrip", 25, 26);
    i0.ɵɵlistener("tabSelect", function CustomerViewComponent_ng_template_1_Template_kendo_tabstrip_tabSelect_52_listener($event) {
      i0.ɵɵrestoreView(_r12);
      const ctx_r28 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r28.tabSelected($event));
    });
    i0.ɵɵelementStart(54, "kendo-tabstrip-tab", 27);
    i0.ɵɵpipe(55, "translate");
    i0.ɵɵtemplate(56, CustomerViewComponent_ng_template_1_ng_template_56_Template, 1, 2, "ng-template", 28);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(57, "kendo-tabstrip-tab", 27);
    i0.ɵɵpipe(58, "translate");
    i0.ɵɵtemplate(59, CustomerViewComponent_ng_template_1_ng_template_59_Template, 1, 2, "ng-template", 28);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(60, "kendo-tabstrip-tab", 27);
    i0.ɵɵpipe(61, "translate");
    i0.ɵɵtemplate(62, CustomerViewComponent_ng_template_1_ng_template_62_Template, 1, 2, "ng-template", 28);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(63, "kendo-tabstrip-tab", 29);
    i0.ɵɵpipe(64, "translate");
    i0.ɵɵtemplate(65, CustomerViewComponent_ng_template_1_ng_template_65_Template, 2, 4, "ng-template", 28);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(66, "kendo-tabstrip-tab", 27);
    i0.ɵɵpipe(67, "translate");
    i0.ɵɵtemplate(68, CustomerViewComponent_ng_template_1_ng_template_68_Template, 1, 2, "ng-template", 28);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("items", ctx_r0.actionBar);
    i0.ɵɵadvance(1);
    i0.ɵɵpropertyInterpolate("title", ctx_r0.title);
    i0.ɵɵproperty("breadcrumb", ctx_r0.breadcrumb);
    i0.ɵɵadvance(5);
    i0.ɵɵproperty("label", i0.ɵɵpipeBind1(7, 60, "ERP Number"))("ngModel", ctx_r0.model.erpNumber)("entity", ctx_r0.model);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("label", i0.ɵɵpipeBind1(10, 62, "Name 1"))("ngModel", ctx_r0.model.name1)("entity", ctx_r0.model);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("label", i0.ɵɵpipeBind1(13, 64, "Address"))("ngModel", ctx_r0.model.addressLine1)("entity", ctx_r0.model);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("label", i0.ɵɵpipeBind1(16, 66, "Street Name"))("ngModel", ctx_r0.model.addressLine2)("entity", ctx_r0.model);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("label", i0.ɵɵpipeBind1(19, 68, "City"))("ngModel", ctx_r0.model.city)("entity", ctx_r0.model);
    i0.ɵɵadvance(4);
    i0.ɵɵproperty("label", i0.ɵɵpipeBind1(23, 70, "Sage Number"))("ngModel", ctx_r0.model.sageNumber)("entity", ctx_r0.model);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("label", i0.ɵɵpipeBind1(26, 72, "Name 2"))("ngModel", ctx_r0.model.name2)("entity", ctx_r0.model);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("label", i0.ɵɵpipeBind1(29, 74, "Country"))("ngModel", ctx_r0.model.countryId)("entity", ctx_r0.model);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("label", i0.ɵɵpipeBind1(32, 76, "Post Code"))("ngModel", ctx_r0.model.postOfficeId)("entity", ctx_r0.model);
    i0.ɵɵadvance(4);
    i0.ɵɵproperty("label", i0.ɵɵpipeBind1(36, 78, "ID Number"))("ngModel", ctx_r0.model.identificationNumber)("entity", ctx_r0.model);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("label", i0.ɵɵpipeBind1(39, 80, "Name 3"))("ngModel", ctx_r0.model.name3)("entity", ctx_r0.model);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("label", i0.ɵɵpipeBind1(42, 82, "Site Number"))("ngModel", ctx_r0.model.siteNumber)("entity", ctx_r0.model);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("label", i0.ɵɵpipeBind1(45, 84, "Site Source Reference"))("ngModel", ctx_r0.model.sitePurposeSourceReference)("entity", ctx_r0.model);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("label", i0.ɵɵpipeBind1(48, 86, "Tax Payer"))("ngModel", ctx_r0.model.taxPayer)("entity", ctx_r0.model);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("label", i0.ɵɵpipeBind1(51, 88, "Blocked"))("ngModel", ctx_r0.model.blocked)("entity", ctx_r0.model);
    i0.ɵɵadvance(4);
    i0.ɵɵproperty("title", i0.ɵɵpipeBind1(55, 90, "Emails") + " (" + ctx_r0.model.emails.length + ")")("selected", true)("selected", ctx_r0.activeTab === 0);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("title", i0.ɵɵpipeBind1(58, 92, "Tax Numbers") + " (" + ctx_r0.model.taxNumbers.length + ")")("selected", ctx_r0.activeTab === 1);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("title", i0.ɵɵpipeBind1(61, 94, "IBAN/BIC Informations") + " (" + ctx_r0.model.bankAccounts.length + ")")("selected", ctx_r0.activeTab === 2);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("title", i0.ɵɵpipeBind1(64, 96, "Attachments") + " (" + ctx_r0.model.attachments.length + ")")("id", "attachments")("selected", ctx_r0.activeTab === 3);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("title", i0.ɵɵpipeBind1(67, 98, "Notes") + " (" + ctx_r0.countActive(ctx_r0.model.notes) + ")")("selected", ctx_r0.activeTab === 4);
  }
}
export class CustomerViewComponent extends AbstractBreezeViewComponent {
  constructor(breezeViewService) {
    super(breezeViewService);
    this.breadcrumb = [{
      icon: 'users',
      title: this.translateService.get('Customers'),
      route: '/customer/list'
    }, {
      icon: 'user',
      title: this.translateService.get('Customer')
    }];
    this.parentRoute = '/customer';
    this.entityName = 'Customer';
    this.actionBar.push({
      label: this.translateService.instant('Print'),
      items: [{
        label: this.translateService.instant('Pdf'),
        icon: 'file-pdf',
        isSplit: true,
        onClick: () => {
          return this.breezeViewService.handleCommand('GenerateCustomerPdf', {
            id: this.model.id,
            language: 'SL'
          }).then(x => {
            UploadActionButtonsComponent.preview('Customer.pdf', x);
          });
        },
        options: [{
          label: this.translateService.instant('SL'),
          icon: 'file-pdf',
          onClick: () => {
            return this.breezeViewService.handleCommand('GenerateCustomerPdf', {
              id: this.model.id,
              language: 'SL'
            }).then(x => {
              UploadActionButtonsComponent.preview('Customer.pdf', x);
            });
          }
        }, {
          label: this.translateService.instant('EN'),
          icon: 'file-pdf',
          onClick: () => {
            return this.breezeViewService.handleCommand('GenerateCustomerPdf', {
              id: this.model.id,
              language: 'EN'
            }).then(x => {
              UploadActionButtonsComponent.preview('Customer.pdf', x);
            });
          }
        }]
      }, {
        label: this.translateService.instant('Xml'),
        icon: 'file',
        onClick: () => {
          return this.breezeViewService.handleCommand('GenerateCustomerPdf', {
            id: this.model.id,
            isXml: true,
            language: 'SL'
          }).then(x => {
            UploadActionButtonsComponent.preview('Customer.xml', x);
          });
        }
      }]
    });
  }
  get title() {
    return this.isNew ? this.translateService.instant('New Customer') : `${this.model.erpNumber ? `${this.model.erpNumber}` : ''} ${this.model.name1}`;
  }
  onPostOfficeChange(id) {
    if (!id) return;
    const query = new EntityQuery('PostOffice').withParameters({
      $method: 'POST',
      $data: {
        id
      }
    });
    this.breezeViewService.entityManager.executeQuery(query).then(result => this.model.city = result.results[0].name);
  }
}
CustomerViewComponent.ɵfac = function CustomerViewComponent_Factory(t) {
  return new (t || CustomerViewComponent)(i0.ɵɵdirectiveInject(i1.BreezeViewService));
};
CustomerViewComponent.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
  type: CustomerViewComponent,
  selectors: [["app-customer-view"]],
  viewQuery: function CustomerViewComponent_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(_c0, 5);
      i0.ɵɵviewQuery(_c1, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.files = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.tabStrip = _t.first);
    }
  },
  features: [i0.ɵɵProvidersFeature([BreezeViewService]), i0.ɵɵInheritDefinitionFeature],
  decls: 2,
  vars: 1,
  consts: [[3, "isBusy"], [3, "items"], [3, "title", "breadcrumb"], [1, "card", "card-border-top"], [1, "card-body"], [1, "row"], [1, "col-2"], ["property", "erpNumber", 3, "label", "ngModel", "entity", "ngModelChange"], [1, "col-3"], ["property", "name1", 3, "label", "ngModel", "entity", "ngModelChange"], ["property", "addressLine1", 3, "label", "ngModel", "entity", "ngModelChange"], ["property", "addressLine2", 3, "label", "ngModel", "entity", "ngModelChange"], ["property", "city", 3, "label", "ngModel", "entity", "ngModelChange"], ["property", "sageNumber", 3, "label", "ngModel", "entity", "ngModelChange"], ["property", "name2", 3, "label", "ngModel", "entity", "ngModelChange"], ["property", "countryId", 3, "label", "ngModel", "entity", "ngModelChange"], [1, "col-4"], ["type", "codelist", "codelist", "PostOffice", "property", "postOfficeId", 3, "label", "ngModel", "entity", "ngModelChange"], ["property", "identificationNumber", 3, "label", "ngModel", "entity", "ngModelChange"], ["property", "name3", 3, "label", "ngModel", "entity", "ngModelChange"], ["property", "siteNumber", 3, "label", "ngModel", "entity", "ngModelChange"], ["property", "sitePurposeSourceReference", 3, "label", "ngModel", "entity", "ngModelChange"], [1, "col-md-auto"], ["property", "taxPayer", 3, "label", "ngModel", "entity", "ngModelChange"], ["property", "blocked", 3, "label", "ngModel", "entity", "ngModelChange"], [1, "mt-3", 3, "tabSelect"], ["tabStrip", ""], [3, "title", "selected"], ["kendoTabContent", ""], [3, "title", "id", "selected"], [3, "items", "editMode"], ["type", "file", "property", "attachments", 3, "multiple", "isDisabled", "ngModel", "entity", "ngModelChange", "addFile"], ["files", ""]],
  template: function CustomerViewComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "app-loader", 0);
      i0.ɵɵtemplate(1, CustomerViewComponent_ng_template_1_Template, 69, 100, "ng-template");
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      i0.ɵɵproperty("isBusy", !ctx.model);
    }
  },
  dependencies: [i2.NgControlStatus, i2.NgModel, i3.TabStripComponent, i3.TabStripTabComponent, i3.TabContentDirective, i4.ActionBarComponent, i5.AppControlComponent, i6.LoaderComponent, i7.ContentHeaderComponent, i8.NoteListComponent, i9.CustomerEmailViewComponent, i10.CustomerTaxNumberViewComponent, i11.CustomerBankAccountViewComponent, i12.TranslatePipe],
  styles: ["\n/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IiIsInNvdXJjZVJvb3QiOiIifQ== */"]
});