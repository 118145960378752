import { InvoiceStatusCodes, InvoiceTypeCodes } from '../../common/classes/codes';
export class InvoiceStateMachine {
  constructor(invoice) {
    this.invoice = invoice;
  }
  canDelete() {
    if (this.invoice.statusId === InvoiceStatusCodes.Closed) {
      return false;
    }
    switch (this.invoice.statusId) {
      case InvoiceStatusCodes.Draft:
        return true;
      default:
        return false;
    }
  }
  canIssue() {
    if (this.invoice.statusId === InvoiceStatusCodes.Closed) {
      return false;
    }
    switch (this.invoice.statusId) {
      case InvoiceStatusCodes.Draft:
        return true;
      default:
        return false;
    }
  }
  canSendToApproval() {
    if (this.invoice.statusId === InvoiceStatusCodes.Closed) {
      return false;
    }
    switch (this.invoice.statusId) {
      case InvoiceStatusCodes.Issued:
        return true;
      default:
        return false;
    }
  }
  canApprove() {
    if (this.invoice.statusId === InvoiceStatusCodes.Closed) {
      return false;
    }
    switch (this.invoice.statusId) {
      case InvoiceStatusCodes.WaitingForApproval:
        return true;
      default:
        return false;
    }
  }
  canReject() {
    if (this.invoice.statusId === InvoiceStatusCodes.Closed) {
      return false;
    }
    switch (this.invoice.statusId) {
      case InvoiceStatusCodes.WaitingForApproval:
        return true;
      default:
        return false;
    }
  }
  canArchive() {
    if (this.invoice.archived) return false;
    switch (this.invoice.statusId) {
      case InvoiceStatusCodes.Approved:
      case InvoiceStatusCodes.Deleted:
      case InvoiceStatusCodes.Rejected:
      case InvoiceStatusCodes.Cancelled:
        //return this.invoice.sentToErp && !this.invoice.archived;
        //return !this.invoice.archived;
        return true;
      case InvoiceStatusCodes.Closed:
        return false;
      default:
        return false;
    }
  }
  canSendToErp() {
    if (this.invoice.statusId === InvoiceStatusCodes.Closed) {
      return false;
    }
    switch (this.invoice.statusId) {
      case InvoiceStatusCodes.Approved:
        //return !this.invoice.sentToErp;
        return false;
      default:
        return false;
    }
  }
  canLock() {
    switch (this.invoice.statusId) {
      case InvoiceStatusCodes.Approved:
        //return this.invoice.sentToErp;
        return false;
      default:
        return false;
    }
  }
  canCreateFullCreditNote() {
    if (this.invoice.typeId !== InvoiceTypeCodes.Invoice || this.invoice.statusId !== InvoiceStatusCodes.Closed) {
      return false;
    }
    switch (this.invoice.statusId) {
      case InvoiceStatusCodes.Approved:
        return this.invoice.sentToErp;
      case InvoiceStatusCodes.Closed:
        // return this.invoice.sentToErp;
        return true;
      default:
        return false;
    }
  }
  /**
   * Returns true if invoice can be edited based on Invoice status.
   * @param invoice Invoice
   */
  canEdit() {
    switch (this.invoice.statusId) {
      case InvoiceStatusCodes.Draft:
        return true;
      default:
        return false;
    }
  }
  /**
   * Returns true if attachment can be added to invoice.
   * @param invoice Invoice
   */
  canAddAttachment() {
    switch (this.invoice.statusId) {
      case InvoiceStatusCodes.Draft:
      case InvoiceStatusCodes.Issued:
      case InvoiceStatusCodes.WaitingForApproval:
      case InvoiceStatusCodes.Approved:
      case InvoiceStatusCodes.Closed:
        return true;
      default:
        return false;
    }
  }
}