import { HttpErrorResponse } from '@angular/common/http';
import { Injector } from '@angular/core';
import { ErrorDialogComponent } from '@common/components/error-dialog.component';
import { NewVersionDialogComponent } from '@common/components/new-version-dialog.component';
import { SignalrService } from '@common/services/signalr.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import * as i0 from "@angular/core";
export class DialogErrorHandler {
  // TODO:
  constructor(injector) {
    this.injector = injector;
    this.handleClientSideError = _.throttle(this.handleClientSideError.bind(this), 50);
    this.handleServerSideError = _.throttle(this.handleServerSideError.bind(this), 50);
    this.remoteLogError = _.throttle(this.remoteLogError.bind(this), 50);
  }
  remoteLogError(error) {
    if (error.toString().match('Cannot read property')) {
      return;
    }
    const signalrService = this.injector.get(SignalrService);
    signalrService.error(error.toString()).catch(err => {
      console.error(err);
    });
  }
  handleError(error) {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    this.remoteLogError(error);
    if (!this.modalService) {
      this.modalService = this.injector.get(NgbModal); // injecting modalService in constructor does not work
    }

    if (error.rejection && error.rejection.httpResponse) {
      // breeze query error
      error = error.rejection.httpResponse.response;
    }
    if (error instanceof HttpErrorResponse) {
      this.handleServerSideError(error);
    } else if (chunkFailedMessage.test(error.message)) {
      this.handleLoadingChunkError(error);
    } else {
      this.handleClientSideError(error);
    }
  }
  handleServerSideError(response) {
    let error = response.error || {};
    if (_.isString(error) && error[0] === '{') {
      // breeze command with status 500
      try {
        error = JSON.parse(error);
      } catch {}
    }
    this.openErrorDialog(error.errorMessage, error.exception, true);
  }
  handleClientSideError(error) {
    console.error(error);
    if (error.toString().match('Cannot read property')) {
      return;
    }
    this.openErrorDialog(error.message, error.stack, false);
  }
  openErrorDialog(errorMessage, stackTrace, serverSide) {
    if (!DialogErrorHandler.openErrorModal) {
      return;
    }
    const dialogRef = this.modalService.open(ErrorDialogComponent, {
      size: 'lg'
    });
    dialogRef.componentInstance.isServerSideError = serverSide;
    dialogRef.componentInstance.setMessage(errorMessage);
    dialogRef.componentInstance.setStackTrace(stackTrace);
  }
  handleLoadingChunkError(error) {
    console.error(error);
    const dialogRef = this.modalService.open(NewVersionDialogComponent, {});
    dialogRef.result.then(x => {
      if (x === true) {
        window.caches.keys().then(cacheNames => {
          Promise.all(cacheNames.map(cacheName => window.caches.delete(cacheName))).then(() => window.location.reload()).catch(() => window.location.reload());
        }).catch(() => window.location.reload());
      }
    });
  }
}
DialogErrorHandler.openErrorModal = true;
DialogErrorHandler.ɵfac = function DialogErrorHandler_Factory(t) {
  return new (t || DialogErrorHandler)(i0.ɵɵinject(i0.Injector));
};
DialogErrorHandler.ɵprov = /*@__PURE__*/i0.ɵɵdefineInjectable({
  token: DialogErrorHandler,
  factory: DialogErrorHandler.ɵfac,
  providedIn: 'root'
});