import { AutoFilterBreezeListComponent } from '@common/classes/auto-filter-list';
import { BreezeViewService } from '@common/services/breeze-view.service';
import { SharedService } from '@common/services/shared.service';
import * as i0 from "@angular/core";
import * as i1 from "@common/services/breeze-view.service";
import * as i2 from "@common/services/shared.service";
import * as i3 from "@angular/common";
import * as i4 from "@angular/router";
import * as i5 from "@angular/forms";
import * as i6 from "@ng-bootstrap/ng-bootstrap";
import * as i7 from "@fortawesome/angular-fontawesome";
import * as i8 from "@progress/kendo-angular-grid";
import * as i9 from "../../../../common/components/action-bar/action-bar.component";
import * as i10 from "../../../../common/components/app-control/app-control.component";
import * as i11 from "../../../../common/components/app-form/app-form.component";
import * as i12 from "../../../../common/components/content-header/content-header.component";
import * as i13 from "../../../../common/components/app-grid/app-grid.component";
import * as i14 from "../../../../common/components/app-control-container/app-control-container.component";
import * as i15 from "../../../../common/components/accordion-header.component";
import * as i16 from "@ngx-translate/core";
function ExchangeRateListComponent_ng_template_6_span_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span");
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r4 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate1("\u00A0(", ctx_r4.numberOfActiveFilters, ")");
  }
}
function ExchangeRateListComponent_ng_template_6_Template(rf, ctx) {
  if (rf & 1) {
    const _r6 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "app-accordion-header", 21);
    i0.ɵɵlistener("panelChange", function ExchangeRateListComponent_ng_template_6_Template_app_accordion_header_panelChange_0_listener() {
      i0.ɵɵrestoreView(_r6);
      const ctx_r5 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r5.handleFilterHighlighter(true));
    });
    i0.ɵɵelementStart(1, "h5");
    i0.ɵɵelement(2, "fa-icon", 22);
    i0.ɵɵtext(3);
    i0.ɵɵpipe(4, "translate");
    i0.ɵɵtemplate(5, ExchangeRateListComponent_ng_template_6_span_5_Template, 2, 1, "span", 23);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const panel_r3 = ctx.$implicit;
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("panel", panel_r3);
    i0.ɵɵadvance(3);
    i0.ɵɵtextInterpolate1(" \u00A0", i0.ɵɵpipeBind1(4, 3, "Filters"), " ");
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngIf", ctx_r0.numberOfActiveFilters > 0);
  }
}
function ExchangeRateListComponent_ng_template_7_Template(rf, ctx) {
  if (rf & 1) {
    const _r9 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "app-form", 24);
    i0.ɵɵlistener("submit", function ExchangeRateListComponent_ng_template_7_Template_app_form_submit_0_listener() {
      i0.ɵɵrestoreView(_r9);
      const ctx_r8 = i0.ɵɵnextContext();
      ctx_r8.search();
      return i0.ɵɵresetView(ctx_r8.onAutoFilterChange());
    });
    i0.ɵɵelementStart(1, "app-control-container", null, 25)(3, "div", 26)(4, "div", 27)(5, "app-control", 28);
    i0.ɵɵlistener("ngModelChange", function ExchangeRateListComponent_ng_template_7_Template_app_control_ngModelChange_5_listener($event) {
      i0.ɵɵrestoreView(_r9);
      const ctx_r10 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r10.filter.dateFrom = $event);
    });
    i0.ɵɵpipe(6, "translate");
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(7, "div", 27)(8, "app-control", 29);
    i0.ɵɵlistener("ngModelChange", function ExchangeRateListComponent_ng_template_7_Template_app_control_ngModelChange_8_listener($event) {
      i0.ɵɵrestoreView(_r9);
      const ctx_r11 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r11.filter.dateTo = $event);
    });
    i0.ɵɵpipe(9, "translate");
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(10, "div", 27)(11, "app-control", 30);
    i0.ɵɵlistener("ngModelChange", function ExchangeRateListComponent_ng_template_7_Template_app_control_ngModelChange_11_listener($event) {
      i0.ɵɵrestoreView(_r9);
      const ctx_r12 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r12.filter.currencyId = $event);
    });
    i0.ɵɵpipe(12, "translate");
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(13, "div", 27)(14, "app-control", 31);
    i0.ɵɵlistener("ngModelChange", function ExchangeRateListComponent_ng_template_7_Template_app_control_ngModelChange_14_listener($event) {
      i0.ɵɵrestoreView(_r9);
      const ctx_r13 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r13.filter.exchangeRateTypeId = $event);
    });
    i0.ɵɵpipe(15, "translate");
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(16, "div", 27)(17, "app-control", 32);
    i0.ɵɵlistener("ngModelChange", function ExchangeRateListComponent_ng_template_7_Template_app_control_ngModelChange_17_listener($event) {
      i0.ɵɵrestoreView(_r9);
      const ctx_r14 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r14.filter.exchangeRateSourceId = $event);
    });
    i0.ɵɵpipe(18, "translate");
    i0.ɵɵelementEnd()()();
    i0.ɵɵelementStart(19, "div", 33)(20, "div", 27)(21, "div", 34)(22, "button", 35);
    i0.ɵɵlistener("click", function ExchangeRateListComponent_ng_template_7_Template_button_click_22_listener() {
      i0.ɵɵrestoreView(_r9);
      const ctx_r15 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r15.clearFilter());
    });
    i0.ɵɵelement(23, "fa-icon", 36);
    i0.ɵɵtext(24, " \u00A0 ");
    i0.ɵɵelementStart(25, "span", 37);
    i0.ɵɵtext(26);
    i0.ɵɵpipe(27, "translate");
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(28, "button", 38);
    i0.ɵɵlistener("click", function ExchangeRateListComponent_ng_template_7_Template_button_click_28_listener() {
      i0.ɵɵrestoreView(_r9);
      const ctx_r16 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r16.search());
    });
    i0.ɵɵelement(29, "fa-icon", 39);
    i0.ɵɵtext(30, " \u00A0 ");
    i0.ɵɵelementStart(31, "span", 37);
    i0.ɵɵtext(32);
    i0.ɵɵpipe(33, "translate");
    i0.ɵɵelementEnd()()()()()()();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance(5);
    i0.ɵɵpropertyInterpolate("label", i0.ɵɵpipeBind1(6, 17, "Date From"));
    i0.ɵɵproperty("ngModel", ctx_r1.filter.dateFrom)("entity", ctx_r1.filter);
    i0.ɵɵadvance(3);
    i0.ɵɵpropertyInterpolate("label", i0.ɵɵpipeBind1(9, 19, "Date To"));
    i0.ɵɵproperty("ngModel", ctx_r1.filter.dateTo)("entity", ctx_r1.filter);
    i0.ɵɵadvance(3);
    i0.ɵɵpropertyInterpolate("label", i0.ɵɵpipeBind1(12, 21, "Currency"));
    i0.ɵɵproperty("ngModel", ctx_r1.filter.currencyId)("entity", ctx_r1.filter);
    i0.ɵɵadvance(3);
    i0.ɵɵpropertyInterpolate("label", i0.ɵɵpipeBind1(15, 23, "Type"));
    i0.ɵɵproperty("ngModel", ctx_r1.filter.exchangeRateTypeId)("entity", ctx_r1.filter);
    i0.ɵɵadvance(3);
    i0.ɵɵpropertyInterpolate("label", i0.ɵɵpipeBind1(18, 25, "Source"));
    i0.ɵɵproperty("ngModel", ctx_r1.filter.exchangeRateSourceId)("entity", ctx_r1.filter);
    i0.ɵɵadvance(9);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(27, 27, "Clear"));
    i0.ɵɵadvance(6);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(33, 29, "Search"));
  }
}
const _c0 = function (a1, a2) {
  return ["/administration/exchange-rate/", a1, a2];
};
function ExchangeRateListComponent_ng_template_14_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "a", 40);
    i0.ɵɵtext(1);
    i0.ɵɵpipe(2, "date");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const row_r17 = ctx.$implicit;
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵproperty("routerLink", i0.ɵɵpureFunction2(5, _c0, ctx_r2.defaultViewMode, row_r17.id));
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind2(2, 2, row_r17.date, "dd.MM.yyyy"), " ");
  }
}
const _c1 = function () {
  return {
    enabled: true,
    checkboxOnly: true
  };
};
export class ExchangeRateListComponent extends AutoFilterBreezeListComponent {
  constructor(breezeViewService, sharedService) {
    super(breezeViewService, sharedService);
    this.breezeViewService = breezeViewService;
    this.sharedService = sharedService;
    this.queryName = 'ExchangeRates';
    this.parentRoute = '/administration/exchange-rate/';
    this.excelCommand = 'ExportExchangeRatesToExcel';
    this.breadcrumb = [{
      icon: 'exchange-alt',
      title: this.translateService.get('Exchange Rate')
    }];
    this.addDeleteButton();
  }
  getDefaultFilter() {
    return this.entityManager.createEntity('GetExchangeRatesQuery');
  }
  addDeleteButton() {
    this.actionBar[0].items = this.actionBar[0].items.concat([{
      label: this.translateService.instant('Import'),
      icon: 'sync',
      onClick: () => {
        return this.breezeViewService.handleCommand('ImportExchangeRates', {
          Ids: this.selection
        }).then(x => {
          this.search();
        });
      }
    }]);
  }
}
ExchangeRateListComponent.ɵfac = function ExchangeRateListComponent_Factory(t) {
  return new (t || ExchangeRateListComponent)(i0.ɵɵdirectiveInject(i1.BreezeViewService), i0.ɵɵdirectiveInject(i2.SharedService));
};
ExchangeRateListComponent.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
  type: ExchangeRateListComponent,
  selectors: [["app-exchange-rate-list"]],
  features: [i0.ɵɵProvidersFeature([BreezeViewService]), i0.ɵɵInheritDefinitionFeature],
  decls: 33,
  vars: 41,
  consts: [[3, "items"], [3, "title", "breadcrumb"], [1, "filter-wrapper"], ["activeIds", "ngb-panel-0"], ["ngbPanelHeader", ""], ["ngbPanelContent", ""], [1, "card", "card-border-top", "mt-3"], [1, "card-body"], [1, "mb-2", 3, "data", "selectable", "selectBy", "selection", "selectionChange"], ["showSelectAll", "true", 3, "width"], ["field", "date", 3, "title"], ["kendoGridCellTemplate", ""], ["field", "currencyId", 3, "title"], ["field", "value", 3, "title"], ["field", "quantity", 3, "title"], ["field", "sourceId", 3, "title"], ["field", "typeId", 3, "title"], ["field", "createdBy.fullName", 3, "title"], ["field", "createdDate", "format", "g", 3, "title"], ["field", "lastModifiedBy.fullName", 3, "title"], ["field", "lastModifiedDate", "format", "g", 3, "title"], [3, "panel", "panelChange"], ["icon", "filter"], [4, "ngIf"], [3, "submit"], ["filterHighlighter", ""], [1, "row"], [1, "col"], ["property", "dateFrom", 3, "label", "ngModel", "entity", "ngModelChange"], ["property", "dateTo", 3, "label", "ngModel", "entity", "ngModelChange"], ["type", "codelist", "codelist", "Currency", "property", "currencyId", 3, "label", "ngModel", "entity", "ngModelChange"], ["type", "codelist", "codelist", "ExchangeRateType", "property", "exchangeRateTypeId", 3, "label", "ngModel", "entity", "ngModelChange"], ["type", "codelist", "codelist", "ExchangeRateSource", "property", "exchangeRateSourceId", 3, "label", "ngModel", "entity", "ngModelChange"], [1, "row", "mt-3"], [1, "float-right"], [1, "btn", "btn-sm", "btn-danger", "mr-2", 3, "click"], ["icon", "trash", 1, "ml-1"], [1, "btn-text"], [1, "btn", "btn-sm", "btn-primary", 3, "click"], ["icon", "search", 1, "ml-1"], [1, "text-primary", "link-box", 3, "routerLink"]],
  template: function ExchangeRateListComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelement(0, "app-action-bar", 0)(1, "app-content-header", 1);
      i0.ɵɵpipe(2, "translate");
      i0.ɵɵelementStart(3, "div", 2)(4, "ngb-accordion", 3)(5, "ngb-panel");
      i0.ɵɵtemplate(6, ExchangeRateListComponent_ng_template_6_Template, 6, 5, "ng-template", 4);
      i0.ɵɵtemplate(7, ExchangeRateListComponent_ng_template_7_Template, 34, 31, "ng-template", 5);
      i0.ɵɵelementEnd()()();
      i0.ɵɵelementStart(8, "div", 6)(9, "div", 7)(10, "app-grid", 8);
      i0.ɵɵlistener("selectionChange", function ExchangeRateListComponent_Template_app_grid_selectionChange_10_listener($event) {
        return ctx.selection = $event;
      });
      i0.ɵɵelement(11, "kendo-grid-checkbox-column", 9);
      i0.ɵɵelementStart(12, "kendo-grid-column", 10);
      i0.ɵɵpipe(13, "translate");
      i0.ɵɵtemplate(14, ExchangeRateListComponent_ng_template_14_Template, 3, 8, "ng-template", 11);
      i0.ɵɵelementEnd();
      i0.ɵɵelement(15, "kendo-grid-column", 12);
      i0.ɵɵpipe(16, "translate");
      i0.ɵɵelement(17, "kendo-grid-column", 13);
      i0.ɵɵpipe(18, "translate");
      i0.ɵɵelement(19, "kendo-grid-column", 14);
      i0.ɵɵpipe(20, "translate");
      i0.ɵɵelement(21, "kendo-grid-column", 15);
      i0.ɵɵpipe(22, "translate");
      i0.ɵɵelement(23, "kendo-grid-column", 16);
      i0.ɵɵpipe(24, "translate");
      i0.ɵɵelement(25, "kendo-grid-column", 17);
      i0.ɵɵpipe(26, "translate");
      i0.ɵɵelement(27, "kendo-grid-column", 18);
      i0.ɵɵpipe(28, "translate");
      i0.ɵɵelement(29, "kendo-grid-column", 19);
      i0.ɵɵpipe(30, "translate");
      i0.ɵɵelement(31, "kendo-grid-column", 20);
      i0.ɵɵpipe(32, "translate");
      i0.ɵɵelementEnd()()();
    }
    if (rf & 2) {
      i0.ɵɵproperty("items", ctx.actionBar);
      i0.ɵɵadvance(1);
      i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(2, 18, "Exchange Rates"));
      i0.ɵɵproperty("breadcrumb", ctx.breadcrumb);
      i0.ɵɵadvance(9);
      i0.ɵɵproperty("data", ctx.query)("selectable", i0.ɵɵpureFunction0(40, _c1))("selectBy", "id")("selection", ctx.selection);
      i0.ɵɵadvance(1);
      i0.ɵɵproperty("width", 1);
      i0.ɵɵadvance(1);
      i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(13, 20, "Date"));
      i0.ɵɵadvance(3);
      i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(16, 22, "Currency"));
      i0.ɵɵadvance(2);
      i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(18, 24, "Value"));
      i0.ɵɵadvance(2);
      i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(20, 26, "Quantity"));
      i0.ɵɵadvance(2);
      i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(22, 28, "Source"));
      i0.ɵɵadvance(2);
      i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(24, 30, "Type"));
      i0.ɵɵadvance(2);
      i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(26, 32, "Created By"));
      i0.ɵɵadvance(2);
      i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(28, 34, "Created Date"));
      i0.ɵɵadvance(2);
      i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(30, 36, "Last Modified By"));
      i0.ɵɵadvance(2);
      i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(32, 38, "Last Modified Date"));
    }
  },
  dependencies: [i3.NgIf, i4.RouterLink, i5.NgControlStatus, i5.NgModel, i6.NgbAccordion, i6.NgbPanel, i6.NgbPanelContent, i6.NgbPanelHeader, i7.FaIconComponent, i8.ColumnComponent, i8.CheckboxColumnComponent, i8.CellTemplateDirective, i9.ActionBarComponent, i10.AppControlComponent, i11.AppFormComponent, i12.ContentHeaderComponent, i13.AppGridComponent, i14.AppControlContainerComponent, i15.AccordionHeaderComponent, i3.DatePipe, i16.TranslatePipe],
  styles: ["\n/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IiIsInNvdXJjZVJvb3QiOiIifQ== */"]
});