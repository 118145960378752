import { BreezeViewService } from '@common/services/breeze-view.service';
import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';
import { endOfDay, getYear, isWithinInterval } from 'date-fns';
import * as _ from 'lodash';
import * as i0 from "@angular/core";
import * as i1 from "@progress/kendo-angular-dialog";
import * as i2 from "@common/services/breeze-view.service";
import * as i3 from "@angular/common";
import * as i4 from "@angular/forms";
import * as i5 from "@ngx-translate/core";
import * as i6 from "@progress/kendo-angular-buttons";
import * as i7 from "../../../common/components/app-control/app-control.component";
import * as i8 from "../../../common/components/loader/loader.component";
function InvoiceIssueDateComponent_div_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "div", 10);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("innerHTML", ctx_r0.message, i0.ɵɵsanitizeHtml);
  }
}
function InvoiceIssueDateComponent_div_8_Template(rf, ctx) {
  if (rf & 1) {
    const _r4 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 2)(1, "app-control", 11);
    i0.ɵɵlistener("ngModelChange", function InvoiceIssueDateComponent_div_8_Template_app_control_ngModelChange_1_listener($event) {
      i0.ɵɵrestoreView(_r4);
      const ctx_r3 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r3.invoice.serviceDate = $event);
    });
    i0.ɵɵpipe(2, "translate");
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("label", i0.ɵɵpipeBind1(2, 5, "Service Date"))("time", true)("isDisabled", !ctx_r1.enableEditIssueDate)("ngModel", ctx_r1.invoice.serviceDate)("entity", ctx_r1.invoice);
  }
}
function InvoiceIssueDateComponent_ng_template_11_div_0_p_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "p");
    i0.ɵɵtext(1);
    i0.ɵɵpipe(2, "translate");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(2, 1, "Invoicing period not found"));
  }
}
function InvoiceIssueDateComponent_ng_template_11_div_0_p_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "p");
    i0.ɵɵtext(1);
    i0.ɵɵpipe(2, "translate");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(2, 1, "Service date out of valid invoice period"));
  }
}
function InvoiceIssueDateComponent_ng_template_11_div_0_p_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "p");
    i0.ɵɵtext(1);
    i0.ɵɵpipe(2, "translate");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(2, 1, "Account outside the allowed business period"));
  }
}
function InvoiceIssueDateComponent_ng_template_11_div_0_div_6_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div")(1, "p", 14);
    i0.ɵɵtext(2);
    i0.ɵɵpipe(3, "translate");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(4, "p", 15);
    i0.ɵɵtext(5);
    i0.ɵɵpipe(6, "translate");
    i0.ɵɵelementStart(7, "span", 16);
    i0.ɵɵtext(8);
    i0.ɵɵpipe(9, "date");
    i0.ɵɵpipe(10, "translate");
    i0.ɵɵpipe(11, "date");
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(12, "p", 15);
    i0.ɵɵtext(13);
    i0.ɵɵpipe(14, "translate");
    i0.ɵɵelementStart(15, "span", 16);
    i0.ɵɵtext(16);
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(17, "p", 15);
    i0.ɵɵtext(18);
    i0.ɵɵpipe(19, "translate");
    i0.ɵɵelementStart(20, "span", 16);
    i0.ɵɵtext(21);
    i0.ɵɵelementEnd()()();
  }
  if (rf & 2) {
    const ctx_r9 = i0.ɵɵnextContext(3);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind1(3, 9, "Currently allowed period for issuing invoices"), ": ");
    i0.ɵɵadvance(3);
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind1(6, 11, "Financial period for service date from"), " ");
    i0.ɵɵadvance(3);
    i0.ɵɵtextInterpolate3(" ", i0.ɵɵpipeBind2(9, 13, ctx_r9.invoicePeriod.validIssueDateFrom, "dd/MM/yyyy"), " ", i0.ɵɵpipeBind1(10, 16, "to"), " ", i0.ɵɵpipeBind2(11, 18, ctx_r9.invoicePeriod.validIssueDateTo, "dd/MM/yyyy"), " ");
    i0.ɵɵadvance(5);
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind1(14, 21, "Current business year"), ": ");
    i0.ɵɵadvance(3);
    i0.ɵɵtextInterpolate(ctx_r9.invoicePeriod.currentAccountingYear);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind1(19, 23, "Allowed year"), ": ");
    i0.ɵɵadvance(3);
    i0.ɵɵtextInterpolate(ctx_r9.invoicePeriod.alternativeAccountingYear);
  }
}
function InvoiceIssueDateComponent_ng_template_11_div_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div")(1, "span", 13);
    i0.ɵɵtemplate(2, InvoiceIssueDateComponent_ng_template_11_div_0_p_2_Template, 3, 3, "p", 12);
    i0.ɵɵtemplate(3, InvoiceIssueDateComponent_ng_template_11_div_0_p_3_Template, 3, 3, "p", 12);
    i0.ɵɵtemplate(4, InvoiceIssueDateComponent_ng_template_11_div_0_p_4_Template, 3, 3, "p", 12);
    i0.ɵɵelementEnd();
    i0.ɵɵelement(5, "hr");
    i0.ɵɵtemplate(6, InvoiceIssueDateComponent_ng_template_11_div_0_div_6_Template, 22, 25, "div", 12);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r5 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngIf", !ctx_r5.isInvoicePeriodSet());
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", !ctx_r5.isServiceDateValid());
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", !ctx_r5.isServiceYearValid());
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngIf", ctx_r5.isInvoicePeriodSet());
  }
}
function InvoiceIssueDateComponent_ng_template_11_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, InvoiceIssueDateComponent_ng_template_11_div_0_Template, 7, 4, "div", 12);
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngIf", ctx_r2.invoice);
  }
}
export class InvoiceIssueDateComponent extends DialogContentBase {
  constructor(dialog, breezeViewService) {
    super(dialog);
    this.dialog = dialog;
    this.breezeViewService = breezeViewService;
    this.invoicePeriod = {};
    this.isBusy = false;
  }
  ok() {
    this.dialog.close({
      success: true,
      date: this.date,
      serviceDate: this.invoice?.serviceDate
    });
  }
  close() {
    this.invoice?.entityAspect.rejectChanges();
    this.dialog.close({
      success: false
    });
  }
  ngOnInit() {
    this.isBusy = true;
    const enableEditIssueDate = false;
    // const enableEditIssueDate = this.breezeViewService.handleQuery('EnableEditIssueDate');
    // Promise.resolve(enableEditIssueDate).then(enableEditIssueDate => this.enableEditIssueDate = _.first(enableEditIssueDate));
    if (!this.invoice) {
      const invoicesPromise = this.breezeViewService.handleQuery('Invoices', {
        ids: this.invoiceIds
      });
      const invoicePeriodsForInvoicesPromise = this.breezeViewService.handleQuery('InvoicePeriodsForInvoices', {
        invoiceIds: this.invoiceIds
      });
      Promise.all([invoicesPromise, invoicePeriodsForInvoicesPromise]).then(([invoices, invoicePeriods]) => {
        this.invoices = invoices;
        this.invoicePeriods = invoicePeriods;
      }).finally(() => this.isBusy = false);
    } else {
      const invoicePeriodForInvoicePromise = this.breezeViewService.handleQuery('InvoicePeriodForInvoice', {
        invoiceId: this.invoice.id
      });
      Promise.resolve(invoicePeriodForInvoicePromise).then(invoicePeriod => this.invoicePeriod = _.first(invoicePeriod)).finally(() => this.isBusy = false);
    }
  }
  isServiceDateValid() {
    if (!this.isInvoicePeriodSet()) return false;
    if (!this.invoice) {
      this.invoices.forEach(invoice => {
        const invoicePeriod = this.invoicePeriods.filter(ip => ip.organizationId === invoice.organizationId)[0];
        if (!invoicePeriod) return false;
        const start = invoicePeriod.validIssueDateFrom;
        const end = endOfDay(invoicePeriod.validIssueDateTo);
        if (!invoicePeriod || !invoice || start > end) return false;
        const withinInterval = isWithinInterval(invoice.serviceDate, {
          start,
          end
        });
        if (!withinInterval) return false;
      });
      return true;
    } else {
      const start = this.invoicePeriod.validIssueDateFrom;
      const end = endOfDay(this.invoicePeriod.validIssueDateTo);
      if (!this.invoicePeriod || !this.invoice || start > end) return false;
      return isWithinInterval(this.invoice.serviceDate, {
        start,
        end
      });
    }
  }
  isServiceYearValid() {
    if (!this.isInvoicePeriodSet()) return false;
    if (!this.invoice) {
      this.invoices.forEach(invoice => {
        const invoicePeriod = this.invoicePeriods.filter(ip => ip.organizationId === invoice.organizationId)[0];
        if (!invoicePeriod) return false;
        const correctYear = this.invoicePeriod.currentAccountingYear === getYear(this.invoice.serviceDate) || this.invoicePeriod.currentAccountingYear !== getYear(this.invoice.serviceDate) && this.invoicePeriod.alternativeAccountingYear === getYear(this.invoice.serviceDate);
        if (!correctYear) return false;
      });
      return true;
    } else {
      return this.invoicePeriod.currentAccountingYear === getYear(this.invoice.serviceDate) || this.invoicePeriod.currentAccountingYear !== getYear(this.invoice.serviceDate) && this.invoicePeriod.alternativeAccountingYear === getYear(this.invoice.serviceDate);
    }
  }
  isInvoicePeriodSet() {
    if (!this.invoicePeriod || !this.invoice && !this.invoiceIds) return false;
    return true;
  }
}
InvoiceIssueDateComponent.ɵfac = function InvoiceIssueDateComponent_Factory(t) {
  return new (t || InvoiceIssueDateComponent)(i0.ɵɵdirectiveInject(i1.DialogRef), i0.ɵɵdirectiveInject(i2.BreezeViewService));
};
InvoiceIssueDateComponent.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
  type: InvoiceIssueDateComponent,
  selectors: [["app-invoice-issue-date"]],
  inputs: {
    date: "date",
    title: "title",
    message: "message",
    organizationCode: "organizationCode",
    invoice: "invoice",
    invoiceIds: "invoiceIds",
    invoicePeriod: "invoicePeriod"
  },
  features: [i0.ɵɵProvidersFeature([BreezeViewService]), i0.ɵɵInheritDefinitionFeature],
  decls: 17,
  vars: 13,
  consts: [["class", "m-1 text-center", 3, "innerHTML", 4, "ngIf"], [1, "row"], [1, "col"], ["type", "datetime", "property", "date", 3, "label", "time", "isDisabled", "ngModel", "entity", "ngModelChange"], ["class", "col", 4, "ngIf"], [1, "mt-3"], [3, "isBusy"], [3, "layout"], ["kendoButton", "", "translate", "", 3, "disabled", "click"], ["kendoButton", "", "primary", "true", "translate", "", 3, "disabled", "click"], [1, "m-1", "text-center", 3, "innerHTML"], ["type", "datetime", "property", "serviceDate", 3, "label", "time", "isDisabled", "ngModel", "entity", "ngModelChange"], [4, "ngIf"], [1, "mb-1", "font-weight-bold", "text-center", 2, "color", "red"], [1, "h6", "font-weight-bold"], [1, "mb-0"], [1, "font-weight-bold"]],
  template: function InvoiceIssueDateComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "kendo-dialog-titlebar")(1, "h5");
      i0.ɵɵtext(2);
      i0.ɵɵelementEnd()();
      i0.ɵɵtemplate(3, InvoiceIssueDateComponent_div_3_Template, 1, 1, "div", 0);
      i0.ɵɵelementStart(4, "div", 1)(5, "div", 2)(6, "app-control", 3);
      i0.ɵɵlistener("ngModelChange", function InvoiceIssueDateComponent_Template_app_control_ngModelChange_6_listener($event) {
        return ctx.date = $event;
      });
      i0.ɵɵpipe(7, "translate");
      i0.ɵɵelementEnd()();
      i0.ɵɵtemplate(8, InvoiceIssueDateComponent_div_8_Template, 3, 7, "div", 4);
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(9, "div", 5)(10, "app-loader", 6);
      i0.ɵɵtemplate(11, InvoiceIssueDateComponent_ng_template_11_Template, 1, 1, "ng-template");
      i0.ɵɵelementEnd()();
      i0.ɵɵelementStart(12, "kendo-dialog-actions", 7)(13, "button", 8);
      i0.ɵɵlistener("click", function InvoiceIssueDateComponent_Template_button_click_13_listener() {
        return ctx.close();
      });
      i0.ɵɵtext(14, "Cancel");
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(15, "button", 9);
      i0.ɵɵlistener("click", function InvoiceIssueDateComponent_Template_button_click_15_listener() {
        return ctx.ok();
      });
      i0.ɵɵtext(16, " Ok ");
      i0.ɵɵelementEnd()();
    }
    if (rf & 2) {
      i0.ɵɵadvance(2);
      i0.ɵɵtextInterpolate(ctx.title);
      i0.ɵɵadvance(1);
      i0.ɵɵproperty("ngIf", ctx.message);
      i0.ɵɵadvance(3);
      i0.ɵɵproperty("label", i0.ɵɵpipeBind1(7, 11, "Invoice Issue Date"))("time", true)("isDisabled", !ctx.enableEditIssueDate)("ngModel", ctx.date);
      i0.ɵɵadvance(2);
      i0.ɵɵproperty("ngIf", ctx.invoice);
      i0.ɵɵadvance(2);
      i0.ɵɵproperty("isBusy", ctx.isBusy);
      i0.ɵɵadvance(2);
      i0.ɵɵproperty("layout", "normal");
      i0.ɵɵadvance(1);
      i0.ɵɵproperty("disabled", ctx.isBusy);
      i0.ɵɵadvance(2);
      i0.ɵɵproperty("disabled", ctx.isBusy || !ctx.isServiceDateValid() || !ctx.isServiceYearValid());
    }
  },
  dependencies: [i3.NgIf, i4.NgControlStatus, i4.NgModel, i5.TranslateDirective, i6.Button, i1.DialogTitleBarComponent, i1.DialogActionsComponent, i7.AppControlComponent, i8.LoaderComponent, i3.DatePipe, i5.TranslatePipe],
  styles: ["\n/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IiIsInNvdXJjZVJvb3QiOiIifQ== */"]
});