import { RouterModule } from '@angular/router';
import { CanDeactivateView } from '@common/guards/view.deactivate';
import { ViewMode } from '@common/models/view-mode';
import { CommonModule } from '../../common/common.module';
import { RoleEditComponent } from './components/role-edit/role-edit.component';
import { RoleListComponent } from './components/role-list/role-list.component';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
export const ROLE_ROUTES = [{
  path: '',
  redirectTo: 'list',
  pathMatch: 'full'
}, {
  path: 'list',
  component: RoleListComponent
}, {
  path: 'view/:id',
  component: RoleEditComponent,
  data: {
    mode: ViewMode.view
  }
}, {
  path: 'edit/:id',
  component: RoleEditComponent,
  data: {
    mode: ViewMode.edit
  },
  canDeactivate: [CanDeactivateView]
}, {
  path: 'create',
  component: RoleEditComponent,
  data: {
    mode: ViewMode.create
  },
  canDeactivate: [CanDeactivateView]
}, {
  path: '**',
  redirectTo: 'list'
}];
export class RoleModule {}
RoleModule.ɵfac = function RoleModule_Factory(t) {
  return new (t || RoleModule)();
};
RoleModule.ɵmod = /*@__PURE__*/i0.ɵɵdefineNgModule({
  type: RoleModule
});
RoleModule.ɵinj = /*@__PURE__*/i0.ɵɵdefineInjector({
  providers: [CanDeactivateView],
  imports: [CommonModule, RouterModule.forChild(ROLE_ROUTES)]
});
(function () {
  (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(RoleModule, {
    declarations: [RoleListComponent, RoleEditComponent],
    imports: [CommonModule, i1.RouterModule]
  });
})();