import { AutoFilterBreezeListComponent } from '@common/classes/auto-filter-list';
import { AppGridComponent } from '@common/components/app-grid/app-grid.component';
import { BreezeViewService } from '@common/services/breeze-view.service';
import { SharedService } from '@common/services/shared.service';
import * as i0 from "@angular/core";
import * as i1 from "@common/services/breeze-view.service";
import * as i2 from "@common/services/shared.service";
import * as i3 from "@angular/common";
import * as i4 from "@angular/forms";
import * as i5 from "@ng-bootstrap/ng-bootstrap";
import * as i6 from "@fortawesome/angular-fontawesome";
import * as i7 from "@progress/kendo-angular-grid";
import * as i8 from "../../../common/components/action-bar/action-bar.component";
import * as i9 from "../../../common/components/app-control/app-control.component";
import * as i10 from "../../../common/components/app-form/app-form.component";
import * as i11 from "../../../common/components/content-header/content-header.component";
import * as i12 from "../../../common/components/app-grid/app-grid.component";
import * as i13 from "../../../common/components/app-control-container/app-control-container.component";
import * as i14 from "../../../common/components/accordion-header.component";
import * as i15 from "@ngx-translate/core";
function PricePerUnitReportComponent_ng_template_6_span_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span");
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r3 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate1("\u00A0(", ctx_r3.numberOfActiveFilters, ")");
  }
}
function PricePerUnitReportComponent_ng_template_6_Template(rf, ctx) {
  if (rf & 1) {
    const _r5 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "app-accordion-header", 22);
    i0.ɵɵlistener("panelChange", function PricePerUnitReportComponent_ng_template_6_Template_app_accordion_header_panelChange_0_listener() {
      i0.ɵɵrestoreView(_r5);
      const ctx_r4 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r4.handleFilterHighlighter(true));
    });
    i0.ɵɵelementStart(1, "h5");
    i0.ɵɵelement(2, "fa-icon", 23);
    i0.ɵɵtext(3);
    i0.ɵɵpipe(4, "translate");
    i0.ɵɵtemplate(5, PricePerUnitReportComponent_ng_template_6_span_5_Template, 2, 1, "span", 24);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const panel_r2 = ctx.$implicit;
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("panel", panel_r2);
    i0.ɵɵadvance(3);
    i0.ɵɵtextInterpolate1(" \u00A0", i0.ɵɵpipeBind1(4, 3, "Filters"), " ");
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngIf", ctx_r0.numberOfActiveFilters > 0);
  }
}
function PricePerUnitReportComponent_ng_template_7_Template(rf, ctx) {
  if (rf & 1) {
    const _r8 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "app-form", 25);
    i0.ɵɵlistener("submit", function PricePerUnitReportComponent_ng_template_7_Template_app_form_submit_0_listener() {
      i0.ɵɵrestoreView(_r8);
      const ctx_r7 = i0.ɵɵnextContext();
      ctx_r7.search();
      return i0.ɵɵresetView(ctx_r7.onAutoFilterChange());
    });
    i0.ɵɵelementStart(1, "app-control-container", null, 26)(3, "div", 8)(4, "div", 27)(5, "div", 8)(6, "div", 27)(7, "app-control", 28);
    i0.ɵɵlistener("ngModelChange", function PricePerUnitReportComponent_ng_template_7_Template_app_control_ngModelChange_7_listener($event) {
      i0.ɵɵrestoreView(_r8);
      const ctx_r9 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r9.filter.recipientId = $event);
    });
    i0.ɵɵpipe(8, "translate");
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(9, "div", 27)(10, "app-control", 29);
    i0.ɵɵlistener("ngModelChange", function PricePerUnitReportComponent_ng_template_7_Template_app_control_ngModelChange_10_listener($event) {
      i0.ɵɵrestoreView(_r8);
      const ctx_r10 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r10.filter.serviceId = $event);
    });
    i0.ɵɵpipe(11, "translate");
    i0.ɵɵelementEnd()()()();
    i0.ɵɵelementStart(12, "div", 27)(13, "div", 8)(14, "div", 27)(15, "app-control", 30);
    i0.ɵɵlistener("ngModelChange", function PricePerUnitReportComponent_ng_template_7_Template_app_control_ngModelChange_15_listener($event) {
      i0.ɵɵrestoreView(_r8);
      const ctx_r11 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r11.filter.serviceDateFrom = $event);
    });
    i0.ɵɵpipe(16, "translate");
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(17, "div", 27)(18, "app-control", 31);
    i0.ɵɵlistener("ngModelChange", function PricePerUnitReportComponent_ng_template_7_Template_app_control_ngModelChange_18_listener($event) {
      i0.ɵɵrestoreView(_r8);
      const ctx_r12 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r12.filter.serviceDateTo = $event);
    });
    i0.ɵɵpipe(19, "translate");
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(20, "div", 27)(21, "app-control", 32);
    i0.ɵɵlistener("ngModelChange", function PricePerUnitReportComponent_ng_template_7_Template_app_control_ngModelChange_21_listener($event) {
      i0.ɵɵrestoreView(_r8);
      const ctx_r13 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r13.filter.createdDateFrom = $event);
    });
    i0.ɵɵpipe(22, "translate");
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(23, "div", 27)(24, "app-control", 33);
    i0.ɵɵlistener("ngModelChange", function PricePerUnitReportComponent_ng_template_7_Template_app_control_ngModelChange_24_listener($event) {
      i0.ɵɵrestoreView(_r8);
      const ctx_r14 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r14.filter.createdDateTo = $event);
    });
    i0.ɵɵpipe(25, "translate");
    i0.ɵɵelementEnd()()()()();
    i0.ɵɵelementStart(26, "div", 34)(27, "div", 9)(28, "div", 35)(29, "button", 36);
    i0.ɵɵlistener("click", function PricePerUnitReportComponent_ng_template_7_Template_button_click_29_listener() {
      i0.ɵɵrestoreView(_r8);
      const ctx_r15 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r15.clearFilter());
    });
    i0.ɵɵelement(30, "fa-icon", 37);
    i0.ɵɵtext(31, " \u00A0 ");
    i0.ɵɵelementStart(32, "span", 38);
    i0.ɵɵtext(33);
    i0.ɵɵpipe(34, "translate");
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(35, "button", 39);
    i0.ɵɵlistener("click", function PricePerUnitReportComponent_ng_template_7_Template_button_click_35_listener() {
      i0.ɵɵrestoreView(_r8);
      const ctx_r16 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r16.search());
    });
    i0.ɵɵelement(36, "fa-icon", 40);
    i0.ɵɵtext(37, " \u00A0 ");
    i0.ɵɵelementStart(38, "span", 38);
    i0.ɵɵtext(39);
    i0.ɵɵpipe(40, "translate");
    i0.ɵɵelementEnd()()()()()()();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance(7);
    i0.ɵɵpropertyInterpolate("label", i0.ɵɵpipeBind1(8, 24, "Recipient"));
    i0.ɵɵproperty("ngModel", ctx_r1.filter.recipientId)("entity", ctx_r1.filter);
    i0.ɵɵadvance(3);
    i0.ɵɵpropertyInterpolate("label", i0.ɵɵpipeBind1(11, 26, "Service"));
    i0.ɵɵproperty("ngModel", ctx_r1.filter.serviceId)("entity", ctx_r1.filter);
    i0.ɵɵadvance(5);
    i0.ɵɵpropertyInterpolate("label", i0.ɵɵpipeBind1(16, 28, "Realization Date From"));
    i0.ɵɵproperty("time", true)("ngModel", ctx_r1.filter.serviceDateFrom)("entity", ctx_r1.filter);
    i0.ɵɵadvance(3);
    i0.ɵɵpropertyInterpolate("label", i0.ɵɵpipeBind1(19, 30, "Realization Date To"));
    i0.ɵɵproperty("time", true)("ngModel", ctx_r1.filter.serviceDateTo)("entity", ctx_r1.filter);
    i0.ɵɵadvance(3);
    i0.ɵɵpropertyInterpolate("label", i0.ɵɵpipeBind1(22, 32, "Created Date From"));
    i0.ɵɵproperty("time", true)("ngModel", ctx_r1.filter.createdDateFrom)("entity", ctx_r1.filter);
    i0.ɵɵadvance(3);
    i0.ɵɵpropertyInterpolate("label", i0.ɵɵpipeBind1(25, 34, "Created Date To"));
    i0.ɵɵproperty("time", true)("ngModel", ctx_r1.filter.createdDateTo)("entity", ctx_r1.filter);
    i0.ɵɵadvance(9);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(34, 36, "Clear"));
    i0.ɵɵadvance(6);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(40, 38, "Search"));
  }
}
const _c0 = function () {
  return {
    enabled: true,
    checkboxOnly: true
  };
};
export class PricePerUnitReportComponent extends AutoFilterBreezeListComponent {
  constructor(breezeViewService, sharedService) {
    super(breezeViewService, sharedService);
    this.breezeViewService = breezeViewService;
    this.sharedService = sharedService;
    this.queryName = 'PricePerUnitInvoices';
    this.parentRoute = '/invoice/';
    this.excelCommand = 'ExportPricePerUnitInvoicesToExcel';
    this.gridSort = [{
      field: 'invoiceNumber',
      dir: 'asc'
    }, {
      field: 'serviceId',
      dir: 'asc'
    }];
    this.actionGroup.isVisible = () => false;
  }
  search() {
    this.appGrid.isBusy = true;
    this.handleFilterHighlighter();
    return this.entityManager.executeQuery(this.getQuery()).then(data => {
      this.data = data.results;
    }).finally(() => {
      this.appGrid.isBusy = false;
    });
  }
  getDefaultFilter(data) {
    return this.breezeViewService.entityManager.createEntity('GetPricePerUnitInvoicesQuery', {
      ...data
    });
  }
}
PricePerUnitReportComponent.ɵfac = function PricePerUnitReportComponent_Factory(t) {
  return new (t || PricePerUnitReportComponent)(i0.ɵɵdirectiveInject(i1.BreezeViewService), i0.ɵɵdirectiveInject(i2.SharedService));
};
PricePerUnitReportComponent.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
  type: PricePerUnitReportComponent,
  selectors: [["app-price-per-unit-report"]],
  viewQuery: function PricePerUnitReportComponent_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(AppGridComponent, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.appGrid = _t.first);
    }
  },
  features: [i0.ɵɵProvidersFeature([BreezeViewService]), i0.ɵɵInheritDefinitionFeature],
  decls: 34,
  vars: 42,
  consts: [[3, "items"], [3, "title"], [1, "filter-wrapper"], ["activeIds", "ngb-panel-0"], ["ngbPanelHeader", ""], ["ngbPanelContent", ""], [1, "card", "card-border-top", "mt-3"], [1, "card-body"], [1, "row"], [1, "col"], [1, "mb-2", "voyage-summary-table", 3, "pageable", "data", "sortable", "sort", "selectable", "selection", "selectionChange"], ["showSelectAll", "true", 3, "width"], ["field", "invoiceNumber", 3, "title"], ["field", "recipientName1", 3, "title"], ["field", "customerName1", 3, "title"], ["field", "createdDate", "format", "g", 3, "title"], ["field", "serviceDate", "format", "g", 3, "title"], ["field", "serviceId", 3, "title"], ["field", "description", 3, "title"], ["field", "unitOfMeasurementId", 3, "title"], ["field", "originalPricePerUnit", 3, "title"], ["field", "pricePerUnit", 3, "title"], [3, "panel", "panelChange"], ["icon", "filter"], [4, "ngIf"], [3, "submit"], ["filterHighlighter", ""], [1, "col-6"], ["type", "codelist", "codelist", "Customer", "property", "recipientId", 3, "label", "ngModel", "entity", "ngModelChange"], ["type", "codelist", "codelist", "Service", "property", "serviceId", 3, "label", "ngModel", "entity", "ngModelChange"], ["property", "serviceDateFrom", 3, "label", "time", "ngModel", "entity", "ngModelChange"], ["property", "serviceDateTo", 3, "label", "time", "ngModel", "entity", "ngModelChange"], ["property", "createdDateFrom", 3, "label", "time", "ngModel", "entity", "ngModelChange"], ["property", "createdDateTo", 3, "label", "time", "ngModel", "entity", "ngModelChange"], [1, "row", "mt-3"], [1, "float-right"], [1, "btn", "btn-sm", "btn-danger", "mr-2", 3, "click"], ["icon", "trash", 1, "ml-1"], [1, "btn-text"], [1, "btn", "btn-sm", "btn-primary", 3, "click"], ["icon", "search", 1, "ml-1"]],
  template: function PricePerUnitReportComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelement(0, "app-action-bar", 0)(1, "app-content-header", 1);
      i0.ɵɵpipe(2, "translate");
      i0.ɵɵelementStart(3, "div", 2)(4, "ngb-accordion", 3)(5, "ngb-panel");
      i0.ɵɵtemplate(6, PricePerUnitReportComponent_ng_template_6_Template, 6, 5, "ng-template", 4);
      i0.ɵɵtemplate(7, PricePerUnitReportComponent_ng_template_7_Template, 41, 40, "ng-template", 5);
      i0.ɵɵelementEnd()()();
      i0.ɵɵelementStart(8, "div", 6)(9, "div", 7)(10, "div", 8)(11, "div", 9)(12, "app-grid", 10);
      i0.ɵɵlistener("selectionChange", function PricePerUnitReportComponent_Template_app_grid_selectionChange_12_listener($event) {
        return ctx.selection = $event;
      });
      i0.ɵɵelement(13, "kendo-grid-checkbox-column", 11)(14, "kendo-grid-column", 12);
      i0.ɵɵpipe(15, "translate");
      i0.ɵɵelement(16, "kendo-grid-column", 13);
      i0.ɵɵpipe(17, "translate");
      i0.ɵɵelement(18, "kendo-grid-column", 14);
      i0.ɵɵpipe(19, "translate");
      i0.ɵɵelement(20, "kendo-grid-column", 15);
      i0.ɵɵpipe(21, "translate");
      i0.ɵɵelement(22, "kendo-grid-column", 16);
      i0.ɵɵpipe(23, "translate");
      i0.ɵɵelement(24, "kendo-grid-column", 17);
      i0.ɵɵpipe(25, "translate");
      i0.ɵɵelement(26, "kendo-grid-column", 18);
      i0.ɵɵpipe(27, "translate");
      i0.ɵɵelement(28, "kendo-grid-column", 19);
      i0.ɵɵpipe(29, "translate");
      i0.ɵɵelement(30, "kendo-grid-column", 20);
      i0.ɵɵpipe(31, "translate");
      i0.ɵɵelement(32, "kendo-grid-column", 21);
      i0.ɵɵpipe(33, "translate");
      i0.ɵɵelementEnd()()()()();
    }
    if (rf & 2) {
      i0.ɵɵproperty("items", ctx.actionBar);
      i0.ɵɵadvance(1);
      i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(2, 19, "Price Per Unit Report"));
      i0.ɵɵadvance(11);
      i0.ɵɵproperty("pageable", false)("data", ctx.data)("sortable", true)("sort", ctx.gridSort)("selectable", i0.ɵɵpureFunction0(41, _c0))("selection", ctx.selection);
      i0.ɵɵadvance(1);
      i0.ɵɵproperty("width", 1);
      i0.ɵɵadvance(1);
      i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(15, 21, "Invoice Number"));
      i0.ɵɵadvance(2);
      i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(17, 23, "Recipient"));
      i0.ɵɵadvance(2);
      i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(19, 25, "Customer"));
      i0.ɵɵadvance(2);
      i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(21, 27, "Date Created"));
      i0.ɵɵadvance(2);
      i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(23, 29, "DOS"));
      i0.ɵɵadvance(2);
      i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(25, 31, "Service"));
      i0.ɵɵadvance(2);
      i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(27, 33, "Description"));
      i0.ɵɵadvance(2);
      i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(29, 35, "Unit Of Measurement"));
      i0.ɵɵadvance(2);
      i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(31, 37, "Original Price Per Unit"));
      i0.ɵɵadvance(2);
      i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(33, 39, "Changed Price Per Unit"));
    }
  },
  dependencies: [i3.NgIf, i4.NgControlStatus, i4.NgModel, i5.NgbAccordion, i5.NgbPanel, i5.NgbPanelContent, i5.NgbPanelHeader, i6.FaIconComponent, i7.ColumnComponent, i7.CheckboxColumnComponent, i8.ActionBarComponent, i9.AppControlComponent, i10.AppFormComponent, i11.ContentHeaderComponent, i12.AppGridComponent, i13.AppControlContainerComponent, i14.AccordionHeaderComponent, i15.TranslatePipe],
  styles: ["\n/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IiIsInNvdXJjZVJvb3QiOiIifQ== */"]
});