import { RouterModule } from '@angular/router';
import { CanDeactivateView } from '@common/guards/view.deactivate';
import { ViewMode } from '@common/models/view-mode';
import { CommonModule } from '../../common/common.module';
import { VesselRevisionViewComponent } from './components/vessel-revision-view/vessel-revision-view.component';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
export const VESSEL_REVISION_ROUTES = [{
  path: '',
  redirectTo: 'list',
  pathMatch: 'full'
}, {
  path: 'view/:id',
  component: VesselRevisionViewComponent,
  data: {
    mode: ViewMode.view
  }
}];
export class VesselRevisionModule {}
VesselRevisionModule.ɵfac = function VesselRevisionModule_Factory(t) {
  return new (t || VesselRevisionModule)();
};
VesselRevisionModule.ɵmod = /*@__PURE__*/i0.ɵɵdefineNgModule({
  type: VesselRevisionModule
});
VesselRevisionModule.ɵinj = /*@__PURE__*/i0.ɵɵdefineInjector({
  providers: [CanDeactivateView],
  imports: [CommonModule, RouterModule.forChild(VESSEL_REVISION_ROUTES)]
});
(function () {
  (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(VesselRevisionModule, {
    declarations: [VesselRevisionViewComponent],
    imports: [CommonModule, i1.RouterModule]
  });
})();