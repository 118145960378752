import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { AdministrationPermissions, CodeListPermissions, CustomersPermissions, ExchangeRatePermissions, InvoicePeriodPermissions, InvoicePermissions, PayerRecipientMapsPermissions, PriceListPermissions, VesselPermissions, VesselVisitPermissions, VTSPermissions } from '@common/classes/permissions';
export class MenuItem {}
export const NAVIGATION = [{
  title: _('Dashboard'),
  icon: 'tachometer-alt',
  route: '/home'
}, {
  title: _('Invoicing'),
  icon: 'file-alt',
  permissions: [InvoicePermissions, InvoicePeriodPermissions, InvoicePermissions.Menu.approvalInvoice, InvoicePermissions.Menu.invoicePayer, PayerRecipientMapsPermissions],
  children: [{
    title: _('Create Manual Invoice'),
    icon: 'edit',
    route: '/invoice/create',
    permissions: InvoicePermissions.Action.create
  }, {
    title: _('Invoice List'),
    icon: 'list',
    route: '/invoice/list',
    permissions: InvoicePermissions.Menu.all
  }, {
    title: _('Invoice Period List'),
    icon: 'list',
    route: '/invoice/invoice-period-list',
    permissions: [InvoicePeriodPermissions]
  }]
}, {
  title: _('Vessel Visits'),
  icon: 'ship',
  permissions: [VesselVisitPermissions, VTSPermissions],
  children: [{
    title: _('MSW Vessel Visit List'),
    icon: 'list',
    route: '/msw-vessel-visit/list',
    permissions: [VTSPermissions.View]
  },
  // {
  //     title: _('VTS Vessel Visit List'),
  //     icon: 'list',
  //     route: '/vts-vessel-visit/list',
  //     permissions: [VesselVisitPermissions.View]
  // },
  {
    title: _('Manual Vessel Visit List'),
    icon: 'list',
    route: '/vessel-visit/list',
    permissions: [VesselVisitPermissions.View]
  }, {
    title: _('Create Manual Vessel Visit'),
    icon: 'ship',
    route: '/vessel-visit/create',
    permissions: VesselVisitPermissions.Action.create
  }]
}, {
  title: _('MSW Vessel Permits'),
  icon: 'ship',
  permissions: [VTSPermissions.View],
  children: [{
    title: _('MSW Vessel Permit List'),
    icon: 'list',
    route: '/vessel-permit/list'
  }]
}, {
  title: _('Commercial System'),
  icon: 'calculator',
  permissions: [PriceListPermissions, CustomersPermissions],
  children: [{
    title: _('Customers'),
    icon: 'users',
    route: '/customer/list',
    permissions: [CustomersPermissions.View]
  }, {
    title: _('Price Lists'),
    icon: 'list',
    route: '/price-list/list',
    permissions: [PriceListPermissions.View]
  }]
}, {
  title: _('Code Lists'),
  icon: 'list',
  permissions: [CodeListPermissions.PostOffice.view, CodeListPermissions.Imdg.view, VTSPermissions.View, VesselPermissions.View],
  children: [{
    title: _('Post Offices'),
    icon: 'list',
    route: '/code-list/post-office-list',
    permissions: CodeListPermissions.PostOffice.view
  }, {
    title: _('IMDG'),
    icon: 'exclamation-circle',
    route: '/code-list/imdg',
    permissions: CodeListPermissions.Imdg.view
  }, {
    title: _('Vessels'),
    icon: 'ship',
    route: '/code-list/vessel',
    permissions: [VesselPermissions.View]
  }
  // {
  //     title: _('VTS Vessels'),
  //     icon: 'ship',
  //     route: '/code-list/vts-vessel',
  //     permissions: [VTSPermissions.View]
  // }
  ]
}, {
  title: _('Administration'),
  icon: 'cog',
  permissions: [AdministrationPermissions, ExchangeRatePermissions],
  children: [{
    title: _('Users'),
    icon: 'users',
    route: '/administration/user',
    permissions: [AdministrationPermissions.User]
  }, {
    title: _('Roles'),
    icon: 'user-plus',
    route: '/administration/role',
    permissions: [AdministrationPermissions.Role]
  }, {
    title: _('Organizations'),
    icon: 'building',
    route: '/administration/organization',
    permissions: [AdministrationPermissions.Organization]
  }, {
    title: _('Pdf Templates'),
    icon: 'file',
    route: '/administration/pdf-template',
    permissions: [AdministrationPermissions.Settings]
  }, {
    title: _('Exchange Rates'),
    icon: 'exchange-alt',
    route: '/administration/exchange-rate',
    permissions: [ExchangeRatePermissions]
  }]
}, {
  title: _('Help'),
  icon: 'info-circle',
  children: [{
    title: _('User Manual'),
    icon: 'question',
    route: '/help/user-manual'
  }]
}];