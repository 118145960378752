import { EntityManager, EntityQuery } from '@cime/breeze-client';
import { PriceListTypeCodes } from '@common/classes/codes';
import { BreezeViewService } from '@common/services/breeze-view.service';
import { isBefore, isWithinInterval } from 'date-fns';
import * as _ from 'lodash';
import * as i0 from "@angular/core";
import * as i1 from "@cime/breeze-client";
import * as i2 from "@common/services/breeze-view.service";
export class PriceListService {
  constructor(entityManager, breezeViewService) {
    this.entityManager = entityManager;
    this.breezeViewService = breezeViewService;
    this.isSegmentValid = x => this.showAllSegments || !_.isDate(x.validFrom) || !_.isDate(x.validTo) || !isBefore(x.validFrom, x.validTo) || isWithinInterval(new Date(), {
      start: x.validFrom,
      end: x.validTo
    });
    this.onRowDrop = (context, items) => {
      const newItems = _.filter(items, x => context.draggedRow !== x);
      newItems.splice(context.dropIndex, 0, context.draggedRow);
      this.resetSequenceNumbers(newItems);
    };
    this.fetchUns = imdgId => {
      return imdgId ? this.breezeViewService.handleQuery('Uns', {
        imdgId: imdgId
      }).then(uns => uns.map(x => ({
        label: x.id,
        value: x.id
      }))) : Promise.resolve([]);
    };
  }
  getEventTypes() {
    if (!this.eventTypesPromise) {
      const query = EntityQuery.from('CodeList').withParameters({
        name: 'EventType'
      });
      this.eventTypesPromise = this.entityManager.executeQuery(query).then(response => response.results);
    }
    return this.eventTypesPromise;
  }
  getContainerTypes() {
    if (!this.containerTypesPromise) {
      const query = EntityQuery.from('CodeList').withParameters({
        name: 'ContainerType'
      });
      this.containerTypesPromise = this.entityManager.executeQuery(query).then(response => response.results);
    }
    return this.containerTypesPromise;
  }
  getManipulations() {
    return _.chain(this.priceList.manipulations).filter(this.isSegmentValid).orderBy(x => x.sequenceNumber).clone().value();
  }
  getAdditionalWork() {
    return _.chain(this.priceList.priceListAdditionalWorkSegments).filter(this.isSegmentValid).value();
  }
  getPayers() {
    const orderedItems = _.orderBy(this.priceList.payers, x => x.sequenceNumber);
    return this.isTariff() ? orderedItems : _.filter(orderedItems, x => !x.dynamic);
  }
  isTariff() {
    return this.priceList.priceListTypeId === PriceListTypeCodes.Tariff;
  }
  getStorages() {
    return _.chain(this.priceList.storage).filter(this.isSegmentValid).orderBy(x => x.sequenceNumber).value();
  }
  getStorageSegments(storage) {
    return _.chain(storage.segments).orderBy(x => x.sequenceNumber).value();
  }
  getManipulationEvents(manipulation) {
    return _.orderBy(manipulation.events, x => x.sequenceNumber);
  }
  getServices(event) {
    return _.orderBy(event.services, x => x.sequenceNumber);
  }
  getNextSequenceNumber(items) {
    if (!items.length) {
      return 1;
    }
    const maxSequenceNumber = _.max(items.map(x => x.sequenceNumber));
    return maxSequenceNumber + 1;
  }
  resetSequenceNumbers(items) {
    _.each(items, (x, i) => {
      x.sequenceNumber = i + 1;
    });
  }
  resetUns(uns) {
    setTimeout(() => {
      _.each(_.clone(uns), un => {
        un.entityAspect.setDeleted();
      });
    }, 200);
  }
  getHolidays() {
    return _.chain(this.priceList.holidays).filter(this.isSegmentValid).value();
  }
  getOvertimeSegments() {
    return _.chain(this.priceList.overtimeSegments).filter(this.isSegmentValid).value();
  }
  getImdgs() {
    return _.chain(this.priceList.imdgs).filter(this.isSegmentValid).value();
  }
  getOutOfGauges() {
    return _.chain(this.priceList.outOfGauges).filter(this.isSegmentValid).value();
  }
  getPortDues() {
    return _.chain(this.priceList.portDues).filter(this.isSegmentValid).value();
  }
  getIsps() {
    return _.chain(this.priceList.isps).filter(this.isSegmentValid).value();
  }
}
PriceListService.ɵfac = function PriceListService_Factory(t) {
  return new (t || PriceListService)(i0.ɵɵinject(i1.EntityManager), i0.ɵɵinject(i2.BreezeViewService));
};
PriceListService.ɵprov = /*@__PURE__*/i0.ɵɵdefineInjectable({
  token: PriceListService,
  factory: PriceListService.ɵfac
});