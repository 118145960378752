import { AbstractBreezeViewComponent } from '@common/classes/breeze-view';
import { VesselPermissions } from '@common/classes/permissions';
import { ViewMode } from '@common/models/view-mode';
import { BreezeViewService } from '@common/services/breeze-view.service';
import * as i0 from "@angular/core";
import * as i1 from "@common/services/breeze-view.service";
import * as i2 from "@angular/router";
import * as i3 from "@angular/forms";
import * as i4 from "@progress/kendo-angular-grid";
import * as i5 from "../../../../common/components/action-bar/action-bar.component";
import * as i6 from "../../../../common/components/app-control/app-control.component";
import * as i7 from "../../../../common/components/loader/loader.component";
import * as i8 from "../../../../common/components/content-header/content-header.component";
import * as i9 from "../../../../common/components/app-grid/app-grid.component";
import * as i10 from "@angular/common";
import * as i11 from "@ngx-translate/core";
import * as i12 from "../../../../common/pipes/codelist.pipe";
const _c0 = function (a2) {
  return ["/vessel-revision", "view", a2];
};
function VesselViewComponent_ng_template_2_ng_template_61_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "a", 31);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const row_r4 = ctx.$implicit;
    i0.ɵɵproperty("routerLink", i0.ɵɵpureFunction1(2, _c0, row_r4.id));
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate1(" ", row_r4.id, " ");
  }
}
function VesselViewComponent_ng_template_2_ng_template_64_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtext(0);
    i0.ɵɵpipe(1, "async");
    i0.ɵɵpipe(2, "codelist");
  }
  if (rf & 2) {
    const row_r5 = ctx.$implicit;
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind1(1, 1, i0.ɵɵpipeBind2(2, 3, row_r5.createdById, "User")), " ");
  }
}
function VesselViewComponent_ng_template_2_ng_template_69_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtext(0);
    i0.ɵɵpipe(1, "async");
    i0.ɵɵpipe(2, "codelist");
  }
  if (rf & 2) {
    const row_r6 = ctx.$implicit;
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind1(1, 1, i0.ɵɵpipeBind2(2, 3, row_r6.sourceId, "SourceSystem")), " ");
  }
}
const _c1 = function () {
  return {
    field: "createdDate",
    dir: "desc"
  };
};
const _c2 = function (a0) {
  return [a0];
};
function VesselViewComponent_ng_template_2_Template(rf, ctx) {
  if (rf & 1) {
    const _r8 = i0.ɵɵgetCurrentView();
    i0.ɵɵelement(0, "app-action-bar", 2)(1, "app-content-header", 3);
    i0.ɵɵelementStart(2, "div", 4)(3, "div", 5)(4, "div", 6)(5, "div", 7)(6, "app-control", 8);
    i0.ɵɵlistener("ngModelChange", function VesselViewComponent_ng_template_2_Template_app_control_ngModelChange_6_listener($event) {
      i0.ɵɵrestoreView(_r8);
      const ctx_r7 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r7.model.iMONumber = $event);
    });
    i0.ɵɵpipe(7, "translate");
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(8, "div", 7)(9, "app-control", 9);
    i0.ɵɵlistener("ngModelChange", function VesselViewComponent_ng_template_2_Template_app_control_ngModelChange_9_listener($event) {
      i0.ɵɵrestoreView(_r8);
      const ctx_r9 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r9.model.mmsi = $event);
    });
    i0.ɵɵpipe(10, "translate");
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(11, "div", 7)(12, "app-control", 10);
    i0.ɵɵlistener("ngModelChange", function VesselViewComponent_ng_template_2_Template_app_control_ngModelChange_12_listener($event) {
      i0.ɵɵrestoreView(_r8);
      const ctx_r10 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r10.model.callSign = $event);
    });
    i0.ɵɵpipe(13, "translate");
    i0.ɵɵelementEnd()()();
    i0.ɵɵelementStart(14, "div", 11)(15, "div", 7)(16, "app-control", 12);
    i0.ɵɵlistener("ngModelChange", function VesselViewComponent_ng_template_2_Template_app_control_ngModelChange_16_listener($event) {
      i0.ɵɵrestoreView(_r8);
      const ctx_r11 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r11.model.name = $event);
    });
    i0.ɵɵpipe(17, "translate");
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(18, "div", 7)(19, "app-control", 13);
    i0.ɵɵlistener("ngModelChange", function VesselViewComponent_ng_template_2_Template_app_control_ngModelChange_19_listener($event) {
      i0.ɵɵrestoreView(_r8);
      const ctx_r12 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r12.model.typeId = $event);
    });
    i0.ɵɵpipe(20, "translate");
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(21, "div", 7)(22, "app-control", 14);
    i0.ɵɵlistener("ngModelChange", function VesselViewComponent_ng_template_2_Template_app_control_ngModelChange_22_listener($event) {
      i0.ɵɵrestoreView(_r8);
      const ctx_r13 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r13.model.countryId = $event);
    });
    i0.ɵɵpipe(23, "translate");
    i0.ɵɵelementEnd()()();
    i0.ɵɵelementStart(24, "div", 11)(25, "div", 7)(26, "app-control", 15);
    i0.ɵɵlistener("ngModelChange", function VesselViewComponent_ng_template_2_Template_app_control_ngModelChange_26_listener($event) {
      i0.ɵɵrestoreView(_r8);
      const ctx_r14 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r14.model.grossTonnage = $event);
    });
    i0.ɵɵpipe(27, "translate");
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(28, "div", 7)(29, "app-control", 16);
    i0.ɵɵlistener("ngModelChange", function VesselViewComponent_ng_template_2_Template_app_control_ngModelChange_29_listener($event) {
      i0.ɵɵrestoreView(_r8);
      const ctx_r15 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r15.model.netTonnage = $event);
    });
    i0.ɵɵpipe(30, "translate");
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(31, "div", 7)(32, "app-control", 17);
    i0.ɵɵlistener("ngModelChange", function VesselViewComponent_ng_template_2_Template_app_control_ngModelChange_32_listener($event) {
      i0.ɵɵrestoreView(_r8);
      const ctx_r16 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r16.model.deadWeight = $event);
    });
    i0.ɵɵpipe(33, "translate");
    i0.ɵɵelementEnd()()();
    i0.ɵɵelementStart(34, "div", 11)(35, "div", 7)(36, "app-control", 18);
    i0.ɵɵlistener("ngModelChange", function VesselViewComponent_ng_template_2_Template_app_control_ngModelChange_36_listener($event) {
      i0.ɵɵrestoreView(_r8);
      const ctx_r17 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r17.model.breadthExtreme = $event);
    });
    i0.ɵɵpipe(37, "translate");
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(38, "div", 7)(39, "app-control", 19);
    i0.ɵɵlistener("ngModelChange", function VesselViewComponent_ng_template_2_Template_app_control_ngModelChange_39_listener($event) {
      i0.ɵɵrestoreView(_r8);
      const ctx_r18 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r18.model.breadthMoulded = $event);
    });
    i0.ɵɵpipe(40, "translate");
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(41, "div", 7)(42, "app-control", 20);
    i0.ɵɵlistener("ngModelChange", function VesselViewComponent_ng_template_2_Template_app_control_ngModelChange_42_listener($event) {
      i0.ɵɵrestoreView(_r8);
      const ctx_r19 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r19.model.draft = $event);
    });
    i0.ɵɵpipe(43, "translate");
    i0.ɵɵelementEnd()()();
    i0.ɵɵelementStart(44, "div", 11)(45, "div", 7)(46, "app-control", 21);
    i0.ɵɵlistener("ngModelChange", function VesselViewComponent_ng_template_2_Template_app_control_ngModelChange_46_listener($event) {
      i0.ɵɵrestoreView(_r8);
      const ctx_r20 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r20.model.length = $event);
    });
    i0.ɵɵpipe(47, "translate");
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(48, "div", 7)(49, "app-control", 22);
    i0.ɵɵlistener("ngModelChange", function VesselViewComponent_ng_template_2_Template_app_control_ngModelChange_49_listener($event) {
      i0.ɵɵrestoreView(_r8);
      const ctx_r21 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r21.model.statusId = $event);
    });
    i0.ɵɵpipe(50, "translate");
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(51, "div", 7)(52, "app-control", 23);
    i0.ɵɵlistener("ngModelChange", function VesselViewComponent_ng_template_2_Template_app_control_ngModelChange_52_listener($event) {
      i0.ɵɵrestoreView(_r8);
      const ctx_r22 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r22.model.wasteExemption = $event);
    });
    i0.ɵɵpipe(53, "translate");
    i0.ɵɵelementEnd()()()()();
    i0.ɵɵelementStart(54, "div", 24)(55, "div", 5)(56, "h5");
    i0.ɵɵtext(57, "Vessel history");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(58, "app-grid", 25)(59, "kendo-grid-column", 26);
    i0.ɵɵpipe(60, "translate");
    i0.ɵɵtemplate(61, VesselViewComponent_ng_template_2_ng_template_61_Template, 2, 4, "ng-template", 27);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(62, "kendo-grid-column", 28);
    i0.ɵɵpipe(63, "translate");
    i0.ɵɵtemplate(64, VesselViewComponent_ng_template_2_ng_template_64_Template, 3, 6, "ng-template", 27);
    i0.ɵɵelementEnd();
    i0.ɵɵelement(65, "kendo-grid-column", 29);
    i0.ɵɵpipe(66, "translate");
    i0.ɵɵelementStart(67, "kendo-grid-column", 30);
    i0.ɵɵpipe(68, "translate");
    i0.ɵɵtemplate(69, VesselViewComponent_ng_template_2_ng_template_69_Template, 3, 6, "ng-template", 27);
    i0.ɵɵelementEnd()()()();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("items", ctx_r0.actionBar);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("title", ctx_r0.title)("breadcrumb", ctx_r0.breadcrumb);
    i0.ɵɵadvance(5);
    i0.ɵɵproperty("label", i0.ɵɵpipeBind1(7, 58, "IMO Number"))("min", 0)("disabled", true)("ngModel", ctx_r0.model.iMONumber)("entity", ctx_r0.model);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("label", i0.ɵɵpipeBind1(10, 60, "MMSI"))("maxlength", 9)("ngModel", ctx_r0.model.mmsi)("entity", ctx_r0.model);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("label", i0.ɵɵpipeBind1(13, 62, "Call Sign"))("maxlength", 7)("ngModel", ctx_r0.model.callSign)("entity", ctx_r0.model);
    i0.ɵɵadvance(4);
    i0.ɵɵproperty("label", i0.ɵɵpipeBind1(17, 64, "Vessel Name"))("ngModel", ctx_r0.model.name)("entity", ctx_r0.model);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("label", i0.ɵɵpipeBind1(20, 66, "Vessel Type"))("ngModel", ctx_r0.model.typeId)("entity", ctx_r0.model);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("label", i0.ɵɵpipeBind1(23, 68, "Flag"))("ngModel", ctx_r0.model.countryId)("entity", ctx_r0.model);
    i0.ɵɵadvance(4);
    i0.ɵɵproperty("label", i0.ɵɵpipeBind1(27, 70, "Gross Tonnage"))("ngModel", ctx_r0.model.grossTonnage)("entity", ctx_r0.model);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("label", i0.ɵɵpipeBind1(30, 72, "Net Tonnage"))("ngModel", ctx_r0.model.netTonnage)("entity", ctx_r0.model);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("label", i0.ɵɵpipeBind1(33, 74, "Deadweight Tonnage"))("ngModel", ctx_r0.model.deadWeight)("entity", ctx_r0.model);
    i0.ɵɵadvance(4);
    i0.ɵɵproperty("label", i0.ɵɵpipeBind1(37, 76, "Breadth Extreme"))("ngModel", ctx_r0.model.breadthExtreme)("entity", ctx_r0.model);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("label", i0.ɵɵpipeBind1(40, 78, "Breadth Moulded"))("ngModel", ctx_r0.model.breadthMoulded)("entity", ctx_r0.model);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("label", i0.ɵɵpipeBind1(43, 80, "Draft"))("ngModel", ctx_r0.model.draft)("entity", ctx_r0.model);
    i0.ɵɵadvance(4);
    i0.ɵɵproperty("label", i0.ɵɵpipeBind1(47, 82, "Length"))("ngModel", ctx_r0.model.length)("entity", ctx_r0.model);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("label", i0.ɵɵpipeBind1(50, 84, "Status"))("ngModel", ctx_r0.model.statusId)("entity", ctx_r0.model);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("label", i0.ɵɵpipeBind1(53, 86, "Waste Exemption"))("ngModel", ctx_r0.model.wasteExemption)("entity", ctx_r0.model);
    i0.ɵɵadvance(6);
    i0.ɵɵproperty("data", ctx_r0.model.revisions)("sort", i0.ɵɵpureFunction1(97, _c2, i0.ɵɵpureFunction0(96, _c1)));
    i0.ɵɵadvance(1);
    i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(60, 88, "Id"));
    i0.ɵɵadvance(3);
    i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(63, 90, "Created By"));
    i0.ɵɵadvance(3);
    i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(66, 92, "Created Date"));
    i0.ɵɵadvance(2);
    i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(68, 94, "Source"));
  }
}
export class VesselViewComponent extends AbstractBreezeViewComponent {
  constructor(breezeViewService) {
    super(breezeViewService);
    this.entityName = 'Vessel';
    this.parentRoute = '/code-list/vessel';
    this.createPermission = VesselPermissions.Action.create;
    this.editPermission = VesselPermissions.Action.edit;
    this.breadcrumb = [{
      icon: 'ship',
      title: this.translateService.get('Vessels'),
      route: '/code-list/vessel/list'
    }, {
      icon: 'ship',
      title: this.translateService.get('Vessel')
    }];
  }
  get title() {
    return !this.createMode ? `${this.model.name} ${this.model.iMONumber ? `(${this.model.iMONumber})` : ''}` : super.title;
  }
  canEdit() {
    return this.model.manual;
  }
  afterSave() {
    if (this.model.id !== null && this.mode !== ViewMode.view) {
      return this.router.navigate([`${this.parentRoute}/view/${this.model.id}`]);
    }
  }
}
VesselViewComponent.ɵfac = function VesselViewComponent_Factory(t) {
  return new (t || VesselViewComponent)(i0.ɵɵdirectiveInject(i1.BreezeViewService));
};
VesselViewComponent.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
  type: VesselViewComponent,
  selectors: [["app-vessel-view"]],
  features: [i0.ɵɵProvidersFeature([BreezeViewService]), i0.ɵɵInheritDefinitionFeature],
  decls: 3,
  vars: 1,
  consts: [[3, "isBusy"], [1, "row", "separator"], [3, "items"], [3, "title", "breadcrumb"], [1, "card", "card-border-top"], [1, "card-body"], [1, "row"], [1, "col-4"], ["format", "#", "property", "iMONumber", 3, "label", "min", "disabled", "ngModel", "entity", "ngModelChange"], ["format", "'#'", "property", "mmsi", 3, "label", "maxlength", "ngModel", "entity", "ngModelChange"], ["property", "callSign", 3, "label", "maxlength", "ngModel", "entity", "ngModelChange"], [1, "row", "mt-3"], ["pattern", "[\\w\\d\\s\\.\\-\\']", "property", "name", 3, "label", "ngModel", "entity", "ngModelChange"], ["type", "codelist", "codelist", "VesselType", "property", "typeId", 3, "label", "ngModel", "entity", "ngModelChange"], ["type", "codelist", "codelist", "Country", "property", "countryId", 3, "label", "ngModel", "entity", "ngModelChange"], ["format", "n3", "property", "grossTonnage", 3, "label", "ngModel", "entity", "ngModelChange"], ["format", "n3", "property", "netTonnage", 3, "label", "ngModel", "entity", "ngModelChange"], ["format", "n3", "property", "deadWeight", 3, "label", "ngModel", "entity", "ngModelChange"], ["property", "breadthExtreme", 3, "label", "ngModel", "entity", "ngModelChange"], ["property", "breadthMoulded", 3, "label", "ngModel", "entity", "ngModelChange"], ["property", "draft", 3, "label", "ngModel", "entity", "ngModelChange"], ["property", "length", 3, "label", "ngModel", "entity", "ngModelChange"], ["type", "codelist", "property", "statusId", 3, "label", "ngModel", "entity", "ngModelChange"], ["type", "boolean", "property", "wasteExemption", 3, "label", "ngModel", "entity", "ngModelChange"], [1, "card", "card-border-top", "mt-3"], [3, "data", "sort"], ["field", "id", 3, "title"], ["kendoGridCellTemplate", ""], ["field", "createdById", 3, "title"], ["field", "createdDate", "format", "g", 3, "title"], ["field", "sourceId", 3, "title"], [1, "text-primary", "link-box", 3, "routerLink"]],
  template: function VesselViewComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "app-loader", 0);
      i0.ɵɵelement(1, "div", 1);
      i0.ɵɵtemplate(2, VesselViewComponent_ng_template_2_Template, 70, 99, "ng-template");
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      i0.ɵɵproperty("isBusy", !ctx.model);
    }
  },
  dependencies: [i2.RouterLink, i3.NgControlStatus, i3.MaxLengthValidator, i3.PatternValidator, i3.NgModel, i4.ColumnComponent, i4.CellTemplateDirective, i5.ActionBarComponent, i6.AppControlComponent, i7.LoaderComponent, i8.ContentHeaderComponent, i9.AppGridComponent, i10.AsyncPipe, i11.TranslatePipe, i12.CodelistPipe],
  styles: ["\n/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IiIsInNvdXJjZVJvb3QiOiIifQ== */"]
});