import * as i0 from "@angular/core";
export class ActivePipe {
  transform(value, ...args) {
    const filtered = value?.filter(x => x.active === undefined || x.active === true);
    return value?.length === filtered?.length ? value : filtered;
  }
}
ActivePipe.ɵfac = function ActivePipe_Factory(t) {
  return new (t || ActivePipe)();
};
ActivePipe.ɵpipe = /*@__PURE__*/i0.ɵɵdefinePipe({
  name: "active",
  type: ActivePipe,
  pure: false
});