import { InvoiceStatusCodes } from '@common/classes/codes';
import { BreezeViewService } from '@common/services/breeze-view.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import * as i0 from "@angular/core";
import * as i1 from "@ng-bootstrap/ng-bootstrap";
import * as i2 from "ngx-toastr";
import * as i3 from "@common/services/breeze-view.service";
import * as i4 from "@ngx-translate/core";
import * as i5 from "@progress/kendo-angular-grid";
import * as i6 from "@progress/kendo-angular-buttons";
import * as i7 from "../../../common/components/app-grid/app-grid.component";
import * as i8 from "../../../common/pipes/pad.pipe";
function AddExistingInvoiceModalComponent_ng_template_14_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "a", 16);
    i0.ɵɵtext(1);
    i0.ɵɵpipe(2, "pad");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const dataItem_r3 = ctx.$implicit;
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind1(2, 1, dataItem_r3.internalInvoiceNumber), " ");
  }
}
function AddExistingInvoiceModalComponent_ng_template_17_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtext(0);
  }
  if (rf & 2) {
    const dataItem_r4 = ctx.$implicit;
    i0.ɵɵtextInterpolate1(" ", dataItem_r4.statusId, " ");
  }
}
function AddExistingInvoiceModalComponent_ng_template_20_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtext(0);
  }
  if (rf & 2) {
    const dataItem_r5 = ctx.$implicit;
    i0.ɵɵtextInterpolate1(" ", dataItem_r5.payer.name1, " ");
  }
}
const _c0 = function () {
  return {
    enabled: true,
    checkboxOnly: true
  };
};
export class AddExistingInvoiceModalComponent {
  constructor(activeModal, toastrService, breezeViewService) {
    this.activeModal = activeModal;
    this.toastrService = toastrService;
    this.breezeViewService = breezeViewService;
    this.statusIds = [InvoiceStatusCodes.Draft
    //InvoiceStatusCodes.Issued,
    //InvoiceStatusCodes.WaitingForApproval
    ];

    this.selection = [];
    this.singleSelection = false;
    this.listUnlinked = true;
  }
  ngOnInit() {
    this.fetchInvoices();
  }
  fetchInvoices() {
    return this.breezeViewService.handleQuery('InvoicesByStatus', {
      statusIds: this.statusIds,
      listUnlinked: this.listUnlinked,
      vesselVisitId: this.vesselVisitId,
      vtsVesselVisitId: this.vtsVesselVisitId
    }).then(invoices => {
      this.invoices = invoices;
    });
  }
  confirm() {
    if (this.selection.length === 0) return this.toastrService.error('Invoice selection is empty');
    if (this.singleSelection) {
      if (this.invoices.find(invoice => invoice.id === this.selection[0]).statusId !== InvoiceStatusCodes.Draft) return this.toastrService.error('Selected Invoice must be a draft');
      return this.close(this.selection[0]);
    }
    this.breezeViewService.handleCommand(this.vesselVisitId ? 'invoices/addToVisit' : 'invoices/addToVtsVisit', {
      vesselVisitId: this.vesselVisitId,
      vtsVesselVisitId: this.vtsVesselVisitId,
      ids: this.selection
    }).finally(() => this.close());
  }
  close(result = false) {
    this.activeModal.close(result);
  }
  onSelectionChange(event) {
    if (this.singleSelection && this.selection.length > 1) this.selection = this.selection.splice(1);
  }
}
AddExistingInvoiceModalComponent.ɵfac = function AddExistingInvoiceModalComponent_Factory(t) {
  return new (t || AddExistingInvoiceModalComponent)(i0.ɵɵdirectiveInject(i1.NgbActiveModal), i0.ɵɵdirectiveInject(i2.ToastrService), i0.ɵɵdirectiveInject(i3.BreezeViewService));
};
AddExistingInvoiceModalComponent.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
  type: AddExistingInvoiceModalComponent,
  selectors: [["app-add-existing-invoice-modal"]],
  features: [i0.ɵɵProvidersFeature([BreezeViewService])],
  decls: 26,
  vars: 17,
  consts: [[1, "modal-header"], [1, "modal-title"], ["type", "button", "aria-label", "Close", 1, "close", 3, "click"], ["aria-hidden", "true"], [1, "modal-body"], [1, "row"], [1, "col-12"], [1, "mb-2", 3, "data", "selectable", "selection", "selectionChange"], ["width", "42px", 3, "showSelectAll"], ["field", "internalInvoiceNumber", 3, "title"], ["kendoGridCellTemplate", ""], ["field", "statusId", 3, "title"], ["field", "payerId", 3, "title"], [1, "modal-footer"], ["kendoButton", "", "translate", "", 3, "click"], ["kendoButton", "", "primary", "true", "translate", "", 3, "click"], [1, "text-primary", "link-box", 2, "cursor", "pointer"]],
  template: function AddExistingInvoiceModalComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "div", 0)(1, "h5", 1);
      i0.ɵɵtext(2);
      i0.ɵɵpipe(3, "translate");
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(4, "button", 2);
      i0.ɵɵlistener("click", function AddExistingInvoiceModalComponent_Template_button_click_4_listener() {
        return ctx.close();
      });
      i0.ɵɵelementStart(5, "span", 3);
      i0.ɵɵtext(6, "\u00D7");
      i0.ɵɵelementEnd()()();
      i0.ɵɵelementStart(7, "div", 4)(8, "div", 5)(9, "div", 6)(10, "app-grid", 7);
      i0.ɵɵlistener("selectionChange", function AddExistingInvoiceModalComponent_Template_app_grid_selectionChange_10_listener($event) {
        return ctx.selection = $event;
      })("selectionChange", function AddExistingInvoiceModalComponent_Template_app_grid_selectionChange_10_listener($event) {
        return ctx.onSelectionChange($event);
      });
      i0.ɵɵelement(11, "kendo-grid-checkbox-column", 8);
      i0.ɵɵelementStart(12, "kendo-grid-column", 9);
      i0.ɵɵpipe(13, "translate");
      i0.ɵɵtemplate(14, AddExistingInvoiceModalComponent_ng_template_14_Template, 3, 3, "ng-template", 10);
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(15, "kendo-grid-column", 11);
      i0.ɵɵpipe(16, "translate");
      i0.ɵɵtemplate(17, AddExistingInvoiceModalComponent_ng_template_17_Template, 1, 1, "ng-template", 10);
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(18, "kendo-grid-column", 12);
      i0.ɵɵpipe(19, "translate");
      i0.ɵɵtemplate(20, AddExistingInvoiceModalComponent_ng_template_20_Template, 1, 1, "ng-template", 10);
      i0.ɵɵelementEnd()()()()();
      i0.ɵɵelementStart(21, "div", 13)(22, "button", 14);
      i0.ɵɵlistener("click", function AddExistingInvoiceModalComponent_Template_button_click_22_listener() {
        return ctx.close();
      });
      i0.ɵɵtext(23, "Cancel");
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(24, "button", 15);
      i0.ɵɵlistener("click", function AddExistingInvoiceModalComponent_Template_button_click_24_listener() {
        return ctx.confirm();
      });
      i0.ɵɵtext(25, "Add");
      i0.ɵɵelementEnd()();
    }
    if (rf & 2) {
      i0.ɵɵadvance(2);
      i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(3, 8, "Invoices"));
      i0.ɵɵadvance(8);
      i0.ɵɵproperty("data", ctx.invoices)("selectable", i0.ɵɵpureFunction0(16, _c0))("selection", ctx.selection);
      i0.ɵɵadvance(1);
      i0.ɵɵproperty("showSelectAll", !ctx.singleSelection);
      i0.ɵɵadvance(1);
      i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(13, 10, "Internal Invoice Number"));
      i0.ɵɵadvance(3);
      i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(16, 12, "Invoice Status"));
      i0.ɵɵadvance(3);
      i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(19, 14, "Payer"));
    }
  },
  dependencies: [i4.TranslateDirective, i5.ColumnComponent, i5.CheckboxColumnComponent, i5.CellTemplateDirective, i6.Button, i7.AppGridComponent, i4.TranslatePipe, i8.PadPipe],
  styles: ["\n/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IiIsInNvdXJjZVJvb3QiOiIifQ== */"]
});