import { RouterModule } from '@angular/router';
import { CommonModule } from '@common/common.module';
import { NmswVesselVisitViewComponent } from '@common/components/nmsw-vessel-visit-view/nmsw-vessel-visit-view.component';
import { ViewMode } from '@common/models/view-mode';
import { VtsVesselVisitListComponent } from './components/vts-vessel-visit-list/vts-vessel-visit-list.component';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
const VTS_VESSEL_VISIT_ROUTES = [{
  path: '',
  redirectTo: 'list',
  pathMatch: 'full'
}, {
  path: 'list',
  component: VtsVesselVisitListComponent
}, {
  path: 'view/:id',
  component: NmswVesselVisitViewComponent,
  data: {
    mode: ViewMode.view
  }
}, {
  path: 'edit/:id',
  component: NmswVesselVisitViewComponent,
  data: {
    mode: ViewMode.edit
  }
}, {
  path: 'create',
  component: NmswVesselVisitViewComponent,
  data: {
    mode: ViewMode.create
  }
}, {
  path: '**',
  redirectTo: 'list'
}];
export class VtsVesselVisitModule {}
VtsVesselVisitModule.ɵfac = function VtsVesselVisitModule_Factory(t) {
  return new (t || VtsVesselVisitModule)();
};
VtsVesselVisitModule.ɵmod = /*@__PURE__*/i0.ɵɵdefineNgModule({
  type: VtsVesselVisitModule
});
VtsVesselVisitModule.ɵinj = /*@__PURE__*/i0.ɵɵdefineInjector({
  imports: [CommonModule, RouterModule.forChild(VTS_VESSEL_VISIT_ROUTES)]
});
(function () {
  (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(VtsVesselVisitModule, {
    declarations: [VtsVesselVisitListComponent],
    imports: [CommonModule, i1.RouterModule]
  });
})();