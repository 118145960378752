import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import * as i0 from "@angular/core";
import * as i1 from "@ng-bootstrap/ng-bootstrap";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
function ErrorDialogComponent_div_9_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div")(1, "div", 10)(2, "div", 11);
    i0.ɵɵtext(3, "StackTrace");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(4, "div", 12);
    i0.ɵɵelement(5, "pre", 13);
    i0.ɵɵelementEnd()()();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(5);
    i0.ɵɵproperty("innerHTML", ctx_r0.stackTrace, i0.ɵɵsanitizeHtml);
  }
}
export class ErrorDialogComponent {
  constructor(activeModal, translateService) {
    this.activeModal = activeModal;
    this.translateService = translateService;
  }
  get title() {
    return this.isServerSideError ? this.translateService.instant('Server side error occurred') : this.translateService.instant('Client side error occurred');
  }
  hasStackTrace() {
    return !!this.stackTrace;
  }
  setMessage(message) {
    if (!message || !_.isString(message)) {
      console.error(message);
      message = this.translateService.instant('Check the console for error details');
    }
    const lines = message.indexOf('\r\n') < 0 ? message.split('\n') : message.split('\r\n');
    let html = '<span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>';
    html += `<span> ${lines[0]}</span>`;
    // Client side error message contains also the stacktrace which we do not want to display
    if (lines.length === 1 || !this.isServerSideError) {
      this.message = html;
      return;
    }
    html += '<br />';
    for (let i = 1; i < lines.length; i++) {
      html += `<span>${lines[i]}</span><br />`;
    }
    this.message = html;
  }
  setStackTrace(stackTrace) {
    if (stackTrace && !_.isString(stackTrace)) {
      console.error(stackTrace);
      stackTrace = stackTrace.toString();
    }
    this.stackTrace = stackTrace;
  }
}
ErrorDialogComponent.ɵfac = function ErrorDialogComponent_Factory(t) {
  return new (t || ErrorDialogComponent)(i0.ɵɵdirectiveInject(i1.NgbActiveModal), i0.ɵɵdirectiveInject(i2.TranslateService));
};
ErrorDialogComponent.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
  type: ErrorDialogComponent,
  selectors: [["ng-component"]],
  decls: 13,
  vars: 3,
  consts: [[1, "modal-header"], [1, "modal-title"], ["type", "button", "aria-label", "Close", 1, "close", 3, "click"], ["aria-hidden", "true"], [1, "modal-body"], ["role", "alert", 1, "alert", "alert-danger"], [3, "innerHTML"], [4, "ngIf"], [1, "modal-footer"], ["translate", "", 1, "btn", "btn-primary", 3, "click"], [1, "panel", "panel-danger"], ["translate", "", 1, "panel-heading"], [1, "panel-body"], [2, "color", "#c7254e", "max-height", "500px", "overflow-y", "scroll", 3, "innerHTML"]],
  template: function ErrorDialogComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "div", 0)(1, "h5", 1);
      i0.ɵɵtext(2);
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(3, "button", 2);
      i0.ɵɵlistener("click", function ErrorDialogComponent_Template_button_click_3_listener() {
        return ctx.activeModal.dismiss();
      });
      i0.ɵɵelementStart(4, "span", 3);
      i0.ɵɵtext(5, "\u00D7");
      i0.ɵɵelementEnd()()();
      i0.ɵɵelementStart(6, "div", 4)(7, "div", 5);
      i0.ɵɵelement(8, "div", 6);
      i0.ɵɵelementEnd();
      i0.ɵɵtemplate(9, ErrorDialogComponent_div_9_Template, 6, 1, "div", 7);
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(10, "div", 8)(11, "button", 9);
      i0.ɵɵlistener("click", function ErrorDialogComponent_Template_button_click_11_listener() {
        return ctx.activeModal.close(true);
      });
      i0.ɵɵtext(12, "Ok");
      i0.ɵɵelementEnd()();
    }
    if (rf & 2) {
      i0.ɵɵadvance(2);
      i0.ɵɵtextInterpolate(ctx.title);
      i0.ɵɵadvance(6);
      i0.ɵɵproperty("innerHTML", ctx.message, i0.ɵɵsanitizeHtml);
      i0.ɵɵadvance(1);
      i0.ɵɵproperty("ngIf", ctx.hasStackTrace());
    }
  },
  dependencies: [i3.NgIf, i2.TranslateDirective],
  encapsulation: 2
});