import * as _ from 'lodash';
import { environment as base } from './environment.base';
const baseEnv = _.cloneDeep(base);
export const environment = _.merge(baseEnv, {
  production: false,
  environment: 'development',
  serverUrl: `${window.location.protocol}//${window.location.hostname}/be`,
  apiUrl: `${window.location.protocol}//${window.location.hostname}/be/api`,
  title: 'Billing Malta Development',
  settings: {
    header: {
      logo: '/assets/img/malta-logo.svg',
      title: 'Billing',
      welcomeMessage: 'Welcome to Billing',
      environmentColor: 'color: #2cda43'
    },
    nmswPermitViewUrl: 'https://nmsw-malta.portline.eu/vessel-permit/view/'
  }
});