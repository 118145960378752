import { AbstractBreezeViewComponent } from '@common/classes/breeze-view';
import { CodeListPermissions } from '@common/classes/permissions';
import { BreezeViewService } from '@common/services/breeze-view.service';
import * as i0 from "@angular/core";
import * as i1 from "@common/services/breeze-view.service";
import * as i2 from "@angular/common";
import * as i3 from "@angular/forms";
import * as i4 from "../../../common/components/action-bar/action-bar.component";
import * as i5 from "../../../common/components/app-control/app-control.component";
import * as i6 from "../../../common/components/loader/loader.component";
import * as i7 from "../../../common/components/content-header/content-header.component";
import * as i8 from "@ngx-translate/core";
const _c0 = function (a0, a1) {
  return {
    "border-danger": a0,
    "border-success": a1
  };
};
function PostOfficeViewComponent_ng_template_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r2 = i0.ɵɵgetCurrentView();
    i0.ɵɵelement(0, "app-action-bar", 1)(1, "app-content-header", 2);
    i0.ɵɵpipe(2, "translate");
    i0.ɵɵelementStart(3, "div", 3)(4, "div", 4)(5, "div", 5)(6, "div", 6)(7, "app-control", 7);
    i0.ɵɵlistener("ngModelChange", function PostOfficeViewComponent_ng_template_1_Template_app_control_ngModelChange_7_listener($event) {
      i0.ɵɵrestoreView(_r2);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.model.id = $event);
    });
    i0.ɵɵpipe(8, "translate");
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(9, "div", 6)(10, "app-control", 8);
    i0.ɵɵlistener("ngModelChange", function PostOfficeViewComponent_ng_template_1_Template_app_control_ngModelChange_10_listener($event) {
      i0.ɵɵrestoreView(_r2);
      const ctx_r3 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r3.model.name = $event);
    });
    i0.ɵɵpipe(11, "translate");
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(12, "div", 6)(13, "app-control", 9);
    i0.ɵɵlistener("ngModelChange", function PostOfficeViewComponent_ng_template_1_Template_app_control_ngModelChange_13_listener($event) {
      i0.ɵɵrestoreView(_r2);
      const ctx_r4 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r4.model.lastModifiedById = $event);
    });
    i0.ɵɵpipe(14, "translate");
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(15, "div", 6)(16, "app-control", 10);
    i0.ɵɵlistener("ngModelChange", function PostOfficeViewComponent_ng_template_1_Template_app_control_ngModelChange_16_listener($event) {
      i0.ɵɵrestoreView(_r2);
      const ctx_r5 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r5.model.lastModifiedDate = $event);
    });
    i0.ɵɵpipe(17, "translate");
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(18, "div", 6)(19, "app-control", 11);
    i0.ɵɵlistener("ngModelChange", function PostOfficeViewComponent_ng_template_1_Template_app_control_ngModelChange_19_listener($event) {
      i0.ɵɵrestoreView(_r2);
      const ctx_r6 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r6.model.createdById = $event);
    });
    i0.ɵɵpipe(20, "translate");
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(21, "div", 6)(22, "app-control", 12);
    i0.ɵɵlistener("ngModelChange", function PostOfficeViewComponent_ng_template_1_Template_app_control_ngModelChange_22_listener($event) {
      i0.ɵɵrestoreView(_r2);
      const ctx_r7 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r7.model.createdDate = $event);
    });
    i0.ɵɵpipe(23, "translate");
    i0.ɵɵelementEnd()()()()();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("items", ctx_r0.actionBar)("collapsed", false);
    i0.ɵɵadvance(1);
    i0.ɵɵpropertyInterpolate2("title", "", i0.ɵɵpipeBind1(2, 33, "Post Office"), " ", ctx_r0.model.id, "");
    i0.ɵɵproperty("breadcrumb", ctx_r0.breadcrumb);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction2(47, _c0, ctx_r0.model.error, ctx_r0.model.processed));
    i0.ɵɵadvance(4);
    i0.ɵɵproperty("label", i0.ɵɵpipeBind1(8, 35, "Post Code"))("isDisabled", !ctx_r0.isNew)("ngModel", ctx_r0.model.id)("entity", ctx_r0.model);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("label", i0.ɵɵpipeBind1(11, 37, "Name"))("ngModel", ctx_r0.model.name)("entity", ctx_r0.model);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("label", i0.ɵɵpipeBind1(14, 39, "Last Modified By"))("time", true)("isDisabled", true)("ngModel", ctx_r0.model.lastModifiedById)("entity", ctx_r0.model);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("label", i0.ɵɵpipeBind1(17, 41, "Last Modified Date"))("time", true)("isDisabled", true)("ngModel", ctx_r0.model.lastModifiedDate)("entity", ctx_r0.model);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("label", i0.ɵɵpipeBind1(20, 43, "Created By"))("time", true)("isDisabled", true)("ngModel", ctx_r0.model.createdById)("entity", ctx_r0.model);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("label", i0.ɵɵpipeBind1(23, 45, "Created Date"))("time", true)("isDisabled", true)("ngModel", ctx_r0.model.createdDate)("entity", ctx_r0.model);
  }
}
export class PostOfficeViewComponent extends AbstractBreezeViewComponent {
  createEntity() {
    const entity = super.createEntity();
    entity.active = true;
    return entity;
  }
  constructor(breezeViewService) {
    super(breezeViewService);
    this.breezeViewService = breezeViewService;
    this.entityName = 'PostOffice';
    this.parentRoute = 'code-list/post-office-list';
    this.editPermission = CodeListPermissions.PostOffice.create;
    this.breadcrumb = [{
      icon: 'list',
      title: this.translateService.get('Post Office List'),
      route: '/code-list/post-office-list'
    }, {
      icon: 'file',
      title: this.translateService.get('Post Office')
    }];
    this.editActionBarGroup.items.push({
      label: this.translateService.instant('Delete'),
      icon: 'trash',
      isVisible: () => {
        return true;
      },
      onClick: () => {
        return this.dialogService.confirm(this.translateService.instant('Confirm'), this.translateService.instant('Are you sure you want to delete this item?')).then(result => {
          if (result === true) {
            this.model.active = false;
            return this.saveChanges().then(x => {
              this.router.navigate([`${this.parentRoute}`]);
            });
          }
        });
      }
    });
  }
}
PostOfficeViewComponent.ɵfac = function PostOfficeViewComponent_Factory(t) {
  return new (t || PostOfficeViewComponent)(i0.ɵɵdirectiveInject(i1.BreezeViewService));
};
PostOfficeViewComponent.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
  type: PostOfficeViewComponent,
  selectors: [["app-post-office-view"]],
  features: [i0.ɵɵProvidersFeature([BreezeViewService]), i0.ɵɵInheritDefinitionFeature],
  decls: 2,
  vars: 1,
  consts: [[3, "isBusy"], [3, "items", "collapsed"], [3, "title", "breadcrumb"], [1, "card", "card-border-top", 3, "ngClass"], [1, "card-body"], [1, "row"], [1, "col-3"], ["property", "id", 3, "label", "isDisabled", "ngModel", "entity", "ngModelChange"], ["property", "name", 3, "label", "ngModel", "entity", "ngModelChange"], ["type", "codelist", "codelist", "User", "property", "lastModifiedById", 3, "label", "time", "isDisabled", "ngModel", "entity", "ngModelChange"], ["property", "lastModifiedDate", 3, "label", "time", "isDisabled", "ngModel", "entity", "ngModelChange"], ["type", "codelist", "codelist", "User", "property", "createdById", 3, "label", "time", "isDisabled", "ngModel", "entity", "ngModelChange"], ["property", "createdDate", 3, "label", "time", "isDisabled", "ngModel", "entity", "ngModelChange"]],
  template: function PostOfficeViewComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "app-loader", 0);
      i0.ɵɵtemplate(1, PostOfficeViewComponent_ng_template_1_Template, 24, 50, "ng-template");
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      i0.ɵɵproperty("isBusy", !ctx.model);
    }
  },
  dependencies: [i2.NgClass, i3.NgControlStatus, i3.NgModel, i4.ActionBarComponent, i5.AppControlComponent, i6.LoaderComponent, i7.ContentHeaderComponent, i8.TranslatePipe],
  styles: ["\n/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IiIsInNvdXJjZVJvb3QiOiIifQ== */"]
});