import { BreezeViewService } from '@common/services/breeze-view.service';
import * as i0 from "@angular/core";
import * as i1 from "@common/services/breeze-view.service";
import * as i2 from "@angular/router";
import * as i3 from "@progress/kendo-angular-grid";
import * as i4 from "../app-grid/app-grid.component";
import * as i5 from "../check-icon.component";
import * as i6 from "../popover-list/popover-list.component";
import * as i7 from "@angular/common";
import * as i8 from "@ngx-translate/core";
import * as i9 from "../../pipes/codelist.pipe";
import * as i10 from "../../pipes/pad.pipe";
const _c0 = function (a1) {
  return ["/invoice/view/", a1];
};
function InvoiceListComponent_ng_template_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "a", 13);
    i0.ɵɵtext(1);
    i0.ɵɵpipe(2, "pad");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const dataItem_r9 = ctx.$implicit;
    i0.ɵɵproperty("routerLink", i0.ɵɵpureFunction1(4, _c0, dataItem_r9.id));
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind1(2, 2, dataItem_r9.internalInvoiceNumber), " ");
  }
}
const _c1 = function (a0) {
  return {
    text: a0,
    separator: ";",
    width: "150px"
  };
};
function InvoiceListComponent_ng_template_6_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "app-popover-list", 14);
  }
  if (rf & 2) {
    const dataItem_r10 = ctx.$implicit;
    i0.ɵɵproperty("data", i0.ɵɵpureFunction1(1, _c1, dataItem_r10.services));
  }
}
function InvoiceListComponent_ng_template_9_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "a", 13);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const dataItem_r11 = ctx.$implicit;
    i0.ɵɵproperty("routerLink", i0.ɵɵpureFunction1(2, _c0, dataItem_r11.id));
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate1(" ", dataItem_r11.invoiceNumber, " ");
  }
}
function InvoiceListComponent_ng_template_12_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtext(0);
  }
  if (rf & 2) {
    const dataItem_r12 = ctx.$implicit;
    i0.ɵɵtextInterpolate1(" ", dataItem_r12.recipientData.name1, " ");
  }
}
function InvoiceListComponent_ng_template_15_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtext(0);
    i0.ɵɵpipe(1, "async");
    i0.ɵɵpipe(2, "codelist");
  }
  if (rf & 2) {
    const dataItem_r13 = ctx.$implicit;
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind1(1, 1, i0.ɵɵpipeBind2(2, 3, dataItem_r13.typeId, "InvoiceType")), " ");
  }
}
function InvoiceListComponent_ng_template_18_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtext(0);
    i0.ɵɵpipe(1, "async");
    i0.ɵɵpipe(2, "codelist");
  }
  if (rf & 2) {
    const dataItem_r14 = ctx.$implicit;
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind1(1, 1, i0.ɵɵpipeBind2(2, 3, dataItem_r14.statusId, "InvoiceStatus")), " ");
  }
}
function InvoiceListComponent_ng_template_21_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtext(0);
    i0.ɵɵpipe(1, "async");
    i0.ɵɵpipe(2, "codelist");
  }
  if (rf & 2) {
    const dataItem_r15 = ctx.$implicit;
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind1(1, 1, i0.ɵɵpipeBind2(2, 3, dataItem_r15.issuedById, "User")), " ");
  }
}
function InvoiceListComponent_ng_template_26_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtext(0);
    i0.ɵɵpipe(1, "async");
    i0.ɵɵpipe(2, "codelist");
  }
  if (rf & 2) {
    const dataItem_r16 = ctx.$implicit;
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind1(1, 1, i0.ɵɵpipeBind2(2, 3, dataItem_r16.approvedById, "User")), " ");
  }
}
function InvoiceListComponent_ng_template_31_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "app-check-icon", 15);
  }
  if (rf & 2) {
    const dataItem_r17 = ctx.$implicit;
    i0.ɵɵproperty("check", dataItem_r17.automatic);
  }
}
export class InvoiceListComponent {
  constructor(breezeViewService) {
    this.breezeViewService = breezeViewService;
  }
}
InvoiceListComponent.ɵfac = function InvoiceListComponent_Factory(t) {
  return new (t || InvoiceListComponent)(i0.ɵɵdirectiveInject(i1.BreezeViewService));
};
InvoiceListComponent.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
  type: InvoiceListComponent,
  selectors: [["app-invoice-list"]],
  inputs: {
    items: "items",
    editMode: "editMode"
  },
  decls: 32,
  vars: 35,
  consts: [[1, "mb-2", 3, "data", "pageable"], ["field", "internalInvoiceNumber", 3, "title"], ["kendoGridCellTemplate", ""], ["field", "services", "width", "160px", 3, "title"], ["field", "invoiceNumber", 3, "title"], ["field", "recipientName1", 3, "title"], ["field", "typeId", 3, "title"], ["field", "statusId", 3, "title"], ["field", "issuedById", 3, "title"], ["field", "issuedDate", "format", "g", 3, "title"], ["field", "approvedById", 3, "title"], ["field", "approvedDate", "format", "g", 3, "title"], ["field", "automatic", 3, "title"], [1, "text-primary", "link-box", 3, "routerLink"], [3, "data"], [3, "check"]],
  template: function InvoiceListComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "app-grid", 0)(1, "kendo-grid-column", 1);
      i0.ɵɵpipe(2, "translate");
      i0.ɵɵtemplate(3, InvoiceListComponent_ng_template_3_Template, 3, 6, "ng-template", 2);
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(4, "kendo-grid-column", 3);
      i0.ɵɵpipe(5, "translate");
      i0.ɵɵtemplate(6, InvoiceListComponent_ng_template_6_Template, 1, 3, "ng-template", 2);
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(7, "kendo-grid-column", 4);
      i0.ɵɵpipe(8, "translate");
      i0.ɵɵtemplate(9, InvoiceListComponent_ng_template_9_Template, 2, 4, "ng-template", 2);
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(10, "kendo-grid-column", 5);
      i0.ɵɵpipe(11, "translate");
      i0.ɵɵtemplate(12, InvoiceListComponent_ng_template_12_Template, 1, 1, "ng-template", 2);
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(13, "kendo-grid-column", 6);
      i0.ɵɵpipe(14, "translate");
      i0.ɵɵtemplate(15, InvoiceListComponent_ng_template_15_Template, 3, 6, "ng-template", 2);
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(16, "kendo-grid-column", 7);
      i0.ɵɵpipe(17, "translate");
      i0.ɵɵtemplate(18, InvoiceListComponent_ng_template_18_Template, 3, 6, "ng-template", 2);
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(19, "kendo-grid-column", 8);
      i0.ɵɵpipe(20, "translate");
      i0.ɵɵtemplate(21, InvoiceListComponent_ng_template_21_Template, 3, 6, "ng-template", 2);
      i0.ɵɵelementEnd();
      i0.ɵɵelement(22, "kendo-grid-column", 9);
      i0.ɵɵpipe(23, "translate");
      i0.ɵɵelementStart(24, "kendo-grid-column", 10);
      i0.ɵɵpipe(25, "translate");
      i0.ɵɵtemplate(26, InvoiceListComponent_ng_template_26_Template, 3, 6, "ng-template", 2);
      i0.ɵɵelementEnd();
      i0.ɵɵelement(27, "kendo-grid-column", 11);
      i0.ɵɵpipe(28, "translate");
      i0.ɵɵelementStart(29, "kendo-grid-column", 12);
      i0.ɵɵpipe(30, "translate");
      i0.ɵɵtemplate(31, InvoiceListComponent_ng_template_31_Template, 1, 1, "ng-template", 2);
      i0.ɵɵelementEnd()();
    }
    if (rf & 2) {
      i0.ɵɵproperty("data", ctx.items)("pageable", false);
      i0.ɵɵadvance(1);
      i0.ɵɵproperty("title", i0.ɵɵpipeBind1(2, 13, "Internal Invoice Number"));
      i0.ɵɵadvance(3);
      i0.ɵɵproperty("title", i0.ɵɵpipeBind1(5, 15, "Type of Dues"));
      i0.ɵɵadvance(3);
      i0.ɵɵproperty("title", i0.ɵɵpipeBind1(8, 17, "Invoice Number"));
      i0.ɵɵadvance(3);
      i0.ɵɵproperty("title", i0.ɵɵpipeBind1(11, 19, "Payer"));
      i0.ɵɵadvance(3);
      i0.ɵɵproperty("title", i0.ɵɵpipeBind1(14, 21, "Type"));
      i0.ɵɵadvance(3);
      i0.ɵɵproperty("title", i0.ɵɵpipeBind1(17, 23, "Status"));
      i0.ɵɵadvance(3);
      i0.ɵɵproperty("title", i0.ɵɵpipeBind1(20, 25, "Issued By"));
      i0.ɵɵadvance(3);
      i0.ɵɵproperty("title", i0.ɵɵpipeBind1(23, 27, "Issued Date"));
      i0.ɵɵadvance(2);
      i0.ɵɵproperty("title", i0.ɵɵpipeBind1(25, 29, "Approved By"));
      i0.ɵɵadvance(3);
      i0.ɵɵproperty("title", i0.ɵɵpipeBind1(28, 31, "Approved Date"));
      i0.ɵɵadvance(2);
      i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(30, 33, "Auto. inv."));
    }
  },
  dependencies: [i2.RouterLink, i3.ColumnComponent, i3.CellTemplateDirective, i4.AppGridComponent, i5.CheckIconComponent, i6.PopoverListComponent, i7.AsyncPipe, i8.TranslatePipe, i9.CodelistPipe, i10.PadPipe],
  styles: ["\n/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IiIsInNvdXJjZVJvb3QiOiIifQ== */"]
});