import { AbstractBreezeViewComponent } from '@common/classes/breeze-view';
import { BreezeViewService } from '@common/services/breeze-view.service';
import * as i0 from "@angular/core";
import * as i1 from "@common/services/breeze-view.service";
import * as i2 from "@angular/forms";
import * as i3 from "../../../../common/components/action-bar/action-bar.component";
import * as i4 from "../../../../common/components/app-control/app-control.component";
import * as i5 from "../../../../common/components/loader/loader.component";
import * as i6 from "../../../../common/components/content-header/content-header.component";
import * as i7 from "@ngx-translate/core";
function PdfTemplateViewComponent_ng_template_2_Template(rf, ctx) {
  if (rf & 1) {
    const _r2 = i0.ɵɵgetCurrentView();
    i0.ɵɵelement(0, "app-action-bar", 2)(1, "app-content-header", 3);
    i0.ɵɵelementStart(2, "div", 4)(3, "div", 5)(4, "div", 6)(5, "div", 7)(6, "app-control", 8);
    i0.ɵɵlistener("ngModelChange", function PdfTemplateViewComponent_ng_template_2_Template_app_control_ngModelChange_6_listener($event) {
      i0.ɵɵrestoreView(_r2);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.model.name = $event);
    });
    i0.ɵɵpipe(7, "translate");
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(8, "div", 7)(9, "app-control", 9);
    i0.ɵɵlistener("ngModelChange", function PdfTemplateViewComponent_ng_template_2_Template_app_control_ngModelChange_9_listener($event) {
      i0.ɵɵrestoreView(_r2);
      const ctx_r3 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r3.model.internalNameId = $event);
    });
    i0.ɵɵpipe(10, "translate");
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(11, "div", 7)(12, "app-control", 10);
    i0.ɵɵlistener("ngModelChange", function PdfTemplateViewComponent_ng_template_2_Template_app_control_ngModelChange_12_listener($event) {
      i0.ɵɵrestoreView(_r2);
      const ctx_r4 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r4.model.languageId = $event);
    });
    i0.ɵɵpipe(13, "translate");
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(14, "div", 11)(15, "app-control", 12);
    i0.ɵɵlistener("ngModelChange", function PdfTemplateViewComponent_ng_template_2_Template_app_control_ngModelChange_15_listener($event) {
      i0.ɵɵrestoreView(_r2);
      const ctx_r5 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r5.model.content = $event);
    });
    i0.ɵɵpipe(16, "translate");
    i0.ɵɵelementEnd()()()()();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("items", ctx_r0.actionBar);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("title", ctx_r0.title)("breadcrumb", ctx_r0.breadcrumb);
    i0.ɵɵadvance(5);
    i0.ɵɵproperty("label", i0.ɵɵpipeBind1(7, 16, "Name"))("ngModel", ctx_r0.model.name)("entity", ctx_r0.model);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("label", i0.ɵɵpipeBind1(10, 18, "Internal Name"))("ngModel", ctx_r0.model.internalNameId)("entity", ctx_r0.model);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("label", i0.ɵɵpipeBind1(13, 20, "Language"))("ngModel", ctx_r0.model.languageId)("entity", ctx_r0.model);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("label", i0.ɵɵpipeBind1(16, 22, "Pdf Template"))("isDisabled", !ctx_r0.editMode)("ngModel", ctx_r0.model.content)("entity", ctx_r0.model);
  }
}
export class PdfTemplateViewComponent extends AbstractBreezeViewComponent {
  constructor(breezeViewService) {
    super(breezeViewService);
    this.breezeViewService = breezeViewService;
    this.entityName = 'PdfTemplate';
    this.parentRoute = '/administration/pdf-template/';
    this.breadcrumb = [{
      icon: 'list',
      title: this.translateService.get('Pdf Templates'),
      route: '/administration/pdf-template/list'
    }, {
      icon: 'file',
      title: this.translateService.get('Pdf Template')
    }];
    this.editActionBarGroup.items.push({
      label: this.translateService.instant('Delete'),
      icon: 'trash',
      isVisible: () => true,
      onClick: () => {
        return this.dialogService.confirm(this.translateService.instant('Confirm'), this.translateService.instant('You are going to delete the pdf template')).then(result => {
          if (result !== true) return;
          return this.breezeViewService.handleCommand('DeletePdfTemplates', {
            Ids: [this.model.id]
          }).then(x => this.router.navigate([`${this.parentRoute}/list`]));
        });
      }
    });
    this.editActionBarGroup.items.push({
      label: this.translateService.instant('Activate'),
      icon: 'check-square',
      isVisible: () => !this.model.active || this.isNew,
      isDisabled: () => !this.model.content,
      onClick: () => {
        return this.breezeViewService.handleCommand('ActivatePdfTemplate', {
          Id: this.model.id
        }).then(x => this.router.navigate([`${this.parentRoute}/list`]));
      }
    });
    this.editActionBarGroup.items.push({
      label: this.translateService.instant('Deactivate'),
      icon: 'square',
      isVisible: () => this.model.active,
      onClick: () => {
        return this.breezeViewService.handleCommand('DeactivatePdfTemplate', {
          Id: this.model.id
        }).then(x => this.router.navigate([`${this.parentRoute}/list`]));
      }
    });
  }
}
PdfTemplateViewComponent.ɵfac = function PdfTemplateViewComponent_Factory(t) {
  return new (t || PdfTemplateViewComponent)(i0.ɵɵdirectiveInject(i1.BreezeViewService));
};
PdfTemplateViewComponent.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
  type: PdfTemplateViewComponent,
  selectors: [["app-pdf-template-view"]],
  features: [i0.ɵɵProvidersFeature([BreezeViewService]), i0.ɵɵInheritDefinitionFeature],
  decls: 3,
  vars: 1,
  consts: [[3, "isBusy"], [1, "row", "separator"], [3, "items"], [3, "title", "breadcrumb"], [1, "card", "card-border-top"], [1, "card-body"], [1, "row"], [1, "col-4"], ["property", "name", 3, "label", "ngModel", "entity", "ngModelChange"], ["property", "internalNameId", 3, "label", "ngModel", "entity", "ngModelChange"], ["type", "codelist", "codelist", "Language", "property", "languageId", 3, "label", "ngModel", "entity", "ngModelChange"], [1, "col-12"], ["type", "file", "property", "content", 3, "label", "isDisabled", "ngModel", "entity", "ngModelChange"]],
  template: function PdfTemplateViewComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "app-loader", 0);
      i0.ɵɵelement(1, "div", 1);
      i0.ɵɵtemplate(2, PdfTemplateViewComponent_ng_template_2_Template, 17, 24, "ng-template");
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      i0.ɵɵproperty("isBusy", !ctx.model);
    }
  },
  dependencies: [i2.NgControlStatus, i2.NgModel, i3.ActionBarComponent, i4.AppControlComponent, i5.LoaderComponent, i6.ContentHeaderComponent, i7.TranslatePipe],
  styles: ["\n/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IiIsInNvdXJjZVJvb3QiOiIifQ== */"]
});