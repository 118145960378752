import { AbstractBreezeListComponent } from '@common/classes/breeze-list';
import { BreezeViewService } from '@common/services/breeze-view.service';
import * as i0 from "@angular/core";
import * as i1 from "@common/services/breeze-view.service";
import * as i2 from "@angular/common";
import * as i3 from "@angular/forms";
import * as i4 from "@ng-bootstrap/ng-bootstrap";
import * as i5 from "@fortawesome/angular-fontawesome";
import * as i6 from "@progress/kendo-angular-grid";
import * as i7 from "../../../common/components/app-control/app-control.component";
import * as i8 from "../../../common/components/app-form/app-form.component";
import * as i9 from "../../../common/components/app-grid/app-grid.component";
import * as i10 from "../../../common/components/app-control-container/app-control-container.component";
import * as i11 from "@ngx-translate/core";
function UnbilledStorageEventListComponent_ng_template_33_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span");
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const row_r4 = ctx.$implicit;
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate2("", row_r4.containerStatusId, " - ", row_r4.containerStatusName, "");
  }
}
function UnbilledStorageEventListComponent_ng_template_36_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span");
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const row_r5 = ctx.$implicit;
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate2("", row_r5.storageAreaId, " - ", row_r5.storageAreaName, "");
  }
}
function UnbilledStorageEventListComponent_ng_template_39_span_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span");
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const row_r6 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate2("", row_r6.typeId, " - ", row_r6.typeName, "");
  }
}
function UnbilledStorageEventListComponent_ng_template_39_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, UnbilledStorageEventListComponent_ng_template_39_span_0_Template, 2, 2, "span", 22);
  }
  if (rf & 2) {
    const row_r6 = ctx.$implicit;
    i0.ɵɵproperty("ngIf", row_r6.typeId);
  }
}
const _c0 = function () {
  return ["fas", "trash"];
};
const _c1 = function () {
  return ["fas", "search"];
};
export class UnbilledStorageEventListComponent extends AbstractBreezeListComponent {
  constructor(breezeViewService) {
    super(breezeViewService);
    this.breezeViewService = breezeViewService;
    this.queryName = 'UnbilledContainerStorageEvents';
    this.gridSort = [{
      field: 'containerOwnerId',
      dir: 'asc'
    }, {
      field: 'storageAreaId',
      dir: 'asc'
    }, {
      field: 'containerStatusId',
      dir: 'asc'
    }];
  }
  getDefaultFilter(data) {
    return this.breezeViewService.entityManager.createEntity('GetUnbilledContainerStorageEventsQuery', {
      ...data
    });
  }
  search() {
    this.appGrid.isBusy = true;
    this.handleFilterHighlighter();
    return this.entityManager.executeQuery(this.getQuery()).then(data => {
      this.data = data.results;
    }).finally(() => {
      this.appGrid.isBusy = false;
    });
  }
}
UnbilledStorageEventListComponent.ɵfac = function UnbilledStorageEventListComponent_Factory(t) {
  return new (t || UnbilledStorageEventListComponent)(i0.ɵɵdirectiveInject(i1.BreezeViewService));
};
UnbilledStorageEventListComponent.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
  type: UnbilledStorageEventListComponent,
  selectors: [["app-unbilled-storage-event-list"]],
  features: [i0.ɵɵProvidersFeature([BreezeViewService]), i0.ɵɵInheritDefinitionFeature],
  decls: 42,
  vars: 43,
  consts: [[1, "row"], [1, "col-12"], [2, "text-transform", "uppercase"], [1, "row", "mb-3"], [3, "submit"], ["filterHighlighter", ""], [1, "col-3"], ["property", "serviceDateFrom", 3, "label", "ngModel", "entity", "ngModelChange"], ["property", "serviceDateTo", 3, "label", "ngModel", "entity", "ngModelChange"], [1, "row", "mt-2"], [1, "col"], [1, "float-right"], ["placement", "bottom", 1, "btn", "btn-danger", "mr-2", 3, "ngbTooltip", "click"], [3, "icon"], ["placement", "bottom", 1, "btn", "btn-primary", 3, "ngbTooltip", "click"], [1, "mb-2", "voyage-summary-table", 3, "filterable", "pageSize", "data", "sort", "sortable"], ["field", "containerOwnerId", 3, "title"], ["field", "containerStatusId", 3, "title"], ["kendoGridCellTemplate", ""], ["field", "storageAreaId", 3, "title"], ["field", "typeId", 3, "title"], ["field", "count", 3, "title"], [4, "ngIf"]],
  template: function UnbilledStorageEventListComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "div", 0)(1, "div", 1)(2, "h6", 2);
      i0.ɵɵtext(3);
      i0.ɵɵpipe(4, "translate");
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(5, "div", 3)(6, "div", 1)(7, "app-form", 4);
      i0.ɵɵlistener("submit", function UnbilledStorageEventListComponent_Template_app_form_submit_7_listener() {
        return ctx.search();
      });
      i0.ɵɵelementStart(8, "app-control-container", null, 5)(10, "div", 0)(11, "div", 6)(12, "app-control", 7);
      i0.ɵɵlistener("ngModelChange", function UnbilledStorageEventListComponent_Template_app_control_ngModelChange_12_listener($event) {
        return ctx.filter.serviceDateFrom = $event;
      });
      i0.ɵɵpipe(13, "translate");
      i0.ɵɵelementEnd()();
      i0.ɵɵelementStart(14, "div", 6)(15, "app-control", 8);
      i0.ɵɵlistener("ngModelChange", function UnbilledStorageEventListComponent_Template_app_control_ngModelChange_15_listener($event) {
        return ctx.filter.serviceDateTo = $event;
      });
      i0.ɵɵpipe(16, "translate");
      i0.ɵɵelementEnd()()();
      i0.ɵɵelementStart(17, "div", 9)(18, "div", 10)(19, "div", 11)(20, "button", 12);
      i0.ɵɵlistener("click", function UnbilledStorageEventListComponent_Template_button_click_20_listener() {
        return ctx.clearFilter();
      });
      i0.ɵɵpipe(21, "translate");
      i0.ɵɵelement(22, "fa-icon", 13);
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(23, "button", 14);
      i0.ɵɵlistener("click", function UnbilledStorageEventListComponent_Template_button_click_23_listener() {
        return ctx.search();
      });
      i0.ɵɵpipe(24, "translate");
      i0.ɵɵelement(25, "fa-icon", 13);
      i0.ɵɵelementEnd()()()()()()()()()();
      i0.ɵɵelementStart(26, "div", 0)(27, "div", 1)(28, "app-grid", 15);
      i0.ɵɵelement(29, "kendo-grid-column", 16);
      i0.ɵɵpipe(30, "translate");
      i0.ɵɵelementStart(31, "kendo-grid-column", 17);
      i0.ɵɵpipe(32, "translate");
      i0.ɵɵtemplate(33, UnbilledStorageEventListComponent_ng_template_33_Template, 2, 2, "ng-template", 18);
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(34, "kendo-grid-column", 19);
      i0.ɵɵpipe(35, "translate");
      i0.ɵɵtemplate(36, UnbilledStorageEventListComponent_ng_template_36_Template, 2, 2, "ng-template", 18);
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(37, "kendo-grid-column", 20);
      i0.ɵɵpipe(38, "translate");
      i0.ɵɵtemplate(39, UnbilledStorageEventListComponent_ng_template_39_Template, 1, 1, "ng-template", 18);
      i0.ɵɵelementEnd();
      i0.ɵɵelement(40, "kendo-grid-column", 21);
      i0.ɵɵpipe(41, "translate");
      i0.ɵɵelementEnd()()();
    }
    if (rf & 2) {
      i0.ɵɵadvance(3);
      i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(4, 21, "Storage"));
      i0.ɵɵadvance(9);
      i0.ɵɵpropertyInterpolate("label", i0.ɵɵpipeBind1(13, 23, "Service Date From"));
      i0.ɵɵproperty("ngModel", ctx.filter.serviceDateFrom)("entity", ctx.filter);
      i0.ɵɵadvance(3);
      i0.ɵɵpropertyInterpolate("label", i0.ɵɵpipeBind1(16, 25, "Service Date To"));
      i0.ɵɵproperty("ngModel", ctx.filter.serviceDateTo)("entity", ctx.filter);
      i0.ɵɵadvance(5);
      i0.ɵɵpropertyInterpolate("ngbTooltip", i0.ɵɵpipeBind1(21, 27, "Clear"));
      i0.ɵɵadvance(2);
      i0.ɵɵproperty("icon", i0.ɵɵpureFunction0(41, _c0));
      i0.ɵɵadvance(1);
      i0.ɵɵpropertyInterpolate("ngbTooltip", i0.ɵɵpipeBind1(24, 29, "Search"));
      i0.ɵɵadvance(2);
      i0.ɵɵproperty("icon", i0.ɵɵpureFunction0(42, _c1));
      i0.ɵɵadvance(3);
      i0.ɵɵproperty("filterable", true)("pageSize", 20)("data", ctx.data)("sort", ctx.gridSort)("sortable", true);
      i0.ɵɵadvance(1);
      i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(30, 31, "Owner"));
      i0.ɵɵadvance(2);
      i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(32, 33, "Container Status"));
      i0.ɵɵadvance(3);
      i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(35, 35, "Storage Area"));
      i0.ɵɵadvance(3);
      i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(38, 37, "Type"));
      i0.ɵɵadvance(3);
      i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(41, 39, "Count"));
    }
  },
  dependencies: [i2.NgIf, i3.NgControlStatus, i3.NgModel, i4.NgbTooltip, i5.FaIconComponent, i6.ColumnComponent, i6.CellTemplateDirective, i7.AppControlComponent, i8.AppFormComponent, i9.AppGridComponent, i10.AppControlContainerComponent, i11.TranslatePipe],
  styles: ["\n/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IiIsInNvdXJjZVJvb3QiOiIifQ== */"]
});