import { environment } from '@environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
export class FooterComponent {
  constructor() {
    this.version = environment.version;
    this.year = new Date().getFullYear();
  }
}
FooterComponent.ɵfac = function FooterComponent_Factory(t) {
  return new (t || FooterComponent)();
};
FooterComponent.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
  type: FooterComponent,
  selectors: [["app-footer"]],
  decls: 14,
  vars: 8,
  consts: [[1, "text-xs-center"], [1, "float-right", "hidden-xs"], ["translate", ""], ["id", "copyright"], ["href", "http://www.actual.si/"]],
  template: function FooterComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "footer", 0)(1, "div", 1)(2, "b")(3, "span", 2);
      i0.ɵɵtext(4, "Version");
      i0.ɵɵelementEnd()();
      i0.ɵɵtext(5);
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(6, "strong", 3);
      i0.ɵɵtext(7);
      i0.ɵɵpipe(8, "translate");
      i0.ɵɵelementStart(9, "a", 4);
      i0.ɵɵtext(10, "Actual I.T.");
      i0.ɵɵelementEnd()();
      i0.ɵɵelementStart(11, "span");
      i0.ɵɵtext(12);
      i0.ɵɵpipe(13, "translate");
      i0.ɵɵelementEnd()();
    }
    if (rf & 2) {
      i0.ɵɵadvance(5);
      i0.ɵɵtextInterpolate1(" ", ctx.version, " ");
      i0.ɵɵadvance(2);
      i0.ɵɵtextInterpolate2(" ", i0.ɵɵpipeBind1(8, 4, "Copyright"), " \u00A9 ", ctx.year, " ");
      i0.ɵɵadvance(5);
      i0.ɵɵtextInterpolate1("", i0.ɵɵpipeBind1(13, 6, "All rights reserved"), ".");
    }
  },
  dependencies: [i1.TranslateDirective, i1.TranslatePipe],
  styles: ["[_nghost-%COMP%] {\n  font-size: 12px;\n  background: #fff;\n  color: #444;\n  border-top: 1px solid #d2d6de;\n  width: 100%;\n  padding: 10px;\n  transition: transform 0.3s ease-in-out, margin 0.3s ease-in-out;\n}\n\n.fw[_nghost-%COMP%] {\n  width: 100%;\n  margin-left: 0;\n}\n/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIndlYnBhY2s6Ly8uL3NyYy9hcHAvY29tbW9uL2NvbXBvbmVudHMvZm9vdGVyL2Zvb3Rlci5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNJLGVBQUE7RUFDQSxnQkFBQTtFQUNBLFdBQUE7RUFDQSw2QkFBQTtFQUNBLFdBQUE7RUFDQSxhQUFBO0VBQ0EsK0RBQ0k7QUFBUjs7QUFJQTtFQUNJLFdBQUE7RUFDQSxjQUFBO0FBREoiLCJzb3VyY2VzQ29udGVudCI6WyI6aG9zdCB7XHJcbiAgICBmb250LXNpemU6IDEycHg7XHJcbiAgICBiYWNrZ3JvdW5kOiAjZmZmO1xyXG4gICAgY29sb3I6ICM0NDQ7XHJcbiAgICBib3JkZXItdG9wOiAxcHggc29saWQgI2QyZDZkZTtcclxuICAgIHdpZHRoOiAxMDAlO1xyXG4gICAgcGFkZGluZzogMTBweDtcclxuICAgIHRyYW5zaXRpb246XHJcbiAgICAgICAgdHJhbnNmb3JtIDAuM3MgZWFzZS1pbi1vdXQsXHJcbiAgICAgICAgbWFyZ2luIDAuM3MgZWFzZS1pbi1vdXQ7XHJcbn1cclxuXHJcbjpob3N0LmZ3IHtcclxuICAgIHdpZHRoOiAxMDAlO1xyXG4gICAgbWFyZ2luLWxlZnQ6IDA7XHJcbn1cclxuIl0sInNvdXJjZVJvb3QiOiIifQ== */"],
  changeDetection: 0
});