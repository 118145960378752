import { EntitiesErrors } from '@common/classes/entities-errors';
import * as i0 from "@angular/core";
import * as i1 from "@common/classes/entities-errors";
import * as i2 from "@progress/kendo-angular-grid";
import * as i3 from "../app-grid/app-grid.component";
import * as i4 from "@ngx-translate/core";
function EntitiesErrorsComponent_ng_template_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span");
    i0.ɵɵtext(1);
    i0.ɵɵpipe(2, "translate");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const item_r2 = ctx.$implicit;
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(2, 1, item_r2.entity.entityType.shortName));
  }
}
function EntitiesErrorsComponent_ng_template_6_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span");
    i0.ɵɵtext(1);
    i0.ɵɵpipe(2, "translate");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const item_r3 = ctx.$implicit;
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(2, 1, item_r3.propertyName));
  }
}
export class EntitiesErrorsComponent {
  constructor(errors) {
    this.errors = errors.errors;
  }
}
EntitiesErrorsComponent.ɵfac = function EntitiesErrorsComponent_Factory(t) {
  return new (t || EntitiesErrorsComponent)(i0.ɵɵdirectiveInject(i1.EntitiesErrors));
};
EntitiesErrorsComponent.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
  type: EntitiesErrorsComponent,
  selectors: [["app-entities-errors"]],
  decls: 9,
  vars: 10,
  consts: [[1, "mb-2", 3, "data"], ["headerClass", "bg-danger text-white", 3, "title"], ["kendoGridCellTemplate", ""], ["field", "propertyName", "headerClass", "bg-danger text-white", 3, "title"], ["field", "errorMessage", "headerClass", "bg-danger text-white", 3, "title"]],
  template: function EntitiesErrorsComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "app-grid", 0)(1, "kendo-grid-column", 1);
      i0.ɵɵpipe(2, "translate");
      i0.ɵɵtemplate(3, EntitiesErrorsComponent_ng_template_3_Template, 3, 3, "ng-template", 2);
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(4, "kendo-grid-column", 3);
      i0.ɵɵpipe(5, "translate");
      i0.ɵɵtemplate(6, EntitiesErrorsComponent_ng_template_6_Template, 3, 3, "ng-template", 2);
      i0.ɵɵelementEnd();
      i0.ɵɵelement(7, "kendo-grid-column", 4);
      i0.ɵɵpipe(8, "translate");
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      i0.ɵɵproperty("data", ctx.errors);
      i0.ɵɵadvance(1);
      i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(2, 4, "Entity"));
      i0.ɵɵadvance(3);
      i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(5, 6, "Property"));
      i0.ɵɵadvance(3);
      i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(8, 8, "Error"));
    }
  },
  dependencies: [i2.ColumnComponent, i2.CellTemplateDirective, i3.AppGridComponent, i4.TranslatePipe],
  styles: ["\n/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IiIsInNvdXJjZVJvb3QiOiIifQ== */"]
});