import { printf } from '@common/functions/printf';
import { BreezeViewService } from '@common/services/breeze-view.service';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import * as i0 from "@angular/core";
import * as i1 from "@common/services/breeze-view.service";
import * as i2 from "@angular/common";
import * as i3 from "@fortawesome/angular-fontawesome";
import * as i4 from "@ng-bootstrap/ng-bootstrap";
import * as i5 from "@progress/kendo-angular-indicators";
import * as i6 from "../loader/loader.component";
import * as i7 from "@ngx-translate/core";
function NotificationComponent_ng_template_7_div_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 8);
    i0.ɵɵtext(1);
    i0.ɵɵpipe(2, "translate");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(2, 1, "No notifications"));
  }
}
function NotificationComponent_ng_template_7_div_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "div", 9);
  }
  if (rf & 2) {
    const notification_r3 = ctx.$implicit;
    i0.ɵɵproperty("innerHTML", notification_r3, i0.ɵɵsanitizeHtml);
  }
}
function NotificationComponent_ng_template_7_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, NotificationComponent_ng_template_7_div_0_Template, 3, 3, "div", 6);
    i0.ɵɵtemplate(1, NotificationComponent_ng_template_7_div_1_Template, 1, 1, "div", 7);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngIf", !ctx_r0.notifications.length);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngForOf", ctx_r0.notifications);
  }
}
export class NotificationComponent {
  constructor(breezeViewService) {
    this.breezeViewService = breezeViewService;
    this.notifications = [];
  }
  onBellClick(isOpened) {
    if (this.isBusy || !isOpened) {
      return;
    }
    this.refreshNotifications();
  }
  refreshNotifications() {
    this.isBusy = true;
    this.breezeViewService.handleQuery('Notifications').then(notifications => {
      this.notifications = notifications.map(x => this.notificationToTemplate(x));
    }).finally(() => {
      this.isBusy = false;
    });
  }
  notificationToTemplate(notification) {
    if (notification.type === 'ApproveInvoiceNotification') {
      const content = JSON.parse(notification.content);
      const invoiceNumber = content.InvoiceNumber;
      const invoiceRecipient = content.InvoiceRecipient;
      const invoiceTypeId = content.InvoiceTypeId;
      const invoiceId = content.InvoiceId;
      return printf(this.breezeViewService.translateService.instant('<b>{0}: <a href="/invoice/view/{1}">{2}</a> for {3} was approved</b>'), invoiceTypeId, invoiceId, invoiceNumber, invoiceRecipient);
    }
    if (notification.type === 'InvoiceCreation') {
      const content = JSON.parse(notification.content);
      const invoiceRecipient = content.InvoiceRecipient;
      const internalInvoiceNumber = content.InternalInvoiceNumber;
      const invoiceId = content.InvoiceId;
      return printf(this.breezeViewService.translateService.instant('<b>Invoice creation <a href="/invoice/view/{0}">{1}</a> for {2} was successful</b>'), invoiceId, internalInvoiceNumber, invoiceRecipient);
    }
  }
}
NotificationComponent.ɵfac = function NotificationComponent_Factory(t) {
  return new (t || NotificationComponent)(i0.ɵɵdirectiveInject(i1.BreezeViewService));
};
NotificationComponent.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
  type: NotificationComponent,
  selectors: [["app-notification"]],
  viewQuery: function NotificationComponent_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(NgbDropdown, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.bellDropdown = _t.first);
    }
  },
  features: [i0.ɵɵProvidersFeature([BreezeViewService])],
  decls: 8,
  vars: 4,
  consts: [["ngbDropdown", "", 1, "d-inline-block", 3, "openChange"], ["id", "dropdownForm1", "ngbDropdownToggle", "", 1, "btn", "btn-primary", "bell-button"], [3, "title"], ["icon", "bell"], ["ngbDropdownMenu", "", "aria-labelledby", "dropdownForm1", 1, "bell-item-wrapper"], [3, "isBusy"], ["ngbDropdownItem", "", 4, "ngIf"], ["class", "test", "ngbDropdownItem", "", 3, "innerHTML", 4, "ngFor", "ngForOf"], ["ngbDropdownItem", ""], ["ngbDropdownItem", "", 1, "test", 3, "innerHTML"]],
  template: function NotificationComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "div", 0);
      i0.ɵɵlistener("openChange", function NotificationComponent_Template_div_openChange_0_listener($event) {
        return ctx.onBellClick($event);
      });
      i0.ɵɵelementStart(1, "button", 1)(2, "kendo-badge-container", 2);
      i0.ɵɵpipe(3, "translate");
      i0.ɵɵelement(4, "fa-icon", 3);
      i0.ɵɵelementEnd()();
      i0.ɵɵelementStart(5, "div", 4)(6, "app-loader", 5);
      i0.ɵɵtemplate(7, NotificationComponent_ng_template_7_Template, 2, 2, "ng-template");
      i0.ɵɵelementEnd()()();
    }
    if (rf & 2) {
      i0.ɵɵadvance(2);
      i0.ɵɵproperty("title", i0.ɵɵpipeBind1(3, 2, "New invoices"));
      i0.ɵɵadvance(4);
      i0.ɵɵproperty("isBusy", ctx.isBusy);
    }
  },
  dependencies: [i2.NgForOf, i2.NgIf, i3.FaIconComponent, i4.NgbDropdown, i4.NgbDropdownToggle, i4.NgbDropdownMenu, i4.NgbDropdownItem, i5.BadgeContainerComponent, i6.LoaderComponent, i7.TranslatePipe],
  styles: [".bell-button.dropdown-toggle[_ngcontent-%COMP%], .bell-button[_ngcontent-%COMP%], .bell-button[_ngcontent-%COMP%]:focus {\n  background-color: transparent !important;\n  border-color: transparent !important;\n  outline: none !important;\n  box-shadow: none !important;\n}\n.bell-button.dropdown-toggle[_ngcontent-%COMP%]::after {\n  content: none;\n}\n\n.bell-item-wrapper[_ngcontent-%COMP%] {\n  max-height: 400px;\n  overflow-y: auto;\n}\n\n.test[_ngcontent-%COMP%] {\n  width: 400px;\n  word-wrap: break-word;\n  white-space: break-spaces;\n}\n/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIndlYnBhY2s6Ly8uL3NyYy9hcHAvY29tbW9uL2NvbXBvbmVudHMvbm90aWZpY2F0aW9uL25vdGlmaWNhdGlvbi5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFDSTtFQUdJLHdDQUFBO0VBQ0Esb0NBQUE7RUFDQSx3QkFBQTtFQUNBLDJCQUFBO0FBRlI7QUFJSTtFQUNJLGFBQUE7QUFGUjs7QUFNQTtFQUNJLGlCQUFBO0VBQ0EsZ0JBQUE7QUFISjs7QUFNQTtFQUNJLFlBQUE7RUFFQSxxQkFBQTtFQUNBLHlCQUFBO0FBSkoiLCJzb3VyY2VzQ29udGVudCI6WyIuYmVsbC1idXR0b24ge1xyXG4gICAgJi5kcm9wZG93bi10b2dnbGUsXHJcbiAgICAmLFxyXG4gICAgJjpmb2N1cyB7XHJcbiAgICAgICAgYmFja2dyb3VuZC1jb2xvcjogdHJhbnNwYXJlbnQgIWltcG9ydGFudDtcclxuICAgICAgICBib3JkZXItY29sb3I6IHRyYW5zcGFyZW50ICFpbXBvcnRhbnQ7XHJcbiAgICAgICAgb3V0bGluZTogbm9uZSAhaW1wb3J0YW50O1xyXG4gICAgICAgIGJveC1zaGFkb3c6IG5vbmUgIWltcG9ydGFudDtcclxuICAgIH1cclxuICAgICYuZHJvcGRvd24tdG9nZ2xlOjphZnRlciB7XHJcbiAgICAgICAgY29udGVudDogbm9uZTtcclxuICAgIH1cclxufVxyXG5cclxuLmJlbGwtaXRlbS13cmFwcGVyIHtcclxuICAgIG1heC1oZWlnaHQ6IDQwMHB4O1xyXG4gICAgb3ZlcmZsb3cteTogYXV0bztcclxufVxyXG5cclxuLnRlc3Qge1xyXG4gICAgd2lkdGg6IDQwMHB4O1xyXG4gICAgLy9vdmVyZmxvdy13cmFwOiBicmVhay13b3JkO1xyXG4gICAgd29yZC13cmFwOiBicmVhay13b3JkO1xyXG4gICAgd2hpdGUtc3BhY2U6IGJyZWFrLXNwYWNlcztcclxufVxyXG4iXSwic291cmNlUm9vdCI6IiJ9 */"]
});