import { RouterModule } from '@angular/router';
import { CanDeactivateView } from '@common/guards/view.deactivate';
import { ViewMode } from '@common/models/view-mode';
import { CommonModule } from '../../common/common.module';
import { OrganizationEditComponent } from './components/organization-edit/organization-edit.component';
import { OrganizationListComponent } from './components/organization-list/organization-list.component';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
export const ORG_ROUTES = [{
  path: '',
  redirectTo: 'list',
  pathMatch: 'full'
}, {
  path: 'list',
  component: OrganizationListComponent
}, {
  path: 'view/:id',
  component: OrganizationEditComponent,
  data: {
    mode: ViewMode.view
  }
}, {
  path: 'edit/:id',
  component: OrganizationEditComponent,
  data: {
    mode: ViewMode.edit
  },
  canDeactivate: [CanDeactivateView]
}, {
  path: 'create',
  component: OrganizationEditComponent,
  data: {
    mode: ViewMode.create
  },
  canDeactivate: [CanDeactivateView]
}, {
  path: '**',
  redirectTo: 'list'
}];
export class OrganizationModule {}
OrganizationModule.ɵfac = function OrganizationModule_Factory(t) {
  return new (t || OrganizationModule)();
};
OrganizationModule.ɵmod = /*@__PURE__*/i0.ɵɵdefineNgModule({
  type: OrganizationModule
});
OrganizationModule.ɵinj = /*@__PURE__*/i0.ɵɵdefineInjector({
  providers: [CanDeactivateView],
  imports: [CommonModule, RouterModule.forChild(ORG_ROUTES)]
});
(function () {
  (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(OrganizationModule, {
    declarations: [OrganizationListComponent, OrganizationEditComponent],
    imports: [CommonModule, i1.RouterModule]
  });
})();