import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "@ngx-translate/core";
function HomeComponent_img_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "img", 6);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("src", ctx_r0.logo, i0.ɵɵsanitizeUrl);
  }
}
function HomeComponent_h1_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "h1", 7);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate(ctx_r1.welcomeMessage);
  }
}
export class HomeComponent {
  constructor() {
    this.logo = environment.settings.header.logo;
    this.welcomeMessage = environment.settings.header.welcomeMessage;
  }
}
HomeComponent.ɵfac = function HomeComponent_Factory(t) {
  return new (t || HomeComponent)();
};
HomeComponent.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
  type: HomeComponent,
  selectors: [["ng-component"]],
  decls: 6,
  vars: 2,
  consts: [[1, "card", "mt-4"], [1, "card-body", "p-5", "m-5", 2, "height", "600px"], [1, "row", "mt-5"], [1, "col"], [3, "src", 4, "ngIf"], ["class", "mt-2", "translate", "", 4, "ngIf"], [3, "src"], ["translate", "", 1, "mt-2"]],
  template: function HomeComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "div", 0)(1, "div", 1)(2, "div", 2)(3, "div", 3);
      i0.ɵɵtemplate(4, HomeComponent_img_4_Template, 1, 1, "img", 4);
      i0.ɵɵtemplate(5, HomeComponent_h1_5_Template, 2, 1, "h1", 5);
      i0.ɵɵelementEnd()()()();
    }
    if (rf & 2) {
      i0.ɵɵadvance(4);
      i0.ɵɵproperty("ngIf", ctx.logo);
      i0.ɵɵadvance(1);
      i0.ɵɵproperty("ngIf", ctx.welcomeMessage);
    }
  },
  dependencies: [i1.NgIf, i2.TranslateDirective],
  encapsulation: 2
});