import * as _ from 'lodash';
import * as i0 from "@angular/core";
export class BreezeService {
  constructor() {}
  convertToDto(model) {
    return BreezeService._convertToDto(model);
  }
  getEntites(rootEntity, predicate = null, processedEntities = []) {
    let results = [];
    let i;
    let navProp;
    let relatedEntities;
    const navProps = rootEntity.entityType.navigationProperties;
    if (!processedEntities.length) {
      // first call
      results.push(rootEntity);
    }
    processedEntities.push(rootEntity);
    for (i = 0; i < navProps.length; i++) {
      navProp = navProps[i];
      relatedEntities = rootEntity[navProp.name];
      if (!_.isArray(relatedEntities)) {
        relatedEntities = [relatedEntities];
      }
      _.each(relatedEntities, item => {
        if (!item || !item.entityAspect || processedEntities.includes(item)) {
          return;
        }
        if (!predicate || predicate(item)) {
          results.push(item);
        }
        results = results.concat(this.getEntites(item, predicate, processedEntities));
        processedEntities.push(item);
      });
    }
    return results;
  }
  clearServerValidationErrors(entityAspect) {
    _.forEach(this.getServerErrors(entityAspect), error => {
      entityAspect.removeValidationError(error);
    });
  }
  getServerErrors(entityAspect) {
    return _.filter(entityAspect.getValidationErrors(), error => error.isServerError);
  }
  getEntityErrors(entity) {
    return _.map(entity.entityAspect.getValidationErrors(), error => ({
      entity,
      errorName: error.key,
      errorMessage: error.errorMessage,
      propertyName: error.propertyName,
      isServerError: error.isServerError
    }));
  }
  // eslint-disable-next-line @typescript-eslint/member-ordering
  static _convertToDto(val, opts = null) {
    let dto;
    opts = opts || {};
    if (!opts.hasOwnProperty('refs')) {
      opts.refs = {}; // key: refId, value: entity
      opts.nextRefId = 1;
    }
    if (val?._backingStore) {
      // is entity
      if (val.hasOwnProperty('entityAspect') && (val.entityAspect.entityState.isDeleted() || val.entityAspect.entityState.isDetached())) {
        return null; // skip deleted/detached entities
      }
      // check if the entity was already been processed (prevents an infinite loop)
      for (const refId in opts.refs) {
        if (opts.refs[refId] === val) {
          return {
            $ref: refId.toString()
          }; // Json.NET convention for references
        }
      }

      dto = {
        $id: opts.nextRefId.toString()
      };
      // Save the current entity
      opts.refs[dto.$id] = val;
      opts.nextRefId++;
      if (_.isFunction(opts.interceptEntity)) {
        opts.interceptEntity(val);
      }
      _.forIn(val._backingStore, (v, k) => {
        if (k === 'entityAspect' || k === 'complexAspect') {
          return;
        }
        if (v != null && (v.hasOwnProperty('entityAspect') /*scalar*/ || v.hasOwnProperty('getEntityAspect')) /*non-scalar*/ && (opts.skipNavigationProperties === true || _.isFunction(opts.skipNavigationProperties) && opts.skipNavigationProperties(k, v))) {
          return;
        }
        const kVal = BreezeService._convertToDto(v, opts);
        if (kVal != null) {
          // Skip if the value is null as it can be a relation that was not been yet loaded
          dto[k] = kVal;
        }
      });
      return dto;
    }
    if (_.isArray(val)) {
      dto = [];
      _.forEach(val, arrItem => {
        const item = BreezeService._convertToDto(arrItem, opts);
        if (item) {
          dto.push(item);
        }
      });
      return dto;
    }
    return val;
  }
}
BreezeService.ɵfac = function BreezeService_Factory(t) {
  return new (t || BreezeService)();
};
BreezeService.ɵprov = /*@__PURE__*/i0.ɵɵdefineInjectable({
  token: BreezeService,
  factory: BreezeService.ɵfac,
  providedIn: 'root'
});