import { BreezeViewService } from '@common/services/breeze-view.service';
import * as i0 from "@angular/core";
import * as i1 from "../../../common/components/content-header/content-header.component";
import * as i2 from "../unbilled-manipulation-list/unbilled-manipulation-list.component";
import * as i3 from "../unbilled-storage-event-list/unbilled-storage-event-list.component";
import * as i4 from "../unbilled-additional-work-list/unbilled-additional-work-list.component";
import * as i5 from "../unbilled-vessel-visit-event-list/unbilled-vessel-visit-event-list.component";
import * as i6 from "@ngx-translate/core";
export class UnbilledEventsReportComponent {
  constructor() {}
}
UnbilledEventsReportComponent.ɵfac = function UnbilledEventsReportComponent_Factory(t) {
  return new (t || UnbilledEventsReportComponent)();
};
UnbilledEventsReportComponent.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
  type: UnbilledEventsReportComponent,
  selectors: [["app-unbilled-events-report"]],
  features: [i0.ɵɵProvidersFeature([BreezeViewService])],
  decls: 19,
  vars: 3,
  consts: [[3, "title"], [1, "row"], [1, "col-6"], [1, "card", "card-border-top", "mt-3"], [1, "card-body"]],
  template: function UnbilledEventsReportComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelement(0, "app-content-header", 0);
      i0.ɵɵpipe(1, "translate");
      i0.ɵɵelementStart(2, "div", 1)(3, "div", 2)(4, "div", 3)(5, "div", 4);
      i0.ɵɵelement(6, "app-unbilled-manipulation-list");
      i0.ɵɵelementEnd()()();
      i0.ɵɵelementStart(7, "div", 2)(8, "div", 3)(9, "div", 4);
      i0.ɵɵelement(10, "app-unbilled-storage-event-list");
      i0.ɵɵelementEnd()()();
      i0.ɵɵelementStart(11, "div", 2)(12, "div", 3)(13, "div", 4);
      i0.ɵɵelement(14, "app-unbilled-additional-work-list");
      i0.ɵɵelementEnd()()();
      i0.ɵɵelementStart(15, "div", 2)(16, "div", 3)(17, "div", 4);
      i0.ɵɵelement(18, "app-unbilled-vessel-visit-event-list");
      i0.ɵɵelementEnd()()()();
    }
    if (rf & 2) {
      i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(1, 1, "Unbilled Events"));
    }
  },
  dependencies: [i1.ContentHeaderComponent, i2.UnbilledManipulationListComponent, i3.UnbilledStorageEventListComponent, i4.UnbilledAdditionalWorkListComponent, i5.UnbilledVesselVisitEventListComponent, i6.TranslatePipe],
  styles: ["\n/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IiIsInNvdXJjZVJvb3QiOiIifQ== */"]
});