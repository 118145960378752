import { HttpClient } from '@angular/common/http';
import { catchError, firstValueFrom, from, ReplaySubject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class CodelistService {
  constructor(http) {
    this.http = http;
    this.cachedCodelists = new Map();
  }
  getCodelist({
    name,
    selectedIds,
    filter,
    take,
    useCache
  }) {
    selectedIds = selectedIds?.filter(Boolean) || []; // In case selectedIds contains falsy values
    const codelistObservable = this.http.post('api:///query/CodeList', {
      name,
      selectedIds,
      filter,
      take
    });
    const ids = selectedIds.toString() || '';
    const cacheKey = `${name}-${ids}-${take}`;
    const resultObservable = this.cachedCodelists.has(cacheKey) && useCache ? this.cachedCodelists.get(cacheKey) : filter ? codelistObservable : this.addToCodelistCache(cacheKey, codelistObservable);
    return firstValueFrom(resultObservable);
  }
  addToCodelistCache(codelistCacheKey, codelistObservable) {
    const newSubject = new ReplaySubject();
    const observable = newSubject.asObservable();
    codelistObservable.pipe(catchError(err => from(console.error(err)))).subscribe(data => newSubject.next(data));
    this.cachedCodelists.set(codelistCacheKey, observable);
    return observable;
  }
}
CodelistService.ɵfac = function CodelistService_Factory(t) {
  return new (t || CodelistService)(i0.ɵɵinject(i1.HttpClient));
};
CodelistService.ɵprov = /*@__PURE__*/i0.ɵɵdefineInjectable({
  token: CodelistService,
  factory: CodelistService.ɵfac,
  providedIn: 'root'
});