import { AutoFilterBreezeListComponent } from '@common/classes/auto-filter-list';
import { AppGridComponent } from '@common/components/app-grid/app-grid.component';
import { BreezeViewService } from '@common/services/breeze-view.service';
import { SharedService } from '@common/services/shared.service';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import * as i0 from "@angular/core";
import * as i1 from "@common/services/breeze-view.service";
import * as i2 from "@common/services/shared.service";
import * as i3 from "@angular/common";
import * as i4 from "@angular/forms";
import * as i5 from "@ng-bootstrap/ng-bootstrap";
import * as i6 from "@fortawesome/angular-fontawesome";
import * as i7 from "@progress/kendo-angular-grid";
import * as i8 from "../../../common/components/action-bar/action-bar.component";
import * as i9 from "../../../common/components/app-control/app-control.component";
import * as i10 from "../../../common/components/app-form/app-form.component";
import * as i11 from "../../../common/components/content-header/content-header.component";
import * as i12 from "../../../common/components/app-grid/app-grid.component";
import * as i13 from "../../../common/components/app-control-container/app-control-container.component";
import * as i14 from "../../../common/components/accordion-header.component";
import * as i15 from "@ngx-translate/core";
function ManuallyChangedEventsReportComponent_ng_template_6_span_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span");
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r4 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate1("\u00A0(", ctx_r4.numberOfActiveFilters, ")");
  }
}
function ManuallyChangedEventsReportComponent_ng_template_6_Template(rf, ctx) {
  if (rf & 1) {
    const _r6 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "app-accordion-header", 23);
    i0.ɵɵlistener("panelChange", function ManuallyChangedEventsReportComponent_ng_template_6_Template_app_accordion_header_panelChange_0_listener() {
      i0.ɵɵrestoreView(_r6);
      const ctx_r5 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r5.handleFilterHighlighter(true));
    });
    i0.ɵɵelementStart(1, "h5");
    i0.ɵɵelement(2, "fa-icon", 24);
    i0.ɵɵtext(3);
    i0.ɵɵpipe(4, "translate");
    i0.ɵɵtemplate(5, ManuallyChangedEventsReportComponent_ng_template_6_span_5_Template, 2, 1, "span", 25);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const panel_r3 = ctx.$implicit;
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("panel", panel_r3);
    i0.ɵɵadvance(3);
    i0.ɵɵtextInterpolate1(" \u00A0", i0.ɵɵpipeBind1(4, 3, "Filters"), " ");
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngIf", ctx_r0.numberOfActiveFilters > 0);
  }
}
function ManuallyChangedEventsReportComponent_ng_template_7_Template(rf, ctx) {
  if (rf & 1) {
    const _r9 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "app-form", 26);
    i0.ɵɵlistener("submit", function ManuallyChangedEventsReportComponent_ng_template_7_Template_app_form_submit_0_listener() {
      i0.ɵɵrestoreView(_r9);
      const ctx_r8 = i0.ɵɵnextContext();
      ctx_r8.search();
      return i0.ɵɵresetView(ctx_r8.onAutoFilterChange());
    });
    i0.ɵɵelementStart(1, "app-control-container", null, 27)(3, "div", 8)(4, "div", 28)(5, "app-control", 29);
    i0.ɵɵlistener("ngModelChange", function ManuallyChangedEventsReportComponent_ng_template_7_Template_app_control_ngModelChange_5_listener($event) {
      i0.ɵɵrestoreView(_r9);
      const ctx_r10 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r10.filter.type = $event);
    });
    i0.ɵɵpipe(6, "translate");
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(7, "div", 28)(8, "app-control", 30);
    i0.ɵɵlistener("ngModelChange", function ManuallyChangedEventsReportComponent_ng_template_7_Template_app_control_ngModelChange_8_listener($event) {
      i0.ɵɵrestoreView(_r9);
      const ctx_r11 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r11.filter.ownerId = $event);
    });
    i0.ɵɵpipe(9, "translate");
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(10, "div", 28)(11, "app-control", 31);
    i0.ɵɵlistener("ngModelChange", function ManuallyChangedEventsReportComponent_ng_template_7_Template_app_control_ngModelChange_11_listener($event) {
      i0.ɵɵrestoreView(_r9);
      const ctx_r12 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r12.filter.serviceDateFrom = $event);
    });
    i0.ɵɵpipe(12, "translate");
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(13, "div", 28)(14, "app-control", 32);
    i0.ɵɵlistener("ngModelChange", function ManuallyChangedEventsReportComponent_ng_template_7_Template_app_control_ngModelChange_14_listener($event) {
      i0.ɵɵrestoreView(_r9);
      const ctx_r13 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r13.filter.serviceDateTo = $event);
    });
    i0.ɵɵpipe(15, "translate");
    i0.ɵɵelementEnd()()();
    i0.ɵɵelementStart(16, "div", 33)(17, "div", 9)(18, "div", 34)(19, "button", 35);
    i0.ɵɵlistener("click", function ManuallyChangedEventsReportComponent_ng_template_7_Template_button_click_19_listener() {
      i0.ɵɵrestoreView(_r9);
      const ctx_r14 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r14.clearFilter());
    });
    i0.ɵɵelement(20, "fa-icon", 36);
    i0.ɵɵtext(21, " \u00A0 ");
    i0.ɵɵelementStart(22, "span", 37);
    i0.ɵɵtext(23);
    i0.ɵɵpipe(24, "translate");
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(25, "button", 38);
    i0.ɵɵlistener("click", function ManuallyChangedEventsReportComponent_ng_template_7_Template_button_click_25_listener() {
      i0.ɵɵrestoreView(_r9);
      const ctx_r15 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r15.search());
    });
    i0.ɵɵelement(26, "fa-icon", 39);
    i0.ɵɵtext(27, " \u00A0 ");
    i0.ɵɵelementStart(28, "span", 37);
    i0.ɵɵtext(29);
    i0.ɵɵpipe(30, "translate");
    i0.ɵɵelementEnd()()()()()()();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance(5);
    i0.ɵɵpropertyInterpolate("label", i0.ɵɵpipeBind1(6, 17, "Type"));
    i0.ɵɵproperty("options", ctx_r1.types)("ngModel", ctx_r1.filter.type)("entity", ctx_r1.filter);
    i0.ɵɵadvance(3);
    i0.ɵɵpropertyInterpolate("label", i0.ɵɵpipeBind1(9, 19, "Owner"));
    i0.ɵɵproperty("ngModel", ctx_r1.filter.ownerId)("entity", ctx_r1.filter);
    i0.ɵɵadvance(3);
    i0.ɵɵpropertyInterpolate("label", i0.ɵɵpipeBind1(12, 21, "Realization Date From"));
    i0.ɵɵproperty("time", true)("ngModel", ctx_r1.filter.serviceDateFrom)("entity", ctx_r1.filter);
    i0.ɵɵadvance(3);
    i0.ɵɵpropertyInterpolate("label", i0.ɵɵpipeBind1(15, 23, "Realization Date To"));
    i0.ɵɵproperty("time", true)("ngModel", ctx_r1.filter.serviceDateTo)("entity", ctx_r1.filter);
    i0.ɵɵadvance(9);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(24, 25, "Clear"));
    i0.ɵɵadvance(6);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(30, 27, "Search"));
  }
}
function ManuallyChangedEventsReportComponent_ng_template_34_span_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span");
    i0.ɵɵtext(1, "...");
    i0.ɵɵelementEnd();
  }
}
function ManuallyChangedEventsReportComponent_ng_template_34_Template(rf, ctx) {
  if (rf & 1) {
    const _r20 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "span", 40, 41);
    i0.ɵɵlistener("click", function ManuallyChangedEventsReportComponent_ng_template_34_Template_span_click_0_listener() {
      i0.ɵɵrestoreView(_r20);
      const _r17 = i0.ɵɵreference(1);
      const ctx_r19 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r19.open(_r17));
    });
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(3, ManuallyChangedEventsReportComponent_ng_template_34_span_3_Template, 2, 0, "span", 25);
  }
  if (rf & 2) {
    const row_r16 = ctx.$implicit;
    i0.ɵɵpropertyInterpolate("ngbTooltip", row_r16.remark);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate1(" ", row_r16.remark.substring(0, 70), " ");
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", row_r16.remark.length > 70);
  }
}
const _c0 = function () {
  return {
    enabled: true,
    checkboxOnly: true
  };
};
export class ManuallyChangedEventsReportComponent extends AutoFilterBreezeListComponent {
  constructor(breezeViewService, sharedService) {
    super(breezeViewService, sharedService);
    this.breezeViewService = breezeViewService;
    this.sharedService = sharedService;
    this.queryName = 'ManuallyChangedEvents';
    this.parentRoute = '/invoice/';
    this.excelCommand = 'ExportManuallyChangedEventsToExcel';
    this.gridSort = [{
      field: 'containerNumber',
      dir: 'asc'
    }, {
      field: 'ownerId',
      dir: 'asc'
    }];
    this.types = ['ContainerVisitManipulation', 'AdditionalWork', 'ContainerVisitStorageEvent'].map(x => ({
      value: x,
      label: this.breezeViewService.translateService.instant(x)
    }));
    this.actionGroup.isVisible = () => false;
  }
  search() {
    this.appGrid.isBusy = true;
    this.handleFilterHighlighter();
    return this.entityManager.executeQuery(this.getQuery()).then(data => {
      this.data = data.results;
    }).finally(() => {
      this.appGrid.isBusy = false;
    });
  }
  getDefaultFilter(data) {
    return this.breezeViewService.entityManager.createEntity('GetManuallyChangedEventsQuery', {
      ...data
    });
  }
  getType(type) {
    return this.breezeViewService.translateService.instant(type);
  }
  open(tooltip) {
    _.each(this.tooltips.toArray(), t => {
      t.close();
    });
    tooltip.open();
  }
}
ManuallyChangedEventsReportComponent.ɵfac = function ManuallyChangedEventsReportComponent_Factory(t) {
  return new (t || ManuallyChangedEventsReportComponent)(i0.ɵɵdirectiveInject(i1.BreezeViewService), i0.ɵɵdirectiveInject(i2.SharedService));
};
ManuallyChangedEventsReportComponent.ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
  type: ManuallyChangedEventsReportComponent,
  selectors: [["app-manually-changed-events-report"]],
  viewQuery: function ManuallyChangedEventsReportComponent_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(AppGridComponent, 5);
      i0.ɵɵviewQuery(NgbTooltip, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.appGrid = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.tooltips = _t);
    }
  },
  features: [i0.ɵɵProvidersFeature([BreezeViewService]), i0.ɵɵInheritDefinitionFeature],
  decls: 35,
  vars: 42,
  consts: [[3, "items"], [3, "title"], [1, "filter-wrapper"], ["activeIds", "ngb-panel-0"], ["ngbPanelHeader", ""], ["ngbPanelContent", ""], [1, "card", "card-border-top", "mt-3"], [1, "card-body"], [1, "row"], [1, "col"], [1, "mb-2", "voyage-summary-table", 3, "pageable", "data", "sortable", "sort", "selectable", "selection", "selectionChange"], ["showSelectAll", "true", 3, "width"], ["field", "containerNumber", 3, "title"], ["field", "ownerId", 3, "title"], ["field", "eventStatus", 3, "title"], ["field", "serviceDate", "format", "g", 3, "title"], ["field", "dateFrom", "format", "g", 3, "title"], ["field", "dateTo", "format", "g", 3, "title"], ["field", "service", 3, "title"], ["field", "type", 3, "title"], ["field", "lastModifiedFullName", 3, "title"], ["field", "remark", 3, "title"], ["kendoGridCellTemplate", ""], [3, "panel", "panelChange"], ["icon", "filter"], [4, "ngIf"], [3, "submit"], ["filterHighlighter", ""], [1, "col-3"], ["type", "codelist", "property", "type", 3, "label", "options", "ngModel", "entity", "ngModelChange"], ["type", "codelist", "codelist", "ShipOwner", "property", "ownerId", 3, "label", "ngModel", "entity", "ngModelChange"], ["property", "serviceDateFrom", 3, "label", "time", "ngModel", "entity", "ngModelChange"], ["property", "serviceDateTo", 3, "label", "time", "ngModel", "entity", "ngModelChange"], [1, "row", "mt-3"], [1, "float-right"], [1, "btn", "btn-sm", "btn-danger", "mr-2", 3, "click"], ["icon", "trash", 1, "ml-1"], [1, "btn-text"], [1, "btn", "btn-sm", "btn-primary", 3, "click"], ["icon", "search", 1, "ml-1"], ["triggers", "manual", "placement", "bottom", 3, "ngbTooltip", "click"], ["tooltip", "ngbTooltip"]],
  template: function ManuallyChangedEventsReportComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelement(0, "app-action-bar", 0)(1, "app-content-header", 1);
      i0.ɵɵpipe(2, "translate");
      i0.ɵɵelementStart(3, "div", 2)(4, "ngb-accordion", 3)(5, "ngb-panel");
      i0.ɵɵtemplate(6, ManuallyChangedEventsReportComponent_ng_template_6_Template, 6, 5, "ng-template", 4);
      i0.ɵɵtemplate(7, ManuallyChangedEventsReportComponent_ng_template_7_Template, 31, 29, "ng-template", 5);
      i0.ɵɵelementEnd()()();
      i0.ɵɵelementStart(8, "div", 6)(9, "div", 7)(10, "div", 8)(11, "div", 9)(12, "app-grid", 10);
      i0.ɵɵlistener("selectionChange", function ManuallyChangedEventsReportComponent_Template_app_grid_selectionChange_12_listener($event) {
        return ctx.selection = $event;
      });
      i0.ɵɵelement(13, "kendo-grid-checkbox-column", 11)(14, "kendo-grid-column", 12);
      i0.ɵɵpipe(15, "translate");
      i0.ɵɵelement(16, "kendo-grid-column", 13);
      i0.ɵɵpipe(17, "translate");
      i0.ɵɵelement(18, "kendo-grid-column", 14);
      i0.ɵɵpipe(19, "translate");
      i0.ɵɵelement(20, "kendo-grid-column", 15);
      i0.ɵɵpipe(21, "translate");
      i0.ɵɵelement(22, "kendo-grid-column", 16);
      i0.ɵɵpipe(23, "translate");
      i0.ɵɵelement(24, "kendo-grid-column", 17);
      i0.ɵɵpipe(25, "translate");
      i0.ɵɵelement(26, "kendo-grid-column", 18);
      i0.ɵɵpipe(27, "translate");
      i0.ɵɵelement(28, "kendo-grid-column", 19);
      i0.ɵɵpipe(29, "translate");
      i0.ɵɵelement(30, "kendo-grid-column", 20);
      i0.ɵɵpipe(31, "translate");
      i0.ɵɵelementStart(32, "kendo-grid-column", 21);
      i0.ɵɵpipe(33, "translate");
      i0.ɵɵtemplate(34, ManuallyChangedEventsReportComponent_ng_template_34_Template, 4, 3, "ng-template", 22);
      i0.ɵɵelementEnd()()()()()();
    }
    if (rf & 2) {
      i0.ɵɵproperty("items", ctx.actionBar);
      i0.ɵɵadvance(1);
      i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(2, 19, "Manually Changed Events"));
      i0.ɵɵadvance(11);
      i0.ɵɵproperty("pageable", false)("data", ctx.data)("sortable", true)("sort", ctx.gridSort)("selectable", i0.ɵɵpureFunction0(41, _c0))("selection", ctx.selection);
      i0.ɵɵadvance(1);
      i0.ɵɵproperty("width", 1);
      i0.ɵɵadvance(1);
      i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(15, 21, "Container Number"));
      i0.ɵɵadvance(2);
      i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(17, 23, "Owner"));
      i0.ɵɵadvance(2);
      i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(19, 25, "Status"));
      i0.ɵɵadvance(2);
      i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(21, 27, "Service Date"));
      i0.ɵɵadvance(2);
      i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(23, 29, "Date From"));
      i0.ɵɵadvance(2);
      i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(25, 31, "Date To"));
      i0.ɵɵadvance(2);
      i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(27, 33, "Service"));
      i0.ɵɵadvance(2);
      i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(29, 35, "Type"));
      i0.ɵɵadvance(2);
      i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(31, 37, "Last Modified By"));
      i0.ɵɵadvance(2);
      i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(33, 39, "Remark"));
    }
  },
  dependencies: [i3.NgIf, i4.NgControlStatus, i4.NgModel, i5.NgbAccordion, i5.NgbPanel, i5.NgbPanelContent, i5.NgbPanelHeader, i5.NgbTooltip, i6.FaIconComponent, i7.ColumnComponent, i7.CheckboxColumnComponent, i7.CellTemplateDirective, i8.ActionBarComponent, i9.AppControlComponent, i10.AppFormComponent, i11.ContentHeaderComponent, i12.AppGridComponent, i13.AppControlContainerComponent, i14.AccordionHeaderComponent, i15.TranslatePipe],
  styles: ["\n/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IiIsInNvdXJjZVJvb3QiOiIifQ== */"]
});